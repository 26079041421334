import { useEffect, useState } from 'react';
import { BundleRelease } from '../../../api/engineering/domain/types';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { useBundleConfigurationVersion } from '../../bundles/hooks/useBundleConfigurationVersion';

export function useBundleConfigurationSelection(
  projectId: string,
  bundleId: string,
  selection: BundleConfigurationSelection
): { isLoading: boolean; release?: BundleRelease } {
  const validateId = (id: string): boolean => {
    if (id === '') return false;
    if (id === '-1') return false;
    const res = parseInt(id, 10);
    return res > -1;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [release, setRelease] = useState<BundleRelease | undefined>(undefined);

  const bundleReleaseA = useBundleConfigurationVersion(projectId, bundleId, selection.bundleConfigurationId, selection.bundleConfigurationVersionId);

  useEffect(() => {
    const idsAreValid =
      validateId(projectId) && validateId(bundleId) && validateId(selection.bundleConfigurationId) && validateId(selection.bundleConfigurationVersionId);
    if (!idsAreValid) {
      setIsLoading(false);
      setRelease(undefined);
    } else {
      setIsLoading(true);
    }
  }, [projectId, bundleId, selection.bundleConfigurationId, selection.bundleConfigurationVersionId]);

  useEffect(() => {
    if (bundleReleaseA.isSuccess) {
      setIsLoading(false);
      setRelease(bundleReleaseA.data);
    }
    if (bundleReleaseA.isError) {
      setIsLoading(false);
      setRelease(undefined);
    }
  }, [bundleReleaseA.data, bundleReleaseA.isSuccess, bundleReleaseA.isError]);

  return { isLoading, release };
}
