import { FlatifiedResult } from '../hooks/useFlatifiedReports';
import { ColumnSelectData } from './ColumnSelectData';
import { ColumnSelectButton } from './ColumnSelectButton';

interface ColumnSelectProps {
  data?: FlatifiedResult;
  loading: boolean;
  onSelect: (val: string[]) => void;
  projectId: number;
  sourceId: string;
  localStorageKey: string;
  disabled?: boolean;
}

export const ColumnSelect: React.FC<ColumnSelectProps> = (props) => {
  const hasColumns = props.data && props.data.columns.length > 0;
  const canColumnSelect = !props.loading && hasColumns;
  return canColumnSelect ? <ColumnSelectData {...props} data={props.data!} /> : <ColumnSelectButton loading={props.loading} disabled={props.disabled} />;
};
