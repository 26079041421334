import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { ColumnsWithMvccId } from '../../../api/report/domain/types';
import { presentSuccess } from '../../../api';

export const useUpsertFolderColumns = (projectId: number, sourceId: string, folderId: string) => {
  const { backend } = useReportBackend();
  const queryClient = useQueryClient();

  return useMutation<void, any, ColumnsWithMvccId>(async (columns) => {
    try {
      await backend.upsertFolderColumns(projectId, sourceId, folderId, columns);
      queryClient.invalidateQueries(['projectSources', projectId]);
      queryClient.invalidateQueries(['computedFolderColumns', projectId, folderId, sourceId]);

      presentSuccess('Successfully saved default columns');
    } catch (error) {
      /* nothing to do here */
    }
  });
};
