export const formatDateTime = (d: Date): string => {
  // in case of migrated timestamps, return -
  if (d.getTime() === 0) return '-';

  return d.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
};
export const formatDate = (d: Date): string => {
  return d.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
};

export const getEstimatedPeriod = (inputDate: string) => {
  // Parse the input date
  const dateParts = inputDate.split('.');
  const day = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript
  const year = 2000 + parseInt(dateParts[2]); // Assuming the year is in the format YY, so adding 2000

  // Create Date objects for today and the input date
  const today = new Date();
  const inputDateObj = new Date(year, month, day);

  // Calculate the time difference in milliseconds
  const timeDiff = inputDateObj.getTime() - today.getTime();

  // Convert milliseconds to days
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Determine the estimated period
  if (daysDiff < 30) {
    return 'Less than 1 month';
  } else if (daysDiff < 365) {
    const months = Math.floor(daysDiff / 30);
    return `${months === 12 ? '1 year' : months + ' months'}`;
  } else {
    const years = Math.floor(daysDiff / 365);
    return `${years} year${years > 1 ? 's' : ''}`;
  }
};
