import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';

import { ExpandableMenuItem } from './ExpandableMenuItem';

export type ConfirmationDeleteButtonProps = {
  onOk: () => any;
  title?: string;
  caption?: string;
  description?: string;
  disabled?: boolean;
  loading?: boolean;
  outlined?: boolean;
  /** Whether the expandable menu item should have a danger color. Should be used for delete actions.
   * @default false
   */
  danger?: boolean;
  /** Size of the confirmation button. */
  size?: SizeType;
  okText?: string;
  children?: any;
  /**
   * Defines whether the button is used in a dropdown menu or not.
   * @default "expandable-menu"
   */
  buttonType?: 'regular' | 'expandable-menu';
  /**
   * If `buttonType="regular"`, determines the type of the button.
   * Reference: https://ant.design/components/button#components-button-demo-basic
   * @default "text"
   */
  regularButtonType?: 'primary' | 'dashed' | 'link' | 'text' | 'default';
  /**
   * When true it does not show any icon. Used for a non delete confirmation
   */
  noIcon?: boolean;
};

/**
 * A button that opens a modal asking for confirmation on deleting something.
 *
 * ### Regular Button Example
 * You can use `buttonType="regular"` to get a regular button, for which
 * you can pick the Ant Design button type with the `regularButtonType` prop.
 *
 * ```jsx
 * <ConfirmationButton
 *  buttonType="regular"
 *  regularButtonType="default"
 * />
 * ```
 */
export const ConfirmationButton = (props: ConfirmationDeleteButtonProps) => {
  const [modal, contextHolder] = Modal.useModal();

  const confirm = () => {
    modal.confirm({
      title: props.title ?? 'Are you sure you want to delete? This cannot be undone.',
      content: props.description ? (
        <>
          <br />
          <Typography.Text type="warning">{props.description}</Typography.Text>
        </>
      ) : null,
      okButtonProps: {
        danger: props.danger !== undefined ? props.danger : true
      },
      okText: props.okText ?? 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        props.onOk();
      }
    });
  };

  return (
    <>
      {props.buttonType === 'regular' ? (
        <Button
          {...props} // required so that tooltips work
          icon={!props.noIcon && <DeleteOutlined />}
          type={props.regularButtonType ?? 'text'}
          onClick={confirm}
        >
          {props.children}
        </Button>
      ) : (
        <ExpandableMenuItem
          loading={props.loading}
          disabled={props.disabled}
          danger={props.danger}
          icon={<DeleteOutlined />}
          onClick={() => confirm()}
          buttonMode={props.outlined}
        >
          {props.caption}
        </ExpandableMenuItem>
      )}

      {contextHolder}
    </>
  );
};
