import { RevisionMeta, SourceMeta, Document } from '../../../api/report/domain/types';
import { Project, SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../api/engineering/domain/types';
import { MetricTag, METRIC_TAGS } from '../../../api/metrics/domain/types';
import { SoftwareComponent } from '../../../api';
import { documentId } from '../../../contexts/reports/shared/util';

export type RawTagType = {
  component?: string;
  componentId?: string;
  componentVersion?: string;
  componentVersionId?: string;
  subComponent?: string;
  subComponentId?: string;
  context?: string;
  contextId?: string;
  componentType?: 'tool' | 'app' | 'report';
  componentScope?: 'project' | 'common';
};

export const rawToTags = (data: RawTagType): MetricTag[] => {
  const res: MetricTag[] = [];

  if (data.component) {
    res.push({
      key: METRIC_TAGS.component,
      value: data.component
    });
  }
  if (data.componentId) {
    res.push({
      key: METRIC_TAGS.componentId,
      value: data.componentId
    });
  }
  if (data.componentVersion) {
    res.push({
      key: METRIC_TAGS.componentVersion,
      value: data.componentVersion
    });
  }
  if (data.componentVersionId) {
    res.push({
      key: METRIC_TAGS.componentVersionId,
      value: data.componentVersionId
    });
  }
  if (data.subComponent) {
    res.push({
      key: METRIC_TAGS.subComponent,
      value: data.subComponent
    });
  }
  if (data.subComponentId) {
    res.push({
      key: METRIC_TAGS.subComponentId,
      value: data.subComponentId
    });
  }
  if (data.context) {
    res.push({
      key: METRIC_TAGS.context,
      value: data.context
    });
  }
  if (data.contextId) {
    res.push({
      key: METRIC_TAGS.contextId,
      value: data.contextId
    });
  }
  if (data.componentType) {
    res.push({
      key: METRIC_TAGS.componentType,
      value: data.componentType
    });
  }
  if (data.componentScope) {
    res.push({
      key: METRIC_TAGS.componentScope,
      value: data.componentScope
    });
  }
  return res;
};

export const toolToTags = (tool: Tool): MetricTag[] => {
  return rawToTags({ component: tool.name, componentId: tool.id.toString() });
};

export const toolVersionToTags = (tool: ToolVersion): MetricTag[] => {
  return rawToTags({ componentVersion: tool.version, componentVersionId: tool.idToolVersion.toString() });
};

export const appToTags = (app: SoftwareApp): MetricTag[] => {
  return rawToTags({ component: app.name, componentId: app.idSoftwareApp.toString() });
};

export const appVersionToTags = (version: SoftwareAppVersion): MetricTag[] => {
  return rawToTags({ componentVersion: version.version, componentVersionId: version.idSoftwareAppVersion.toString() });
};

export const projectToTags = (project: Project): MetricTag[] => {
  return rawToTags({ context: project.name, contextId: project.idProject.toString() });
};

export const componentToTags = (component: SoftwareComponent): MetricTag[] => {
  return rawToTags({
    component: component.name,
    componentId: component.id.toString(),
    componentVersion: component.version,
    componentVersionId: component.versionId.toString(),
    componentScope: component.scope as unknown as 'project' | 'common',
    componentType: component.type as unknown as 'tool' | 'app'
  });
};

export const reportMetaToTags = (source: SourceMeta, revision: RevisionMeta): MetricTag[] => {
  return rawToTags({
    component: source.displayName,
    componentId: source.id,
    componentVersion: revision.revision.toString(),
    componentVersionId: revision.revision.toString()
  });
};
export const reportDocumentToTags = (document: Document): MetricTag[] => {
  return rawToTags({
    subComponent: document.meta.name,
    subComponentId: documentId(document)
  });
};

export const scopeTag = (scope: 'project' | 'common') => {
  return rawToTags({ componentScope: scope });
};

export const typeTag = (type: 'app' | 'tool' | 'report') => {
  return rawToTags({ componentType: type });
};
