import { Button, Tooltip } from 'antd';
import { Document, RevisionMeta, SourceMeta } from '../../../api/report/domain/types';
import { LinkExternal } from '../../shared/components/icons/LinkExternal';
import { documentId, reportLink } from '../shared/util';
import { DownloadOutlined } from '@ant-design/icons';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { Project } from '../../../api';
import { MetricClickIds } from '../../../contexts/metrics/constants';

export const OpenReportButton = (props: { project: Project; source: SourceMeta; revision: RevisionMeta; document: Document; mode: 'web' | 'download' }) => {
  const id = documentId(props.document);
  const metrics = useSubmitMetric();
  const operationId = props.mode === 'web' ? MetricClickIds.reportOpen : MetricClickIds.reportDownload;
  return (
    <Tooltip title={props.mode === 'web' ? 'Open Report' : 'Download Report'}>
      <Button
        // TODO: fix external id
        type="text"
        href={reportLink({
          projectId: props.project.idProject.toString(),
          reportId: id,
          revision: props.revision.revision.toString(),
          sourceId: props.source.id,
          mode: props.mode
        })}
        onClick={() => {
          metrics.submitClick({ operationId }).withReportDocumentContext(props.source, props.revision, props.document, props.project);
        }}
        target="_blank"
        icon={props.mode === 'web' ? <LinkExternal /> : <DownloadOutlined />}
      />
    </Tooltip>
  );
};
