import { useCallback, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { EngineeringToolSelection } from '../../../domain';
import { ComparisonResult } from '../../comparison';
import { useEngineeringTools } from '../hooks/useEngineeringTools';
import { EngineeringToolVersionComparisonResult, engineeringToolVersionComparisonResultValue } from './EngineeringToolVersionComparisonResult';
import { Comparator } from '../../../domain/extensions/comparison';
import { MarkdownPreview } from '../../shared/components/MarkdownPreview';
import { Project, Tool } from '../../../api/engineering/domain/types';
import Table from '../../../contexts/shared/components/Table/Table';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { projectToTags } from '../../../contexts/metrics/utils/mapping';

export type EngineeringToolsCompareListProps = {
  project?: Project;
  selectedToolsRight?: EngineeringToolSelection[];
  selectedToolsLeft?: EngineeringToolSelection[];
  selectedTitleRight: string;
  selectedTitleLeft: string;
  loading?: boolean;
};

const findTool = (tool: Tool, selection?: EngineeringToolSelection[]) => {
  return selection?.find((t) => tool.id === t.engineeringTool.id);
};

export const EngineeringToolsCompareList = (props: EngineeringToolsCompareListProps) => {
  const tools = useEngineeringTools();
  const [filteredTools, setFilteredTools] = useState([]);
  const [loading, setLoading] = useState(true);

  const isLoading = tools.isLoading || props.selectedToolsLeft === undefined || props.selectedToolsRight === undefined || props.loading;
  const isSuccess = tools.isSuccess && !isLoading;

  const sort = useCallback(
    (a: any, b: any) => {
      const aaApp = findTool(a, props.selectedToolsRight);
      const abApp = findTool(a, props.selectedToolsLeft);
      const aComparisonResult = engineeringToolVersionComparisonResultValue(aaApp?.version, abApp?.version);

      const baApp = findTool(b, props.selectedToolsRight);
      const bbApp = findTool(b, props.selectedToolsLeft);
      const bComparisonResult = engineeringToolVersionComparisonResultValue(baApp?.version, bbApp?.version);

      return aComparisonResult - bComparisonResult;
    },
    [props.selectedToolsLeft, props.selectedToolsRight]
  );

  const filterTools = useCallback(
    () =>
      new Promise((resolve) => {
        const filteredTool = tools.data?.filter(
          (t) => props.selectedToolsRight?.find((tA) => tA.engineeringTool.id === t.id) || props.selectedToolsLeft?.find((tB) => tB.engineeringTool.id === t.id)
        );
        resolve(filteredTool);
      }),
    [props.selectedToolsLeft, props.selectedToolsRight, tools.data]
  );

  useEffect(() => {
    setLoading(true);
    filterTools().then((data: any) => {
      setFilteredTools(data);
      setLoading(false);
    });
  }, [filterTools]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: (a: Tool, b: Tool) => Comparator.lexicographicalComparison(a.name, b.name)
    },
    {
      title: 'Description',
      key: 'description',
      width: 400,
      render: (t: Tool) => <MarkdownPreview title="Description" content={t.description || ''} />
    },
    {
      title: 'Status',
      key: 'status',
      filters: [
        {
          text: 'Hide Equal Items',
          value: ComparisonResult.EQUAL
        }
      ],
      onFilter: (value: any, record: Tool) => {
        const aApp = findTool(record, props.selectedToolsRight);
        const bApp = findTool(record, props.selectedToolsLeft);
        if (aApp?.version.idToolVersion === bApp?.version.idToolVersion) {
          return false;
        }
        return true;
      },
      sortDirections: ['descend' as any],
      defaultSortOrder: 'descend' as any,
      sorter: (a: any, b: any) => {
        return sort(a, b);
      },
      render: (swa: Tool) => {
        const aApp = findTool(swa, props.selectedToolsRight);
        const bApp = findTool(swa, props.selectedToolsLeft);
        return <EngineeringToolVersionComparisonResult a={aApp?.version} b={bApp?.version} />;
      },
      width: 200
    },
    {
      title: props.selectedTitleLeft,
      key: 'versionA',
      render: (swa: Tool) => {
        const bApp = findTool(swa, props.selectedToolsLeft);
        return bApp?.version?.version || '--';
      },
      width: 200
    },
    {
      title: props.selectedTitleRight,
      key: 'versionB',
      render: (swa: Tool) => {
        const aApp = findTool(swa, props.selectedToolsRight);
        return aApp?.version?.version || '--';
      },
      width: 200
    }
  ];

  let table = null;
  if (!loading) {
    table = <Table scroll={{ x: true }} columns={columns} rowKey={(record: Tool) => record.id?.toString() || ''} dataSource={filteredTools} />;
  }
  return (
    <div>
      <MetricOnVisible view={MetricViewIds.toolsCompareList} payload={props.project ? projectToTags(props.project) : undefined} />
      {isLoading && <Skeleton active />}
      {isSuccess && <div>{table}</div>}
    </div>
  );
};
