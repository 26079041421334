import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectPhase } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateProjectPhase() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectPhase, any, [ProjectPhase]>(([create]) => {
    return new Promise<ProjectPhase>((resolve, reject) => {
      backend
        .updateProjectPhase(create)
        .then((dev) => {
          presentSuccess(`Successfully updated Project Phase ${create.name}`);
          queryClient.invalidateQueries(['projectPhases']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
