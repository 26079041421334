import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Card, Flex, Skeleton, Tag, Typography } from 'antd';
import { useDeploymentPlans } from '../hooks/useDeploymentPlans';
import { formatDateTime, ExpandableMenu, ExpandableMenuItem, ConfirmationButton, StopEventPropagationWrapper } from '../../shared/components';
import styled from 'styled-components';
import { Environment } from '../../../api';

interface EnvironmentCardProps {
  env: Environment;
  projectId: number;
  canEdit: boolean;
  canDelete: boolean;
  onNavigateToDeployments: (id: string) => void;
  onUpdateEnvironment: (env: Environment) => void;
  onConfirmDelete: () => void;
}

const FullWidthCard = styled(Card)`
  width: 380px;
`;

const LoadingTag = styled(Skeleton.Input)`
  margin-right: 4px;
`;

const DescriptionItemWrapper = styled.div`
  max-width: 50%;
`;

const EnvironmentCard: React.FC<EnvironmentCardProps> = ({
  env,
  onNavigateToDeployments,
  onUpdateEnvironment,
  onConfirmDelete,
  projectId,
  canEdit,
  canDelete
}) => {
  const deploymentPlans = useDeploymentPlans(projectId, env.id);
  const nullSafePlantList = deploymentPlans.data || [];
  const activePlans =
    nullSafePlantList
      .filter((plan) => !plan.locked)
      .sort((a, b) => {
        return Date.parse(b.createdAt!) - Date.parse(a.createdAt!);
      }) || [];
  const activePlan = activePlans?.[0];

  const tagComponent = (isInstalled: boolean, activePlanExists: boolean) => {
    if (isInstalled) {
      return <Tag color="success">All installed</Tag>;
    } else if (!activePlanExists) {
      return <Tag>No active deployment</Tag>;
    } else {
      return <Tag color="warning">Pending installations</Tag>;
    }
  };

  const isLoading = deploymentPlans.isLoading;

  const DescriptionItem = (diProps: { text: string | undefined }) => (
    <DescriptionItemWrapper>
      <Typography.Paragraph ellipsis={{ tooltip: diProps.text || '-' }} strong>
        {diProps.text || '-'}
      </Typography.Paragraph>
    </DescriptionItemWrapper>
  );

  return (
    <>
      <FullWidthCard
        id={`env-card-${env.id}`}
        size="small"
        loading={isLoading}
        hoverable
        onClick={() => onNavigateToDeployments(env.id)}
        title={env.name}
        extra={
          <StopEventPropagationWrapper>
            {isLoading ? <LoadingTag size="small" active /> : tagComponent(activePlan?.fullyInstalled, activePlan != null)}
            <ExpandableMenu id={`update-deployment-environment-${env.id}`} icon={<MoreOutlined />} disabled={isLoading}>
              {canEdit ? (
                <ExpandableMenuItem
                  icon={<EditOutlined />}
                  disabled={isLoading}
                  onClick={() => {
                    onUpdateEnvironment(env);
                  }}
                >
                  Edit
                </ExpandableMenuItem>
              ) : null}
              {canDelete ? (
                <ConfirmationButton
                  danger
                  disabled={isLoading}
                  title={`Are you sure you want to delete the ${env.name} environment?`}
                  caption="Delete"
                  description="All deployment plans associated with this environment will also be deleted."
                  onOk={onConfirmDelete}
                />
              ) : null}
            </ExpandableMenu>
          </StopEventPropagationWrapper>
        }
      >
        <Flex justify="space-between">
          <Typography.Paragraph>Configuration version</Typography.Paragraph>
          <DescriptionItem text={activePlan?.release?.releaseName} />
        </Flex>
        <Flex justify="space-between">
          <Typography.Paragraph>Configuration name</Typography.Paragraph>
          <DescriptionItem text={activePlan?.release.configName} />
        </Flex>
        <Flex justify="space-between">
          <Typography.Paragraph>Deployment name</Typography.Paragraph>
          <DescriptionItem text={activePlan?.name} />
        </Flex>
        <Flex justify="space-between">
          <Typography.Paragraph>Deployment creation</Typography.Paragraph>
          <DescriptionItem text={activePlan?.createdAt ? formatDateTime(new Date(activePlan?.createdAt)) : undefined} />
        </Flex>
        <Flex justify="space-between">
          <Typography.Paragraph>Deployment creator</Typography.Paragraph>
          <DescriptionItem text={activePlan?.createdByName} />
        </Flex>
      </FullWidthCard>
    </>
  );
};

export default EnvironmentCard;
