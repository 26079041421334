import { SoftwareComponent } from '../../../api';
import { useProjectSoftwareApps } from '../hooks/useProjectSoftwareapps';
import { useProjectSoftwareAppVersions } from '../hooks/useProjectSoftwareappVersions';
import { SoftwareAppVersionPreviewContent } from './SoftwareAppVersionPreviewContent';

export const ProjectSoftwareAppVersionContentProvider = (props: { projectId: string; component: SoftwareComponent }) => {
  const apps = useProjectSoftwareApps(props.projectId);
  const appVersions = useProjectSoftwareAppVersions(props.projectId, props.component.id.toString());
  const app = apps.data?.find((a) => a.idSoftwareApp === props.component.id);
  const appVersion = appVersions.data?.find((a) => a.idSoftwareAppVersion === props.component.versionId);
  const isLoading = apps.isLoading || appVersions.isLoading;

  return <SoftwareAppVersionPreviewContent isLoading={isLoading} app={app} version={appVersion} />;
};
