import { useEffect } from 'react';
import { useUserBackend, useSetInitialUserBackend } from './useUserBackend';
import { useRestUserBackend } from './useRestUserBackend';
import { presentApiError } from '../../shared/errorPresenter';
import { GlobalState } from '../../../state/globalState';

export const useRestUserBackendProvider = (state: GlobalState) => {
  const backend = useRestUserBackend(state);
  useSetInitialUserBackend(backend);
  const { setBackend } = useUserBackend();
  useEffect(() => {
    backend.onError(presentApiError);
    setBackend(backend);
  }, [backend, setBackend]);
};
