import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { PacTSNotification } from '../../../api/notifications/domain/types';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useArchiveNotificationDebounced } from '../hooks/useNotifications';

export const ArchiveNotification = (props: { notification: PacTSNotification }) => {
  const archive = useArchiveNotificationDebounced();
  const permissions = usePermissions();
  const hasPermission = permissions.notificationSvc$userArchiveNotificationsRequest;
  return (
    <Tooltip title="Clear">
      <Button
        type="text"
        size="small"
        disabled={!hasPermission}
        onClick={() => {
          archive(props.notification.id);
        }}
        icon={<CloseOutlined />}
      />
    </Tooltip>
  );
};
