import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

/* eslint-disable max-len */

type IconProps = {
    style?: React.CSSProperties;
    className?: string;
};

export const ReportIcon = (props: IconProps) => {
    const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
        <svg width="1em" height="1em" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.2797 5.26406L11.2359 0.220313C11.0953 0.0796875 10.9055 0 10.7062 0H0.75C0.335156 0 0 0.335156 0 0.75V20.25C0 20.6648 0.335156 21 0.75 21H15.75C16.1648 21 16.5 20.6648 16.5 20.25V5.79609C16.5 5.59687 16.4203 5.40469 16.2797 5.26406ZM14.7703 6.14062H10.3594V1.72969L14.7703 6.14062ZM14.8125 19.3125H1.6875V1.6875H8.76562V6.75C8.76562 7.01107 8.86934 7.26145 9.05394 7.44606C9.23855 7.63066 9.48893 7.73438 9.75 7.73438H14.8125V19.3125Z" fill="currentColor" />
            <path d="M7.53814 8.78666C7.48854 8.7869 7.44105 8.80671 7.40598 8.84178C7.37092 8.87685 7.35111 8.92434 7.35086 8.97393L7.35252 14.0634H5.53945C5.50448 14.0635 5.47022 14.0733 5.4406 14.0919C5.41097 14.1105 5.38719 14.137 5.37196 14.1685C5.35674 14.2 5.3507 14.2352 5.35453 14.2699C5.35836 14.3047 5.3719 14.3377 5.39361 14.3651L8.13807 17.8884C8.21431 17.9846 8.35683 17.9846 8.43307 17.8885L11.1792 14.3634C11.2753 14.2408 11.1875 14.0634 11.0333 14.0618H9.22028L9.22028 8.97062C9.22028 8.86786 9.13576 8.78334 9.033 8.78334L7.53814 8.78666Z" fill="currentColor" />
        </svg>
    );

    return <Icon {...props} component={svg} />;
};
