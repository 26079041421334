import { Table } from 'antd';
import styled from 'styled-components';

export default styled(Table)`
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: ${({ theme }) => theme.fontSizeMD}px !important;
  }
  .ant-table-row, .ant-table-cell {
    background: ${({ theme }) => theme.colorBgElevated};
  }
  .ant-table-column-sort.ant-table-cell-row-hover {
    background: ${({ theme }) => theme.Table?.rowHoverBg} !important;
  }
` as typeof Table;
