import { CenteredLayout } from '../../layout';
import { Segmented } from '../../../contexts/shared/components/Segmented/Segmented.styled';
import { Space, Typography } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { AllArtifactResources } from './AllArtifactResources';
import { AllMembershipResources } from './AllMembershipResources';
import styled from 'styled-components';
import { AllProjectResources } from './AllProjectResources';
import { SyncProjectRolesButton } from './SyncProjectRolesButton';
import { useSearchParameter } from '../../../contexts/navigation/hooks';

export enum SegmentedTabsEnum {
  Projects = 'projects',
  Memberships = 'memberships',
  Artifacts = 'artifacts'
}

const TabLabel = styled(Typography.Text)`
  margin: 0;
  padding: 0;
`;

const LabelContainer = styled.div`
  max-width: 164px;
  margin-bottom: 4px;
`;

const AllResources = () => {
  const [selectedTabRaw, setSelectedTab] = useSearchParameter('tab', SegmentedTabsEnum.Projects, true, false);
  const selectedTab = selectedTabRaw as SegmentedTabsEnum;

  const handleTabChange = (newValue: SegmentedValue) => {
    setSelectedTab(newValue as SegmentedTabsEnum);
  };

  const contentBySelectedTab = {
    [SegmentedTabsEnum.Projects]: <AllProjectResources />,
    [SegmentedTabsEnum.Memberships]: <AllMembershipResources />,
    [SegmentedTabsEnum.Artifacts]: <AllArtifactResources />
  };

  return (
    <CenteredLayout title="PacTS Formation Resources" extra={[<SyncProjectRolesButton key="sync-project-roles" />]}>
      <Segmented
        onChange={handleTabChange}
        value={selectedTab}
        options={[
          {
            label: (
              <Space size={0}>
                <LabelContainer>
                  <TabLabel ellipsis>Projects</TabLabel>
                </LabelContainer>
              </Space>
            ),
            value: SegmentedTabsEnum.Projects
          },
          {
            label: (
              <Space size={0}>
                <LabelContainer>
                  <TabLabel ellipsis>Artifacts</TabLabel>
                </LabelContainer>
              </Space>
            ),
            value: SegmentedTabsEnum.Artifacts
          },
          {
            label: (
              <Space size={0}>
                <LabelContainer>
                  <TabLabel ellipsis>Memberships</TabLabel>
                </LabelContainer>
              </Space>
            ),
            value: SegmentedTabsEnum.Memberships
          }
        ]}
      />
      {contentBySelectedTab[selectedTab]}
    </CenteredLayout>
  );
};

export default AllResources;
