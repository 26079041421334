import { AxiosError } from 'axios';
import { ApiError } from './apiError';
import { invokeUiNotification } from '../../contexts/shared/hooks/userNotificationProvider';

// TODO: Error 403 -> logout

const isElectron = typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0;

// TODO: check which error handler is actually used
const skipMessage = (err: AxiosError): boolean => {
  const responseCode = err.response?.status || -1;
  // TODO: refactor error messages
  if (isElectron) {
    return true;
  }
  // skip ui message of 404 errors until we are sure all race conditions are solved
  if (responseCode === 404) {
    return true;
  }
  return false;
};

const extractMessage = (err: AxiosError): string => {
  const responseCode = err.response?.status || -1;
  try {
    if (responseCode === 500) {
      return 'Internal server error';
    }
    if (responseCode === 404) {
      return 'The resource was not found';
    }
    if (responseCode === 405) {
      return 'Not allowed, resource referenced';
    }
    if (responseCode === 403) {
      return 'Insufficient permissions to perform this action';
    }
    if (err.response) {
      if (err.response.data) {
        if ((err.response.data as any).message) {
          return (err.response.data as any).message;
        }
      }
      if (typeof err.response.data === 'string') {
        return err.response.data;
      }
      return JSON.stringify(err.response.data);
    }
    return `${err.name}: ${err.message}`;
  } catch (error) {
    return JSON.stringify(err);
  }
};

const errorMessageMapping = (err: AxiosError): string => {
  let message = extractMessage(err);
  if (message === '') {
    message = 'Unexpected Error';
  }
  return message;
};

export const presentAxiosError = (err: AxiosError) => {
  console.warn('API Request Error', err, JSON.stringify(err));
  if (skipMessage(err)) return;
  invokeUiNotification({
    type: 'error',
    message: 'Something went wrong',
    key: `${err.name}: ${err.message}`,
    description: errorMessageMapping(err)
  });
};

const apiErrorMessageMapping = (err: ApiError): string => {
  // If message is not default error message
  if(!err.message.includes("Request failed with status code")) {
    return err.message;
  }
  
  const responseCode = err.statusCode;
  if (responseCode === 500) {
    return 'Internal server error';
  }
  if (responseCode === 404) {
    return 'Resource not found';
  }
  if (responseCode === 409) {
    return 'Not allowed, conflicting resource exists';
  }
  if (responseCode === 405) {
    return 'Not allowed, resource referenced or out of date. Try refreshing the page.';
  }
  if (responseCode === 403) {
    return 'Insufficient permissions to perform this action';
  }
  return '';
};

const suppressError = (err: Error): boolean => {
  const message = err.message.toLowerCase();

  // Ignore token refresh exception
  if (message.includes('token refresh failed')) return true;

  // Ignore session expired
  if (message.includes('session expired')) return true;

  // Ignore timeout exceptions, do not report back to the user
  if (/^timeout of \d+ms exceeded/.test(message)) return true;
  return false;
};

export const presentApiError = (err: ApiError) => {
  console.warn('API Request Error', err, JSON.stringify(err));

  if (suppressError(err)) return;

  const mappedDetails = err.reasons.map((r) => `${r.path}: ${r.message} (${r.value})`).join(', ');
  const message = mappedDetails ||  apiErrorMessageMapping(err) || err.name;

  if (isElectron) {
    return;
  }

  invokeUiNotification({
    type: 'error',
    message: 'Something went wrong',
    key: `${err.name}: ${message}`,
    description: message
  });
};

export const presentAppError = (err: Error) => {
  console.warn('Request Error', err);
  invokeUiNotification({
    type: 'error',
    message: 'Something went wrong',
    key: `${err.name}: ${err.message}`,
    description: `${err.name}: ${err.message}`
  });
};
