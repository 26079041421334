import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { ProjectRoleWithPermissions, ProjectRoleCreate } from '../../../api/engineering/domain/types';

export function useCreateProjectRole() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectRoleWithPermissions, any, [ProjectRoleCreate]>(([role]) => {
    return new Promise<ProjectRoleWithPermissions>((resolve, reject) => {
      backend
        .createProjectRole(role)
        .then((newRoles) => {
          presentSuccess(`Successfully created Project Role ${role.name}`);
          queryClient.invalidateQueries(['projectRoles']);
          return resolve(newRoles);
        })
        .catch(reject);
    });
  });
}

export default useCreateProjectRole;
