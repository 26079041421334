import { useSession } from './useSession';
import { UserInfo } from '../../../api/user/domain/users';
import { useContext } from 'react';
import { PacTSContext } from '../../../state/store';

const emptyUser: UserInfo = {
  id: -1,
  name: '',
  email: '',
  gid: '',
  status: 'initial',
  permissions: {}
};

export const useCurrentUser = () => {
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  return session.userInfo ?? emptyUser;
};
