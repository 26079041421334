import { Select as AntSelect, Tag } from 'antd';
import type { SelectProps } from 'antd';

import { User, UserRole } from '../../../api/user/domain/users';
import { useUpdateUserRolesOfUser } from '../hooks/useUpdateUserRolesOfUser';
import { useUserRoles } from '../hooks/useUserRoles';
import { useUserRolesOfUser } from '../hooks/useUserRolesOfUser';
import styled from 'styled-components';
import { Comparator } from '../../../domain/extensions/comparison';

const { Option } = AntSelect;

export type UserRolesDropdownProps = {
  user: User;
};

const Select = styled(AntSelect)`
  width: 100%;
` as typeof AntSelect;

const tagRender: SelectProps['tagRender'] = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isAdmin = label && label.toString().toLowerCase().includes('admin');

  return (
    <Tag color={isAdmin ? 'error' : undefined} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginInlineEnd: 4 }}>
      {label}
    </Tag>
  );
};

export const UserRolesDropdown = (props: UserRolesDropdownProps) => {
  const userRoles = useUserRoles();
  const userRolesOfUser = useUserRolesOfUser(props.user);
  const setUserRolesOfUser = useUpdateUserRolesOfUser();

  const isLoading = userRoles.isLoading || userRolesOfUser.isLoading || setUserRolesOfUser.isLoading;

  const onChange = (selectedRoleIds: string[]) => {
    const selectedRoles = selectedRoleIds.map((sId) => userRoles.data?.find((pr) => pr.id === parseInt(sId, 10))).filter((r) => r !== undefined) as UserRole[];

    setUserRolesOfUser.mutate([props.user, selectedRoles]);
  };

  return (
    <>
      <Select
        className={`user-roles-selector-${props.user.id}`}
        loading={isLoading}
        mode="multiple"
        tagRender={tagRender}
        value={isLoading ? [] : userRolesOfUser?.data?.map((ur) => ur.id.toString())}
        placeholder="No Roles"
        onChange={onChange}
        filterOption={(input, option) => (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
      >
        {userRoles.data
          ?.sort((a, b) => {
            // Some goofy role sorting implementation to prevent accidental admin clicks
            // and have user always on the first position
            if (a.name.toLowerCase() === 'user') return -1;
            if (b.name.toLowerCase() === 'user') return 1;
            if (a.name.toLowerCase().includes('admin')) return 1;
            if (b.name.toLowerCase().includes('admin')) return -1;
            return Comparator.lexicographicalComparison(a.name, b.name);
          })
          .map((pr) => {
            return (
              <Option key={pr.id.toString() || '-1'} value={pr.id.toString() || '-1'}>
                {pr.name}
              </Option>
            );
          })}
      </Select>
    </>
  );
};

export default UserRolesDropdown;
