import {
  Download,
  EngineeringBackend,
  EngineeringBackendError,
  SoftwareApp,
  SoftwareAppVersion,
  TargetWithDownloadLink,
  Tool,
  ToolVersion
} from '../../../../api';

import { BadRequestError, ChecksumMismatchError, InternalError, NoAccessError, NotFoundError } from './downloadErrors';
import { DownloadMode, DownloadTarget } from './types';

export const getDownloadUrl = async (
  backend: EngineeringBackend,
  args: { mode: DownloadMode; component: Tool | SoftwareApp; version: ToolVersion | SoftwareAppVersion; projectId?: number; target?: DownloadTarget }
): Promise<Download> => {
  try {
    // Tool
    if (args.mode === 'tool') {
      return await backend.downloadToolVersion((args.component as Tool).id, (args.version as ToolVersion).idToolVersion);
    }
    // If app and has project id -> project app
    if (args.mode === 'app' && !!args.projectId) {
      return await backend.downloadProjectAppVersionTarget(
        args.projectId!,
        (args.component as SoftwareApp).idSoftwareApp,
        (args.version as SoftwareAppVersion).idSoftwareAppVersion,
        (args.target as TargetWithDownloadLink).target.idTarget
      );
    }
    // If app and no project id -> common app
    if (args.mode === 'app' && !args.projectId) {
      return await backend.downloadCommonAppVersionTarget(
        (args.component as SoftwareApp).idSoftwareApp,
        (args.version as SoftwareAppVersion).idSoftwareAppVersion,
        (args.target as TargetWithDownloadLink).target.idTarget
      );
    }
  } catch (error) {
    const apiError = error as EngineeringBackendError;

    if (apiError.statusCode === 400) {
      throw new BadRequestError(
        `${apiError.message}${apiError.reasons.length > 0 ? ` (${apiError.reasons.map((r) => `${r.path}: ${r.message}`).join(', ')})` : ''}`
      );
    }
    if (apiError.statusCode === 404) {
      throw new NotFoundError();
    }
    if (apiError.statusCode === 405) {
      throw new ChecksumMismatchError();
    }
    if (apiError.statusCode === 401 || apiError.statusCode === 403) {
      throw new NoAccessError();
    }
  }

  throw new InternalError();
};
