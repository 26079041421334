import { WarningOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

export const PermanentActionWarningLabel = () => (
  <Space align="center" direction="vertical" style={{ width: '100%', marginTop: 8 }}>
    <Typography.Text type="danger" strong>
      <WarningOutlined style={{ marginRight: 8 }} />
      Note: this action is permanent and can not be reverted
      <WarningOutlined style={{ marginLeft: 8 }} />
    </Typography.Text>
  </Space>
);
