import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectType } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteProjectType() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [ProjectType]>(([pst]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteProjectType(pst.idProjectType.toString())
        .then(() => {
          presentSuccess(`Successfully deleted Project Type ${pst.name}`);
          queryClient.invalidateQueries(['projectTypes']);
          resolve();
        })
        .catch(reject);
    });
  });
}
