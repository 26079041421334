import { Project, SourceMeta } from '../../../api';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { useInAppNavigate } from '../../../contexts/navigation/hooks/useInAppNavigate';
import { useReportRevisions } from '../hooks/useReportRevisions';
import { RevisionSelectorDropdown } from './RevisionSelectorDropdown';

export const RevisionSelector = (props: { project?: Project; source?: SourceMeta; selectedRevision?: string | 'latest' }) => {
  const navigate = useInAppNavigate();

  const metrics = useSubmitMetric();

  const revisions = useReportRevisions(props.project?.idProject ?? -1, props.source?.id ?? '');
  const latestRevision = revisions.data?.sort((a, b) => b.revision - a.revision).find(Boolean);
  const selectedRevision =
    props.selectedRevision === 'latest' || !props.selectedRevision
      ? latestRevision
      : revisions.data?.find((r) => r.revision.toString() === props.selectedRevision);

  const handleRevisionNavigate = (to: number) => {
    if (props.source && props.project && selectedRevision) {
      metrics.submitClick({ operationId: MetricClickIds.reportSelectRevision }).withReportMetaContext(props.source, selectedRevision, props.project);
    } else {
      metrics.submitClick({ operationId: MetricClickIds.reportSelectRevision }).withoutContext();
    }
    navigate({ pathname: `/projects/${props.project?.idProject}/reports/${props.source?.id}/${to}`, search: location.search }, { replace: true });
  };

  return (
    <RevisionSelectorDropdown
      onChange={handleRevisionNavigate}
      latestRevision={latestRevision?.revision}
      loading={!revisions.isSuccess}
      revisions={revisions.data?.sort((a, b) => b.revision - a.revision)}
      selectedRevision={selectedRevision?.revision}
    />
  );
};
