import { Button, Row, Typography } from 'antd';
import { StyledCol, StyledResult } from '../../../../../contexts/shared/base/Components.styled';

export const DownloadError = (props: { error: Error; onRetry: () => void }) => {
  return (
    <StyledResult status="error" padding="8px 16px 8px 16px" contentStyle={{ backgroundColor: 'transparent', padding: '0' }}>
      <Row justify="center">
        <StyledCol span={24} textAlign="center">
          <Typography.Text>{props.error.message}</Typography.Text>
        </StyledCol>
        <StyledCol textAlign="center" marginTop="16px">
          <Button type="link" onClick={props.onRetry}>
            Retry
          </Button>
        </StyledCol>
      </Row>
    </StyledResult>
  );
};
