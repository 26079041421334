import { VersionAdditionalNumber } from './versionAdditionalNumber';
import { VersionAdditionalString } from './versionAdditionalString';
import { VersionNumber } from './versionNumber';
import { VersionPart } from './versionPart';

export class SemanticVersion implements VersionPart {
  // eslint-disable-next-line no-useless-escape
  private static readonly groupingRegex = /([0-9\.]+|[^0-9]+)/gm;

  private static readonly versionCandidate = /[0-9]+/;

  private static readonly initialVersion = '0.0.1';

  private readonly versionParts: VersionPart[];

  private constructor(parts: VersionPart[]) {
    if (parts.filter((part) => part instanceof VersionNumber).length !== 1) {
      throw new Error('Must have exactly one version number part (number)');
    }
    this.versionParts = parts;
  }

  toString(): string {
    return this.versionParts.join('');
  }

  equals(part: VersionPart): boolean {
    if (part instanceof SemanticVersion) {
      return this.toString() === part.toString();
    }
    return false;
  }

  compareTo(part: VersionPart): number {
    if (part instanceof SemanticVersion) {
      const minLength = Math.min(part.versionParts.length, this.versionParts.length);
      for (let i = 0; i < minLength; i++) {
        const res = this.versionParts[i].compareTo(part.versionParts[i]);
        if (res !== 0) {
          return res;
        }
      }
      return this.versionParts.length - part.versionParts.length;
    }
    return -1;
  }

  increment(): SemanticVersion {
    (this.versionParts.find((part) => part instanceof VersionNumber) as VersionNumber)!.increment();
    return this;
  }

  public static fromString(s: string) {
    if (s === '') {
      throw new Error('String must not be empty');
    }
    const matches: RegExpExecArray[] = [];
    for (let m = this.groupingRegex.exec(s); m; m = this.groupingRegex.exec(s)) {
      matches.push(m);
    }

    const parts: VersionPart[] = [];
    matches.forEach((match) => {
      const matchString = match[0];
      const isVersionCandidate = this.versionCandidate.test(matchString);
      const isNonVersionCandidate = !isVersionCandidate;

      if (isVersionCandidate) {
        parts.push(new VersionNumber(matchString));
      } else if (isNonVersionCandidate) {
        parts.push(new VersionAdditionalString(matchString));
      } else {
        throw new Error(`String matches neither version not non version, this must not happen ${matchString}`);
      }
    });

    // Cleanup if multiple version parts
    const versionCandidates = parts
      .filter((part) => part instanceof VersionNumber)
      .sort((a, b) => (b as VersionNumber).numberOfParts() - (a as VersionNumber).numberOfParts());
    versionCandidates.forEach((versionCandidate, index) => {
      if (index === 0) {
        if (
          versionCandidates
            .filter((_, innerIndex) => index !== innerIndex)
            .find((vc) => (vc as VersionNumber).numberOfParts() === (versionCandidate as VersionNumber).numberOfParts())
        ) {
          // Multiple equally long potential version parts found
          // should not happen for valid semantic versions
        }
      } else {
        // Replace candidate with additional;
        const partsIndex = parts.indexOf(versionCandidate);
        parts[partsIndex] = new VersionAdditionalNumber((versionCandidate as VersionNumber).inputString());
      }
    });
    return new SemanticVersion(parts);
  }

  public static initial() {
    return this.fromString(this.initialVersion);
  }

  public static withPrefix(prefix: string) {
    if (prefix !== '') {
      return this.fromString(`${prefix}-${this.initialVersion}`);
    }
    return this.initial();
  }

  public static fromUnsafeString(s: string) {
    try {
      return this.fromString(s);
    } catch (error) {
      return this.withPrefix(s);
    }
  }

  public static sorter(a: string, b: string): number {
    return SemanticVersion.fromUnsafeString(a).compareTo(SemanticVersion.fromUnsafeString(b));
  }

  public static isValid(s: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.fromString(s);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}
