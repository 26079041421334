/* eslint-disable @typescript-eslint/no-redeclare */

export type AuthOauthCodeResponse = { code: string };
export type AuthOauthTokenResponse = { access_token: string };
export type AuthRedeemRequest = { code: string };
export type AuthTokenRefreshRequest = { refreshToken: string };
export type AuthTokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export type AuthRefreshTokenAuthFlowEnum = (typeof AuthRefreshTokenAuthFlowEnum)[keyof typeof AuthRefreshTokenAuthFlowEnum];

export const AuthRefreshTokenAuthFlowEnum: {
  readonly AuthCodeGrant: 'auth_code_grant';
  readonly DeviceAuthGrant: 'device_auth_grant';
} = { AuthCodeGrant: 'auth_code_grant', DeviceAuthGrant: 'device_auth_grant' };

export type AuthRefreshTokenProviderEnum = (typeof AuthRefreshTokenProviderEnum)[keyof typeof AuthRefreshTokenProviderEnum];
export const AuthRefreshTokenProviderEnum: {
  readonly Azuread: 'azuread';
} = { Azuread: 'azuread' };

export type AuthRefreshTokenClientIdEnum = (typeof AuthRefreshTokenClientIdEnum)[keyof typeof AuthRefreshTokenClientIdEnum];
export const AuthRefreshTokenClientIdEnum: {
  readonly Pacts: 'pacts';
  readonly Impacts: 'impacts';
  readonly External: 'external';
} = { External: 'external', Impacts: 'impacts', Pacts: 'pacts' };

export type OauthDeviceCodeResponse = {
  device_code: string;
  user_code: string;
  verification_uri: string;
  expires_in: number;
  interval: number;
};

export const TokenResponseErrorErrorEnum: {
  readonly InvalidRequest: 'invalid_request';
  readonly InvalidProvider: 'invalid_provider';
  readonly InvalidClient: 'invalid_client';
  readonly InvalidGrant: 'invalid_grant';
  readonly UnauthorizedClient: 'unauthorized_client';
  readonly UnsupportedGrantType: 'unsupported_grant_type';
  readonly InvalidScope: 'invalid_scope';
  readonly AuthorizationPending: 'authorization_pending';
  readonly AccessDenied: 'access_denied';
  readonly ExpiredToken: 'expired_token';
  readonly SlowDown: 'slow_down';
} = {
  InvalidRequest: 'invalid_request',
  InvalidProvider: 'invalid_provider',
  InvalidClient: 'invalid_client',
  InvalidGrant: 'invalid_grant',
  UnauthorizedClient: 'unauthorized_client',
  UnsupportedGrantType: 'unsupported_grant_type',
  InvalidScope: 'invalid_scope',
  AuthorizationPending: 'authorization_pending',
  AccessDenied: 'access_denied',
  ExpiredToken: 'expired_token',
  SlowDown: 'slow_down'
};
