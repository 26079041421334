import { useQuery } from '@tanstack/react-query';
import { ReportRevision } from '../../../api/report/domain/types';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useReportRevision(projectId: number, sourceId: string, revision: number, disabled?: boolean) {
  const { backend } = useReportBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery<ReportRevision, [string, string, number]>(
    ['projectReportRevision', projectId.toString(), sourceId, revision],
    (): Promise<ReportRevision> => {
      return backend.getRevision(projectId, sourceId, revision);
    },
    {
      enabled: projectId > -1 && permissions.reportSvc$getReportRevision && !disabled
    }
  );
}
