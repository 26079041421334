import { useQuery } from '@tanstack/react-query';
import { ReportRevision } from '../../../api/report/domain/types';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useLatestReportRevision(projectId: number, sourceId: string, disabled?: boolean) {
  const { backend } = useReportBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery<ReportRevision, [string, string, string]>(
    ['projectReportRevision', projectId.toString(), sourceId, 'latest'],
    (): Promise<ReportRevision> => {
      return backend.getLatestRevision(projectId, sourceId);
    },
    {
      enabled: projectId > -1 && permissions.reportSvc$getReportLatestRevision && !disabled
    }
  );
}
