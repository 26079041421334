import { Space, SpaceProps } from 'antd';

const CenteredSpace: React.FC<SpaceProps> = (props) => (
  <Space
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...props.style
    }}
  >
    {props.children}
  </Space>
);

export default CenteredSpace;
