import { Empty, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { FullWidthLayout as AntFullWidthLayout } from '../../layout/components/FullWidthLayout';
import { BackToProjectButton } from '../../navigation/components/BackToProjectButton';
import { useProject } from '../../projects/hooks/useProject';
import { usePermissions } from '../../session/hooks/usePermissions';
import { DeploymentPlans } from './DeploymentPlans';
import { Devices } from './Devices';
import { Otcs } from './Otcs';
import { ClosedDeployments } from './ClosedDeployments';
import styled from 'styled-components';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { useDeploymentEnvironments } from '../hooks/useDeploymentEnvironments';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';

const FullWidthLayout = styled(AntFullWidthLayout)`
  .ant-card-body {
    min-height: 78vh;
  }
`;

export const ProjectDeployments = () => {
  const params = useParams() as any;
  const projectId = parseInt(params.projectId);
  const envId = params.envId ?? '';
  const project = useProject(projectId.toString());
  const permissions = usePermissions({ projectId: projectId.toString(), environmentId: envId ?? undefined });
  const [tab, setTab] = useSearchParameter('tab', 'current_deployment');

  const environments = useDeploymentEnvironments(projectId);
  const currentEnvironment = environments.data?.find((e) => e.id === envId);

  const loading = !project.isSuccess || !environments.isSuccess;

  if (loading) return <PageLoading />;

  return (
    <FullWidthLayout
      title={`${project.data?.name || ''} Deployments ${currentEnvironment?.name ?? ''}`}
      padding
      extra={[<BackToProjectButton key="back" projectId={projectId.toString()} />]}
    >
      <Tabs
        type="card"
        activeKey={tab}
        onChange={(key) => {
          setTab(key);
        }}
      >
        {envId && (
          <>
            {permissions.deploymentSvc$getDeploymentPlans && (
              <Tabs.TabPane tab="Current deployment" key="current_deployment">
                <DeploymentPlans project={project.data!} envId={envId} />
              </Tabs.TabPane>
            )}
            {permissions.deploymentSvc$getDeploymentPlans && (
              <Tabs.TabPane tab="Closed deployments" key="closed_deployments">
                <ClosedDeployments project={project.data!} envId={envId} />
              </Tabs.TabPane>
            )}
            {permissions.deploymentSvc$getDevices && (
              <Tabs.TabPane tab="Devices" key="device">
                <Devices projectId={projectId} />
              </Tabs.TabPane>
            )}
            {permissions.deploymentSvc$getOtcs && (
              <Tabs.TabPane tab="OTC" key="otc">
                <Otcs projectId={projectId} />
              </Tabs.TabPane>
            )}
          </>
        )}
      </Tabs>
      {!envId && <Empty description="Please Select Config" style={{ width: '100%' }} />}
    </FullWidthLayout>
  );
};

export default ProjectDeployments;
