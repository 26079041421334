import { useContext, useMemo } from 'react';
import { defaultPermissions, PermissionPlaceholders, PermissionsCalculator } from '@pacts/permissions-lib';
import { useSession } from './useSession';
import { PacTSContext } from '../../../state/store';
import { GlobalState } from '../../../state/globalState';

export function usePermissions(placeholders?: Partial<PermissionPlaceholders>, ctx?: GlobalState) {
  const [state] = useContext(PacTSContext);
  const session = useSession(ctx ?? state);

  return useMemo(() => {
    if (!session.userInfo) {
      return { ...defaultPermissions };
    }

    const permissions = PermissionsCalculator.calculate(session.userInfo.permissions, placeholders ?? {}).calculatedPermission;

    return { ...permissions };
  }, [session.userInfo, placeholders]);
}

export function useHasActiveSession(ctx?: GlobalState) {
  const [state] = useContext(PacTSContext);
  const session = useSession(ctx ?? state);

  const isActive = session.userInfo?.status === 'active';
  return isActive;
}

export const useRestrictedUseCasePermissions = () => {
  const permissions = usePermissions();

  return useMemo(() => {
    const hasProjectsPermission = permissions.engineeringSvc$getProjects || permissions.engineeringSvc$getProject$specific().length > 0;
    const hasPlatformToolsPermissions = permissions.engineeringSvc$getTools || permissions.engineeringSvc$getTool$specific().length > 0;
    const hasPlatformAppsPermissions =
      permissions.engineeringSvc$getCommonSoftwareApps || permissions.engineeringSvc$getCommonSoftwareApp$specific().length > 0;

    return {
      hasProjectsPermission,
      hasPlatformToolsPermissions,
      hasPlatformAppsPermissions
    };
  }, [permissions]);
};
