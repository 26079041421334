import { PushpinOutlined } from '@ant-design/icons';
import { Card, List, Space } from 'antd';
import { PrimaryHeader } from '../../shared/components/PrimaryHeader';
import { usePinnedProjects } from '../../projects/hooks/usePinnedProjects';
import { PinnedProjectListItem } from './PinnedProjectListItem';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const PinnedProjects = () => {
  const pinned = usePinnedProjects();
  const permissions = usePermissions();

  const hasPermissions = permissions.engineeringSvc$getProjects || permissions.engineeringSvc$getProject$specific().length > 0;

  // allow pinned projects only if projects can be listed
  if (!hasPermissions) return null;

  return (
    <Card
      loading={pinned.isLoading}
      className="centerMenuElement"
      title={<PrimaryHeader>My Pinned Projects</PrimaryHeader>}
      bodyStyle={{ padding: 8, paddingLeft: 16, paddingRight: 16 }}
    >
      <List
        style={{ maxHeight: '8rem', overflow: 'auto' }}
        dataSource={pinned.data}
        split={false}
        itemLayout="horizontal"
        locale={{
          emptyText: (
            <Space>
              <PushpinOutlined />
              You can pin projects by clicking the icon next to the project name
            </Space>
          )
        }}
        renderItem={(item) => <PinnedProjectListItem project={item} />}
      />
    </Card>
  );
};
