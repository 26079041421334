import { Badge, Card, Space } from 'antd';
import { useFilteredNotifications } from '../hooks/useNotifications';
import { ArchiveAllNotifications } from './ArchiveAllNotifications';
import { PrimaryHeader } from '../../shared/components/PrimaryHeader';
import { NotificationsList } from './NotificationsList';
import { NotificationMatcher } from '../domain';
import { useStyles } from '../../theme/useStyles';

export const Notifications = (props: {
  filter?: RegExp;
  title: string;
  matcher: NotificationMatcher[];
  height?: string;
  style?: React.CSSProperties;
  clearDisabled?: boolean;
}) => {
  const notifications = useFilteredNotifications(props.filter);
  const token = useStyles();
  return (
    <Card
      style={props.style}
      bodyStyle={{ padding: 8 }}
      bordered
      title={
        <Space>
          <PrimaryHeader>{props.title}</PrimaryHeader>
          <Badge overflowCount={20} style={{ backgroundColor: token.colorPrimary }} count={notifications.data ? notifications.data.length : 0} />
        </Space>
      }
      extra={[!props.clearDisabled && <ArchiveAllNotifications key="archiveAll" ids={notifications.data?.map((n) => n.id) || []} />]}
    >
      <NotificationsList {...props} />
    </Card>
  );
};
