import { GlobalState, GlobalStateAction } from './globalState';
import { TokenActions } from './actions/tokenActions';
import { BasePathActions } from './actions/basePathActions';

export const Reducer = (state: GlobalState, action: GlobalStateAction): GlobalState => {
  switch (action.type) {
    case TokenActions.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case BasePathActions.SET_BASEPATH:
      return {
        ...state,
        engineeringServicePath: action.payload
      };
    default:
      return state;
  }
};
