import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { jwtIsNotExpired } from './tokenValidator';
import { PacTSContext, readTokenFromStore, refreshTokenDiffers } from '../../state/store';
import { useAuthBackend } from '../auth/hooks/useAuthBackend';
import { useLogout } from '../../contexts/session/hooks/useLogout';
import { ApiError } from './apiError';
import { Token, TokenActions } from '../../state/actions/tokenActions';

// Keep query promises global to deal with multiple attachments of the useRefreshToken hook
const queryPromises: ((res: Token, err?: Error) => void)[] = [];

const isUnauthorizedError = (err: Error): boolean => {
  const typedError = err as ApiError;
  const isUnauthorized = typedError?.statusCode === 401 || typedError?.statusCode === 403;
  return isUnauthorized;
};

const useRefreshToken = (): {
  refresh: () => Promise<Token>;
} => {
  const [state, dispatch] = useContext(PacTSContext);
  const { backend } = useAuthBackend();
  const logout = useLogout();

  // Use ref here because rerendering on change is not necessary due to on-demand refresh calls
  const refreshToken = useRef(state.token.refreshToken);

  // When the state token changes, update the ref
  // Debouncing tokens in the login process is required for this hook to update.
  useEffect(() => {
    refreshToken.current = state.token.refreshToken;
  }, [state.token]);

  const refresh = useCallback(async (): Promise<Token> => {
    // TODO: remove once everything related to refresh is tested
    // actually this shouldn't happen at all
    if (!backend.tokenRefresh) {
      console.warn('invalid backend', backend.tokenRefresh);
      throw new Error('Backend not initialized');
    }

    // Do not refresh if refresh token is not available
    if (!refreshToken.current) {
      throw new Error('Refresh token not available', { cause: new Error('logged out') });
    }

    // Logout if refresh token is expired
    if (!jwtIsNotExpired(refreshToken.current)) {
      console.warn('logout due to expired session');
      logout();
      throw new Error('Session expired', { cause: new Error('expired tokens') });
    }

    // Check if somebody is already waiting for a new token
    const hasTask = queryPromises.length > 0;

    // Create a promise-wrapped callback and add to list of pending
    const promise = new Promise<Token>((resolve, reject) => {
      const callback = (res: Token, err?: Error) => {
        if (err) return reject(err);
        return resolve(res);
      };
      queryPromises.push(callback);
    });

    // If fetch task is already running, return here
    if (hasTask) return promise;

    // If not, start fetch call
    backend
      .tokenRefresh({ refreshToken: refreshToken.current })
      .then((res) => {
        dispatch({
          type: TokenActions.SET_TOKEN,
          payload: {
            token: res.accessToken,
            refreshToken: res.refreshToken
          }
        });
        // Wait for population of new token through the hooks
        // Important because requests with old tokens might still be made for a few cycles
        // until the new token is fully propagated throughout the system
        setTimeout(() => {
          const promisesCopy = [...queryPromises];
          // Empty array for new requests while callbacks are called
          // new requests can already be accepted
          queryPromises.length = 0;
          promisesCopy.forEach((cb) => {
            cb({ refreshToken: res.refreshToken, token: res.accessToken });
          });
        }, 10);
      })
      .catch((error) => {
        const isUnauthorized = isUnauthorizedError(error);

        // Wait for population of new token
        const promisesCopy = [...queryPromises];
        // Empty array for new requests
        queryPromises.length = 0;
        promisesCopy.forEach((cb) => {
          cb({ refreshToken: '', token: '' }, new Error('Token Refresh Failed', { cause: error }));
        });

        if (isUnauthorized) {
          console.warn('token refresh failed, logout', error);
          logout();
        }
      });

    return promise;
  }, [logout, backend, dispatch]);

  return useMemo(() => {
    return {
      refresh
    };
  }, [refresh]);
};

export const useToken = () => {
  const [state] = useContext(PacTSContext);
  const refresh = useRefreshToken();

  const tokenRef = useRef(state?.token ?? { token: '', refreshToken: '' });
  useEffect(() => {
    tokenRef.current = state.token;
  }, [state.token]);

  return useMemo(() => {
    const getToken = () => {
      // Hack to make sure the used token is always the current (one from storage)
      // even if the state event has not propagated yet
      const storeToken = readTokenFromStore();
      if (refreshTokenDiffers(storeToken, tokenRef.current)) {
        tokenRef.current = storeToken;
      }

      if (jwtIsNotExpired(tokenRef.current.token)) {
        return Promise.resolve(tokenRef.current);
      } else {
        return refresh.refresh();
      }
    };
    return { token: getToken };
  }, [refresh]);
};

export const useRestBackendConfig = (basePath: string) => {
  const token = useToken();
  return useMemo(() => {
    const getToken = async () => {
      return `Bearer ${(await token.token()).token}`;
    };
    return { apiKey: getToken, basePath };
  }, [basePath, token]);
};
