import { ApiError } from '../../shared/apiError';
import {
  ReportRevisionCreate,
  ReportRevisionCreateResponse,
  Document,
  ReportRevision,
  RevisionMeta,
  SourceMeta,
  ColumnsWithMvccId,
  ColumnsWithFrom,
  Column
} from '../domain/types';

export class ReportBackendError extends ApiError {}

export interface ReportBackend {
  createRevision(projectId: number, body: ReportRevisionCreate): Promise<ReportRevisionCreateResponse>;
  deleteFolderColumns(projectId: number, sourceId: string, folderId: string, mvccId: number): Promise<void>;
  deleteRevision(projectId: number, sourceId: string, revisionId: string): Promise<void>;
  deleteSystemColumns(): Promise<void>;
  getDocuments(projectId: number, sourceId: string, documentId: string): Promise<Document[]>;
  getLatestRevision(projectId: number, sourceId: string): Promise<ReportRevision>;
  getRevision(projectId: number, sourceId: string, revisionId: number): Promise<ReportRevision>;
  getRevisions(projectId: number, sourceId: string): Promise<RevisionMeta[]>;
  getSources(projectId: number): Promise<SourceMeta[]>;
  getSystemColumns(): Promise<{ [key: string]: object[] }>;
  upsertFolderColumns(projectId: number, sourceId: string, folderId: string, body: ColumnsWithMvccId): Promise<void>;
  upsertSystemColumns(sourceId: string, body: { columns: Column[] }): Promise<void>;
  getCalculatedFolderColumns(projectId: number, sourceId: string, folderId: string): Promise<ColumnsWithFrom>;
}
