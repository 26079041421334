import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { AuthBackend } from '../service/authBackend';

type AuthBackendHookType = {
  backend: AuthBackend;
  setBackend: (newBackend: AuthBackend) => any;
};
const initData: AuthBackendHookType = {
  backend: undefined as any as AuthBackend,
  setBackend: () => {
    /* */
  }
};
export const setInitialAuthBackend = (backend: AuthBackend) => {
  initData.backend = backend;
};
export const useAuthBackend = singletonHook<AuthBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<AuthBackend>(initData.backend);
  return {
    backend: backendState,
    setBackend
  };
});
