import { Form, FormInstance, Input, Radio, Select } from 'antd';
import { SoftwareAppUpdate, SoftwareAppVersion, ToolPackageKindEnum } from '../../../api/engineering/domain/types';
import { DescriptionValidator } from '../../../domain/validation/descriptionValidator';
import { DocumentationLinkValidator } from '../../../domain/validation/documentationLinkValidator';
import { NameValidator } from '../../../domain/validation/nameValidator';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { useSoftwareAppCategories } from '../hooks/useSoftwareAppsCategories';

const { Option } = Select;

export const UpdateSoftwareAppForm = (props: {
  initial: SoftwareAppUpdate;
  versions: SoftwareAppVersion[];
  onFinish: (data: SoftwareAppUpdate) => Promise<void>;
  ok: (ok: () => any) => any;
  reset: (cancel: () => any) => any;
  form?: FormInstance;
}) => {
  const categories = useSoftwareAppCategories();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };
  const [form] = Form.useForm<SoftwareAppUpdate>(props.form);

  props.ok(form.submit);
  props.reset(form.resetFields);

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={props.initial}
      form={form}
      onFinish={(value) => {
        const copy = { ...value } as SoftwareAppUpdate;
        if (copy && copy.categories) {
          const id = copy.categories[0].id;
          const cat = categories.data?.find((c) => c.id === id);
          const cats = cat ? [cat] : [];
          copy.categories = cats;
        }
        const id = copy.latestVersion!.idSoftwareAppVersion;
        const vf = props.versions.find((v) => v.idSoftwareAppVersion === id);
        copy.latestVersion = vf!;

        props.onFinish(Object.assign(props.initial, copy));
      }}
    >
      <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        rules={[DescriptionValidator.rule()]}
        label="Description"
        field={['description']}
        shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
      />
      <MarkdownFormItem
        optional
        rules={[DocumentationLinkValidator.rule()]}
        label="Documentation"
        field={['documentationLink']}
        shouldUpdate={(prevValues, currentValues) => prevValues.documentationLink !== currentValues.documentationLink}
      />
      <Form.Item label="Package Kind" name="packageKind" rules={[{ required: true, message: 'Required' }]}>
        <Radio.Group>
          <Radio value={ToolPackageKindEnum.Custom}>Custom</Radio>
          <Radio value={ToolPackageKindEnum.Msi}>Msi</Radio>
          <Radio value={ToolPackageKindEnum.Nsis}>Nsis</Radio>
          <Radio value={ToolPackageKindEnum.Zip}>Zip</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Latest Version" name={['latestVersion', 'idSoftwareAppVersion']} rules={[{ required: true, message: 'Required' }]}>
        <Select
          getPopupContainer={(trigger) => trigger.parentNode}
          showSearch
          filterOption={(inputValue, option) => {
            return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
          }}
        >
          {props.versions?.map((version) => {
            return (
              <Select.Option value={version.idSoftwareAppVersion || -1} title={version.version}>
                {version.version}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Category"
        name={['categories', 0, 'id']}
        rules={[
          {
            required: true,
            message: 'Required',
            validator: (rule, value) => {
              if (value > -1 || form.getFieldValue('categories').length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Argument missing'));
            }
          }
        ]}
      >
        <Select
          loading={categories.isLoading}
          style={{ width: '100%' }}
          placeholder="Please select"
          getPopupContainer={(trigger) => trigger.parentNode}
          showSearch
          filterOption={(inputValue, option) => {
            return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
          }}
        >
          {categories.data?.map((category) => {
            return (
              <Option key={category.id?.toString()} value={category.id || -1} title={category.name}>
                {category.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form>
  );
};
