import { Bundle, Project } from '../../../api/engineering/domain/types';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { OpenContextArgs, ToolManagerArgs, ToolManagerLink } from '../../../domain/toolmanager/toolmanagerArgs';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { PacTSIcon } from '../../shared/components/icons/PacTSIcon';

export const BundleOpenInToolManagerButton = (props: {
  project?: Project;
  bundle?: Bundle;
  selection?: BundleConfigurationSelection;
  assetType: 'app' | 'tool';
}) => {
  const onClick = () => {
    const component: OpenContextArgs = {
      bundleContext: {
        bundleConfigId: props.selection!.bundleConfigurationId,
        bundleConfig: props.selection!.bundleConfigurationName,
        bundleConfigVersion: props.selection!.bundleConfigurationVersionName,
        bundleConfigVersionId: props.selection!.bundleConfigurationVersionId,
        bundle: props.bundle!.name,
        bundleId: props.bundle!.idBundle!.toString(),
        projectContext: {
          projectId: props.project!.idProject!.toString(),
          projectName: props.project!.name
        }
      },
      projectType: {
        projectType: props.project!.projectType!.name,
        projectTypeId: props.project!.projectType!.idProjectType!.toString()
      },
      type: props.assetType
    };
    const args: ToolManagerArgs = {
      action: 'openContext',
      payload: component
    };
    ToolManagerLink.open(args);
  };

  const hasProject = props.project && props.project.idProject?.toString() !== '';
  const hasBundle = props.bundle && props.bundle.idBundle?.toString() !== '';
  const hasSelection = props.selection && props.selection.bundleConfigurationId !== '' && props.selection.bundleConfigurationVersionId !== '';

  const enabled = hasProject && hasBundle && hasSelection;
  return (
    <>
      <ExpandableMenuItem icon={<PacTSIcon />} disabled={!enabled} onClick={onClick}>
        Open in ImPacTS
      </ExpandableMenuItem>
    </>
  );
};
