import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useSharedState = <T>(key: string): [T | null, (val: T) => void, () => void] => {
  const qc = useQueryClient();
  const query = useQuery<T | null, [string]>(
    [key],
    (): Promise<T | null> => {
      return Promise.resolve(null);
    },
    {
      staleTime: Infinity,
      refetchInterval: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  const mutate = (val: T) => {
    qc.setQueryData([key], val, { updatedAt: Date.now() });
  };
  const reset = () => {
    qc.setQueryData([key], null, { updatedAt: Date.now() });
  };

  return [query.data ?? null, mutate, reset];
};
