import { SyncOutlined } from '@ant-design/icons';
import { Tag as AntTag } from 'antd';
import { useDeploymentPlan } from '../hooks/useDeploymentPlan';
import styled from 'styled-components';

const Tag = styled(AntTag)`
  margin: 0px;
`;

export const DeploymentPlanInstallationStatusIndicator = (props: { planId: string; envId: string; projectId: number; isFullyInstalled: boolean }) => {
  const plan = useDeploymentPlan(props.planId, props.envId, props.projectId);
  const everythingInstalled = props.isFullyInstalled;

  if (plan.isLoading) {
    return <Tag icon={<SyncOutlined spin />}>Loading</Tag>;
  }
  return everythingInstalled ? <Tag color="success">Everything Installed</Tag> : <Tag color="warning">Pending Installations</Tag>;
};
