import { Button, Col, Row, Typography } from 'antd';
import { SoftwareAppVersion, TargetWithDownloadLink, ToolVersion } from '../../../../../api';
import styled from 'styled-components';
import { StyledRow } from '../../../../../contexts/shared/base/Components.styled';
import { useValidTargets } from '../hooks/useArtifactoryLinkPreFlightCheck';

const gutter = 16;

const WrapperDiv = styled.div`
  height: 200px;
`;

export const TargetSelector = (props: {
  version: SoftwareAppVersion;
  onSelect: (target: ToolVersion | (SoftwareAppVersion & TargetWithDownloadLink)) => void;
}) => {
  const validTargets = useValidTargets(props.version);

  return (
    <>
      <Row justify="start">
        <Col>
          <Typography.Text>This software has multiple targets for which it can be downloaded.</Typography.Text>
        </Col>
      </Row>
      <WrapperDiv>
        <StyledRow justify="center" align="middle" gutter={[gutter, gutter]} height="100%">
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Typography.Text>Download for</Typography.Text>
              </Col>
            </Row>
            <StyledRow justify="center" gutter={[gutter, gutter]} marginTop={`${gutter}px`}>
              {validTargets.map((t) => {
                return (
                  <Col key={t.target.idTarget}>
                    <Button
                      onClick={() => {
                        props.onSelect({ ...props.version, ...t });
                      }}
                    >
                      {t.target.name}
                    </Button>
                  </Col>
                );
              })}
            </StyledRow>
          </Col>
        </StyledRow>
      </WrapperDiv>
    </>
  );
};
