import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { Column } from '../../../api/report/domain/types';
import { presentSuccess } from '../../../api';

export const useUpsertSystemColumns = (projectId: number, sourceId: string) => {
  const { backend } = useReportBackend();
  const queryClient = useQueryClient();

  return useMutation<void, any, { columns: Column[] }>(async (columns) => {
    try {
      await backend.upsertSystemColumns(sourceId, columns);
      queryClient.invalidateQueries(['systemColumns']);
      queryClient.invalidateQueries(['projectSources', projectId]);
      queryClient.invalidateQueries(['computedFolderColumns', projectId, sourceId, sourceId]);
      presentSuccess('Successfully saved default columns');
    } catch (error) {
      presentSuccess('Something went wrong');
    }
  });
};
