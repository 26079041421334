import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { ReportBackend } from '../service/reportBackend';

type ReportBackendHookType = {
  backend: ReportBackend;
  setBackend: (newBackend: ReportBackend) => any;
};
const initData: ReportBackendHookType = {
  backend: undefined as any as ReportBackend,
  setBackend: () => {
    /* */
  }
};
export const useSetInitialReportBackend = (backend: ReportBackend) => {
  initData.backend = backend;
};
export const useReportBackend = singletonHook<ReportBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<ReportBackend>(initData.backend);
  return {
    backend: backendState,
    setBackend
  };
});
