import * as axios from 'axios';
import { SharedAxiosInstance } from '../shared/sharedAxiosInstance';

const appName = 'PACTS';
const authorization = 'Hh9r2M+VxrWnSYRNecew4H8WybJatYA12HdWjMM2';
export const submitUserSatisfaction = (basePath: string, stars: number, feedback: string): Promise<axios.AxiosResponse<any>> => {
  const roundedNumber = Math.round(stars);
  if (roundedNumber < 0 || roundedNumber > 5) {
    return Promise.reject(new Error('Must be between 0 and 5'));
  }
  return SharedAxiosInstance.instance().post(`${basePath}?app=${appName}&rating=${roundedNumber}`, feedback, {
    headers: { 'Content-Type': 'application/json', Authorization: authorization }
  });
};
