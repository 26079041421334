import { Col, Row, Space, Statistic } from 'antd';
import { useMemo } from 'react';
import { UserWithRoles } from '../../../api/user/domain/users';
import { SubscriptionPopup } from '../../notifications';
import { usePermissions } from '../../session/hooks/usePermissions';

export const UserStats = (props: { usersWithRoles: UserWithRoles[] }) => {
  const permissions = usePermissions();
  const externalUsers = useMemo(() => {
    return props.usersWithRoles.filter((u) => {
      if (u.email?.includes('.ext')) return true;
      if (!u.email?.endsWith('@siemens-energy.com')) return true;
      return false;
    });
  }, [props.usersWithRoles]);

  const usersWithoutRoles = useMemo(() => {
    return props.usersWithRoles.filter((u) => {
      return (u.roles?.length || 0) < 1;
    });
  }, [props.usersWithRoles]);
  return (
    <Row align="middle">
      <Col flex="auto">
        <Space size="large" style={{ marginLeft: 16, width: '100%' }}>
          <Statistic title="Total Users" value={props.usersWithRoles.length} />
          <Statistic title="External Users" value={externalUsers.length} />
          <Statistic title="Users without Roles" value={usersWithoutRoles.length} />
        </Space>
      </Col>
      {permissions.notificationSvc$restrictedUserSubscriptionAddRequest ? (
        <Col flex="32px">
          <SubscriptionPopup showText title="User Created" topics={[{ key: 'users/*/created', title: 'User Created' }]} />
        </Col>
      ) : null}
    </Row>
  );
};
