import { Result, Button, Collapse, Descriptions } from 'antd';
import { useRouteError } from 'react-router-dom';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { PlainRouteLayout } from '../../../routing/PlainRouteLayout';
import React from 'react';

export const ErrorBoundary = () => {
  const error = useRouteError() as any;
  console.error(error);
  return (
    <PlainRouteLayout>
      <CenteredLayout>
        <Result
          title="Something went wrong"
          subTitle="Please report the error displayed in details"
          status="warning"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                document.location.href = '/';
              }}
            >
              Back to Home
            </Button>
          ]}
        >
          <Collapse ghost>
            <Collapse.Panel header="Details" key="1">
              <Descriptions column={1} labelStyle={{ width: '240px' }}>
                <Descriptions.Item label="Name">{error?.name}</Descriptions.Item>
                <Descriptions.Item label="Message"> {error?.message}</Descriptions.Item>
                <Descriptions.Item label="Stack"> {error?.stack}</Descriptions.Item>
                <Descriptions.Item label="Url"> {window.location.href}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
        </Result>
      </CenteredLayout>
    </PlainRouteLayout>
  );
};
