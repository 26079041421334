import _ from 'lodash';
import EventEmitter from 'eventemitter3';
import { useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import { MessageManager } from '../../shared/messaging/messageManager';
import { useNotificationWebsocket } from '../../shared/messaging/useNotificationWebsocket';
import { NotificationsBackend } from '../service/notificationsBackend';
import { SubscriptionDetail, PacTSNotification, PacTSNotificationPage, RequestNotification } from '../domain/types';
import { WebsocketOperations } from '../../shared/messaging/managedWebsocket';

class WebsocketNotificationsBackend implements NotificationsBackend {
  private readonly emitter = new EventEmitter();

  constructor(public readonly ws: MessageManager, private readonly ops: WebsocketOperations) {
    this.ws.on('notifications/receive', (n: PacTSNotification) =>
      this.emitter.emit('notifications/receive', WebsocketNotificationsBackend.fixDatesForNotification(n))
    );
  }

  reconnect(): void {
    this.ops.reconnect();
  }

  offNotification(handler: (notification: PacTSNotification) => any): void {
    this.emitter.off('notifications/receive', handler);
  }

  offNotificationDeleted(handler: (notificationIds: string[]) => any): void {
    this.ws.off('notifications/delete', handler);
  }

  offNotificationArchived(handler: (notificationIds: string[]) => any): void {
    this.ws.off('notifications/archive', handler);
  }

  offSubscriptionAdded(handler: () => any): void {
    this.ws.off('restricted/subscriptions/add', handler);
    this.ws.off('subscriptions/add', handler);
  }

  offSubscriptionDeleted(handler: () => any): void {
    this.ws.off('restricted/subscriptions/delete', handler);
    this.ws.off('subscriptions/delete', handler);
  }

  onNotification(handler: (notification: PacTSNotification) => any): void {
    this.emitter.on('notifications/receive', handler);
  }

  onNotificationDeleted(handler: (notificationIds: string[]) => any): void {
    this.ws.on('notifications/delete', handler);
  }

  onNotificationArchived(handler: (notificationIds: string[]) => any): void {
    this.ws.on('notifications/archive', handler);
  }

  onSubscriptionAdded(handler: () => any): void {
    this.ws.on('subscriptions/add', handler);
  }

  onSubscriptionDeleted(handler: () => any): void {
    this.ws.on('subscriptions/delete', handler);
  }

  ping(args: { timeout?: number }): Promise<void> {
    return this.ws.send('ping', undefined, undefined, args?.timeout);
  }

  subscribe(topics: SubscriptionDetail[], restricted: boolean): Promise<void> {
    return this.ws.send(`${restricted ? 'restricted/' : ''}subscriptions/add`, topics);
  }

  unsubscribe(topics: SubscriptionDetail[], restricted: boolean): Promise<void> {
    return this.ws.send(`${restricted ? 'restricted/' : ''}subscriptions/delete`, topics);
  }

  deleteUser(userId: string): Promise<void> {
    return this.ws.send('users/delete', userId);
  }

  sendNotification(
    notification: { title: string; content?: string | undefined; topic: string; expiry?: string | undefined; trigger?: { tokenRefresh?: boolean } },
    sender: string, restricted: boolean
  ): Promise<void> {
    const { title, content, topic, expiry } = notification;
    const payload: RequestNotification = {
      content: {
        title,
        content
      },
      id: v4(),
      meta: {
        user: {
          name: sender
        },
        trigger: notification.trigger
      },
      topic,
      expiry
    };
    return this.ws.send(`${restricted ? 'restricted/' : ''}notifications/send`, payload, undefined, 60000);
  }

  archiveNotifications(ids: string[]): Promise<void> {
    return this.ws.send('notifications/archive', ids);
  }

  deleteNotifications(ids: string[]): Promise<void> {
    return this.ws.send('notifications/delete', ids);
  }

  async queryNotifications(): Promise<PacTSNotification[]> {
    const pages: PacTSNotificationPage[] = [];
    let nextPageParam;
    do {
      const pageQuery: { before: Date | undefined } = {
        before: nextPageParam
      };
      // eslint-disable-next-line no-await-in-loop
      const result = await this.ws.send<PacTSNotificationPage>('notifications', pageQuery);
      pages.push(result);
      if (result.page.count === 0) break;
      if (result.page.count >= result.page.totalCount) break;
      nextPageParam = result.page.oldest;
    } while (nextPageParam);
    return _.uniqBy(
      pages
        .map((p) => p.content)
        .flat()
        .map(WebsocketNotificationsBackend.fixDatesForNotification)
        .sort((a, b) => b.received.getTime() - a.received.getTime()),
      (e) => e.id
    );
  }

  async queryNotificationsPage(pageParams: { before: Date | undefined }): Promise<PacTSNotificationPage> {
    const params = {
      before: pageParams.before?.toISOString()
    };
    const res = await this.ws.send<PacTSNotificationPage>('notifications', params);
    WebsocketNotificationsBackend.fixDatesForPage(res);
    return res;
  }

  queryNotification(id: string): Promise<PacTSNotification> {
    return this.ws.send<PacTSNotification>('notifications/get', id);
  }

  querySubscriptions(): Promise<SubscriptionDetail[]> {
    return this.ws.send<SubscriptionDetail[]>('subscriptions');
  }

  private static fixDatesForNotification(n: PacTSNotification) {
    try {
      n.received = new Date(n.received);
      n.expiry = new Date(n.expiry);
    } catch (error) {
      console.warn('cannot parse notification dates', error);
      n.received = new Date();
      n.expiry = new Date();
    }
    return n;
  }

  private static fixDatesForPage(c: PacTSNotificationPage) {
    c.content.forEach(WebsocketNotificationsBackend.fixDatesForNotification);
    c.page.latest = new Date(c.page.latest);
    c.page.oldest = new Date(c.page.oldest);
    return c;
  }
}

export const useWebsocketNotificationsBackend = () => {
  const ws = useNotificationWebsocket();
  const activeBackend = useRef<WebsocketNotificationsBackend>(new WebsocketNotificationsBackend(ws[0], ws[2]));
  const backend = useMemo(() => {
    if (activeBackend.current.ws !== ws[0]) {
      console.warn('new backend instance');
      activeBackend.current = new WebsocketNotificationsBackend(ws[0], ws[2]);
      return activeBackend.current;
    }
    return activeBackend.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws[0], ws[2]]);

  return useMemo(() => {
    return { backend, state: ws[1] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backend, ws[1]]);
};
