import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

type IconProps = {
  style?: React.CSSProperties;
  size?: number;
  className?: string;
};
export const Circle = (props: IconProps) => {
  const usedSize = props.size ?? 16;
  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg width={usedSize} height={usedSize} viewBox={`0 0 ${usedSize} ${usedSize}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle fill="currentColor" cx={usedSize / 2} cy={usedSize / 2} r={usedSize / 2} />
    </svg>
  );
  return <Icon {...props} component={svg} />;
};
