import { SyncOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';

const uploadingText = `
This version is not yet available.
Please check back in a few minutes.
`;

export const SyncingTag = () => (
  <Tooltip title={uploadingText}>
    <Tag icon={<SyncOutlined spin />} color="processing">
      syncing
    </Tag>
  </Tooltip>
);
