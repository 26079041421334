import { Table } from 'antd';
import { DeviceAction } from '../../../api';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { Comparator } from '../../../domain/extensions/comparison';

export const CurrentInstallationsTable = (props: { data: DeviceAction[] }) => {
  const nameSearch = useTableSearch({ searchValueProvider: 'affectedSoftwareComponent.name' });
  const installerNameSearch = useTableSearch({ searchValueProvider: 'executor.name' });
  const installDateSorter = (a: DeviceAction, b: DeviceAction) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
  const softwareSorter = (a: DeviceAction, b: DeviceAction) =>
    Comparator.lexicographicalComparison(a.affectedSoftwareComponent.name, b.affectedSoftwareComponent.name);
  const executorSorter = (a: DeviceAction, b: DeviceAction) => Comparator.lexicographicalComparison(a.executor.name, b.executor.name);

  return (
    <Table
      size="small"
      columns={[
        {
          title: 'Software',
          key: 'component',
          ...nameSearch,
          sorter: softwareSorter,
          render: (i: DeviceAction) => {
            return i.affectedSoftwareComponent.name;
          }
        },
        { title: 'Version', key: 'version', render: (i: DeviceAction) => i.affectedSoftwareComponent.version },
        { title: 'Installation responsible', key: 'executor', sorter: executorSorter, ...installerNameSearch, render: (i: DeviceAction) => i.executor.name },
        { title: 'Installation date', key: 'date', sorter: installDateSorter, render: (i: DeviceAction) => formatDateTime(new Date(i.timestamp)) }
      ]}
      dataSource={props.data}
    />
  );
};
