import { invokeUiNotification } from '../../contexts/shared/hooks/userNotificationProvider';
import { v4 } from 'uuid';

export const presentInfo = (message: string) => {
  invokeUiNotification({
    type: 'info',
    message: 'Info',
    key: v4(),
    description: message
  });
};

export default presentInfo;
