import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useUpdateOtc } from '../hooks/useUpdateOtc';
import { OtcForm } from './OtcForm';
import { Otc } from '../../../api';
import { Button, Drawer, Flex, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';

export const UpdateOtc = (props: { otc: Otc; projectId: number }) => {
  const params = useParams();
  const envId = params?.envId || '';
  const updateOtc = useUpdateOtc(props.projectId, envId);
  const [modalVisible, setModalVisible] = useState(false);
  let okHandler: () => any;
  let resetHandler: () => any;

  const handleCloseDrawer = () => {
    resetHandler();
    setModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Edit">
        <Button
          loading={updateOtc.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
          type="text"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Drawer
        title="Edit OTC"
        width={780}
        destroyOnClose
        onClose={handleCloseDrawer}
        footer={
          <Flex justify="end">
            <Button style={{ width: '125px', marginRight: '10px' }} onClick={handleCloseDrawer} loading={updateOtc.isLoading}>
              Cancel
            </Button>
            <Button style={{ width: '125px' }} type="primary" onClick={() => okHandler()} loading={updateOtc.isLoading}>
              Save
            </Button>
          </Flex>
        }
        open={modalVisible}
      >
        <OtcForm
          projectId={props.projectId}
          initial={{ ...props.otc }}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          onFinish={async (c) => {
            await updateOtc.mutateAsync([c]);
            setModalVisible(false);
          }}
        />
      </Drawer>
    </>
  );
};
