import { Button, Space, Tooltip, Typography } from 'antd';
import { Project } from '../../../api';
import { useCurrentUser, usePermissions } from '../../session';
import { useProjectMembers } from '../hooks';
import { useCreateProjectAccessRequest } from '../hooks/useCreateProjectAccessRequest';
import { useProjectAccessRequest } from '../hooks/useProjectAccessRequest';
import { MarkdownRenderer } from '../../../contexts/shared/components/MarkdownRenderer';

export const ProjectMembership = (props: { project: Project }) => {
  const accessRequest = useProjectAccessRequest(props.project.idProject);
  const joinProject = useCreateProjectAccessRequest();
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });

  const hasPermissions = permissions.engineeringSvc$getProjectMemberAccessRequest && permissions.engineeringSvc$addProjectMemberAccessRequest;
  const user = useCurrentUser();
  const members = useProjectMembers(props.project.idProject.toString());
  const myself = members.data?.find((m) => m.member.idMember === user.id);

  const isLoading = !accessRequest.isSuccess || !members.isSuccess;

  const canRequestAccess = !isLoading && !myself && !accessRequest.data;
  const hasRequestedAccess = !!accessRequest.data;

  if (!hasPermissions) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (canRequestAccess || hasRequestedAccess) {
    return (
      <Tooltip title="Joining the project will give you access to the project's resources.">
        <Button type="primary" loading={isLoading} onClick={() => joinProject.mutate(props.project)} disabled={hasRequestedAccess}>
          {hasRequestedAccess ? 'Request sent' : 'Join Project'}
        </Button>
      </Tooltip>
    );
  }

  const tooltip = (
    <>
      <Typography.Text style={{ color: 'inherit' }} strong>
        My role(s) for this project
      </Typography.Text>
      <ul style={{ paddingLeft: 16 }}>
        {myself?.roles.map((r) => (
          <>
            <li style={{ marginTop: 16 }}>
              <Typography.Text style={{ color: 'inherit' }} strong>
                {r.name}
              </Typography.Text>
              <MarkdownRenderer>{r.description}</MarkdownRenderer>
            </li>
          </>
        ))}
      </ul>
    </>
  );

  return (
    <Space size={4}>
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>My role(s):</Typography.Text>
      <Tooltip title={tooltip}>
        <div style={{ maxWidth: 160 }}>
          <Typography.Link ellipsis>{myself?.roles.map((r) => r.name).join(', ')}</Typography.Link>
        </div>
      </Tooltip>
    </Space>
  );
};
