import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Project, ProjectCreate } from '../../../api/engineering/domain/types';

export function useCreateProject() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<Project, any, [ProjectCreate]>(([project]) => {
    return new Promise<Project>((resolve, reject) => {
      backend
        .createProject(project)
        .then((updatedProject) => {
          queryClient.invalidateQueries(['projects']);
          presentSuccess(`Successfully created Project ${updatedProject.name}`);
          return resolve(updatedProject);
        })
        .catch(reject);
    });
  });
}
