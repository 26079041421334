import { Typography } from 'antd';
import { InputExplanationWrapper } from './InputExplanationWrapper';

export const ExcelReportSignWarning = () => (
  <InputExplanationWrapper>
    <Typography.Paragraph type="danger">
      Singing Excel-Type reports is not recommended since even opening and saving without any change will alter the file's content, rendering the signature
      invalid. PDF is recommended for this case. The generated excel reports are protected when signing is enabled.
    </Typography.Paragraph>
  </InputExplanationWrapper>
);
