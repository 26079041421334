import { BellFilled, BellOutlined } from '@ant-design/icons';
import { Button, Popover, Typography } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useMemo } from 'react';
import { usePermissions } from '../../session/hooks/usePermissions';
import { isRestrictedTopic } from '../domain/restricted';
import { useDeleteSubscription, useSendSubscriptions, useSubscriptionRegex } from '../hooks/useSubscriptions';
import { SubscriptionToggles } from './SubscriptionToggles';
import { BellTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

const checkTransportMeans = ['PACTS', 'IMPACTS', 'EMAIL'];

export const BellHalfFilled = styled(BellTwoTone)`
  color: purple;
  > svg > path:nth-child(1) {
    // fill
    fill: ${({ theme }) => theme.colorTextDisabled} !important;
  }
  > svg > path:nth-child(2) {
    // stroke
    fill: ${({ theme }) => theme.colorText} !important;
  }
`;

export const SubscriptionPopup = (props: {
  topics: { key: string; title: string }[];
  title?: string;
  showText?: boolean;
  disabled?: boolean;
  placement?: TooltipPlacement;
  /**
   * Define the type of the button.
   * Reference: https://ant.design/components/button#components-button-demo-icon
   * @default "default"
   */
  type?: 'text' | 'default' | 'primary' | 'dashed' | 'link';
}) => {
  const permissions = usePermissions();
  const subscriptions = useSubscriptionRegex();
  const subscribe = useSendSubscriptions();
  const unsubscribe = useDeleteSubscription();

  const subscribeRestrictedPermissions =
    permissions.notificationSvc$restrictedUserSubscriptionAddRequest && permissions.notificationSvc$restrictedUserSubscriptionsDeleteRequest;
  const subscribePermissions = permissions.notificationSvc$userSubscriptionAddRequest && permissions.notificationSvc$userSubscriptionsDeleteRequest;

  const onlyRestricted = useMemo(() => props.topics.every((t) => isRestrictedTopic(t.key)), [props.topics]);
  const hasPermissions = onlyRestricted ? subscribeRestrictedPermissions : subscribePermissions;

  const matchState = useMemo(() => {
    const matches = props.topics.map((t) => {
      const topicMatches = subscriptions.data?.filter((s) => s.topicPatternRegex.test(t.key)) || [];
      const fullMatch = checkTransportMeans.every((tm) => topicMatches.find((m) => m.transportMean === tm));
      return {
        key: t.key,
        matches: topicMatches,
        fullMatch,
        partialMatch: topicMatches.length > 0
      };
    });
    const partialMatch = matches.some((m) => m.partialMatch);
    const fullMatch = matches.every((m) => m.fullMatch);

    return {
      matches,
      partialMatch,
      fullMatch
    };
  }, [subscriptions.data, props.topics]);

  const matchIcon = useMemo(() => {
    if (matchState.fullMatch) {
      return <BellFilled />;
    }
    if (matchState.partialMatch) {
      return <BellHalfFilled />;
    }
    return <BellOutlined />;
  }, [matchState]);

  const loading = subscriptions.isLoading || subscribe.isLoading || unsubscribe.isLoading;

  return (
    <>
      {!hasPermissions && <></>}
      {hasPermissions && (
        <Popover
          placement={props.placement || 'bottomRight'}
          content={
            <div style={{ padding: 8 }}>
              <SubscriptionToggles topics={props.topics} />
            </div>
          }
          title={<Typography.Text strong>{props.title && props.showText ? props.title : 'Notifications'}</Typography.Text>}
          trigger="click"
        >
          <Button
            id={`subscription-popup-${props.topics.map((t) => t.key).join('+')}`}
            loading={loading}
            icon={matchIcon}
            disabled={props.disabled || !hasPermissions}
            type={props.type ?? 'default'}
          />
        </Popover>
      )}
    </>
  );
};
