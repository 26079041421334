import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content .ant-modal-title::after {
    content: '';
    display: block;
    height: 1px;
    background: ${({ theme }) => theme.colorBorderSecondary};
    margin: 20px -24px;
  }

  .ant-modal-content .ant-modal-footer::before {
    content: '';
    display: block;
    height: 1px;
    background: ${({ theme }) => theme.colorBorderSecondary};
    margin: 20px -24px;
  }
`;