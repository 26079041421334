import { useState, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';

import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useProjectSoftwareAppVersions } from '../hooks/useProjectSoftwareappVersions';
import { useUpdateProjectSoftwareApp } from '../hooks/useUpdateProjectSoftwareApp';
import { UpdateSoftwareAppForm } from './UpdateSoftwareAppForm';
import { SoftwareApp } from '../../../api/engineering/domain/types';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { Form } from 'antd';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export type UpdateSoftwareAppProps = {
  projectId: string;
  softwareApp: SoftwareApp;
  detailView?: boolean
};

export const UpdateProjectSoftwareApp = (props: UpdateSoftwareAppProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const updateSoftwareApp = useUpdateProjectSoftwareApp();
  const versions = useProjectSoftwareAppVersions(props.projectId, props.softwareApp.idSoftwareApp?.toString() || '');
  const permission = usePermissions({
    softwareAppId: props.softwareApp.idSoftwareApp.toString(),
    projectId: props.projectId
  });


  let okHandler: () => any;
  let resetHandler: () => any;
  const [form] = Form.useForm();

  useEffect(() => {
    if (updateSoftwareApp.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [form, updateSoftwareApp.isSuccess]);


  if (!permission.engineeringSvc$updateProjectSoftwareApp) return null;

  return (
    <>
      <ExpandableMenuItem
        icon={<EditOutlined />}
        loading={updateSoftwareApp.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
        buttonMode={props.detailView}
      >
        Edit
      </ExpandableMenuItem>
      <ScrollDialog
        title="Edit Software App"
        destroyOnClose
        confirmLoading={updateSoftwareApp.isLoading}
        open={modalVisible}
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
      >
        <UpdateSoftwareAppForm
          initial={props.softwareApp}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              updateSoftwareApp
                .mutateAsync([props.projectId, ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
          versions={versions.data || []}
        />
      </ScrollDialog>
    </>
  );
};
