import { useContext, useEffect } from 'react';
import { StepsWithNumber } from '../types';
import { ProductTourContext } from '../ProductTourContext';

const useAddTourSteps = (newSteps: StepsWithNumber, tourId: string, deps: any[] = []) => {
  const { handleAddSteps, stepsState } = useContext(ProductTourContext);
  const selectedTourSteps = stepsState[tourId] ?? [];
  const selectedTourIds = selectedTourSteps.map((step) => step.step);

  useEffect(() => {
    const computedSteps = newSteps.flatMap((step) => {
      if (selectedTourIds.includes(step.step)) {
        return [];
      }

      return [{ ...step }];
    });
    handleAddSteps(computedSteps, tourId);

    // In order to avoid unnecessary re-renders we need to keep minimal dependency array
    // therefore we disable eslint dependency check as there is an open issue for this purpose
    // eslint-disable-next-line
  }, deps);
};

export default useAddTourSteps;
