import { useState } from 'react';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useCreateProject } from '../hooks';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { ProjectForm } from './ProjectForm';
import { ProjectCreate } from '../../../api/engineering/domain/types';
import { ScrollDialog } from '../../layout/components/ScrollDialog';

const initial: ProjectCreate = {
  name: '',
  description: '',
  status: '',
  startDate: '',
  endDate: '',
  isArchived: false,
  projectType: {
    idProjectType: -1
  },
  projectSolutionType: {
    id: -1
  },
  projectPhase: {
    id: -1
  }
};

export const AddProject = () => {
  const permissions = usePermissions();
  const [modalVisible, setModalVisible] = useState(false);

  const createProject = useCreateProject();
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  return (
    <>
      {permissions.engineeringSvc$addProject && (
        <ExpandableMenuItem
          icon={<PlusOutlined />}
          buttonMode
          loading={createProject.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add Project
        </ExpandableMenuItem>
      )}
      <ScrollDialog
        title="Add Project"
        confirmLoading={createProject.isLoading}
        open={modalVisible}
        destroyOnClose
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
      >
        <ProjectForm
          initial={initial}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              createProject
                .mutateAsync([ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ScrollDialog>
    </>
  );
};
