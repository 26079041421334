import EventEmitter from 'eventemitter3';
import { v4 } from 'uuid';
import { WebSocketError, WebSocketMessage } from './types';

export class MessageManager {
  private readonly bufferSize = 64;

  private readonly topicEmitter = new EventEmitter();

  private readonly idEmitter = new EventEmitter();

  private sendMessage: ((message: string) => any) | undefined = undefined;

  private readonly messageBuffer: string[] = [];

  public setSendMessage(newSendMessage: (message: string) => any) {
    this.sendMessage = newSendMessage;
    this.messageBuffer.forEach(newSendMessage);
    this.messageBuffer.length = 0;
  }

  public addInputEmitter(inputEmitter: EventEmitter) {
    inputEmitter.on('message', (msg) => this.onMessage(msg));
  }

  private onMessage = (compressedMessage: MessageEvent<any>) => {
    if (!compressedMessage) {
      return;
    }
    if (!compressedMessage.data) {
      return;
    }
    try {
      const rawParsed = JSON.parse(compressedMessage.data);
      const parsed = { ...rawParsed } as WebSocketMessage;
      // this.pushToMessageLog(parsed, MessageDirection.INCOMING);

      // TODO: harmonize message id structure
      if (parsed.action) {
        this.topicEmitter.emit(parsed.action, parsed.payload, parsed.correlationId);
      }
      if (parsed.correlationId) {
        this.idEmitter.emit(parsed.correlationId, parsed, parsed.correlationId);
      }
      // TODO: remove because legacy
      if (parsed.messageId) {
        this.idEmitter.emit(parsed.messageId, parsed, parsed.messageId);
      }
    } catch (error) {
      console.error('Unsupported websocket message', error, compressedMessage);
    }
  };

  public send = async <T>(action: string, payload?: any, predefinedRequestId?: string, timeout?: number, shouldAddRequest = true): Promise<T> => {
    return new Promise((resolve, reject) => {
      const messageId = predefinedRequestId || v4();
      const traceId = v4();
      // TODO: remove once fixed in pactsformation
      const content = {
        messageId,
        traceId,
        payload,
        action
      };
      const request = {
        action,
        messageId,
        traceId,
        request: shouldAddRequest ? content : undefined,
        payload
      };
      const stringifiedRequest = JSON.stringify(request);
      let timeoutHandle: NodeJS.Timeout | undefined;
      const handler = (data: WebSocketMessage | WebSocketError, correlationId: string): void => {
        // Unsubscribe message id emitter
        this.idEmitter.off(messageId, handler);
        if (timeoutHandle) {
          clearTimeout(timeoutHandle);
        }
        // Detect errors based on status code of response
        if ((data as WebSocketError)?.statusCode && (data as WebSocketError)?.statusCode > 299) {
          console.warn('websocket usecase error', { data, correlationId });
          return reject(data);
        }
        return resolve(data.payload);
      };
      // Register response handler based on message id
      this.idEmitter.on(messageId, handler);

      // Create timeout handle
      timeoutHandle = setTimeout(
        () => {
          console.error('timeout', messageId, action);
          this.idEmitter.off(messageId, handler);
          const error: WebSocketError = {
            action: 'timeout error',
            reason: [{ propertyPath: action, message: 'timeout' }],
            messageId,
            traceId,
            statusCode: 408
          };
          reject(error);
        },
        timeout ?? 10 * 1000
      );

      if (this.sendMessage) {
        this.sendMessage(stringifiedRequest);
      } else {
        this.bufferMessage(stringifiedRequest);
      }
    });
  };

  private bufferMessage(msg: string) {
    const newLen = this.messageBuffer.push(msg);
    if (newLen > this.bufferSize) {
      const shifted = this.messageBuffer.shift();
      console.warn('shifting buffer', shifted);
    }
  }

  public on = (topic: string, callback: (data: any, correlationId?: string) => any) => {
    this.topicEmitter.on(topic, callback);
    return this;
  };

  public off = (topic: string, callback: (data: any) => any) => {
    this.topicEmitter.off(topic, callback);
    return this;
  };

  public once = (topic: string, callback: (data: any) => any) => {
    this.topicEmitter.once(topic, callback);
    return this;
  };
}
