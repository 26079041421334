import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { DeploymentBackend } from '../service/deploymentBackend';

type DeploymentBackendHookType = {
  backend: DeploymentBackend;
  setBackend: (newBackend: DeploymentBackend) => any;
};
const initData: DeploymentBackendHookType = {
  backend: undefined as any as DeploymentBackend,
  setBackend: () => {
    /* */
  }
};
export const useSetInitialDeploymentBackend = (backend: DeploymentBackend) => {
  initData.backend = backend;
};
export const useDeploymentBackend = singletonHook<DeploymentBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<DeploymentBackend>(initData.backend);
  return {
    backend: backendState,
    setBackend
  };
});
