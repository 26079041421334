import { VersionPart } from './versionPart';

export class VersionAdditionalNumber implements VersionPart {
  private readonly versionParts: number[];

  private readonly raw: string;

  public constructor(s: string) {
    const splitted = s.split('.');
    this.raw = s;
    this.versionParts = splitted.map((v) => parseInt(v, 10));
  }

  equals(part: VersionPart): boolean {
    if (part instanceof VersionAdditionalNumber) {
      return this.toString() === part.toString();
    }
    return false;
  }

  compareTo(part: VersionPart): number {
    if (part instanceof VersionAdditionalNumber) {
      const partAsVersion = part as VersionAdditionalNumber;
      const minPartsLength = Math.min(partAsVersion.numberOfParts(), this.numberOfParts());
      for (let i = 0; i < minPartsLength; i++) {
        if (this.versionParts[i] !== partAsVersion.versionParts[i]) {
          return partAsVersion.versionParts[i] - this.versionParts[i];
        }
      }
      return partAsVersion.numberOfParts() - this.numberOfParts();
    }
    return -1;
  }

  public increment() {
    this.versionParts[this.versionParts.length - 1]++;
  }

  public numberOfParts() {
    return this.versionParts.length;
  }

  public toString(): string {
    return this.raw;
  }
}
