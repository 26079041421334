import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Project, ProjectMemberAccessRequest } from '../../../api/engineering/domain/types';

export function useCreateProjectAccessRequest() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectMemberAccessRequest, any, Project>((project) => {
    return new Promise<ProjectMemberAccessRequest>((resolve, reject) => {
      backend
        .addProjectAccessRequest(project.idProject)
        .then((ret) => {
          queryClient.invalidateQueries(['projectAccessRequest', project.idProject]);
          queryClient.invalidateQueries(['projectAccessRequests', project.idProject]);
          presentSuccess(
            'As soon as the request will be processed, you will be notified via email and PacTS notifications.',
            `Your request to join ${project.name} was sent.`
          );
          return resolve(ret);
        })
        .catch(reject);
    });
  });
}
