/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

type IconProps = {
    style?: React.CSSProperties;
    className?: string;
};

export const SafetyWarning = (props: IconProps) => {
    const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
        <svg height="1em" width="1em" viewBox="64 64 896 896" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M866.996 169.85L527.198 54.0499C523.098 52.6499 517.598 51.95 512.098 51.95C506.598 51.95 501.098 52.6499 496.998 54.0499L157.2 169.85C148.9 172.65 142.1 182.25 142.1 191.05V673.45C142.1 682.25 147.8 693.85 154.7 699.35L499.398 967.95C502.898 970.65 507.398 972.05 511.998 972.05C516.598 972.05 521.198 970.65 524.598 967.95L869.296 699.35C876.196 693.95 881.896 682.35 881.896 673.45V191.05C882.096 182.25 875.296 172.75 866.996 169.85ZM810.096 654.25L512.098 886.45L214.1 654.25V226.65L512.098 125.05L810.096 226.65V654.25Z" fill="currentColor" />
            <path d="M463.902 263.449C463.246 255.751 468.931 249.114 476.182 249.114H547.818C555.069 249.114 560.754 255.751 560.098 263.449L537.679 526.334C537.102 533.105 531.777 538.289 525.4 538.289H498.6C492.223 538.289 486.898 533.105 486.321 526.334L463.902 263.449Z" fill="currentColor" />
            <path d="M561.32 656.587C561.32 685.625 539.239 709.164 512 709.164C484.761 709.164 462.68 685.625 462.68 656.587C462.68 627.55 484.761 604.01 512 604.01C539.239 604.01 561.32 627.55 561.32 656.587Z" fill="currentColor" />
        </svg>
    );

    return <Icon {...props} component={svg} />;
};
