import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { EngineeringBackend } from '../service/engineeringBackend';

type EngineeringBackendHookType = {
  backend: EngineeringBackend;
  setBackend: (newBackend: EngineeringBackend) => any;
};
const initData: EngineeringBackendHookType = {
  backend: undefined as any as EngineeringBackend,
  setBackend: () => {
    /* */
  }
};
export const useSetInitialEngineeringBackend = (backend: EngineeringBackend) => {
  initData.backend = backend;
};
export const useEngineeringBackend = singletonHook<EngineeringBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<EngineeringBackend>(initData.backend);
  return {
    backend: backendState,
    setBackend
  };
});
