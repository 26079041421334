import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { NotificationsBackend } from '../service/notificationsBackend';

type NotificationsBackendState = {
  isReady: boolean;
};

type NotificationsBackendHookType = {
  backend: NotificationsBackend;
  state: NotificationsBackendState;
  setBackend: (args: { backend: NotificationsBackend; state: NotificationsBackendState }) => any;
};
const initData: NotificationsBackendHookType = {
  backend: undefined as any as NotificationsBackend,
  state: {
    isReady: false
  },
  setBackend: () => {
    /* */
  }
};
export const useSetInitialNotificationsBackend = (args: { backend: NotificationsBackend; state: NotificationsBackendState }) => {
  initData.backend = args.backend;
  initData.state = args.state;
};
export const useNotificationsBackend = singletonHook<NotificationsBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<{ backend: NotificationsBackend; state: NotificationsBackendState }>({
    backend: initData.backend,
    state: initData.state
  });
  return {
    backend: backendState.backend,
    state: backendState.state,
    setBackend
  };
});
