import { useQuery } from '@tanstack/react-query';
import { Bundle } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useBundles(projectId: string) {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const permissions = usePermissions({ projectId });

  return useQuery<Bundle[], [string, string]>(
    ['bundles', projectId],
    (): Promise<Bundle[]> => {
      return backend.queryBundles(projectId);
    },
    {
      enabled: parsedProjectId > 0 && permissions.engineeringSvc$getProjectBundles
    }
  );
}

export default useBundles;
