import { formatDateTime } from '../../shared/components/formatDate';
import { Divider, Typography, Row, Col } from 'antd';
import { ReportRevision } from '../../../api';
import styled from 'styled-components';
import { ReleaseNotesTooltip } from './ReleaseNotesTooltip';
import { useRef } from 'react';

const ReleaseNotesWrapper = styled.div`
  max-width: 300px;
`;

export const ReportMetadataHeadline = (props: { report?: ReportRevision }) => {
  const overflowingText = useRef<HTMLDivElement | null>(null);

  if (!props.report) return null;

  // Check if inner text is overflowing and show tooltip if so
  const showTooltip =
    overflowingText.current &&
    (overflowingText.current.offsetHeight < overflowingText.current.scrollHeight || overflowingText.current.offsetWidth < overflowingText.current.scrollWidth);
  const tooltipContent = showTooltip ? props.report.meta.releaseNotes : undefined;

  return (
    <Row gutter={[0, 8]}>
      <Col>
        <span>
          <Divider type="vertical" />
          <Typography.Text type="secondary" ellipsis>
            Created: {formatDateTime(new Date(props.report.meta.createdAt ?? ''))}
          </Typography.Text>
        </span>
      </Col>
      {props.report.meta.releaseNotes ? (
        <Col>
          <Row>
            <Col>
              <Divider type="vertical" />
            </Col>
            <Col>
              <ReleaseNotesTooltip releaseNotes={tooltipContent}>
                <ReleaseNotesWrapper>
                  <Typography.Text ref={overflowingText} ellipsis>
                    {props.report.meta.releaseNotes}
                  </Typography.Text>
                </ReleaseNotesWrapper>
              </ReleaseNotesTooltip>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};
