import { Tag } from 'antd';
import { EqualTag } from './EqualTag';
import { SelectionDiffersTag } from './SelectionDiffersTag';
import { VersionDiffersTag } from './VersionDiffersTag';

export enum ComparisonResult {
  EQUAL,
  VERSION_DIFFERS,
  SELECTION_DIFFERS,
}

export type ComparisonTagProps = {
  comparisonResult: ComparisonResult;
};

export function ComparisonTag(props: ComparisonTagProps) {
  switch (props.comparisonResult) {
    case ComparisonResult.EQUAL:
      return <EqualTag />;
    case ComparisonResult.SELECTION_DIFFERS:
      return <SelectionDiffersTag />;
    case ComparisonResult.VERSION_DIFFERS:
      return <VersionDiffersTag />;
    default:
      return (
        <Tag color="error">
          No specified case occurred
        </Tag>
      );
  }
}
