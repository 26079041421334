import styled from 'styled-components';
import { Space } from 'antd';
import { useRef } from 'react';
import { RevisionMeta } from '../../../api';
import { ReleaseNotesTooltip } from './ReleaseNotesTooltip';
import { RevisionSelectorDropdownLabel } from './RevisionSelectorDropdownLabel';


const TextWrapper = styled.div`
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 95%;
`;

export const RevisionSelectorDropdownOption = (props: { revision: RevisionMeta; latestRevision?: number }) => {
  const overflowingText = useRef<HTMLDivElement | null>(null);

  // Check if inner text is overflowing and show tooltip if so
  const showTooltip =
    overflowingText.current &&
    (overflowingText.current.offsetHeight < overflowingText.current.scrollHeight || overflowingText.current.offsetWidth < overflowingText.current.scrollWidth);
  const tooltipContent = showTooltip ? props.revision.releaseNotes : undefined;

  return (
    <ReleaseNotesTooltip releaseNotes={tooltipContent}>
      <Space direction="vertical" size={0}>
        <RevisionSelectorDropdownLabel revision={props.revision} latestRevision={props.latestRevision} />

        <TextWrapper ref={overflowingText}>
          {props.revision.releaseNotes ?? '-'}
        </TextWrapper>
      </Space>
    </ReleaseNotesTooltip>
  );
};
