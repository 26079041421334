import { useContext } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { ManagedWebsocket, useManagedWebsocket } from './managedWebsocket';
import { MessageManager } from './messageManager';
import { PacTSContext } from '../../../state/store';

const init: ManagedWebsocket = [
  new MessageManager(),
  {
    statusReadable: 'closed',
    status: 0,
    isReady: false,
    isClosed: true,
    isError: false,
    isConnecting: false
  },
  {
    reconnect: () => {
      console.warn('not initialized');
    }
  }
];

export const useNotificationWebsocket = singletonHook(init, () => {
  const [state] = useContext(PacTSContext);
  const notificationWebsocketEnabled = true;
  return useManagedWebsocket({ connectUrl: state.notificationServiceConnectPath, websocketUrl: state.notificationServiceWebsocketPath }, init[0], {
    enabled: notificationWebsocketEnabled
  });
});
