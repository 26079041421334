import { useQuery } from '@tanstack/react-query';
import { Tool } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export function useEngineeringTool(engineeringToolId: string) {
  const { backend } = useEngineeringBackend();
  const parsedToolId = parseInt(engineeringToolId, 10);
  const hasPermission = usePermissions({ toolId: engineeringToolId }).engineeringSvc$getTool;
  return useQuery<Tool, [string, string]>(
    ['engineeringTool', engineeringToolId],
    (): Promise<Tool> => {
      return backend.queryTool(engineeringToolId);
    },
    {
      enabled: parsedToolId > -1 && hasPermission
    }
  );
}
