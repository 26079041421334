import { useCallback, useRef } from 'react';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

export const useInAppNavigate = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  // Set initial uri as ref, updating within this hook is not necessary because referrer uri will not change
  // and rerendering of ths callback when changing the hash must be prevented
  const referrer = useRef(routerLocation?.state?.ref);

  return useCallback(
    (to: To | number, options: NavigateOptions & { keepSearch?: boolean } = {}, hash?: string) => {
      if (typeof to === 'number') {
        navigate(to);
        return;
      }
      // If we have a hash set update the current one
      if (hash) {
        location.hash = hash;
      }

      // If url shall be replaced on the stack, use previous ref if available
      const ref = options.replace ? referrer.current : location.href;
      const isNoopNavigate = location.href?.replace(location.origin, '') === to.toString();
      // Skip navigation to current uri
      if (isNoopNavigate) {
        return;
      }

      if (options.keepSearch && typeof to === 'string') {
        to = to + window.location.search;
      }
      if (options.keepSearch && typeof to !== 'string') {
        to.search = window.location.search;
      }

      navigate(to, { state: { ...options.state, ref, refApp: 'pacts' }, ...options });
    },
    [navigate]
  );
};
