import { useState, useRef, useMemo, useEffect } from 'react';
import { Input, Space, Button, Checkbox } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { Comparator } from '../../../domain';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 8px;
`;

const ValueSpace = styled(Space)`
  max-height: 256px;
  width: 100%;
  overflow-y: auto;
  padding-top: 4px;
  padding-bottom: 4px;
`;
const FilterButton = styled(Button)`
  width: 90px;
`;

const FilterIconWrapper = styled.div<{ filtered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizeLG}px;
  background-color: ${({ filtered, theme }) => (filtered ? theme.colorPrimary : 'transparent')};
  border-radius: 32px;
  width: 32px;
  height: 32px;
  margin: 0 -4px;
`;

const FilterIcon = styled(FilterOutlined)<{ filtered?: boolean }>`
  color: ${({ filtered, theme }) => (filtered ? theme.colorWhite : undefined)};

  svg {
    font-size: ${({ theme }) => theme.fontSizeLG}px;
  }
`;

type TableFilterProps<T> = {
  key: string;
  searchParamId?: string;
  values: { text: string; value: string }[];
  onFilter: (data: T, filterVal: string) => boolean;
};

export const useTableFilter = <T,>(props: TableFilterProps<T>) => {
  const [searchParam, setSearchParam] = useSearchParameter(props.searchParamId || v4());
  const [searchState, setSearchState] = useState({
    searchText: searchParam?.split(',') || []
  });

  const [filterSearch, setFilterSearch] = useState('');

  useEffect(() => {
    if (props.searchParamId) setSearchParam(searchState.searchText.join(',') || '');
  }, [searchState, props.searchParamId, setSearchParam]);

  const searchInput = useRef<any>();
  return useMemo(() => {
    const handleSearch = (selectedKeys: string[], confirm: any) => {
      confirm();
      setSearchState({
        searchText: selectedKeys
      });
    };
    const handleReset = (clearFilters: any) => {
      clearFilters();
      setSearchState({ ...searchState, searchText: [] });
    };
    const getColumnSearchProps = () => ({
      defaultFilteredValue: searchParam ? [searchParam] : [],
      // eslint-disable-next-line
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <Wrapper>
          <Space direction="vertical">
            {props.values.length > 10 && (
              <Input
                value={filterSearch}
                onChange={(e) => {
                  setFilterSearch(e.target.value);
                }}
                placeholder="Search"
              />
            )}
            <ValueSpace direction="vertical">
              {props.values
                .filter((v) => {
                  if (filterSearch === '') return true;
                  return v.text.toLowerCase().includes(filterSearch.toLowerCase());
                })
                .sort((a, b) => Comparator.lexicographicalComparison(a.text, b.text))
                .map((val) => {
                  return (
                    <Checkbox
                      key={val.value}
                      checked={selectedKeys.includes(val.value)}
                      onChange={(e) => {
                        const copy = [...selectedKeys];
                        if (e.target.checked) {
                          if (!selectedKeys.includes(val.value)) copy.push(val.value);
                        } else {
                          copy.splice(copy.indexOf(val.value), 1);
                        }
                        setSelectedKeys(copy);
                      }}
                    >
                      {val.text}
                    </Checkbox>
                  );
                })}
            </ValueSpace>
            <Space>
              <FilterButton type="primary" onClick={() => handleSearch(selectedKeys, confirm)} icon={<FilterOutlined />} size="small">
                Filter
              </FilterButton>
              <FilterButton
                onClick={() => {
                  handleReset(clearFilters);
                  handleSearch([], confirm);
                }}
                size="small"
              >
                Reset
              </FilterButton>
            </Space>
          </Space>
        </Wrapper>
      ),
      filterIcon: (filtered: boolean) => {
        return (
          <FilterIconWrapper id="tour-element1" filtered={filtered}>
            <FilterIcon id={`table-filter-${props.key}`} filtered={filtered} />
          </FilterIconWrapper>
        );
      },
      onFilter: (value: any, record: any) => props.onFilter(record, value),
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      }
    });
    return getColumnSearchProps();
  }, [props, searchState, searchParam, filterSearch]);
};
