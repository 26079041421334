import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useLockDeploymentPlan() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [number, string, string, number]>(([projectId, envId, deploymentPlanId, mvccId]) => {
    return new Promise<void>((resolve, reject) => {
      return backend
        .lockDeploymentPlan(projectId, envId, deploymentPlanId, {
          mvccId
        })
        .then(() => {
          presentSuccess('Successfully closed Deployment');
          queryClient.invalidateQueries(['deploymentplanMetas', projectId, envId]);
          queryClient.invalidateQueries(['deploymentplan', projectId, envId, deploymentPlanId]);
          return resolve();
        })
        .catch(reject);
    });
  });
}
