import { useEffect } from 'react';
import { useEngineeringBackend, useSetInitialEngineeringBackend } from './useEngineeringBackend';
import { useRestEngineeringBackend } from './useRestEngineeringBackend';
import { presentApiError } from '../../shared/errorPresenter';
import { GlobalState } from '../../../state/globalState';

export const useRestEngineeringBackendProvider = (state: GlobalState) => {
  const backend = useRestEngineeringBackend(state);
  useSetInitialEngineeringBackend(backend);
  const { setBackend } = useEngineeringBackend();
  useEffect(() => {
    backend.onError(presentApiError);
    setBackend(backend);
  }, [backend, setBackend]);
};
