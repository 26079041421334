import { CloseCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export function SelectionDiffersTag() {
  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      Selection Differs
    </Tag>
  );
}

export default SelectionDiffersTag;
