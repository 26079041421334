import EventEmitter from 'eventemitter3';
import { useEffect, useState } from 'react';

const pactsUiNotificationEmitter = new EventEmitter();

type PacTSUINotification = {
  type: 'info' | 'success' | 'warning' | 'error';
  message: React.ReactNode;
  description?: React.ReactNode;
  btn?: React.ReactNode;
  key?: React.Key;
  duration?: number | null;
  onClick?: () => void;
};

export const useLastUiNotification = () => {
  const [lastNotification, setLastNotification] = useState<PacTSUINotification | undefined>(undefined);
  useEffect(() => {
    pactsUiNotificationEmitter.on('notification', setLastNotification);
    return () => {
      pactsUiNotificationEmitter.off('notification', setLastNotification);
    };
  }, [setLastNotification]);
  return lastNotification;
};

export const invokeUiNotification = (n: PacTSUINotification): void => {
  pactsUiNotificationEmitter.emit('notification', n);
};
