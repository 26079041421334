import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { UserInfo } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import { useLogout } from '.';
import { AxiosError } from 'axios';
import { GlobalState } from '../../../state/globalState';

const queryKey = ['userInfo'];
export const useUserInfo = (state: GlobalState) => {
  const { backend } = useUserBackend();
  const logout = useLogout();

  // Debounce for backend propagation. This is required for hooks
  // involved in the login process to make sure tokens are available across
  // the system before calls are invoked.
  const debouncedToken = useDebounce<string>(state.token?.token, 100);

  // Since this hook might be called very early in the app's lifecycle
  // we double-check if the backend has propagated through the system
  const hasBackend = backend &&!!backend.getUserInfo;

  // Check if the state currently has a token
  // If a token was already removed, the debounced token might still be available
  const hasToken = !!state.token?.token;

  // Check if the debounced token is not empty and not initial state
  const hasDebouncedToken = !!debouncedToken;

  // Check if the token is stable and has not changed
  const tokenStable = debouncedToken === state.token?.token;

  // Make sure the backend is initialized, in case of loading when a token exists
  // a race condition between querying user info and backend population might exist.
  // Retry would be sufficient, but specifically enabling the query when the
  // backend is ready improves performance.
  // Enable when token has propagated but disable when no token is available
  const enabled = hasDebouncedToken && hasToken && hasBackend && tokenStable;

  return useQuery<UserInfo, [string]>(
    queryKey,
    async (): Promise<UserInfo> => {
      // In case the userinfo endpoint returns 401, the token is not valid
      // The user shall be logged out in this case.
      try {
        return await backend.getUserInfo();
      } catch (error) {
        // Immediately logout if 401 error received, no retry necessary
        console.warn('userinfo failed', error);
        const typedError = (error as AxiosError<{ code?: number }>);
        const unauthorized = typedError?.response?.data?.code === 401 || typedError.response?.status === 401;
        if (unauthorized) {
          console.warn('logout due to unauthorized userinfo');
          logout();
        }
        throw error;
      }
    },
    {
      enabled,
      retry: true,
      retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      retryOnMount: false
    }
  );
};

export const useInvalidateUserInfo = () => {
  const qc = useQueryClient();
  return useCallback(() => {
    qc.cancelQueries(queryKey);
    qc.invalidateQueries(queryKey);
  }, [qc]);
};
