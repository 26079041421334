export class DescriptionValidator {
  private static minLen = 3;

  private static maxLen = 65535;

  private static validate(unsafe?: string): Promise<void> {
    const sanitized = unsafe ?? '';
    const trimmed = sanitized.trim();
    if (trimmed.length < DescriptionValidator.minLen) {
      return Promise.reject(new Error(`Must be at least ${this.minLen} characters long`));
    }
    if (trimmed.length > DescriptionValidator.maxLen) {
      return Promise.reject(new Error(`Must be at most ${this.maxLen} characters long`));
    }
    return Promise.resolve();
  }

  public static rule() {
    return { required: true, validator: (_: any, value: string) => DescriptionValidator.validate(value) };
  }
}
