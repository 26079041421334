import { useQuery } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { usePermissions } from '../../session';
import { Environment } from '../../../api';

export const useDeploymentEnvironments = (projectId: number) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery(
    ['deploymentEnvironments', projectId],
    (): Promise<Environment[]> => {
      return backend.getDeploymentEnvironments(projectId);
    },
    {
      enabled: permissions.deploymentSvc$getEnvironments
    }
  );
};
