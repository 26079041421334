import { useCallback } from 'react';
import { useAuthBackend } from '../../../api/auth/hooks/useAuthBackend';

export const useCodeRedeem = () => {
  const { backend } = useAuthBackend();
  return useCallback(
    (code: string) => {
      return backend.tokenRedeem({ code });
    },
    [backend]
  );
};
