export const METRIC_TAGS = {
  pathname: 'path',
  search: 'search',
  component: 'component',
  componentId: 'component_id',
  componentVersion: 'component_version',
  componentVersionId: 'component_version_id',
  subComponent: 'sub_component',
  subComponentId: 'sub_component_id',
  context: 'context',
  contextId: 'context_id',
  componentType: 'type',
  componentScope: 'scope'
} as const;

type ValueOf<T> = T[keyof T];

export type MetricTag = {
  key: ValueOf<typeof METRIC_TAGS>;
  value: string;
};

export type MeasurementValue = {
  key: string;

  stringValue: string;

  numericValue: number;
};

export type MetricEvent = {
  name: string;
  project: string;
  collection: string;
  timestamp: string;
  tags: Array<MetricTag>;
  correlationId: string;
  operationId: string;
  measurements?: Array<MeasurementValue>;
};

export type SubmitMetricEvent = {
  name: string;
  tags: Array<MetricTag>;
  operationId: string;
  measurements?: Array<MeasurementValue>;
};
