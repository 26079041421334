import { useQuery } from '@tanstack/react-query';
import { ProjectMemberAccessRequest } from '../../../api';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProjectAccessRequests(projectId: number) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  const enabled = permissions.engineeringSvc$getProjectMemberAccessRequests;
  return useQuery<ProjectMemberAccessRequest[], [string, string]>(
    ['projectAccessRequests', projectId],
    (): Promise<ProjectMemberAccessRequest[]> => {
      return backend.getProjectAccessRequests(projectId);
    },
    {
      enabled
    }
  );
}
