/* eslint-disable @typescript-eslint/no-redeclare */

export type Audit = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
};
export type Bundle = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idBundle: number;
  name: string;
  description: string;
};
export type BundleAllOf = {
  idBundle: number;
  name: string;
  description: string;
};
export type BundleConfiguration = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idBundleConfiguration: number;
  name: string;
  description: string;
  latestBundleReleaseId?: number;
};
export type BundleConfigurationAllOf = {
  idBundleConfiguration: number;
  name: string;
  description: string;
  latestBundleReleaseId?: number;
};
export type BundleConfigurationCreate = {
  name: string;
  description: string;
};
export type BundleCreate = {
  name: string;
  description: string;
};

export type ProjectBundleRelease = {
  projectId: number;
  bundleId: number;
  configId: number;
  releaseId: number;
};

export type ProjectBundleReleaseWithNames = ProjectBundleRelease & {
  projectName: string;
  bundleName: string;
  configName: string;
  releaseVersion: string;
};

export type BundleRelease = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idBundleRelease: number;
  version: string;
  releaseNotes: string;
  engineeringTools?: Array<Tool>;
  projectSoftwareApps?: Array<SoftwareApp>;
  softwareApps?: Array<SoftwareApp>;
};

export type BundleReleaseAbstract = {
  idBundleRelease: number;
  version: string;
  releaseNotes: string;
};
export type BundleReleaseAllOf = {
  engineeringTools?: Array<Tool>;
  projectSoftwareApps?: Array<SoftwareApp>;
  softwareApps?: Array<SoftwareApp>;
};
export type BundleReleaseCreate = {
  version: string;
  releaseNotes: string;
  engineeringToolVersionIds?: Array<number>;
  projectSoftwareAppVersionIds?: Array<number>;
  softwareAppVersionIds?: Array<number>;
};
export type BundleReleaseLite = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idBundleRelease: number;
  version: string;
  releaseNotes: string;
  engineeringToolVersionIds?: Array<number>;
  projectSoftwareAppVersionIds?: Array<number>;
  softwareAppVersionIds?: Array<number>;
};
export type BundleReleaseLiteAllOf = {
  engineeringToolVersionIds?: Array<number>;
  projectSoftwareAppVersionIds?: Array<number>;
  softwareAppVersionIds?: Array<number>;
};
export type EngineeringPlatformServicePrincipal = {
  userid?: string;
  name?: string;
  token?: string;
  email?: string;
  gid?: string;
  permissions?: Array<string>;
  validity?: string;
};
export type Member = {
  idMember: number;
  name: string;
  email?: string;
};
export interface ProjectMemberAccessRequest {
  idMember: number;
  idProject: number;
  name: string;
  email?: string;
  creation: Date;
  expiry: Date;
}

export type Project = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idProject: number;
  name: string;
  description: string;
  projectPhase: string;
  status: string;
  startDate: string;
  endDate: string;
  isArchived: boolean;
  projectType: ProjectType;
  projectSolutionType: ProjectSolutionType;
  referenceProjectRelease?: ProjectBundleReleaseWithNames;
  phase: ProjectPhase;
};
export type ProjectAllOf = {
  idProject: number;
  name: string;
  description: string;
  projectPhase: string;
  status: string;
  startDate: string;
  endDate: string;
  isArchived: boolean;
  projectType: ProjectType;
  projectSolutionType: ProjectSolutionType;
  phase: ProjectPhase;
};
export type ProjectCreate = {
  name: string;
  description: string;
  status: string;
  startDate: string;
  endDate: string;
  isArchived: boolean;
  projectType: ProjectCreateProjectType;
  projectSolutionType: ProjectCreateProjectSolutionType;
  projectPhase: ProjectCreateProjectSolutionType;
  referenceProjectRelease?: ProjectBundleRelease;
};
export type ProjectCreateProjectSolutionType = {
  id: number;
};
export type ProjectCreateProjectType = {
  idProjectType: number;
};
export type ProjectMember = {
  member: Member;
  roles: Array<ProjectRole>;
};
export type ProjectMemberChange = {
  member: ProjectMemberChangeMember;
  roles: Array<ProjectMemberChangeRoles>;
};
export type ProjectMemberChangeMember = {
  idMember: number;
};
export type ProjectMemberChangeRoles = {
  idProjectRole: number;
};
export type ProjectPhase = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  id: number;
  name: string;
  description: string;
};
export type ProjectPhaseCreate = {
  name: string;
  description: string;
};
export type ProjectRole = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idProjectRole: number;
  name: string;
  description: string;
};

export type ProjectRoleWithPermissions = ProjectRole & {
  permissions: Record<string, string[]>;
};
export type ProjectRoleAllOf = {
  idProjectRole: number;
  name: string;
  description: string;
  permissions: string;
};
export type ProjectRoleCreate = {
  name: string;
  description: string;
  permissions: Record<string, string[]>;
};
export type ProjectSolutionType = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  id: number;
  name: string;
  description: string;
};
export type ProjectSolutionTypeAllOf = {
  id: number;
  name: string;
  description: string;
};
export type ProjectSolutionTypeCreate = {
  name: string;
  description: string;
};
export type ProjectType = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idProjectType: number;
  name: string;
  description: string;
  isReferenceProject: boolean;
};

export type ProjectTypeUpdate = Omit<ProjectType, 'isReferenceProject'>;

export type ProjectTypeAllOf = {
  idProjectType: number;
  name: string;
  description: string;
};
export type ProjectTypeCreate = {
  name: string;
  description: string;
  isReferenceProject: boolean;
};
export type ProjectUpdate = {
  idProject: number;
  name: string;
  description: string;
  status: string;
  startDate: string;
  endDate: string;
  isArchived: boolean;
  projectType: ProjectCreateProjectType;
  projectSolutionType: ProjectCreateProjectSolutionType;
  projectPhase: ProjectCreateProjectSolutionType;
  referenceProjectRelease?: ProjectBundleRelease;
};

export const ArtifactVersionStateEnum: {
  readonly Consistent: 'consistent';
  readonly CreateArtifactUploading: 'create-artifact-uploading';
  readonly CreateVersionUploading: 'create-version-uploading';
  readonly UpdateVersionUploading: 'update-version-uploading';
} = {
  Consistent: 'consistent',
  CreateArtifactUploading: 'create-artifact-uploading',
  CreateVersionUploading: 'create-version-uploading',
  UpdateVersionUploading: 'update-version-uploading'
};

export type ArtifactVersionStateEnum = (typeof ArtifactVersionStateEnum)[keyof typeof ArtifactVersionStateEnum];

export type SoftwareApp = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idSoftwareApp: number;
  name: string;
  description: string;
  categories: Array<SoftwareAppCategory>;
  latestVersion: SoftwareAppVersion;
  packageKind: SoftwareAppPackageKindEnum;
  installationOptions: string;
  documentationLink: string;
};

export const SoftwareAppPackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type SoftwareAppPackageKindEnum = (typeof SoftwareAppPackageKindEnum)[keyof typeof SoftwareAppPackageKindEnum];

export type SoftwareAppAllOf = {
  idSoftwareApp: number;
  name: string;
  description: string;
  categories: Array<SoftwareAppCategory>;
  latestVersion: SoftwareAppVersion;
  packageKind: SoftwareAppAllOfPackageKindEnum;
  installationOptions: string;
  documentationLink: string;
};
export const SoftwareAppAllOfPackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type SoftwareAppAllOfPackageKindEnum = (typeof SoftwareAppAllOfPackageKindEnum)[keyof typeof SoftwareAppAllOfPackageKindEnum];

export type SoftwareAppCategory = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  id: number;
  name: string;
  description: string;
};
export type SoftwareAppCategoryCreate = {
  name: string;
  description: string;
};
export type SoftwareAppCreate = {
  name: string;
  description: string;
  categories: Array<ProjectCreateProjectSolutionType>;
  latestVersion: SoftwareAppVersionCreate;
  packageKind: SoftwareAppCreatePackageKindEnum;
  installationOptions: string;
  documentationLink?: string;
};
export const SoftwareAppCreatePackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type SoftwareAppCreatePackageKindEnum = (typeof SoftwareAppCreatePackageKindEnum)[keyof typeof SoftwareAppCreatePackageKindEnum];

export type SoftwareAppUpdate = {
  idSoftwareApp: number;
  name: string;
  description: string;
  categories: Array<ProjectCreateProjectSolutionType>;
  latestVersion: SoftwareAppUpdateLatestVersion;
  packageKind: SoftwareAppUpdatePackageKindEnum;
  installationOptions: string;
  documentationLink?: string;
};

export const SoftwareAppUpdatePackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type SoftwareAppUpdatePackageKindEnum = (typeof SoftwareAppUpdatePackageKindEnum)[keyof typeof SoftwareAppUpdatePackageKindEnum];

export type SoftwareAppUpdateLatestVersion = {
  idSoftwareAppVersion: number;
};

export type SoftwareAppUsage = {
  idProject: number;
  configurations: Array<SoftwareAppUsageConfigurations>;
};

export type SoftwareAppUsageConfigurations = {
  idBundleConfiguration: number;
  bundleConfigName: string;
  idSoftwareAppVersion: number;
};

export type SoftwareAppVersion = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idSoftwareAppVersion: number;
  version: string;
  releaseNotes: string;
  targets: Array<TargetWithDownloadLink>;
  state: ArtifactVersionStateEnum;
};

export type SoftwareAppVersionVulnerability = {
  targetId?: number;
  id?: string;
  link?: string;
  notifications?: Array<VulnerabilityNotification>;
};

export type SoftwareAppVersionAllOf = {
  idSoftwareAppVersion: number;
  version: string;
  releaseNotes: string;
  targets: Array<TargetWithDownloadLink>;
};

export type SoftwareAppVersionCreate = {
  version: string;
  releaseNotes: string;
  targets: Array<SoftwareAppVersionCreateTargets>;
};

export type SoftwareAppVersionCreateTarget = {
  idTarget: number;
};

export type SoftwareAppVersionCreateTargets = {
  target: SoftwareAppVersionCreateTarget;
  downloadLink: string;
  sha256?: string;
  icsPortalComponentId?: string;
};

export type SoftwareAppVersionUpdate = {
  idSoftwareAppVersion: number;
  version: string;
  releaseNotes: string;
  targets: Array<SoftwareAppVersionCreateTargets>;
};

export type Target = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idTarget: number;
  name: string;
};

export type TargetAllOf = {
  idTarget: number;
  name: string;
};

export type TargetCreate = {
  name: string;
};

export type TargetWithDownloadLink = {
  target: Target;
  downloadLink: string;
  sha256?: string;
  icsPortalComponentId?: string;
};

export type Tool = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  id: number;
  name: string;
  category: Array<ToolCategory>;
  description: string;
  latestVersion: ToolVersion;
  deprecated: boolean;
  packageKind: ToolPackageKindEnum;
  installationOptions: string;
  documentationLink: string;
};

export const ToolPackageKindEnum: {
  readonly Msi: 'msi';
  readonly Zip: 'zip';
  readonly Nsis: 'nsis';
  readonly Custom: 'custom';
} = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type ToolPackageKindEnum = (typeof ToolPackageKindEnum)[keyof typeof ToolPackageKindEnum];

export type ToolAllOf = {
  id: number;
  name: string;
  category: Array<ToolCategory>;
  description: string;
  latestVersion: ToolVersion;
  deprecated: boolean;
  packageKind: ToolAllOfPackageKindEnum;
  installationOptions: string;
  documentationLink: string;
};

export const ToolAllOfPackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type ToolAllOfPackageKindEnum = (typeof ToolAllOfPackageKindEnum)[keyof typeof ToolAllOfPackageKindEnum];

export type ToolCategory = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  id: number;
  name: string;
  description: string;
};

export type ToolCategoryAllOf = {
  id: number;
  name: string;
  description: string;
};

export type ToolCategoryCreate = {
  name: string;
  description: string;
};

export type ToolCreate = {
  name: string;
  category: Array<ToolCreateCategory>;
  description: string;
  latestVersion: ToolVersionCreate;
  deprecated: boolean;
  packageKind: ToolCreatePackageKindEnum;
  installationOptions: string;
  documentationLink?: string;
};

export const ToolCreatePackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type ToolCreatePackageKindEnum = (typeof ToolCreatePackageKindEnum)[keyof typeof ToolCreatePackageKindEnum];

export type ToolCreateCategory = {
  id: number;
};

export type ToolUpdate = {
  id: number;
  name: string;
  category: Array<ToolCreateCategory>;
  description: string;
  latestVersion: ToolUpdateLatestVersion;
  deprecated: boolean;
  packageKind: ToolUpdatePackageKindEnum;
  installationOptions: string;
  documentationLink?: string;
};

export const ToolUpdatePackageKindEnum: { readonly Msi: 'msi'; readonly Zip: 'zip'; readonly Nsis: 'nsis'; readonly Custom: 'custom' } = {
  Msi: 'msi',
  Zip: 'zip',
  Nsis: 'nsis',
  Custom: 'custom'
};

export type ToolUpdatePackageKindEnum = (typeof ToolUpdatePackageKindEnum)[keyof typeof ToolUpdatePackageKindEnum];

export type ToolUpdateLatestVersion = {
  idToolVersion: number;
};

export type ToolUsage = {
  idProject: number;
  configurations: Array<ToolUsageConfigurations>;
};

export type ToolUsageConfigurations = {
  idBundleConfiguration: number;
  bundleConfigName: string;
  idToolVersion: number;
};

export type ToolVersion = {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  idToolVersion: number;
  version: string;
  releaseNotes: string;
  downloadLink: string;
  state: ArtifactVersionStateEnum;
  sha256?: string;
  icsPortalComponentId?: string;
};

export type ToolVersionCreate = {
  version: string;
  releaseNotes: string;
  downloadLink: string;
  sha256?: string;
  icsPortalComponentId?: string;
};

export type ToolVersionVulnerability = {
  id?: string;
  link?: string;
  notifications?: VulnerabilityNotification[];
};

export type UploadToken = {
  uploadUrl: string;
  contentType: string;
  temporaryFileName: string;
  fileName: string;
};

export type DownloadMeta = {
  pactsSha256: string;
  artifactorySha256?: string;
  contentLength: number;
};

export type Download = DownloadMeta & {
  url: string;
};

export type VulnerabilityNotification = {
  id: string;
  lastUpdateDate?: string;
  publishDate: string;
  cvss?: VulnerabilityNotificationCvss;
  title: string;
};

export type VulnerabilityNotificationCvss = {
  baseScore?: number;
  overallScore?: number;
  temporalScore?: number;
  vector?: string;
};
