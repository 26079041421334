import { Col, Layout, Result, Row, Skeleton } from 'antd';
import { useEffect } from 'react';
import { Comparator } from '../../../domain';
import { CenteredLayout } from '../../layout';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useProjects } from '../hooks';
import { AddProject } from './AddProject';
import { ProjectsMenu } from './ProjectsMenu';
import { SelectedProjectCard } from './SelectedProjectCard';
import { useLocalStorageState } from '../../../contexts/shared/hooks/useLocalStorageState';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';

export const ProjectsPortal = () => {
  const permissions = usePermissions();
  const projects = useProjects();
  const [pinnedProjects] = useLocalStorageState<string[]>('pinned-projects');
  const [type, setType] = useSearchParameter('type');
  const [active, setActive] = useSearchParameter('active');

  const loading = projects.isLoading;

  const typeFilteredProjects = (
    projects.data?.filter((p) => {
      return type ? p.projectType.idProjectType.toString() === type : true;
    }) || []
  )
    .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
    .sort((a, b) => {
      const aN = pinnedProjects?.includes(a.idProject.toString()) ? 1 : 0;
      const bN = pinnedProjects?.includes(b.idProject.toString()) ? 1 : 0;
      return bN - aN;
    });

  const firstUnarchivedProject = typeFilteredProjects.find((p) => !p.isArchived);

  const activeProject =
    typeFilteredProjects?.find((p) => p.idProject.toString() === active) ?? firstUnarchivedProject;

  useEffect(() => {
    if (!active && activeProject) {
      setActive(activeProject.idProject.toString());
    }
  }, [active, activeProject, setActive]);

  useEffect(() => {
    if (!type && activeProject) {
      setType(activeProject.projectType.idProjectType.toString());
    }
  }, [type, activeProject, setType]);

  const siderHeight = 'calc(100vh - 80px - 70px)';

  return (
    <CenteredLayout
      title="Transmission Solutions"
      subtitle={activeProject?.projectType?.name}
      extra={[permissions.engineeringSvc$addProject && <AddProject key="addProject" />]}
      fixed
    >
      <Skeleton loading={loading}>
        <Layout style={{ position: 'relative', marginTop: 0 }}>
          {/* Use grid to fix React 18 Layout issues */}
          <Row wrap={false}>
            <Col>
              <Layout.Sider
                width={240}
                style={{
                  position: 'sticky',
                  top: 64 + 70 + 8,
                  marginRight: 8,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: siderHeight
                }}
              >
                <ProjectsMenu selectableProjects={typeFilteredProjects} />
              </Layout.Sider>
            </Col>
            <Col style={{ minHeight: '100%' }}>
              <Layout.Content style={{ minHeight: '100%' }}>
                {activeProject && <SelectedProjectCard selectedProject={activeProject} minHeight={siderHeight} />}
                {!activeProject && <Result>Select Project from list</Result>}
              </Layout.Content>
            </Col>
          </Row>
        </Layout>
      </Skeleton>
    </CenteredLayout>
  );
};
