import { useEffect } from 'react';
import { useReportBackend, useSetInitialReportBackend } from './useReportBackend';
import { useRestReportBackend } from './useRestReportBackend';
import { presentApiError } from '../../shared/errorPresenter';
import { GlobalState } from '../../../state/globalState';

export const useRestReportBackendProvider = (state: GlobalState) => {
  const backend = useRestReportBackend(state);
  useSetInitialReportBackend(backend);
  const { setBackend } = useReportBackend();
  useEffect(() => {
    backend.onError(presentApiError);
    setBackend(backend);
  }, [backend, setBackend]);
};
