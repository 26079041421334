import { ApiError } from '../../shared/apiError';
import {
  AuthRedeemRequest,
  AuthTokenRefreshRequest,
  AuthTokenResponse,
  AuthRefreshTokenClientIdEnum,
  AuthRefreshTokenProviderEnum,
  OauthDeviceCodeResponse
} from '../domain/auth';

export class AuthBackendError extends ApiError {}

/*
The AuthBackend contains call to the user service which don't need authorization.
It is initialized first, before any token is populate through the system.
*/
export interface AuthBackend {
  onError(handler: (error: AuthBackendError) => any): void;

  tokenRefresh(req: AuthTokenRefreshRequest): Promise<AuthTokenResponse>;
  tokenRedeem(req: AuthRedeemRequest): Promise<AuthTokenResponse>;

  getLoginUri(args: { client: AuthRefreshTokenClientIdEnum; provider: AuthRefreshTokenProviderEnum; redirect: string }): string;

  startDeviceFlow(): Promise<OauthDeviceCodeResponse>;
  pollDeviceFlow(deviceCode: string): Promise<AuthTokenResponse>;

  heartbeat(): Promise<void>;
}
