import dayjs from 'dayjs';
import { useTick } from '../../../hooks/useTick';
import { useMemo } from 'react';

// Account for clock drifts between local browser and S3
const validityOffset = 5;

export const useDownloadUrlExpiration = (url?: string): { remaining: number; total: number } | null => {
  const [tick] = useTick();

  const initialExpiry = useMemo(() => {
    const creationRegex = /X-Amz-Date=([0-9T]+)Z.*X-Amz-Expires=([0-9]+)&/gm;
    const res = creationRegex.exec(url ?? '');
    const hasExpectedRegexParts = res && res.length > 2;

    if (hasExpectedRegexParts) {
      const validity = parseInt(res[2], 10);
      const expiresAt = dayjs(res[1], { format: 'YYYYMMDDTHHmmss', utc: true }).add(validity, 'seconds').subtract(validityOffset, 'seconds').toDate();
      const expiry = Math.floor(expiresAt.getTime() / 1000);
      return { expiry, validity };
    }

    return undefined;
  }, [url]);

  if (!url) return null;
  if (!initialExpiry) return null;
  if (tick < 1) return null;

  const remaining = initialExpiry.expiry - tick;
  if (remaining < 0) {
    return { remaining: 0, total: initialExpiry.validity };
  }

  return { remaining, total: initialExpiry.validity };
};
