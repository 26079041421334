import { useQuery } from '@tanstack/react-query';
import { ProjectMemberAccessRequest } from '../../../api';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProjectAccessRequest(projectId: number) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  const enabled = permissions.engineeringSvc$getProjectMemberAccessRequest;
  return useQuery<ProjectMemberAccessRequest | null, [string, string]>(
    ['projectAccessRequest', projectId],
    async (): Promise<ProjectMemberAccessRequest | null> => {
      const res = await backend.getProjectAccessRequest(projectId);
      if (!res) return null;
      return res;
    },
    {
      enabled
    }
  );
}
