import { SoftwareComponent } from '../../../api';
import { useCommonSoftwareApps } from '../hooks/useCommonSoftwareApps';
import { useCommonSoftwareAppVersions } from '../hooks/useCommonSoftwareappVersions';
import { SoftwareAppVersionPreviewContent } from './SoftwareAppVersionPreviewContent';

export const CommonSoftwareAppVersionContentProvider = (props: { component: SoftwareComponent }) => {
  const apps = useCommonSoftwareApps();
  const appVersions = useCommonSoftwareAppVersions(props.component.id.toString());

  const app = apps.data?.find((a) => a.idSoftwareApp === props.component.id);
  const appVersion = appVersions.data?.find((a) => a.idSoftwareAppVersion === props.component.versionId);
  const isLoading = apps.isLoading || appVersions.isLoading;
  return <SoftwareAppVersionPreviewContent isLoading={isLoading} app={app} version={appVersion} />;
};
