import { List, Skeleton, Space, Typography } from 'antd';
import { Project } from '../../../api';
import { useProjectAccessRequests } from '../hooks/useProjectAccessRequests';
import { ProjectAccessRequestListItem } from './ProjectAccessRequestListItem';
import * as Styled from './ProjectAccessRequests.styled';

export const ProjectAccessRequests = (props: { project: Project }) => {
  const accessRequests = useProjectAccessRequests(props.project.idProject);

  return (
    <Skeleton loading={!accessRequests.isSuccess}>
      <Space>
        <Styled.Badge offset={[8, 0]} count={accessRequests.data?.length}>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Access Requests
          </Typography.Title>
        </Styled.Badge>
      </Space>
      {accessRequests.data?.length ?? 0 > 0 ? (
        <List dataSource={accessRequests.data} renderItem={(item) => <ProjectAccessRequestListItem project={props.project} accessRequest={item} />} />
      ) : (
        <Typography.Paragraph type="secondary">there are no pending access requests</Typography.Paragraph>
      )}
    </Skeleton>
  );
};
