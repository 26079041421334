import { Descriptions, Skeleton } from 'antd';
import { SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { MarkdownRenderer } from '../../shared/components/MarkdownRenderer';

export const SoftwareAppVersionPreviewContent = (props: { app?: SoftwareApp; version?: SoftwareAppVersion; isLoading: boolean }) => {
  return (
    <>
      {props.isLoading && <Skeleton />}
      {!props.isLoading && (
        <Descriptions bordered column={1} labelStyle={{ width: '240px' }}>
          <Descriptions.Item label="App">{props.app?.name}</Descriptions.Item>
          <Descriptions.Item label="Description">
            <MarkdownRenderer>{props.app?.description || ''}</MarkdownRenderer>
          </Descriptions.Item>
          <Descriptions.Item label="Version">{props.version?.version}</Descriptions.Item>
          <Descriptions.Item label="Release Notes">
            <MarkdownRenderer>{props.version?.releaseNotes || 'unknown'}</MarkdownRenderer>
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
