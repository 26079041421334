import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tool } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteEngineeringTool() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [Tool]>(([tool]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteTool(tool.id.toString())
        .then(() => {
          queryClient.invalidateQueries(['engineeringTools']);
          presentSuccess(`Successfully deleted Engineering Tool ${tool.name}`);
          return resolve();
        })
        .catch(reject);
    });
  });
}
