import { ComponentProps } from 'react';

import { VulnerabilityIconButton } from '../../shared/components/VulnerabilityIconButton';

import { useEngineeringToolVersionVulnerabilities } from '../hooks/useEngineeringToolVersionVulnerabilities';

import type { Tool, ToolVersion } from '../../../api/engineering/domain/types';

/**
 * Returns an icon button which opens a drawer that displays
 * the vulnerability notifications.
 */
export const EngineeringToolVersionVulnerabilityButton = (props: {
  app: Tool;
  version: ToolVersion;
  getVulnerabilityDrawerContainer?: ComponentProps<typeof VulnerabilityIconButton>['getDrawerContainer'];
}) => {
  const versionVulnerabilities = useEngineeringToolVersionVulnerabilities(props.app.id, props.version.idToolVersion);

  let notificationsExist = !!versionVulnerabilities.data && !!versionVulnerabilities.data.notifications && versionVulnerabilities.data.notifications.length > 0;

  let vulnerabilities = undefined;

  if (versionVulnerabilities.data) {
    vulnerabilities = [versionVulnerabilities.data];
  }

  return (
    <VulnerabilityIconButton
      appName={props.app.name}
      versionName={props.version.version}
      isLoading={versionVulnerabilities.isLoading}
      isError={versionVulnerabilities.isError}
      error={versionVulnerabilities.error}
      notificationsExist={notificationsExist}
      vulnerabilities={vulnerabilities}
      getDrawerContainer={props.getVulnerabilityDrawerContainer}
    />
  );
};
