import { HistoryOutlined } from '@ant-design/icons';

type IconProps = {
  style?: React.CSSProperties;
  size?: number;
  className?: string;
};
export const Timeout = (props: IconProps) => {
  const usedSize = props.size ?? 16;
  return <HistoryOutlined style={props.style} className={props.className} size={usedSize} />;
};
