import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { UserRoleWithPermissions, UserRoleWithPermissionsUpdate } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';

export function useUpdateUserRole() {
  const { backend } = useUserBackend();
  const queryClient = useQueryClient();
  return useMutation<UserRoleWithPermissions, any, [UserRoleWithPermissionsUpdate]>(([role]) => {
    return new Promise<UserRoleWithPermissions>((resolve, reject) => {
      backend
        .updateRole(role)
        .then((newRoles) => {
          presentSuccess(`Successfully updated User Role ${role.name}`);
          queryClient.invalidateQueries(['userRole', role.id!]);
          queryClient.invalidateQueries(['userRoles']);
          return resolve(newRoles);
        })
        .catch(reject);
    });
  });
}

export default useUpdateUserRole;
