/* eslint-disable @typescript-eslint/no-redeclare */

export type ActionValidationRequest = { method: string; path: string };

export type OauthCodeResponse = { code: string };
export type OauthTokenResponse = { access_token: string };
export type Principal = { userid?: string; permissions?: Array<string>; validity?: string | null };

export type TokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export type User = {
  id: number;
  // TODO: update to non-optional after lib update
  gid?: string;
  name: string;
  email?: string;
  /** @deprecated always undefined */
  functionalAccount?: boolean; // @deprecated
  lastLogin?: string;
} & Audit;
export type UserWithRoles = User & { roles: UserRole[] };

export type UserRole = { id: number; name: string; description: string } & Audit;
export type UserRoleWithPermissions = UserRole & {
  permissions: {
    [key: string]: Array<string>;
  };
};
export type UserRoleWithPermissionsUpdate = {
  id: number;
  name: string;
  description: string;
  permissions: {
    [key: string]: Array<string>;
  };
};
export type UserRoleWithPermissionsCreate = {
  name: string;
  description: string;
  permissions: {
    [key: string]: Array<string>;
  };
};

type Audit = {
  createdById: number;
  createdBy: string;
  createdAt: string;
  updatedById: number;
  updatedBy: string;
  updatedAt: string;
};

export type UserInfoStatusEnum = (typeof UserInfoStatusEnum)[keyof typeof UserInfoStatusEnum];

export const UserInfoStatusEnum: {
  readonly Active: 'active';
  readonly Initial: 'initial';
} = { Active: 'active', Initial: 'initial' };

export type UserInfo = {
  id: number;
  name: string;
  email?: string;
  status: UserInfoStatusEnum;
  gid: string;
  permissions: {
    [key: string]: Array<string>;
  };
};

export type RefreshTokenMetadata = {
  tokenFamily: string;
  counter: number;
  issuedAt: Date;
  lastAccessTokenIssuedAt: Date;
  expiry: Date;
  limit: Date;
  authFlow: RefreshTokenAuthFlowEnum;
  provider: RefreshTokenProviderEnum;
  clientId: RefreshTokenClientIdEnum;
};
export type RefreshTokenMetadataWithUserId = RefreshTokenMetadata & { userid: number };

export type RefreshTokenAuthFlowEnum = (typeof RefreshTokenAuthFlowEnum)[keyof typeof RefreshTokenAuthFlowEnum];

export const RefreshTokenAuthFlowEnum: {
  readonly AuthCodeGrant: 'auth_code_grant';
  readonly DeviceAuthGrant: 'device_auth_grant';
} = { AuthCodeGrant: 'auth_code_grant', DeviceAuthGrant: 'device_auth_grant' };

export type RefreshTokenProviderEnum = (typeof RefreshTokenProviderEnum)[keyof typeof RefreshTokenProviderEnum];
export const RefreshTokenProviderEnum: {
  readonly Azuread: 'azuread';
} = { Azuread: 'azuread' };

export type RefreshTokenClientIdEnum = (typeof RefreshTokenClientIdEnum)[keyof typeof RefreshTokenClientIdEnum];
export const RefreshTokenClientIdEnum: {
  readonly Pacts: 'pacts';
  readonly Impacts: 'impacts';
  readonly External: 'external';
} = { External: 'external', Impacts: 'impacts', Pacts: 'pacts' };
