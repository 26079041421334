import { OneToOneOutlined, ControlOutlined } from '@ant-design/icons';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';

type ComparisonViewSwitchProps = {
  state: boolean;
  onChange: (state: boolean) => any;
};

export const ComparisonViewSwitch = (props: ComparisonViewSwitchProps) => {
  return (
    <ExpandableMenuItem icon={props.state ? <ControlOutlined /> : <OneToOneOutlined />} onClick={() => props.onChange(!props.state)}>
      {`Switch to ${props.state ? 'Control' : 'Compare'} View`}
    </ExpandableMenuItem>
  );
};

export default ComparisonViewSwitch;
