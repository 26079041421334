import { useContext } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { PacTSContext } from '../../../state/store';
import { ManagedWebsocket, useManagedWebsocket } from './managedWebsocket';
import { MessageManager } from './messageManager';
import { usePacTSFormationAvailable } from '../../../contexts/session/hooks/usePacTSFormationAvailable';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

const init: ManagedWebsocket = [
  new MessageManager(),
  {
    statusReadable: 'closed',
    status: 0,
    isReady: false,
    isClosed: true,
    isError: false,
    isConnecting: false
  },
  {
    reconnect: () => {
      console.warn('not initialized');
    }
  }
];

export const usePactsFormationWebsocket = singletonHook(init, () => {
  const [state] = useContext(PacTSContext);
  const permissions = usePermissions();
  const pacTSFormationIsAvailable = usePacTSFormationAvailable();

  const hasRequiredPermissions = permissions.pactsFormationSvc$unrestrictedAdministration;
  const enabled = pacTSFormationIsAvailable && hasRequiredPermissions;

  return useManagedWebsocket({ connectUrl: state.pactsFormationConnectPath, websocketUrl: state.pactsFormationWebsocketPath }, init[0], { enabled });
});
