import { Form, Input, Select, Spin } from 'antd';

import { useEffect, useState } from 'react';
import { formItemLayout } from '../../layout/components/formLayout';
import { useOtcs } from '../hooks/useOtcs';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { Device } from '../../../api';
import { useParams } from 'react-router-dom';

export const DeviceForm = (props: {
  initial: Partial<Device> & { otc: Device['otc'] };
  projectId: number;
  envId: string;
  onFinish: (data: Device) => Promise<void>;
  ok: (ok: () => any) => any;
  reset: (cancel: () => any) => any;
}) => {
  const [form] = Form.useForm();
  const params = useParams();
  const envId = params?.envId || '';
  const otc = useOtcs(props.projectId, envId);

  const [selectedOtcs, setSelectedOtcs] = useState<string[]>(props.initial.otc?.map((c) => c.id));

  props.ok(form.submit);
  props.reset(() => {
    form.resetFields();
    setSelectedOtcs(props.initial.otc?.map((c) => c.id));
  });

  useEffect(() => {
    form.setFieldsValue({
      otc: selectedOtcs.map((o) => otc.data?.find((innerO) => innerO.id === o)).filter((o) => o)
    });
  }, [form, otc.data, selectedOtcs]);

  return (
    <Form
      {...formItemLayout}
      labelAlign="left"
      name="basic"
      initialValues={{ ...props.initial }}
      form={form}
      onFinish={async (value) => {
        const copy = { ...props.initial, ...value } as Device;
        await props.onFinish(copy);
        form.resetFields();
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            min: 4,
            max: 64
          }
        ]}
        label="RDS"
        required
        name={['rds']}
      >
        <Input placeholder="RDS" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            min: 1,
            max: 1024
          }
        ]}
        label="Description"
        required
        name={['description']}
      >
        <DescriptionTextAreaInput placeholder="Description" />
      </Form.Item>
      <Form.Item
        label="OTC"
        name={['otc']}
        required
        rules={[
          {
            required: true,
            validator: async (rule, value: string[]) => {
              if (value.length < 1) {
                throw new Error('at least one OTC is required');
              }
            }
          }
        ]}
      >
        <Form.Item required noStyle>
          <Select
            mode="multiple"
            optionFilterProp="label"
            value={selectedOtcs}
            placeholder="Select OTC"
            notFoundContent={otc.isLoading ? <Spin size="small" /> : null}
            onChange={setSelectedOtcs}
            style={{ width: '100%' }}
            filterOption={(inputValue, option) => {
              return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
            }}
          >
            {otc.data?.map((d) => (
              <Select.Option title={`${d.name} (${d.description})`} value={d.id} key={d.id} label={d.name}>
                {`${d.name} (${d.description})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>
    </Form>
  );
};

export default DeviceForm;
