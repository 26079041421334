import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useSearchParameters = () => {
  const location = useLocation();
  return useMemo(() => {
    const ret = {} as any;
    if ('search' in location) {
      const params = location.search.replace('?', '').split('&');
      params
        .filter((p) => p.includes('='))
        .forEach((p) => {
          const pSplit = p.split('=');
          ret[decodeURIComponent(pSplit[0])] = decodeURIComponent(pSplit[1]);
        });
    }
    return ret;
  }, [location]);
};
