import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectRoleWithPermissions } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteProjectRole() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [ProjectRoleWithPermissions]>(([role]) => {
    return new Promise<void>((resolve, reject) => {
      backend.deleteProjectRole(role.idProjectRole.toString()).then(() => {
        presentSuccess(`Successfully deleted Project Role ${role.name}`);
        queryClient.invalidateQueries(['projectRoles']);
        return resolve();
      }).catch(reject);
    });
  });
}

export default useDeleteProjectRole;
