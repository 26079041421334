import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { DeploymentPlan, DeploymentPlanCreate } from '../../../api';

export function useCreateDeploymentPlan() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<DeploymentPlan, any, [DeploymentPlanCreate, number, string]>(async ([create, projectId, envId]) => {
    const plan = await backend.createDeploymentPlan(projectId, envId, create);
    // Wait until the created plan was refetched
    await queryClient.refetchQueries(['deploymentplanMetas', projectId, envId]);
    queryClient.prefetchQuery(['deploymentplan', plan.id, envId, projectId], () => plan);
    queryClient.invalidateQueries(['device', projectId, envId]);
    presentSuccess(`Successfully created Deployment Plan ${plan.name}`);
    return plan;
  });
}

export default useCreateDeploymentPlan;
