import { SoftwareApp, SoftwareAppVersion, Tool, ToolVersion, useEngineeringBackend } from '../../../../../api';
import { TargetSelector } from './TargetSelector';
import { PrepareDownloadLoading } from './PrepareDownloadLoading';
import { AutoDownload } from './AutoDownload';

import { useMachine } from '@xstate/react';
import { useRef } from 'react';
import { DownloadError } from './DownloadError';
import { machine } from '../downloadStateMachine';
import { getDownloadUrl } from '../urlProvider';
import { useDownloadUrlExpiration } from '../hooks/useDownloadUrlExpiration';
import { DownloadMode, DownloadTarget } from '../types';

export const DownloadData = (props: { component: Tool | SoftwareApp; version: ToolVersion | SoftwareAppVersion; projectId?: number }) => {
  const { backend } = useEngineeringBackend();

  const tool = !!(props.version as ToolVersion).idToolVersion ? (props.version as ToolVersion) : undefined;
  const app = !!(props.version as SoftwareAppVersion).idSoftwareAppVersion ? (props.version as SoftwareAppVersion) : undefined;
  const mode = !!tool ? 'tool' : !!app ? 'app' : 'undefined';

  const initialTarget: DownloadTarget | undefined =
    mode === 'tool' ? tool : mode === 'app' && app?.targets.length === 1 ? { ...app, ...app.targets[0] } : undefined;

  const [state, send] = useMachine(
    useRef(
      machine(
        { target: initialTarget, mode, component: props.component, projectId: props.projectId, version: props.version },
        {
          createDownloadUrl: (args: {
            mode: DownloadMode;
            component: Tool | SoftwareApp;
            version: ToolVersion | SoftwareAppVersion;
            projectId?: number;
            target?: DownloadTarget;
          }) => getDownloadUrl(backend, args)
        }
      )
    ).current
  );

  const downloadUrlExpiration = useDownloadUrlExpiration(state.context.downloadUrl);
  const isNotExpired = !!downloadUrlExpiration ? downloadUrlExpiration.remaining > 0 : null;

  switch (state.value) {
    case 'error':
      return <DownloadError error={state.context.error!} onRetry={() => send({ type: 'RETRY' })} />;
    case 'selectingTarget':
      return <TargetSelector version={app!} onSelect={(target) => send({ type: 'TARGET_SELECTED', target })} />;
    case 'creatingDownloadUrl':
      return <PrepareDownloadLoading />;
    case 'autoDownload':
      return (
        <AutoDownload
          version={state.context.target!}
          downloadLink={state.context.downloadUrl!}
          isNotExpired={isNotExpired}
          infraSha256={state.context.receivedChecksum}
          onRetry={() => send({ type: 'RETRY' })}
        />
      );
    default: {
      throw new Error(`illegal state: ${state.value}`);
    }
  }
};
