import { PacTSContextStore } from './state/store';
import { PacTSRouter } from './routing/PacTSRouter';
import { SingletonHooksContainer } from './contexts/shared/components/SingletonHooksContainer';
import { NotificationContainer } from './contexts/shared/components/NotificationContainer';
import ProductTourContextProvider from './contexts/productTourContext/ProductTourContextProvider';
import { GlobalState } from './state/globalState';
import { PactsFrontendQueryClientProvider } from './state/pactsFrontendQueryClientProvider';
import { BackendProvider } from './api/BackendProvider';
import { ThemeProvider } from './contexts/theme/ThemeProvider';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .ant-tag {
    font-size: 13px;
  }
`;

const defaultEnvironment = 'unstable';

const localToken = import.meta.env.VITE_PACTS_TOKEN;
const localRefreshToken = import.meta.env.VITE_PACTS_REFRESH_TOKEN;
const ENGINEERING_SERVICE_PATH =
  import.meta.env.VITE_ENGINEERING_SERVICE_ENDPOINT || `https://engineering.api.${defaultEnvironment}.pacts.cloud`.replace(/\/+$/, '');
const USER_SERVICE_BASE = import.meta.env.VITE_USER_SERVICE_ENDPOINT || `https://iam.api.${defaultEnvironment}.pacts.cloud`.replace(/\/+$/, '');
const DEPLOYMENT_SERVICE_BASE =
  import.meta.env.VITE_DEPLOYMENT_SERVICE_ENDPOINT || `https://deployment.api.${defaultEnvironment}.pacts.cloud`.replace(/\/+$/, '');
const USER_SATISFACTION_SERVICE_BASE = import.meta.env.VITE_USER_SATISFACTION_ENDPOINT || `https://${defaultEnvironment}-users-api.kpi.pacts.cloud/Test`;
const NOTIFICATION_SERVICE_WS = import.meta.env.VITE_NOTIFICATION_WS_ENDPOINT || `wss://${defaultEnvironment}-ws-notifications.pacts.cloud`.replace(/\/+$/, '');
const NOTIFICATION_SERVICE_CONNECT =
  import.meta.env.VITE_NOTIFICATION_CONNECT_ENDPOINT || `https://${defaultEnvironment}-connect-notifications.pacts.cloud/connecttoken`.replace(/\/+$/, '');
const PACTSFORMATION_CONNECT = import.meta.env.VITE_PACTSFORMATION_CONNECT_ENDPOINT || 'https://connect-pactsformation-unstable.pacts.cloud/connecttoken';
const PACTSFORMATION_WS = import.meta.env.VITE_PACTSFORMATION_WS_ENDPOINT || 'wss://api-pactsformation-unstable.pacts.cloud';
const REPORT_SERVICE_BASE = import.meta.env.VITE_REPORT_SERVICE_ENDPOINT || `https://report.api.${defaultEnvironment}.pacts.cloud/`.replace(/\/+$/, '');
const METRICS_SERVICE_BASE = import.meta.env.VITE_METRICS_SERVICE_ENDPOINT || `https://metrics.api.${defaultEnvironment}.pacts.cloud/`.replace(/\/+$/, '');
const AISFORCE_SERVICE_BASE = import.meta.env.VITE_AISFORCE_SERVICE_ENDPOINT || `https://aisforce.api.${defaultEnvironment}.pacts.cloud/`.replace(/\/+$/, '');

const initialState: Partial<GlobalState> = {
  token: {
    refreshToken: localRefreshToken,
    token: localToken
  },
  engineeringServicePath: ENGINEERING_SERVICE_PATH,
  deploymentServiceBasePath: DEPLOYMENT_SERVICE_BASE,
  userSatisfactionBasePath: USER_SATISFACTION_SERVICE_BASE,
  notificationServiceConnectPath: NOTIFICATION_SERVICE_CONNECT,
  notificationServiceWebsocketPath: NOTIFICATION_SERVICE_WS,
  pactsFormationConnectPath: PACTSFORMATION_CONNECT,
  pactsFormationWebsocketPath: PACTSFORMATION_WS,
  reportServiceBasePath: REPORT_SERVICE_BASE,
  metricsServiceBasePath: METRICS_SERVICE_BASE,
  userServiceBasePath: USER_SERVICE_BASE,
  aisForceServiceBasePath: AISFORCE_SERVICE_BASE
};

function PacTS() {
  return <PacTSRouter />;
}
const App: React.FC = () => (
  <PactsFrontendQueryClientProvider>
    <ThemeProvider>
      <PacTSContextStore initialState={initialState}>
        <>
          <SingletonHooksContainer />
          <NotificationContainer />
          <ProductTourContextProvider>
            <BackendProvider>
              <GlobalStyles />
              <PacTS />
            </BackendProvider>
          </ProductTourContextProvider>
        </>
      </PacTSContextStore>
    </ThemeProvider>
  </PactsFrontendQueryClientProvider>
);
export default App;
