import { NodeIndexOutlined } from '@ant-design/icons';
import { Tooltip, FloatButton as AntFloatButton } from 'antd';
import { useContext } from 'react';
import { ProductTourContext } from './ProductTourContext';
import styled from 'styled-components';

const FloatButton = styled(AntFloatButton)`
  right: 1.5%;
  bottom: 50%;
  box-shadow:
    rgba(0, 0, 0, 0.36) 0px 3px 6px,
    rgba(0, 0, 0, 0.3) 0px 3px 6px;
`;

const IndexIcon = styled(NodeIndexOutlined)`
  color: ${({ theme }) => theme.colorBgLayout};
`;

export const ProductTourFloatingButton = (props: { title: string; tourId: string; buttonId: string }) => {
  const { handleOpenTour } = useContext(ProductTourContext);
  return (
    <Tooltip title={props.title} placement="left">
      <FloatButton
        // Ignore known TS issue from ant design,
        // indeed such a prop works as expected
        // @ts-ignore
        id={props.buttonId}
        onClick={() => handleOpenTour(props.tourId)}
        shape="square"
        type="primary"
        icon={<IndexIcon />}
      />
    </Tooltip>
  );
};
