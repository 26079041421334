import { createContext } from 'react';
import { ProductTourContextType } from './types';

const initialState = {
  toursState: {
    selectedTourId: null as string | null,
    step: 0
  },
  stepsState: {},
  setToursState: () => {},
  handleNextStep: () => {},
  handlePrevStep: () => {},
  handleReset: () => {},
  handleOpenTour: () => {},
  handleAddSteps: () => {},
  setStepsState: () => {}
};

export const ProductTourContext = createContext<ProductTourContextType>(initialState);
