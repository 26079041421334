import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SoftwareAppVersion, SoftwareAppVersionCreate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useCreateProjectSoftwareAppVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareAppVersion, any, [string, string, SoftwareAppVersionCreate], SoftwareAppVersion[]>(
    async ([projectId, softwareAppId, softwareAppVersion]) => {
      const created = await backend.createProjectSoftwareAppVersion(projectId, softwareAppId, softwareAppVersion);
      presentSuccess(`Successfully created Software Application Version ${created.version}`);

      await Promise.all([
        queryClient.invalidateQueries(['softwareapp', projectId, softwareAppId]),
        queryClient.invalidateQueries(['softwareappVersions', projectId, softwareAppId]),
        queryClient.invalidateQueries(['softwareapps', projectId])
      ]);

      return created;
    }
  );
}
