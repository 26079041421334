import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Skeleton, Space, TableColumnsType, Typography } from 'antd';
import { StyledModal, useTableFilter, useTableSearch } from '../../../contexts/shared/components';
import { Comparator } from '../../../domain';
import Table from '../../../contexts/shared/components/Table/Table';
import { ResourceError } from '../../../api';
import { useAllArtifacts } from '../hooks/useArtifacts';
import ArtifactDetails from './ArtifactDetails';
import styled from 'styled-components';
import { valueFromObjectPath } from '../../../contexts/reports';
import { useDebounce } from '../../../contexts/shared/hooks';

export interface DataType {
  id: string;
  versionId: string;
  type: string;
  key: React.Key;
  name: string;
  status: string;
}

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export const AllArtifactResources = () => {
  const artifactResources = useAllArtifacts();
  const isLoading = useDebounce(artifactResources.isLoading || artifactResources.isFetchingNextPage, 10, true);

  const statuses: string[] = uniq(artifactResources.data?.map((project) => project?.status) || []);
  const types: string[] = uniq(artifactResources.data?.map((project) => project?.artifactType) || []);
  const [errorModal, setErrorModal] = useState<null | ResourceError>(null);
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...useTableSearch({
        searchParamId: 'name',
        searchValueProvider: valueFromObjectPath(['name'])
      })
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...useTableFilter({
        searchParamId: 'type',
        values: types.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const value = valueFromObjectPath<string>(['type'])(data);
          return value.includes(filterVal);
        },
        key: statuses.join('+')
      })
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...useTableFilter({
        searchParamId: 'status',
        values: statuses.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const value = valueFromObjectPath<string>(['status'])(data);
          return value.includes(filterVal);
        },
        key: statuses.join('+')
      })
    },
    {
      title: 'Artifact',
      dataIndex: 'id',
      key: 'id',
      ...useTableSearch({
        searchParamId: 'id',
        searchValueProvider: valueFromObjectPath(['id'])
      })
    },
    {
      title: 'Version',
      dataIndex: 'versionId',
      key: 'versionId',
      ...useTableSearch({
        searchParamId: 'versionId',
        searchValueProvider: valueFromObjectPath(['versionId'])
      })
    }
  ];
  const data: DataType[] = useMemo(
    () =>
      artifactResources.data
        ?.sort((a, b) => Comparator.lexicographicalComparison(a.artifactName, b.artifactName))
        .map((project) => {
          return {
            key: project.id,
            name: project.artifactName,
            id: project.artifactId,
            status: project.status,
            type: project.artifactType,
            versionId: project.versionId
          };
        }) || [],
    [artifactResources.data]
  );

  return (
    <FullWidthSpace direction="vertical">
      <StyledModal title={errorModal?.summary} open={errorModal != null} destroyOnClose onCancel={() => setErrorModal(null)} onOk={() => setErrorModal(null)}>
        <Typography.Paragraph>Error Code: {errorModal?.errorCode}</Typography.Paragraph>
        <Typography.Paragraph>Internal Error code: {errorModal?.technicalDetails?.internalErrorCode}</Typography.Paragraph>
        <Typography.Paragraph>Summary: {errorModal?.technicalDetails?.description}</Typography.Paragraph>
      </StyledModal>
      <Skeleton loading={isLoading} active>
        <Table
          columns={columns}
          dataSource={data}
          expandable={{
            rowExpandable: (project) => project.status !== 'DELETED',
            expandedRowRender: (project: DataType) => <ArtifactDetails onSetErrorModal={setErrorModal} project={project} />
          }}
        />
      </Skeleton>
    </FullWidthSpace>
  );
};
