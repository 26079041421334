import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const DEFAULT_STALE_TIME = 5 * 60 * 1000;

const defaultOptions = {
  queries: {
    retry: 3,
    retryDelay: (attempt: number) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 2000, 30 * 1000),
    staleTime: DEFAULT_STALE_TIME
  }
};
const queryClient = new QueryClient({ defaultOptions });

const PactsFrontendQueryClientProvider = (props: { children: React.ReactNode, queryClient?: QueryClient }) => {
  return <QueryClientProvider client={props.queryClient ? props.queryClient : queryClient} contextSharing>{props.children}</QueryClientProvider>;
};

export { PactsFrontendQueryClientProvider };
