import { Project } from '../../../api';
import { useSources } from '../../reports/hooks/useSources';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { DatabaseOutlined } from '@ant-design/icons';
import { usePermissions } from '../../session/hooks/usePermissions';
import { CenterMenuElement } from '../../../contexts/shared/components/CenterMenuElement';

export const ReportTargets = (props: { project: Project }) => {
  const sources = useSources(props.project.idProject);
  const navigate = useInAppNavigate();
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });

  const pdpTarget = sources.data?.find((s) => s.name === 'pam-pdp');
  const hasTarget = pdpTarget !== undefined;
  const hasNeededPermissions = permissions.reportSvc$getReportSources && permissions.reportSvc$getReportRevisions;

  if (!hasNeededPermissions) {
    return null;
  }

  return (
    <CenterMenuElement
      icon={<DatabaseOutlined />}
      disabled={!hasTarget}
      loading={sources.isLoading}
      title={'Plant Data Backbone'}
      subtitle={`${props.project.name} PDP Reports`}
      onClick={() => navigate(`/projects/${props.project.idProject}/reports/${pdpTarget?.id}/latest`)}
    />
  );
};
