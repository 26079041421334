import * as antd from 'antd';
import { Rule } from 'antd/es/form';
import { FileSelection, UploadAnt } from './AntBlobUpload';
import { useRef, useState } from 'react';
import { SoftwareAppPackageKindEnum, ToolPackageKindEnum } from '../../../api/engineering/domain/types';

export const BlobUploadFormItem = (props: {
  label: string;
  field: (string | number)[];
  relativeFieldInList?: (string | number)[];
  labelAlign?: 'left' | 'right';
  fileTypeField?: string[];
  fileType?: ToolPackageKindEnum | SoftwareAppPackageKindEnum;
  rules?: Rule[];
  optional?: boolean;
  uploadDisabled?: boolean;
  shouldUpdate: (prevValuse: any, currentValues: any) => boolean;
}) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [lastEmitted, setLastEmitted] = useState<FileSelection | undefined>();
  const lastAccept = useRef<undefined | string>();
  return (
    <antd.Form.Item
      label={props.label}
      labelAlign={props.labelAlign}
      required={!props.optional}
      shouldUpdate={(prev, next) => {
        if (forceRefresh) {
          setForceRefresh(false);
          return true;
        }
        return props.shouldUpdate(prev, next);
      }}
      style={{ marginTop: 16 }}
    >
      {({ getFieldValue, setFieldValue, getFieldError, validateFields }) => {
        const accept = props.fileTypeField ? (getFieldValue(props.fileTypeField) as ToolPackageKindEnum | SoftwareAppPackageKindEnum) : props.fileType;

        const validateAndForceRefresh = () => {
          validateFields([props.field])
            .catch(() => {
              /* */
            })
            .finally(() => {
              setForceRefresh(true);
            });
        };

        if (lastAccept.current !== accept) {
          lastAccept.current = accept;
          validateAndForceRefresh();
        }
        let acceptedFileTypes: string | undefined = undefined;
        let uploadDisabled = false;
        switch (accept) {
          case 'custom':
            uploadDisabled = true;
            // accept empty array to force validation to not
            // succeed when the acceptedFileTypes are undefined
            // this is a hack to get around the default upload
            // fields behavior.
            acceptedFileTypes = ',';
            break;
          case 'msi':
            acceptedFileTypes = '.msi';
            break;
          case 'nsis':
            acceptedFileTypes = '.exe';
            break;
          case 'zip':
            // For now we accept everything
            break;
          default:
            uploadDisabled = true;
        }

        const rules: Rule[] = [
          ...(props.rules || []),
          {
            validator: () => {
              // Here, the required rule should kick in
              if (!lastEmitted?.link) return Promise.resolve();

              // Reject if invalid extension
              const extension = lastEmitted?.file?.extension || lastEmitted?.link.split('.').pop() || '';
              if (!extension) return Promise.reject(new Error('Invalid file extension.'));

              // If accepted is not set, we accept anything
              if (acceptedFileTypes === undefined) return Promise.resolve();

              // If the file extension is accepted resolve
              const accepted = (acceptedFileTypes?.replaceAll('.', '') || '').split(',').filter(Boolean);
              if (accepted.includes(extension)) return Promise.resolve();

              // If we don't care about file types and get a link -> accept
              if (accepted.length < 1 && !lastEmitted?.file) return Promise.resolve();

              // If we don't have an accepted file type and the link was not yet accepted
              if (accepted.length < 1) return Promise.reject(new Error('Only links allowed.'));

              return Promise.reject(new Error(`The only file extensions allowed are ${accepted.join(', ')}.`));
            }
          }
        ];

        return (
          <>
            <UploadAnt
              initialValue={getFieldValue(props.field)}
              accept={acceptedFileTypes}
              uploadDisabled={props.uploadDisabled || uploadDisabled}
              onChange={(v) => {
                setLastEmitted(v);
                setFieldValue(props.field, v.link);
                // delay validation by one render cycle to make sure
                // the prior state changes have been propagated
                setImmediate(() => {
                  validateAndForceRefresh();
                });
              }}
              showError={getFieldError(props.field).length > 0}
            />
            <antd.Form.Item noStyle name={props.relativeFieldInList || props.field} rules={rules} />
          </>
        );
      }}
    </antd.Form.Item>
  );
};
