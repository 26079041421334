import { useContext } from 'react';
import { useSession } from '../contexts/session/hooks/useSession';
import { PacTSContext } from '../state/store';
import { Navigate, useLocation } from 'react-router-dom';
import { useHasActiveSession } from '../contexts/session/hooks/usePermissions';

// Not found forwarding based on a user's session state is necessary
// because the routes are rendered based on a user's permissions.
// Thus, we have to check if a user is actually logged in before navigating.
export const NotFoundForwarder = () => {
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const hasActiveSession = useHasActiveSession();
  const location = useLocation();

  const loggedIn = session.state === 'loggedIn';

  // If a user is logged in and active forward to 404
  if (loggedIn && hasActiveSession) {
    return <Navigate to="/404" state={{ from: location }} />;
  }

  // If a user is logged in but the session is inactive forward to unauthorized
  if (loggedIn && !hasActiveSession) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  }

  // In case not, forward to login
  return <Navigate to="/login" state={{ from: location }} />;
};
