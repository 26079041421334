import { Typography } from 'antd';
import { StepWithNumber } from './types';
import styled from 'styled-components';

const Title = styled(Typography.Title)`
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const DescriptionWrapper = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const DescriptionParagraph = styled(Typography.Paragraph)`
  margin-bottom: 8px;
  margin-inline: 30px;
`;

const StartJourneyParagraph = styled(Typography.Paragraph)`
  margin-bottom: 30px;
`;

const DiscoverParagraph = styled(Typography.Paragraph)`
  margin-bottom: 75px;
`;

export const productTourStartButton = (args: { title: string; description: string; target: string }): StepWithNumber => {
  const ret: StepWithNumber = {
    style: {
      width: '600px'
    },
    step: 1,
    title: <Title level={5}>{args.title}</Title>,
    target: () => document.querySelector(args.target) as HTMLElement,
    placement: 'left',
    description: (
      <DescriptionWrapper>
        <DescriptionParagraph>{args.description}</DescriptionParagraph>
        <StartJourneyParagraph>Ready to start this journey?</StartJourneyParagraph>
        <DiscoverParagraph strong>Discover our features in just a few steps.</DiscoverParagraph>
      </DescriptionWrapper>
    ),
    nextButtonProps: {
      style: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '35px',
        fontWeight: 500,
        fontSize: '14px',
        borderRadius: '1px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px 16px'
      },
      children: <span>Get Started</span>
    }
  };
  return ret;
};
