import { useQuery } from '@tanstack/react-query';
import { SoftwareAppCategory } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useSoftwareAppCategories() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();
  return useQuery<SoftwareAppCategory[], [string]>(
    ['softwareappCategories'],
    (): Promise<SoftwareAppCategory[]> => {
      return backend.queryAppCategories();
    },
    { enabled: permissions.engineeringSvc$getSoftwareAppCategories }
  );
}
