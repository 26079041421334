import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useProject } from '../../projects/hooks';
import { useInAppNavigate } from '../hooks/useInAppNavigate';
import { usePactsReferrer } from '../hooks/usePactsReferrer';

export const BackToProjectButton = (props: { projectId: string }) => {
  const pactsRef = usePactsReferrer();
  const navigate = useInAppNavigate();
  const project = useProject(props.projectId);
  const onClick = () => {
    if (pactsRef) return navigate(-1);
    navigate(`/projects?type=${project.data!.projectType.idProjectType}&active=${project.data!.idProject?.toString()}`);
  };
  return (
    <Button icon={<ArrowLeftOutlined />} key="back" disabled={!project.data} onClick={onClick}>
      Back
    </Button>
  );
};
