import { Card, Row, Space, Typography } from 'antd';
import { SoftwareAppVersion, TargetWithDownloadLink, ToolVersion } from '../../../../../api';
import { useEffect, useRef } from 'react';
import { StyledCol, StyledResult } from '../../../../../contexts/shared/base/Components.styled';
import styled from 'styled-components';
import { DownloadLinkExpiredLink } from './DownloadLinkExpiredLink';
import { LinkExpiryText } from './LinkExpiryText';
import { ChecksumHowTo } from '../../ChecksumHowTo';

const CardWithBackground = styled(Card)`
  background-color: ${({ theme }) => theme.colorBgContainerDisabled};
`;

const Spacer = styled.div<{ height: number }>`
  margin-top: ${({ height }) => height}px;
`;

export const AutoDownload = (props: {
  version: ToolVersion | (SoftwareAppVersion & TargetWithDownloadLink);
  downloadLink: string;
  isNotExpired: boolean | null;
  infraSha256?: string;
  onRetry: () => void;
}) => {
  // guard the effect hook to make sure only one download
  // is started when any of the parameters changes
  const hasDownloaded = useRef(false);
  useEffect(() => {
    if (hasDownloaded.current) return;
    if (!props.isNotExpired) return;
    if (!props.downloadLink) return;

    hasDownloaded.current = true;

    // Invoke download click
    const link = document.createElement('a');
    link.href = props.downloadLink;
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [props.isNotExpired, props.downloadLink]);

  // TODO: remove after migration period
  // once matching hashes are enforced
  const showChecksum = !!props.version.sha256 && props.version.sha256 === props.infraSha256;

  const DownloadProblemResolution = () => {
    const showDownloadExpiredResolution = props.isNotExpired !== null;

    if (!showDownloadExpiredResolution) return null;

    return props.isNotExpired ? (
      <Space direction="vertical">
        <span>
          <Typography.Text type="secondary">If the download did not start automatically </Typography.Text>
          <Typography.Link href={props.downloadLink} target="_blank">
            click here
          </Typography.Link>
        </span>
        <LinkExpiryText />
      </Space>
    ) : (
      <DownloadLinkExpiredLink onRetry={props.onRetry} />
    );
  };

  return (
    <StyledResult status="success" padding="8px 16px 8px 16px" contentStyle={{ backgroundColor: 'transparent', padding: '0' }}>
      <Row justify="center">
        <StyledCol span={24} textAlign="center">
          <Typography.Paragraph strong>Your file started downloading!</Typography.Paragraph>
          <Spacer height={36} />
          {showChecksum ? (
            <CardWithBackground size="small">
              <Spacer height={14} />
              <Typography.Paragraph strong>
                Please check the integrity of the file after download. <ChecksumHowTo />
              </Typography.Paragraph>
              <Typography.Paragraph copyable={{ text: props.version.sha256 }}>{`SHA256: ${props.version.sha256}`}</Typography.Paragraph>
            </CardWithBackground>
          ) : null}
          <Spacer height={24} />
          <DownloadProblemResolution />
        </StyledCol>
      </Row>
    </StyledResult>
  );
};
