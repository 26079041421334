import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const defaultConfig: AxiosRequestConfig = {
  timeout: 30000
};

export class SharedAxiosInstance {
  private static _instance: SharedAxiosInstance;

  private constructor(private readonly axiosInstance: AxiosInstance) {}

  public static instance(): AxiosInstance {
    if (!!SharedAxiosInstance._instance) return SharedAxiosInstance._instance.axiosInstance;
    SharedAxiosInstance._instance = new SharedAxiosInstance(axios.create(defaultConfig));
    return SharedAxiosInstance._instance.axiosInstance;
  }
}
