import { ToolCategory } from '../../../../api/engineering/domain/types';
import { useEngineeringToolCategories } from '../../../engineeringTools';
import { useSharedState } from '../../../shared/hooks/useSharedState';
import { EntityAdministrationListLayout } from './EntityAdministrationListLayout';

type UsedType = ToolCategory;
const entityName = 'Tool Category';
const entityKey = 'entity-admin-tool-category';
const idAttribute = 'id';
const titleAttribute = 'name';
export const ToolCategoriesList = () => {
  const entities = useEngineeringToolCategories();
  const [selected, setSelected] = useSharedState<UsedType>(entityKey);
  const creatingNew = selected !== null && entities.data?.find((e) => e[idAttribute] === (selected ? selected[idAttribute] : null)) === null;
  return (
    <>
      <EntityAdministrationListLayout
        isLoading={entities.isLoading}
        data={entities.data || []}
        idAttribute={idAttribute}
        titleAttribute={titleAttribute}
        onSelect={(val) => setSelected(val!)}
        selected={selected}
        onReset={() => setSelected({ description: '', id: -1, name: '' })}
        entityName={entityName}
        creatingNew={creatingNew}
      />
    </>
  );
};
