import { SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { ComparisonTag, ComparisonResult } from '../../comparison/components/ComparisonTag';

export type SoftwareAppVersionComparisonResultProps = {
  a: SoftwareAppVersion | undefined;
  b: SoftwareAppVersion | undefined;
};

export function softwareAppVersionComparisonResultValue(a: SoftwareAppVersion | undefined, b: SoftwareAppVersion | undefined) {
  if (a && b) {
    if (a.idSoftwareAppVersion === b.idSoftwareAppVersion) {
      return ComparisonResult.EQUAL;
    }
    return ComparisonResult.VERSION_DIFFERS;
  }
  return ComparisonResult.SELECTION_DIFFERS;
}

export function SoftwareAppVersionComparisonResult(props: SoftwareAppVersionComparisonResultProps) {
  return <ComparisonTag comparisonResult={softwareAppVersionComparisonResultValue(props.a, props.b)} />;
}
