export enum CustomEvents {
  NOTIFICATION_RECEIVED = 'notificationReceived'
}

// Disabled because of build issues with ImPacTS
// export class NotificationEvent extends Event {
//   constructor() {
//     super(CustomEvents.NOTIFICATION_RECEIVED);
//   }
// }
