import { LockOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { PacTSNotification } from '../../../../api/notifications/domain/types';
import { DefaultNotification } from './DefaultNotification';
import { useStyles } from '../../../theme/useStyles';

export const AccessRequest = (props: { notification: PacTSNotification }) => {
  const token = useStyles();
  return (
    <Space size="large">
      <LockOutlined style={{ fontSize: 24, color: token.colorPrimary }} />
      <DefaultNotification withLink notification={props.notification} />
    </Space>
  );
};
