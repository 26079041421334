import { Badge, Tooltip } from 'antd';
import { useNotificationHeartbeat } from '../hooks/useNotificationHeartbeat';

export const NotificationHeartbeat = () => {
  const data = useNotificationHeartbeat();
  let status: 'processing' | 'success' | 'error' | 'default' | 'warning' | undefined = 'processing';
  if (data.isSuccess) {
    status = 'success';
  }
  if (data.isFetching || data.isLoading) {
    status = 'processing';
  }
  if (data.isError) {
    status = 'error';
  }
  return (
    <Tooltip title={`Notification (${status})`}>
      <Badge status={status} />
    </Tooltip>
  );
};
