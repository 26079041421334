import { Col, Row, Space, Typography } from 'antd';
import { SubscriptionTransportMean } from './SubscriptionTransportMean';

export const SubscriptionToggles = (props: { topics: { key: string; title: string }[] }) => {
  const hasMultiple = props.topics.length > 1;
  const flex = hasMultiple ? undefined : 'auto';
  const spaceStyle = hasMultiple ? undefined : { width: '100%' };

  return (
    <Row gutter={[48, 16]} wrap={false}>
      {props.topics
        .map((t) => (
          <Col key={t.key} flex={flex}>
            <Space direction="vertical" style={spaceStyle}>
              {props.topics.length > 1 && <Typography.Text strong>{t.title}</Typography.Text>}
              <SubscriptionTransportMean
                mean="PACTS"
                topic={t.key}
                title="PacTS"
                description={`
          You will receive notifications via PacTS Web application.
    `}
              />
              <SubscriptionTransportMean
                mean="IMPACTS"
                topic={t.key}
                title="ImPacTS"
                description={`
          You will receive push notifications via ImPacTS.
    `}
              />
              <SubscriptionTransportMean
                mean="EMAIL"
                topic={t.key}
                title="Email"
                description={`
          You will receive notifications via Email.
    `}
              />
            </Space>
          </Col>
        ))}
    </Row>
  );
};
