import { useState } from 'react';
import { Tooltip, Checkbox, Radio, Space, Row, Col } from 'antd';
import type { RadioChangeEvent } from 'antd';
import styled from 'styled-components';

import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useDownloader } from '../../shared/hooks/useDownloader';
import { presentAxiosError } from '../../../api/shared/errorPresenter';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Bundle, Project } from '../../../api/engineering/domain/types';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { ReportResult } from '../../../contexts/shared/components/ReportResult';
import { b64toBlob } from '../../../contexts/shared/utils/base64ToBlob';
import { SignReportExplanation } from '../../../contexts/shared/components/Report/SignReportExplanation';
import { ExcelReportSignWarning } from '../../../contexts/shared/components/Report/ExcelReportSignWarning';
import { ReportIcon } from '../../shared/components/icons/ReportIcon';

type EngineeringToolsReportProps = {
  project: Project;
  bundle: Bundle;
  bundleConfig: BundleConfigurationSelection;
  isDirty?: boolean;
};

const Spacer = styled.span`
  margin-bottom: 3px;
`;

// Hack, due to axios lacking streaming, the generate report
// endpoint is called twice. The signature header must be extracted
// from the second call, hence the header key is specified here.
const headerKey = 'x-signature-ecdsa-sha-512';

export const ReportDownloadButton = (props: EngineeringToolsReportProps) => {
  const [download] = useDownloader();
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({
    projectId: props.project.idProject.toString(),
    idBundle: props.bundle.idBundle.toString(),
    idConfiguration: props.bundleConfig.bundleConfigurationId.toString(),
    idRelease: props.bundleConfig.bundleConfigurationVersionId.toString()
  });

  const propsValid = !Object.keys(props)
    .map((key) => (props as any)[key])
    .some((val) => val === '' || val === '-1');

  const [modalVisible, setModalVisible] = useState(false);
  const [waitingForDownload, setWaitingForDownload] = useState(false);
  const [reportSignature, setReportSignature] = useState<string>();
  const [downloaded, setDownloaded] = useState(false);

  const [reportTitle, setReportTitle] = useState(true);
  const onChangeReportTitle = (e: RadioChangeEvent) => {
    setReportTitle(e.target.checked);
  };

  const [softwareName, setSoftwareName] = useState(true);
  const onChangeSoftwareName = (e: RadioChangeEvent) => {
    setSoftwareName(e.target.checked);
  };
  const [softwareVersion, setSoftwareVersion] = useState(true);
  const onChangeSoftwareVersion = (e: RadioChangeEvent) => {
    setSoftwareVersion(e.target.checked);
  };
  const [softwareDescription, setSoftwareDescription] = useState(true);
  const onChangeSoftwareDescription = (e: RadioChangeEvent) => {
    setSoftwareDescription(e.target.checked);
  };
  const [softwareReleaseNotes, setSoftwareReleaseNotes] = useState(true);
  const onChangeSoftwareReleaseNotes = (e: RadioChangeEvent) => {
    setSoftwareReleaseNotes(e.target.checked);
  };
  const [softwareChecksum, setSoftwareChecksum] = useState(false);
  const onChangeSoftwareChecksum = (e: RadioChangeEvent) => {
    setSoftwareChecksum(e.target.checked);
  };
  const [signReport, setSignReport] = useState(false);
  const onChangeSignReport = (e: RadioChangeEvent) => {
    setSignReport(e.target.checked);
  };

  type ColumnTypes = 'softwareName' | 'softwareVersion' | 'softwareDescription' | 'softwareReleaseNotes' | 'softwareChecksum';
  const selectedColumns: Array<ColumnTypes> = [];
  if (softwareName) {
    selectedColumns.push('softwareName');
  }
  if (softwareDescription) {
    selectedColumns.push('softwareDescription');
  }
  if (softwareVersion) {
    selectedColumns.push('softwareVersion');
  }
  if (softwareReleaseNotes) {
    selectedColumns.push('softwareReleaseNotes');
  }
  if (softwareChecksum) {
    selectedColumns.push('softwareChecksum');
  }

  const [sort, setSort] = useState<'ASC' | 'DESC'>('ASC');
  const onChangeSort = (e: RadioChangeEvent) => {
    setSort(e.target.value);
  };
  const [format, setFormat] = useState<'xlsx' | 'pdf'>('xlsx');
  const onChangeFormat = (e: RadioChangeEvent) => {
    setFormat(e.target.value);
  };

  const resetFields = () => {
    setWaitingForDownload(false);
    setReportSignature(undefined);
    setDownloaded(false);

    setReportTitle(true);
    setSoftwareName(true);
    setSoftwareDescription(true);
    setSoftwareVersion(true);
    setSoftwareReleaseNotes(true);
    setSoftwareChecksum(false);
    setSignReport(false);
    setSort('ASC');
    setFormat('xlsx');
  };

  const downloadDisabledDueToNoSelection = selectedColumns.length < 1;

  const fileNameContext = [props.project.name, props.bundle.name, props.bundleConfig.bundleConfigurationName, props.bundleConfig.bundleConfigurationVersionName]
    .filter((f) => f !== undefined)
    .join('-');

  const filename = `PacTS_Report_${fileNameContext}.${format}`;

  const formatSignatureWarning = format === 'xlsx' && signReport;

  const onDownload = () => {
    setWaitingForDownload(true);
    backend
      .getBundleConfigurationReleaseReport(
        +props.project.idProject,
        +props.bundle.idBundle,
        +props.bundleConfig.bundleConfigurationId,
        +props.bundleConfig.bundleConfigurationVersionId,
        reportTitle,
        selectedColumns,
        signReport,
        sort,
        format
      )
      .then((response) => {
        download(response.streamUri, filename, undefined, true, [headerKey], ({ headers }) => {
          const responseHasSignature = !!headers[headerKey];
          if (!responseHasSignature) return Promise.resolve([]);
          const blob = b64toBlob(headers[headerKey]!);
          return Promise.resolve([
            {
              filename: `${filename}_signature.bin`,
              content: blob
            }
          ]);
        })
          .then(({ headers }) => {
            const responseHasSignature = !!headers[headerKey];
            setReportSignature(responseHasSignature ? headers[headerKey] : undefined);
            setDownloaded(true);
          })
          .finally(() => {
            setWaitingForDownload(false);
          });
      })
      .catch((err) => {
        setWaitingForDownload(false);
        presentAxiosError(err);
      });
  };

  if (!permissions.engineeringSvc$getProjectBundleConfigurationReleaseReport) {
    return null;
  }

  const ReportConfiguration = () => (
    <>
      <Space direction="vertical" size="middle">
        <b>General information</b>
        <Checkbox onChange={onChangeReportTitle} checked={reportTitle}>
          Cover page
        </Checkbox>
        <Spacer />
        <b>Columns to be displayed</b>
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 1 }}>
            <Checkbox onChange={onChangeSoftwareName} checked={softwareName}>
              Software name
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 1 }}>
            <Checkbox onChange={onChangeSoftwareVersion} checked={softwareVersion}>
              Software version
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 1 }}>
            <Checkbox onChange={onChangeSoftwareDescription} checked={softwareDescription}>
              Software description
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 1 }}>
            <Checkbox onChange={onChangeSoftwareReleaseNotes} checked={softwareReleaseNotes}>
              Software release notes
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 1 }}>
            <Checkbox onChange={onChangeSoftwareChecksum} checked={softwareChecksum}>
              Software checksum
            </Checkbox>
          </Col>
        </Row>
        <Spacer />
        <b>Sort software components</b>
        <Radio.Group onChange={onChangeSort} value={sort}>
          <Space direction="vertical">
            <Radio value="ASC">Ascending</Radio>
            <Radio value="DESC">Descending</Radio>
          </Space>
        </Radio.Group>
        <Spacer />
        <b>Format</b>
        <Space direction="vertical" size={0}>
          <Radio.Group onChange={onChangeFormat} value={format}>
            <Space direction="vertical">
              <Radio value="xlsx">Excel</Radio>
              {formatSignatureWarning ? <ExcelReportSignWarning /> : null}
              <Radio value="pdf">PDF</Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Spacer />
        <b>Integrity</b>
        <Space direction="vertical" size={0}>
          <Checkbox onChange={onChangeSignReport} checked={signReport}>
            Sign Report
          </Checkbox>
          <SignReportExplanation />
        </Space>
      </Space>
    </>
  );

  return (
    <>
      <Tooltip title="Generate release report">
        <ExpandableMenuItem
          icon={<ReportIcon />}
          onClick={() => {
            setModalVisible(true);
          }}
          disabled={props.isDirty || !propsValid}
        >
          Generate Report
        </ExpandableMenuItem>
      </Tooltip>
      <ScrollDialog
        title="Report configuration"
        okText="Download"
        cancelText={downloaded ? 'Close' : 'Cancel'}
        width={800}
        okButtonProps={{
          disabled: downloadDisabledDueToNoSelection,
          hidden: downloaded
        }}
        confirmLoading={waitingForDownload}
        open={modalVisible}
        onOk={onDownload}
        afterClose={() => {
          resetFields();
        }}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        {downloaded ? <ReportResult signature={reportSignature} /> : <ReportConfiguration />}
      </ScrollDialog>
    </>
  );
};

export default ReportDownloadButton;
