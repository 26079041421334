import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';

export function useDeleteUserRole() {
  const { backend } = useUserBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [number]>(([role]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteRole(role)
        .then(() => {
          presentSuccess(`Successfully deleted User Role`);
          queryClient.invalidateQueries(['userRoles']);
          return resolve();
        })
        .catch(reject);
    });
  });
}

export default useDeleteUserRole;
