import { useQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useEngineeringToolVersionVulnerabilities(toolId: number, toolVersionId: number) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ toolId: toolId.toString(), idToolVersion: toolVersionId.toString() });

  return useQuery(
    ['toolAppVersionVulnerabilities', toolId.toString(), toolVersionId.toString()],
    () => {
      return backend.queryToolVersionVulnerabilities(toolId, toolVersionId);
    },
    {
      enabled: permissions.engineeringSvc$getToolVersionVulnerabilities
    }
  );
}
