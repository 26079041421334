import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToolCategory } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteToolCategory() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [ToolCategory]>(([ent]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteToolCategory(ent.id.toString())
        .then(() => {
          presentSuccess(`Successfully deleted Tool Category ${ent.name}`);
          queryClient.invalidateQueries(['engineeringToolCategories']);
          resolve();
        })
        .catch(reject);
    });
  });
}
