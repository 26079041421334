import { Space } from 'antd';
import type { SoftwareComponent, Project } from '../../../api';
import { ComponentVulnerabilityButton } from './ComponentVulnerabilityButton';

export function DeploymentPlantTableVersionRowItem(props: { project: Project; component: SoftwareComponent }) {
  const { component } = props;
  return (
    <Space size="small">
      {component.version}
      <ComponentVulnerabilityButton {...props} />
    </Space>
  );
}
