import { useQuery } from '@tanstack/react-query';
import { ToolVersion } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../domain';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export function useEngineeringToolVersions(engineeringToolId: string) {
  const { backend } = useEngineeringBackend();
  const parsedToolId = parseInt(engineeringToolId, 10);
  const permissions = usePermissions({ toolId: engineeringToolId });
  const hasPermissions = permissions.engineeringSvc$getToolVersions;
  return useQuery<ToolVersion[], [string, string]>(
    ['engineeringToolVersions', engineeringToolId],
    (): Promise<ToolVersion[]> => {
      return new Promise((resolve, reject) => {
        backend
          .queryToolVersions(engineeringToolId)
          .then((versions) => {
            resolve(versions.sort((a, b) => SemanticVersion.sorter(a.version, b.version)));
          })
          .catch(reject);
      });
    },
    {
      enabled: parsedToolId > -1 && hasPermissions,
      refetchInterval: (data) => {
        const hasInconsistent = data?.some((d) => d.state !== 'consistent');
        return hasInconsistent ? 5000 : false;
      }
    }
  );
}
