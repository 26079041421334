import { CheckOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { useState } from 'react';
import { PermanentActionWarningLabel } from '../../shared/components/PermanentActionWarningLabel';
import { useDevices } from '../hooks';
import { useUpdateDeploymentPlanDeployment } from '../hooks/useUpdateDeploymentPlanDeployment';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { DeploymentPlan, Device, SoftwareComponent } from '../../../api';
import { useParams } from 'react-router-dom';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

const DEFAULT_BUTTON_LABEL = 'Set as installed';

export const UpdateDeploymentPlanDeploymentButton = (props: {
  plan: DeploymentPlan;
  device: Device;
  softwareComponent: SoftwareComponent;
  projectId: number;
  envId: string;
  onDone: () => void;
}) => {
  const update = useUpdateDeploymentPlanDeployment();
  const params = useParams();
  const envId = params?.envId || '';
  const devices = useDevices(envId, props.projectId);
  const device = devices.data?.find((d) => d.id === props.device.id);
  const notFound = devices.isSuccess && !device;
  const [updating, setUpdating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [notes, setNotes] = useState('');

  const NotInstalledText = () => (
    <Typography.Text strong type="warning">
      Not installed
    </Typography.Text>
  );

  const hasPermissions = usePermissions({
    projectId: props.projectId.toString(),
    environmentId: props.envId,
    deploymentPlanId: props.plan.id
  }).deploymentSvc$updateDeployment;

  if (!hasPermissions) return <NotInstalledText />;

  let buttonCaption = DEFAULT_BUTTON_LABEL;
  if (notFound) {
    buttonCaption = 'Device not available';
  }

  if (props.plan.locked) {
    buttonCaption = 'Locked';
  }
  const updateDisabled = props.plan.locked || notFound;
  const loading = updating || !devices.isSuccess;
  return props.plan.locked ? (
    <NotInstalledText />
  ) : (
    <>
      <ScrollDialog
        title="Confirm Installation"
        width={512}
        confirmLoading={updating}
        open={isVisible}
        okButtonProps={{ disabled: updateDisabled }}
        cancelButtonProps={{ disabled: updating }}
        onOk={() => {
          setUpdating(true);
          update
            .mutateAsync([
              {
                action: 'install',
                deviceId: props.device.id,
                mvccId: props.plan.mvccId,
                component: props.softwareComponent,
                notes
              },
              props.plan.id,
              props.projectId,
              props.envId
            ])
            .then(() => {
              setIsVisible(false);
              setNotes('');
              props.onDone();
            })
            .finally(() => {
              setUpdating(false);
            });
        }}
        onCancel={() => {
          setIsVisible(false);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Typography.Text>Please confirm the installation of </Typography.Text>
            <Typography.Text strong type="warning">
              {props.softwareComponent.name}
            </Typography.Text>
            <Typography.Text> version </Typography.Text>
            <Typography.Text strong type="warning">
              {props.softwareComponent.version}
            </Typography.Text>
            <Typography.Text> to device </Typography.Text>
            <Typography.Text strong type="warning">
              {props.device.rds}
            </Typography.Text>
          </div>
          <DescriptionTextAreaInput value={notes} onChange={(e) => setNotes(e.target.value)} placeholder="Installation notes" />
          <PermanentActionWarningLabel />
        </Space>
      </ScrollDialog>
      <Button
        loading={loading}
        onClick={() => {
          setIsVisible(true);
        }}
        key="install"
        icon={buttonCaption === DEFAULT_BUTTON_LABEL ? undefined : <CheckOutlined />}
        type="primary"
        disabled={updateDisabled}
      >
        {`${buttonCaption}`}
      </Button>
    </>
  );
};
