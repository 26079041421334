import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { unwrapAxiosQuery, presentSuccess } from '../../../api';

import { submitUserSatisfaction } from '../../../api/usersatisfaction-service/usersatisfaction-service';
import { PacTSContext } from '../../../state/store';

type UserSatisfactionDto = {
  rating: number;
  feedback: string;
  user: string;
};

export function useSubmitUserSatisfaction() {
  const [state] = useContext(PacTSContext);
  return useMutation<any, any, UserSatisfactionDto>((data) => {
    return new Promise<void>((resolve, reject) => {
      const feedbackString = `${data.feedback} (${data.user})`;
      unwrapAxiosQuery(submitUserSatisfaction(state.userSatisfactionBasePath, data.rating, feedbackString))
        .then(() => {
          presentSuccess('Thank you for your feedback!');
          return resolve();
        })
        .catch((e) => reject(e));
    });
  });
}
