import { SoftwareAppCategory } from '../../../../api/engineering/domain/types';
import { useSharedState } from '../../../shared/hooks/useSharedState';
import { useSoftwareAppCategories } from '../../../softwareApps/hooks/useSoftwareAppsCategories';
import { EntityAdministrationListLayout } from './EntityAdministrationListLayout';

type UsedType = SoftwareAppCategory;
const entityName = 'App Category';
const entityKey = 'entity-admin-app-category';
const idAttribute = 'id';
const titleAttribute = 'name';
export const AppCategoriesList = () => {
  const entities = useSoftwareAppCategories();
  const [selected, setSelected] = useSharedState<UsedType>(entityKey);
  const editingEntity = entities.data?.find((e) => e[idAttribute] === (selected ? selected[idAttribute] : undefined));
  const creatingNew = selected !== null && editingEntity === undefined;
  return (
    <>
      <EntityAdministrationListLayout
        isLoading={entities.isLoading}
        data={entities.data || []}
        idAttribute={idAttribute}
        titleAttribute={titleAttribute}
        onSelect={(val) => setSelected(val!)}
        selected={selected}
        onReset={() => setSelected({ id: -1, description: '', name: '' })}
        entityName={entityName}
        creatingNew={creatingNew}
      />
    </>
  );
};
