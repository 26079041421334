import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const prefix = 'pacts-state-';
export const useLocalStorageState = <T>(key: string, defaultValue?: T): [T | null, (val: T) => void, () => void] => {
  const qc = useQueryClient();
  const localStorageKey = `${prefix}-${key}`;
  const query = useQuery<T | null, [string]>(
    [key],
    (): T | null => {
      const item = localStorage.getItem(localStorageKey) as string;
      if (!item && defaultValue == null) {
        return null;
      }
      if (!item) {
        return defaultValue ?? null;
      }
      try {
        const parsed = JSON.parse(item) as T;
        return parsed;
      } catch (error) {
        console.warn('error parsing local storage', item);
        return null;
      }
    },
    {
      staleTime: Infinity,
      refetchInterval: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  const mutate = useMemo(
    () => (val: T) => {
      localStorage.setItem(localStorageKey, JSON.stringify(val));
      qc.setQueryData([key], val, { updatedAt: Date.now() });
    },
    [qc, key, localStorageKey]
  );
  const reset = useMemo(
    () => () => {
      localStorage.removeItem(localStorageKey);
      qc.setQueryData([key], undefined, { updatedAt: Date.now() });
    },
    [qc, key, localStorageKey]
  );

  return [query.data ?? defaultValue ?? null, mutate, reset];
};
