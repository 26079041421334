import { useQuery } from '@tanstack/react-query';
import { ProjectMember } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useProjectMembers(projectId: string) {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const permissions = usePermissions({ projectId });
  return useQuery<ProjectMember[], [string, string]>(
    ['projectMembers', projectId],
    (): Promise<ProjectMember[]> => {
      return backend.queryProjectMembers(projectId);
    },
    {
      enabled: parsedProjectId > -1 && permissions.engineeringSvc$getProjectMembers
    }
  );
}
