import { useQuery } from '@tanstack/react-query';
import { ColumnsWithFrom } from '../../../api/report/domain/types';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useComputedFolderColumns(projectId: number, sourceId: string, folderId: string) {
  const { backend } = useReportBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery<ColumnsWithFrom, [string, string]>(
    ['computedFolderColumns', projectId, sourceId, folderId],
    (): Promise<ColumnsWithFrom> => {
      return backend.getCalculatedFolderColumns(projectId, sourceId, folderId);
    },
    {
      enabled: projectId > -1 && permissions.reportSvc$getReportCalculatedFolderColumns && sourceId.length > 0 && folderId.length > 0
    }
  );
}
