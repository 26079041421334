import { useEffect } from 'react';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { singletonHook } from 'react-singleton-hook';
import { WS_QUERY_OPTIONS } from '../../../api/shared/messaging/queryOptions';
import { ProjectDto, ProjectReadModelUpdatedEventPayload } from '../../../api/pactsformation/domain/types';
import { usePactsFormationBackend } from '../../../api/pactsformation/hooks/usePactsFormationBackend';

const queryKey = ['pactsformation', 'resources', 'projects'];

const upsertHandler = (queryClient: QueryClient) => (data: ProjectReadModelUpdatedEventPayload) => {
  const queryData = queryClient.getQueryData(queryKey) as ProjectDto[];
  const stateItems = [...queryData];
  console.warn('state items', { stateItems, data });
  const existing = stateItems.findIndex((u) => u.id === data.currentState.id);
  if (existing > -1) {
    stateItems[existing] = data.currentState;
  } else {
    stateItems.push(data.currentState);
  }
  queryClient.setQueryData([...queryKey, data.currentState.id], data.currentState);
  queryClient.setQueryData(queryKey, stateItems);
};

const usePactsFormationEvents = singletonHook(undefined, () => {
  const queryClient = useQueryClient();
  const { backend } = usePactsFormationBackend();

  useEffect(() => {
    const handler = upsertHandler(queryClient);
    backend.onProjectResourceUpdate(handler);
    return () => {
      backend.offProjectResourceUpdate(handler);
    };
  }, [queryClient, backend]);
});

export const usePactsFormationHandler = () => {
  usePactsFormationEvents();
  return useQuery<ProjectDto[]>(
    [...queryKey],
    (): Promise<ProjectDto[]> => {
      return Promise.resolve([]);
    },
    WS_QUERY_OPTIONS
  );
};

export const usePactsFormationProject = (id: string) => {
  const queryClient = useQueryClient();
  const { state, backend } = usePactsFormationBackend();
  const handler = upsertHandler(queryClient);
  return useQuery<ProjectDto>(
    [...queryKey, id],
    (): Promise<ProjectDto> => {
      return new Promise<ProjectDto>((resolve, reject) => {
        backend
          .queryResources(id)
          .then((res) => {
            handler(res);
            return resolve(res.currentState);
          })
          .catch(reject);
      });
    },
    { ...WS_QUERY_OPTIONS, retry: false, enabled: state.isReady }
  );
};
