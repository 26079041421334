import { useQuery } from '@tanstack/react-query';
import { RefreshTokenMetadataWithUserId } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';

export const useUserSessions = () => {
  const { backend } = useUserBackend();
  return useQuery<RefreshTokenMetadataWithUserId[], [string]>(['userSessions'], (): Promise<RefreshTokenMetadataWithUserId[]> => {
    return backend.getUserSessions();
  });
};
