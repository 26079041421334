import { Flex, Skeleton, Space } from 'antd';
import { Comparator } from '../../../domain/extensions/comparison';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useTableSearch } from '../../shared/components/TableSearch';
import { useOtcs } from '../hooks/useOtcs';

import { CreateOtc } from './CreateOtc';
import { OtcCsvExport } from './OtcCsvExport';
import { OtcCsvPopover } from './OtcCsvPopover';
import { DeleteOtcButton } from './OtcDeleteButton';
import { UpdateOtc } from './UpdateOtc';
import Table from '../../../contexts/shared/components/Table/Table';
import { Otc } from '../../../api';
import { useParams } from 'react-router-dom';
import { useSearchParameter } from '../../../contexts/navigation/hooks';

export const Otcs = (props: { projectId: number }) => {
  const params = useParams() as Record<string, any>;
  const envId = params?.envId || '';
  const otcs = useOtcs(props.projectId, envId);
  const permissions = usePermissions({ projectId: props.projectId.toString(), environmentId: envId ?? undefined });
  const nameSearch = useTableSearch({ searchValueProvider: 'name', searchParamId: 'otc_name' });
  const descriptionSearch = useTableSearch({ searchValueProvider: 'description', searchParamId: 'otc_desc' });

  const [tablePage, setTablePage] = useSearchParameter('otc_p', '1');
  const tablePageNumber = parseInt(tablePage || '1');
  const [pageSize, setPageSize] = useSearchParameter('otc_ps', '50');
  const pageSizeNumber = parseInt(pageSize || '50');

  const nameSorter = (a: Otc, b: Otc) => Comparator.lexicographicalComparison(a.name, b.name);
  const descriptionSorter = (a: Otc, b: Otc) => Comparator.lexicographicalComparison(a.description, b.description);

  const columns: any[] = [
    {
      title: 'OTC name',
      key: 'name',
      dataIndex: 'name',
      width: 300,
      fixed: 'left',
      defaultSortOrder: 'ascend',
      ...nameSearch,
      sorter: nameSorter
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      ...descriptionSearch,
      sorter: descriptionSorter,
      fixed: 'left'
    },
    {
      title: 'Actions',
      align: 'center',
      key: 'actions',
      width: 55,
      render: (p: Otc) => (
        <Space>
          {permissions.deploymentSvc$updateOtc && <UpdateOtc otc={p} key="update" projectId={props.projectId} />}

          {permissions.deploymentSvc$deleteOtc && <DeleteOtcButton otc={p} key="delete" projectId={props.projectId} />}
        </Space>
      )
    }
  ];

  const table = (
    <>
      <Table
        size="small"
        scroll={{ x: true }}
        columns={columns}
        rowKey={(record: Otc) => record.id?.toString() || ''}
        dataSource={otcs.data || []}
        pagination={{
          showSizeChanger: true,
          current: tablePageNumber,
          onChange: (n) => setTablePage(n.toString()),
          defaultPageSize: 50,
          pageSize: pageSizeNumber,
          onShowSizeChange: (_, n) => setPageSize(n.toString()),
          pageSizeOptions: ['50', '100', '200']
        }}
      />
    </>
  );

  return (
    <>
      <Flex gap="10px" justify="end" style={{ marginBottom: '15px' }}>
        {permissions.deploymentSvc$addOtc && <OtcCsvPopover projectId={props.projectId} />}
        {permissions.deploymentSvc$addOtc && <OtcCsvExport projectId={props.projectId} />}
        {permissions.deploymentSvc$addOtc && <CreateOtc projectId={props.projectId} />}
      </Flex>

      {otcs.isLoading && <Skeleton active />}
      {otcs.isSuccess && <>{table}</>}
    </>
  );
};
