/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

export type PacTSIconDarkProps = {
  style?: React.CSSProperties;
};

export const PacTSIconSimple: React.FunctionComponent<PacTSIconDarkProps> = (props: PacTSIconDarkProps) => {
  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg viewBox="0 0 257 257" width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M88.7374 73.5016C79.3291 64.1038 79.3291 48.8669 88.7374 39.4691L111.046 17.1854C120.454 7.78757 135.708 7.78755 145.116 17.1854L168.157 40.1999C177.565 49.5977 177.565 64.8346 168.157 74.2324L134.491 107.86C131.355 110.993 126.27 110.993 123.134 107.86L88.7374 73.5016Z"
        fill={props.style?.color ?? '#07C3F2'}
      />
      <path
        d="M148.689 133.416C145.553 130.283 145.553 125.204 148.689 122.072L182.354 88.4438C191.762 79.046 207.016 79.046 216.425 88.4438L239.465 111.458C248.873 120.856 248.873 136.093 239.465 145.491L217.156 167.775C207.748 177.172 192.494 177.172 183.086 167.775L148.689 133.416Z"
        fill={props.style?.color ?? '#07C3F2'}
      />
      <path
        d="M89.4366 216.8C80.0283 207.402 80.0283 192.165 89.4366 182.768L123.102 149.14C126.238 146.007 131.323 146.007 134.459 149.14L168.856 183.498C178.264 192.896 178.264 208.133 168.856 217.531L146.547 239.815C137.139 249.212 121.885 249.212 112.477 239.815L89.4366 216.8Z"
        fill={props.style?.color ?? '#C766FF'}
      />
      <path
        d="M17.5352 144.76C8.12694 135.362 8.12694 120.125 17.5352 110.727L39.8438 88.4437C49.2521 79.0459 64.506 79.0459 73.9143 88.4437L108.311 122.802C111.447 125.935 111.447 131.014 108.311 134.147L74.6459 167.774C65.2376 177.172 49.9837 177.172 40.5754 167.774L17.5352 144.76Z"
        fill={props.style?.color ?? '#C766FF'}
      />
    </svg>
  );

  return <Icon {...props} component={svg} />;
};
