import { useInfiniteQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { MembershipStatusResponse, usePactsFormationBackend } from '../../../api';
import { useEffect } from 'react';

export function useMemberships() {
  const permissions = usePermissions();
  const { backend } = usePactsFormationBackend();

  return useInfiniteQuery<MembershipStatusResponse>(['memberships'], ({ pageParam = undefined }) => backend.getAllMemberships(pageParam), {
    enabled: permissions.pactsFormationSvc$unrestrictedAdministration,
    getNextPageParam: (p) => {
      return p.nextPaginationToken;
    }
  });
}

export const useAllMemberships = () => {
  const memberships = useMemberships();
  useEffect(() => {
    if (memberships.hasNextPage && !memberships.isFetchingNextPage) {
      memberships.fetchNextPage();
    }
  }, [memberships]);

  const sourceData = memberships.data?.pages.map((p) => p.memberships).flat();
  return {
    ...memberships,
    data: sourceData
  };
};
