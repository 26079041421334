import { Project } from '../../../api/engineering/domain/types';
import { SoftwareAppSelection } from '../../../domain';
import { OpenComponentArgs, ToolManagerArgs, ToolManagerLink } from '../../../domain/toolmanager/toolmanagerArgs';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { PacTSIcon } from '../../shared/components/icons/PacTSIcon';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const SoftwareAppsOpenInToolManagerButton = (props: { selection: SoftwareAppSelection; project?: Project }) => {
  const metrics = useSubmitMetric();
  const disabled = props.selection.version.state !== 'consistent';

  const permissions = usePermissions({
    softwareAppId: props.selection.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.selection.version.idSoftwareAppVersion.toString(),
    projectId: props.project?.idProject.toString()
  });

  if (props.project && !permissions.impacts$downloadProjectSoftwareapp) return null;
  if (!props.project && !permissions.impacts$downloadCommonSoftwareapp) return null;

  const onClick = () => {
    const component: OpenComponentArgs = {
      component: {
        category: props.selection.app.categories?.map((c) => c.name!).join(', ') || '',
        id: props.selection.app.idSoftwareApp!.toString(),
        kind: props.selection.app.packageKind!,
        name: props.selection.app.name,
        scope: props.selection.app.scope,
        targets: props.selection.version.targets.map((t) => {
          return {
            targetId: t.target.idTarget,
            downloadLink: t.downloadLink,
            targetName: t.target.name,
            sha256: t.sha256
          };
        }),
        type: 'app',
        version: props.selection.version.version,
        versionId: props.selection.version.idSoftwareAppVersion!.toString(),
        context: props.project ? { projectId: props.project.idProject!.toString(), projectName: props.project.name } : undefined
      }
    };
    const args: ToolManagerArgs = {
      action: 'openComponent',
      payload: component
    };
    ToolManagerLink.open(args);

    if (props.project) {
      metrics
        .submitClick({
          operationId: MetricClickIds.openInImpacts
        })
        .withAppVersionContext(props.selection.app, props.selection.version, props.project);
    } else {
      metrics
        .submitClick({
          operationId: MetricClickIds.openInImpacts
        })
        .withCommonAppVersionContext(props.selection.app, props.selection.version);
    }
  };

  return (
    <>
      <ExpandableMenuItem disabled={disabled} icon={<PacTSIcon />} onClick={onClick}>
        Open in ImPacTS
      </ExpandableMenuItem>
    </>
  );
};
