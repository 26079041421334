import { Row, Col, Card } from 'antd';
import { useState } from 'react';
import { EngineeringToolSelection } from '../../../domain/engineeringToolsSelection';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AddEngineeringTool } from './AddEngineeringTool';
import EngineeringToolsList from './EngineeringToolsList';

export const EngineeringToolsView = () => {
  const [selectedTools, setSelectedTools] = useState([] as EngineeringToolSelection[]);
  const [initialSelection] = useState([] as EngineeringToolSelection[]);

  const permissions = usePermissions();

  return (
    <CenteredLayout fixed title="Engineering Platform Tools Overview" extra={[permissions.engineeringSvc$addTool && <AddEngineeringTool key="addTool" />]}>
      <Card>
        <Row>
          <Col span={24}>
            <EngineeringToolsList selected={selectedTools} onSelect={setSelectedTools} initiallySelected={initialSelection} hideCheckboxes />
          </Col>
        </Row>
      </Card>
    </CenteredLayout>
  );
};
