import { useMemo } from 'react';
import { useInAppNavigate } from './useInAppNavigate';

export const useSetSearchParameter = (shouldReplace: boolean = true) => {
  const navigate = useInAppNavigate();
  return useMemo(
    () => (param: string, value: string) => {
      const encodedParam = encodeURIComponent(param);
      const existingParams = {} as any;
      const hash = location.hash;
      const paramsParts = window.location.search.replace('?', '').split('&');
      paramsParts
        .filter((p) => p.includes('='))
        .forEach((p) => {
          const pSplit = p.split('=');
          existingParams[pSplit[0]] = pSplit[1];
        });
      if (value !== '') {
        existingParams[encodedParam] = encodeURIComponent(value);
      } else {
        delete existingParams[encodedParam];
      }
      const params: string[] = [];
      Object.keys(existingParams).forEach((key) => {
        params.push(`${key}=${existingParams[key]}`);
      });
      const search = params.length > 0 ? `?${params.join('&')}` : '';
      const target = window.location.pathname + search + hash;
      navigate(target, { replace: shouldReplace });
    },
    [navigate, shouldReplace]
  );
};
