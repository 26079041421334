import { useQuery } from '@tanstack/react-query';
import { SoftwareApp } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export const useSoftwareApp = (softwareAppId: string, projectId?: string) => {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId, softwareAppId });
  const hasPermission = projectId ? permissions.engineeringSvc$getProjectSoftwareApp : permissions.engineeringSvc$getCommonSoftwareApp;

  let parsedProjectId = -1;
  let param: string = '';
  let queryKey: string[] = [];
  if (projectId) {
    param = 'softwareapp';
    parsedProjectId = parseInt(projectId, 10);
    queryKey = [param, projectId, softwareAppId];
  } else {
    param = 'commonSoftwareapp';
    queryKey = [param, softwareAppId];
  }
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  return useQuery<SoftwareApp, [string, string, string] | [string, string]>(
    queryKey,
    (): Promise<SoftwareApp> => {
      if (projectId) {
        return backend.queryProjectApp(projectId, softwareAppId);
      }
      return backend.queryCommonApp(softwareAppId);
    },
    {
      enabled: projectId ? parsedProjectId > -1 && parsedSoftwareAppId > -1 : parsedSoftwareAppId > -1 && hasPermission
    }
  );
};
