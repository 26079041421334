import { UseQueryResult } from '@tanstack/react-query';
import { Project } from '../../../api/engineering/domain/types';
import { Comparator } from '../../../domain/extensions/comparison';
import { usePinnedProjectIds } from './usePinnedProjectIds';
import { useProjects } from './useProjects';

export function usePinnedProjects() {
  const [pinnedProjects] = usePinnedProjectIds();
  const projects = useProjects();

  const filteredProjects = projects.data
    ? projects.data.filter((p) => pinnedProjects?.includes(p.idProject.toString())).sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
    : undefined;

  const ret = {
    ...projects,
    data: filteredProjects
  };
  return ret as UseQueryResult<Project[] | undefined, [string]>;
}
