export class RequestBatcher<T> {
  private timeout: any;
  
  private buffer: T[] = [];

  constructor(
    private readonly maxBatchSize: number,
    private readonly maxIntervalInMs: number,
    private readonly callback: (value: T[]) => any
  ) {}

  public submit(value: T) {
    if (!this.timeout && this.buffer.length < 1) {
      this.timeout = setTimeout(this.emit.bind(this), this.maxIntervalInMs);
    }
    this.buffer.push(value);
    if (this.buffer.length >= this.maxBatchSize) {
      this.emit();
    }
  }

  private emit() {
    const bufferCopy = [...this.buffer];
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = undefined;
    this.buffer.length = 0;
    this.callback(bufferCopy);
  }
}
