import { Badge, Tooltip } from 'antd';

export const PacTSFormationHeartbeat = () => {
  // const data = useNotificationHeartbeat();
  // let status: 'processing' | 'success' | 'error' | 'default' | 'warning' | undefined = 'processing';
  // if (data.isSuccess) {
  //   status = 'success';
  // }
  // if (data.isError) {
  //   status = 'error';
  // }
  // if (data.isFetching) {
  //   status = 'processing';
  // }
  return (
    <Tooltip title="PacTS Formation (disabled)">
      <Badge status="default" />
    </Tooltip>
  );
};
