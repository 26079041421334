import { Navigate, useLocation } from 'react-router-dom';
import { AppLayout } from './AppLayout';
import { PlainRouteLayout } from './PlainRouteLayout';
import { useSession } from '../contexts/session/hooks/useSession';
import { useContext, useEffect } from 'react';
import useSubmitMetric from '../contexts/metrics/hooks/useMetrics';
import { useHasActiveSession } from '../contexts/session/hooks/usePermissions';
import { PacTSContext } from '../state/store';
import { useRestrictedUseCaseRedirect } from './hooks/useRestrictedUseCaseRedirect';
import { PacTSPermissions } from '@pacts/permissions-lib';

export type PacTSRouteProto = {
  /** 
   * Public pages are available without prior login
   **/
  public?: boolean;
  /** 
   * Hide the layout (menu bar, etc.)
   **/
  layoutHidden?: boolean;
  /** 
   * Route path
   **/
  path: string;
  /** 
   * Page content
   **/
  element: React.ReactNode;
  /** 
   * Inner array permissions are combined using OR
   * outer array permissions using AND
   * e.g., [[A,B], [C]] means (A OR B) AND C.
   * The router filters routes based on those permissions.
   * Routs w/o permissions are not available to route to
   **/
  requiredPermissions: (keyof PacTSPermissions)[][];
};

export const PacTSLayoutRootElement = (props: { route: PacTSRouteProto }) => {
  // Do not debounce routes here since they immediately have to be available
  // once the token is ready. No propagation delay required.
  const hasActiveSession = useHasActiveSession();
  const location = useLocation();
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const metrics = useSubmitMetric();

  // navigation hook for restricted permissions
  const restrictedUseCaseRedirect = useRestrictedUseCaseRedirect();

  const loggedIn = session.state === 'loggedIn';

  const rewriteToLogin = !loggedIn && !props.route.public;

  // unauthorized is not a public route, but used for a logged in user (token available)
  // who does not have permissions to view any of the available pages (yet)
  // !hasActiveSession currently is an "initial" or in the future "disabled" user
  const unauthorized = !hasActiveSession && !props.route.public && location.pathname !== '/unauthorized';

  let content = props.route.element;
  let hasRedirect = false;

  // On pathname change submit metrics update
  useEffect(() => metrics.submitNavigate(), [metrics, location.pathname]);

  const wrappedContent = (node: React.ReactNode) => (props.route.layoutHidden ? <PlainRouteLayout>{node}</PlainRouteLayout> : <AppLayout>{node}</AppLayout>);

  // always show public routes
  if (props.route.public) {
    return wrappedContent(content);
  }

  // redirect to login if not logged in
  if (rewriteToLogin && !hasRedirect) {
    console.warn('redirecting to login', { loggedIn, public: props.route.public });
    content = <Navigate to="/login" state={{ from: location }} />;
    hasRedirect = true;
  }

  // redirect to unauthorized if logged in but unauthorized
  // do not redirect if prior redirect triggered
  if (unauthorized && !hasRedirect) {
    console.warn('redirecting to unauthorized', { loggedIn, public: props.route.public, location });
    content = <Navigate to="/unauthorized" state={{ from: location }} />;
    hasRedirect = true;
  }
  
  // check for restricted use case redirects
  // in case of limited permissions, we might redirect to certain pages
  // in certain states (e.g., don't show home page if only access to sw platform)
  // do not redirect if prior redirect triggered
  if (!!restrictedUseCaseRedirect && !hasRedirect) {
    content = <Navigate to={restrictedUseCaseRedirect} state={{ from: location }} />;
    hasRedirect = true;
  }

  return wrappedContent(content);
};
