import { NotificationMatcher } from '../domain/extractor';
import { PacTSNotification } from '../../../api/notifications/domain/types';
import { DefaultNotification } from './renderers/DefaultNotification';

export const Notification = (props: {
  notification: PacTSNotification;
  matcher: NotificationMatcher[];
  linkHandler?: (href: string) => { preventDefault: boolean };
}) => {
  const matched = props.matcher.find((m) => m.regex.test(props.notification.topic))?.extractor(props.notification);
  return (
    <>
      {matched &&
        (matched.renderer ? (
          <div>{matched.renderer()}</div>
        ) : (
          <DefaultNotification notification={props.notification} withLink={matched.link.isClickable} linkHandler={props.linkHandler} />
        ))}
      {!matched && <DefaultNotification notification={props.notification} linkHandler={props.linkHandler} />}
    </>
  );
};
