import { useState } from 'react';
import { ExternalTokenDialogContent } from './ExternalTokenDialogContent';
import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { ExpandableMenuItem } from '../shared/components/ExpandableMenuItem';
import { ScrollDialog } from '../layout/components/ScrollDialog';

const StyledCloseButton = styled(Button)`
  width: 100px !important;
`;

export const ExternalTokenDialog = () => {
  const [visible, setVisible] = useState(false);

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <ExpandableMenuItem icon={<LockOutlined />} onClick={() => setVisible(true)}>
        Create Functional Token
      </ExpandableMenuItem>
      <ScrollDialog
        footer={
          <Row gutter={8} justify="end">
            <Col>
              <StyledCloseButton type="primary" onClick={handleCloseModal}>
                Close
              </StyledCloseButton>
            </Col>
          </Row>
        }
        width={868}
        title="Generate refresh token"
        open={visible}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        <ExternalTokenDialogContent />
      </ScrollDialog>
    </>
  );
};
