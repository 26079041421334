import { useQuery } from '@tanstack/react-query';
import { UserRoleWithPermissions } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useUserRoles() {
  const { backend } = useUserBackend();
  const permissions = usePermissions();
  return useQuery<UserRoleWithPermissions[], [string]>(
    ['userRoles'],
    (): Promise<UserRoleWithPermissions[]> => {
      return backend.queryRoles();
    },
    { enabled: permissions.userSvc$getRoles }
  );
}

export default useUserRoles;
