import { Radio, Skeleton } from 'antd';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useEngineeringTool } from '../hooks/useEngineeringTool';
import { EngineeringToolVersionsList } from './EngineeringToolVersionsList';
import { EngineeringToolUsageList } from './EngineeringToolUsageList';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { AddEngineeringToolVersion } from './AddEngineeringToolVersion';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { Project } from '../../../api/engineering/domain/types';
import { BackToProjectOrEngineeringPlatformButton } from '../../navigation/components/BackToProjectOrEngineeringPlatformButton';
import { useProject } from '../../../contexts/projects/hooks/useProject';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const EngineeringToolVersions = () => {
  const { id } = useParams<{ id: string }>();
  const tool = useEngineeringTool(id!);
  const projectRef = useRef<Project | undefined>(undefined);
  const [usageViewSearchParam, setUsageViewSearchParam] = useSearchParameter('view');
  const [projectIdParam] = useSearchParameter('projectId');
  const project = useProject(projectIdParam ?? '');
  const permissions = usePermissions({ toolId: id });

  const hasComparePermissions = permissions.engineeringSvc$getToolUsage;

  const showCompareRadio = hasComparePermissions;

  const tab = usageViewSearchParam || 'versions';
  const options = [
    { label: 'Version details', value: 'versions' },
    { label: 'Project integration', value: 'usage' }
  ];

  return (
    <>
      <CenteredLayout
        fixed
        // In order to avoid an ant-design inteface issue
        // we have to override the prop to string using
        // TS Type casting
        title={
          <>
            {`Versions overview:  ${tool.data?.name ?? ''}`}
            {showCompareRadio ? (
              <>
                <br />
                <Radio.Group
                  style={{ marginTop: 8 }}
                  options={options}
                  onChange={(e) => setUsageViewSearchParam(e.target.value)}
                  value={tab}
                  optionType="button"
                  buttonStyle="solid"
                />
              </>
            ) : null}
          </>
        }
        extra={[
          !!tool.data ? <AddEngineeringToolVersion key="add" tool={tool.data} detailView /> : null,
          <BackToProjectOrEngineeringPlatformButton key="back" project={project?.data ?? null} isLoading={Boolean(project.isLoading && projectIdParam)} />
        ]}
      >
        {tab === 'versions' && (
          <Skeleton loading={!tool.isSuccess}>
            <EngineeringToolVersionsList extraHeaderOffset={showCompareRadio ? 43 : 0} engineeringTool={tool.data!} project={projectRef.current} />
          </Skeleton>
        )}
        {tab === 'usage' && (
          <Skeleton loading={!tool.isSuccess}>
            <EngineeringToolUsageList engineeringTool={tool.data!} />
          </Skeleton>
        )}
      </CenteredLayout>
    </>
  );
};
