const lgLabelWidth = 4;
export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: lgLabelWidth }
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 24 - lgLabelWidth }
  }
};
export const inlineFormItemLayout = {
  labelCol: { xs: { span: 0 } },
  wrapperCol: { xs: { span: 24 } }
};
export const nestedFormItemLayout = {
  labelCol: { xs: { span: 0 } },
  wrapperCol: {
    lg: { span: 24 - lgLabelWidth, push: lgLabelWidth }
  }
};
