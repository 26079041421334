import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { singletonHook } from 'react-singleton-hook';
import { useEffect } from 'react';
import { SubscriptionDetail } from '../../../api/notifications/domain/types';
import { QK_NOTIFICATIONS, QK_SUBSCRIPTION } from './queryKeys';
import { usePermissions } from '../../session/hooks/usePermissions';;
import { useNotificationsBackend } from '../../../api/notifications/hooks/useNotificationsBackend';

const invalidateSubscriptionsHandler = (queryClient: QueryClient) => () => {
  queryClient.invalidateQueries(QK_NOTIFICATIONS);
  queryClient.invalidateQueries(QK_SUBSCRIPTION);
};

const useSubscriptionEvents = singletonHook(undefined, () => {
  const queryClient = useQueryClient();
  const { backend } = useNotificationsBackend();

  useEffect(() => {
    const invalidateHandler = invalidateSubscriptionsHandler(queryClient);
    backend.onSubscriptionAdded(invalidateHandler);
    backend.onSubscriptionDeleted(invalidateHandler);
    return () => {
      backend.offSubscriptionAdded(invalidateHandler);
      backend.offSubscriptionDeleted(invalidateHandler);
    };
  }, [queryClient, backend]);
});

export const useSubscriptions = () => {
  const { backend } = useNotificationsBackend();
  useSubscriptionEvents();
  const permissions = usePermissions();
  const enabled = permissions.notificationSvc$userSubscriptionsRequest;
  return useQuery<SubscriptionDetail[]>(
    QK_SUBSCRIPTION,
    (): Promise<SubscriptionDetail[]> => {
      return backend.querySubscriptions();
    },
    {
      enabled
    }
  );
};

export const useSendSubscriptions = (restricted?: boolean) => {
  const queryClient = useQueryClient();
  const { backend } = useNotificationsBackend();
  return useMutation<any, any, SubscriptionDetail[]>(
    (payload) => {
      return backend.subscribe(payload, restricted ?? false);
    },
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries(QK_SUBSCRIPTION);
        const stateItems = [...(queryClient.getQueryData(QK_SUBSCRIPTION) as SubscriptionDetail[])];
        const previousStateItems = [...stateItems];
        data.forEach((si) => {
          const existing = stateItems.findIndex((u) => u.topicPattern === si.topicPattern && u.transportMean === si.transportMean);
          if (existing > -1) {
            stateItems[existing] = si;
          } else {
            stateItems.push(si);
          }
        });
        queryClient.setQueryData(QK_SUBSCRIPTION, stateItems);
        return { previousStateItems };
      },
      onError: (err, vars, ctx) => {
        queryClient.setQueryData(QK_SUBSCRIPTION, (ctx as any).previousStateItems);
      },
      onSettled: () => {
        invalidateSubscriptionsHandler(queryClient)();
      }
    }
  );
};

export const useDeleteSubscription = (restricted?: boolean) => {
  const queryClient = useQueryClient();
  const { backend } = useNotificationsBackend();
  return useMutation<any, any, SubscriptionDetail[]>(
    (payload) => {
      return backend.unsubscribe(payload, restricted ?? false);
    },
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries(QK_SUBSCRIPTION);
        const stateItems = [...(queryClient.getQueryData(QK_SUBSCRIPTION) as SubscriptionDetail[])];
        const previousStateItems = [...stateItems];
        data.forEach((si) => {
          const existing = stateItems.findIndex((u) => u.topicPattern === si.topicPattern && u.transportMean === si.transportMean);
          if (existing > -1) {
            stateItems.splice(existing, 1);
          }
        });
        queryClient.setQueryData(QK_SUBSCRIPTION, stateItems);
        return { previousStateItems };
      },
      onError: (err, vars, ctx) => {
        queryClient.setQueryData(QK_SUBSCRIPTION, (ctx as any).previousStateItems);
      },
      onSettled: () => {
        invalidateSubscriptionsHandler(queryClient)();
      }
    }
  );
};

const extractMatchLevels = (s: string) => {
  return s
    .replace(new RegExp('[*]{2}', 'g'), '')
    .split('/')
    .filter((e) => e);
};

export const useSubscriptionRegex = () => {
  const subscriptions = useSubscriptions();
  const regexified = subscriptions.data?.map((s) => {
    const replaced = s.topicPattern
      .replace(new RegExp('[*]{2}', 'g'), '[a-zA-Z0-9-/*]*')
      .replace(new RegExp('/[*]', 'g'), '/[a-zA-Z0-9-*]*')
      .replace(new RegExp('/', 'g'), '\\/');
    const regExp = new RegExp(`^${replaced}$`);
    const levels = extractMatchLevels(s.topicPattern);
    return {
      levels,
      topicPattern: s.topicPattern,
      topicPatternRegex: regExp,
      transportMean: s.transportMean,
      directlyMatches: (data: string) => {
        return regExp.test(data) && extractMatchLevels(data).length === levels.length;
      }
    };
  });
  return {
    ...subscriptions,
    data: regexified
  };
};
