import { useQuery } from '@tanstack/react-query';
import { User } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useUsers() {
  const { backend } = useUserBackend();
  const permissions = usePermissions();
  return useQuery<User[], [string]>(
    ['users'],
    (): Promise<User[]> => {
      return backend.queryUsers(false);
    },
    { enabled: permissions.userSvc$getUsers }
  );
}

export default useUsers;
