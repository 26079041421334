import { useContext, useEffect } from 'react';
import { ProductTourFloatingButton } from '../../../contexts/productTourContext/ProductTourFloatingButton';
import { productTourStartButton } from '../../../contexts/productTourContext/ProductTourStartButton';
import { productTourStep } from '../../../contexts/productTourContext/ProductTourStep';
import useAddTourSteps from '../../../contexts/productTourContext/hooks/useAddTourSteps';
import useIsTourPassed from '../../../contexts/productTourContext/hooks/useIsTourPassed';
import { ProductTourContext } from '../../../contexts/productTourContext/ProductTourContext';

const tourId = 'reports-tour';

export const ReportTableTour = () => {
  const isPassed = useIsTourPassed(tourId);
  const { setStepsState } = useContext(ProductTourContext);

  // Fixes the rendering of the tour when navigating
  useEffect(() => {
    return () => {
      setStepsState((prevSteps) => ({
        ...prevSteps,
        [tourId]: []
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAddTourSteps(
    [
      productTourStartButton({
        title: 'Introducing PDP Reports!',
        description:
          'Unlock enhanced accessibility to project reports through our dynamic dashboard. Explore, download, and collaborate on reports with your team.',
        target: '#report-tour-element0'
      }),
      productTourStep({
        title: 'Simplified Filtering and Advanced Search',
        paragraph1: 'Say goodbye to the hassle of manually sorting through folders.',
        paragraph2: 'Easily navigate through your reports using filters such as report name, asset, version and more.',
        step: 2,
        target: '[id^="table-"]'
      }),
      productTourStep({
        title: 'Go Back in Time',
        paragraph1: 'Check what the reports looked like in prior revisions',
        paragraph2: 'Simply select any revision using its version tag or the release notes.',
        step: 3,
        target: '#report-revision-selector'
      }),
      productTourStep({
        title: 'Instant Access to Reports',
        paragraph1: 'Accessing your reports has never been easier.',
        paragraph2: "Dive into your data by selecting the 'Open Report' button to view your report.",
        step: 4,
        target: '#report-tour-element2'
      }),
      productTourStep({
        title: 'Downloads Made Simple',
        paragraph1: 'Download easily the reports you need.',
        paragraph2: 'Enjoy the fast access to report downloads with just one click.',
        step: 5,
        target: '#report-tour-element5'
      }),
      productTourStep({
        title: 'Simplified sharing',
        paragraph1: 'Collaboration made easy.',
        paragraph2: 'Share your reports instantly with your team.',
        step: 6,
        target: '#report-tour-element3'
      }),
      productTourStep({
        title: 'Dive into Details',
        paragraph1: 'See the details of your reports.',
        paragraph2: 'Discover information such as OTC codes, asset data, report timestamps, along with other important data.',
        step: 7,
        target: '#report-tour-element7'
      }),
      productTourStep({
        title: 'Customize your view',
        paragraph1: 'Customize your dashboard according to your unique needs.',
        paragraph2: 'Configure columns to display the information you need. Enjoy a personalized interface that caters to your data insights.',
        step: 8,
        target: '#report-tour-element',
        autoStart: !isPassed
      })
    ],
    tourId,
    []
  );

  return <ProductTourFloatingButton buttonId="report-tour-element0" title="'PDP reports' guided tour" tourId={tourId} />;
};
