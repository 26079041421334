import { useCallback, useEffect, useState } from 'react';
import { AuthTokenResponse, OauthDeviceCodeResponse, TokenResponseErrorErrorEnum, useAuthBackend } from '../../../api';
import { AxiosError } from 'axios';

const pollExtraDelay = 200;

type DeviceFlowError = {
  title: string;
  description: string;
};

const internalError: DeviceFlowError = {
  title: 'Internal Error',
  description: 'An unexpected internal error ocurred, please try again.'
};
const timeoutError: DeviceFlowError = {
  title: 'The code for login expired.',
  description: 'Please close the window and try again to generate a refresh token.'
};

export const useDeviceFlow = () => {
  const { backend } = useAuthBackend();
  const [deviceCodeResponse, setDeviceCodeResponse] = useState<OauthDeviceCodeResponse>();
  const [authTokenResponse, setAuthTokenResponse] = useState<AuthTokenResponse>();
  const [error, setError] = useState<DeviceFlowError>();

  const pollResult = useCallback(
    (deviceCode: string) => {
      backend
        .pollDeviceFlow(deviceCode)
        .then(setAuthTokenResponse)
        .catch((err) => {
          const errMessage = ((err as AxiosError).response?.data as { error: string }).error;
          switch (errMessage) {
            case TokenResponseErrorErrorEnum.AuthorizationPending: {
              break;
            }
            case TokenResponseErrorErrorEnum.ExpiredToken: {
              setError(timeoutError);
              setDeviceCodeResponse(undefined);
              break;
            }
            default: {
              setDeviceCodeResponse(undefined);
              setError(internalError);
            }
          }
        });
    },
    [backend, setAuthTokenResponse, setError, setDeviceCodeResponse]
  );

  useEffect(() => {
    backend
      .startDeviceFlow()
      .then(setDeviceCodeResponse)
      .catch(() => {
        setError(internalError);
        setDeviceCodeResponse(undefined);
      });
  }, [backend, setDeviceCodeResponse, setError]);

  useEffect(() => {
    if (!deviceCodeResponse || authTokenResponse) return;
    const deviceCode = deviceCodeResponse.device_code;
    const interval = setInterval(
      () => {
        pollResult(deviceCode);
      },
      deviceCodeResponse.interval * 1000 + pollExtraDelay
    );
    return () => {
      clearInterval(interval);
    };
  }, [pollResult, deviceCodeResponse, authTokenResponse]);

  return {
    isLoading: deviceCodeResponse === undefined,
    deviceCode: deviceCodeResponse,
    tokenResponse: authTokenResponse,
    err: error
  };
};
