import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, delay?: number, overrideInitial?: T): T => {
  const actualDelay = delay || 100;
  const [debouncedValue, setDebouncedValue] = useState(overrideInitial ?? value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, actualDelay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, actualDelay]);
  return debouncedValue;
};
