import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { usePinnedProjectIds } from '../hooks/usePinnedProjectIds';

export const ProjectPin = (props: { projectId: string, pinStyle?: React.CSSProperties }) => {
  const [pinnedProjects, mutate] = usePinnedProjectIds();
  const ids = pinnedProjects || [];
  const starred = ids.includes(props.projectId);
  // TODO: cleanup

  const pin = () => {
    const updated = [...ids, props.projectId];
    mutate(updated);
  };
  const unpin = () => {
    const index = ids.indexOf(props.projectId);
    const updated = [...ids];
    updated.splice(index, 1);
    mutate(updated);
  };

  return (
    <>
      {starred && (
        <Tooltip title="Remove Pin">
          <Button
            size="small"
            type="link"
            icon={<PushpinFilled style={props.pinStyle} />}
            onClick={(e) => {
              unpin();
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
      {!starred && (
        <Tooltip title="Pin Project">
          <Button
            size="small"
            type="link"
            icon={<PushpinOutlined style={props.pinStyle} />}
            onClick={(e) => {
              pin();
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
