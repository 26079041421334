import { SoftwareApp } from '../../../api/engineering/domain/types';

export type SoftwareAppScope = 'project' | 'common';

export type ScopedSoftwareApp = SoftwareApp & { scope: SoftwareAppScope };

export const scopeToProject = (app: SoftwareApp): ScopedSoftwareApp => {
  return { ...app, scope: 'project' };
};
export const scopeToCommon = (app: SoftwareApp): ScopedSoftwareApp => {
  return { ...app, scope: 'common' };
};
