import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

type IconProps = {
  style?: React.CSSProperties;
  className?: string;
};
export const LinkExternal = (props: IconProps) => {
  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M9.2088 2.25L11.5413 4.71975L6.58301 9.96975L7.58459 11.0302L12.5429 5.78025L14.8755 8.25V2.25H9.2088Z" />
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M13.4583 14.25H3.54167V3.75H8.5L7.08333 2.25H3.54167C2.76037 2.25 2.125 2.92275 2.125 3.75V14.25C2.125 15.0773 2.76037 15.75 3.54167 15.75H13.4583C14.2396 15.75 14.875 15.0773 14.875 14.25V10.5L13.4583 9V14.25Z"
      />
    </svg>
  );
  return <Icon {...props} component={svg} />;
};
