import { formatDateTime } from '../../../contexts/shared/components/formatDate';
import { Document } from '../../../api/report/domain/types';

/* eslint-disable @typescript-eslint/indent*/
export const valueFromObjectPath =
  <T>(path: string[]) =>
  (object: any): T => {
    return path.reduce((acc: any, val: any) => acc[val], object) as T;
  };
/* eslint-enable @typescript-eslint/indent */

export const camelCaseToName = (input: string) => {
  let ret = input.toString().charAt(0).toUpperCase() + input.slice(1);
  return ret.replace(/([A-Z])(?=[a-z])/g, ' $1').trim();
};

const dateTimeRegex =
  // eslint-disable-next-line max-len
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

const empty = '-';

export const formatValue = (input: string): string => {
  if (!input) return empty;
  const sanitized = input.toString().trim();
  if (sanitized.match(dateTimeRegex)) {
    return formatDateTime(new Date(sanitized));
  }
  if (sanitized === '') return empty;
  return sanitized;
};

export const reportLink = (args: { projectId: string; sourceId: string; revision: string; reportId: string; mode: 'web' | 'download' }) => {
  const link = `/projects/${args.projectId}/reports/goto/${args.sourceId}/${args.revision}/${args.reportId}?m=${args.mode === 'web' ? 'web' : 'download'}`;
  return encodeURI(link);
};

export const hashString = (toHash: string, seed = 0): string => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < toHash.length; i++) {
    ch = toHash.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString(16);
};

export const documentId = (document: Document) => {
  // TODO: use correct id
  return hashString(document.meta.extra.pamMetadata.meta.path.value);
};
