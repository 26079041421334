import { useLocation } from 'react-router-dom';
import { useRestrictedUseCasePermissions } from '../../contexts/session';

export const useRestrictedUseCaseRedirect = () => {
  const permissions = useRestrictedUseCasePermissions();
  const location = useLocation();

  // if not on home, ignore
  if (location.pathname !== '/') return;

  // if a user does not have permission to read projects, but permissions to read apps but not tools
  // navigate to apps page
  if (!permissions.hasProjectsPermission && permissions.hasPlatformAppsPermissions && !permissions.hasPlatformToolsPermissions) {
    return '/apps';
  }

  // if a user does not have permission to read projects, but permissions to not read apps but tools
  // navigate to tools page
  if (!permissions.hasProjectsPermission && !permissions.hasPlatformAppsPermissions && permissions.hasPlatformToolsPermissions) {
    return '/tools';
  }
};
