import { useCallback, useContext } from 'react';
import { TokenActions } from '../../../state/actions';
import { PacTSContext } from '../../../state/store';

export const useLogout = () => {
  const [, dispatch] = useContext(PacTSContext);
  return useCallback(() => {
    dispatch({
      type: TokenActions.SET_TOKEN,
      payload: {
        token: '',
        refreshToken: ''
      }
    });
  }, [dispatch]);
};
