import { ProjectRoleWithPermissions } from '../../../../api/engineering/domain/types';
import { useSharedState } from '../../../shared/hooks/useSharedState';
import { useProjectRoles } from '../../hooks';
import { EntityAdministrationListLayout } from './EntityAdministrationListLayout';

type UsedType = ProjectRoleWithPermissions;
const entityName = 'Project Role';
const entityKey = 'entity-admin-project-role';
const idAttribute = 'idProjectRole';
const titleAttribute = 'name';
export const ProjectRolesList = () => {
  const entities = useProjectRoles();
  const [selected, setSelected] = useSharedState<UsedType>(entityKey);
  const creatingNew = selected !== null && entities.data?.find((e) => e[idAttribute] === (selected ? selected[idAttribute] : null)) === null;
  return (
    <>
      <EntityAdministrationListLayout
        isLoading={entities.isLoading}
        data={entities.data || []}
        idAttribute={idAttribute}
        titleAttribute={titleAttribute}
        onSelect={(val) => setSelected(val!)}
        selected={selected}
        onReset={() => setSelected({ description: '', idProjectRole: -1, name: '', permissions: {} })}
        entityName={entityName}
        creatingNew={creatingNew}
      />
    </>
  );
};
