import { Select } from 'antd';
import styled from 'styled-components';
import { RevisionMeta } from '../../../api';
import { RevisionSelectorDropdownOption } from './RevisionSelectorDropdownOption';


const WideSelect = styled(Select)`
  width: 280px;
` as typeof Select;

type RevisionOption = {
  value: RevisionMeta['revision'];
  label: RevisionMeta['buildVersion'];
  revision: RevisionMeta;
};

export const RevisionSelectorDropdown = (props: {
  revisions?: RevisionMeta[];
  loading?: boolean;
  selectedRevision?: number;
  latestRevision?: number;
  onChange: (revision: number) => void;
}) => {

  const options = props.revisions?.map((r) => ({
    value: r.revision,
    label: r.buildVersion,
    revision: r
  }));

  return (
    <WideSelect<number>
      id="report-revision-selector"
      loading={props.loading}
      value={props.loading ? undefined : props.selectedRevision}
      placeholder="Select Revision..."
      onChange={props.onChange}
      filterOption={(input, option) => {
        const revision = (option as RevisionOption).revision;
        return `revision ${revision?.revision} ${revision?.buildVersion ?? ''} ${revision?.releaseNotes ?? ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      showSearch
      options={options}
      optionRender={(option) => {
        if (!option.data.revision) return null;

        return <RevisionSelectorDropdownOption revision={option.data.revision} latestRevision={props.latestRevision} />;
      }}
    />
  );
};
