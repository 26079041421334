const requiredIfIncluded = ['artifactory.siemens-energy.com', 'tmp://'];

export class Sha256Validator {
  private static len = 64;

  private static validate(unsafe?: string, downloadLinkProvider?: () => string): Promise<void> {
    const sanitized = unsafe ?? '';
    const trimmed = sanitized.trim();

    const downloadLink = downloadLinkProvider?.();
    const isRequired = !!downloadLink ? requiredIfIncluded.some((r) => downloadLink.includes(r)) : false;

    // If not required, accept empty values
    if (!isRequired && trimmed.length < 1) {
      return Promise.resolve();
    }

    // If is required because of download link content
    if (trimmed.length < 1) {
      return Promise.reject(new Error('Required for Artifactory Links and Uploads'));
    }

    // Whenever a sha256 is preset, validate content
    const res = String(trimmed)
      .toLowerCase()
      .match(new RegExp(`^[a-f0-9]{${this.len}}$`));
    if (res === null) {
      return Promise.reject(new Error('Invalid SHA256, must be 64 characters long and only contain a-f and 0-9'));
    }
    return Promise.resolve();
  }

  public static rule(downloadLinkProvider: () => string) {
    return { required: true, validator: (_: any, value: string) => Sha256Validator.validate(value, downloadLinkProvider) };
  }
}
