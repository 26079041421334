import { Button, Drawer, Space, Table, TableColumnProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { getCurrentInstallationActionForComponentOnDevice } from '../helper/latestComponents';
import { formatDateTime, useTableFilter, useTableSearch } from '../../../contexts/shared/components';
import { UpdateDeploymentPlanDeploymentButton } from './UpdateDeploymentPlanDeploymentButton';
import { DeploymentPlan } from '../../../api';
import { UpdateDeploymentPlanMultiDeploymentButton } from '..';
import { SoftwareComponentInstallationNotes } from './SoftwareComponentInstallationNotes';
import { usePermissions } from '../../../contexts/session';
import { ComponentForDevice, ComponentForDevices } from '../helper/deploymentTypes';
import { Comparator } from '../../../domain';

const installationLimit = 99;

interface ComponentInstallationsProps {
  component: ComponentForDevices;
  envId: string;
  projectId: number;
  plan: DeploymentPlan;
}

const getRowKey = (d: ComponentForDevice) => {
  return d.device.id;
};

const ComponentInstallations: React.FC<ComponentInstallationsProps> = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ComponentForDevice[]>([]);

  const rdsSearch = useTableSearch({ searchValueProvider: 'device.rds' });

  const installerSearch = useTableSearch({
    searchValueProvider: (c: ComponentForDevice) => getCurrentInstallationActionForComponentOnDevice(c.component, c.device)?.executor.name ?? ''
  });

  const installedFilter = useTableFilter<ComponentForDevice>({
    key: 'hide-installed-filter',
    onFilter: (data, val) => {
      return val === 'hideInstalled' && !data.isInstalled;
    },
    values: [
      {
        text: 'Hide Installed',
        value: 'hideInstalled'
      }
    ]
  });

  const installedSorter = (a: ComponentForDevice, b: ComponentForDevice) => {
    if (a.isInstalled && !b.isInstalled) return -1;
    if (b.isInstalled && !a.isInstalled) return 1;
    return 0;
  };

  const rdsSorter = (a: ComponentForDevice, b: ComponentForDevice) => Comparator.lexicographicalComparison(a.device.rds, b.device.rds);

  const executorSorter = (a: ComponentForDevice, b: ComponentForDevice) => {
    const at = getCurrentInstallationActionForComponentOnDevice(a.component, a.device);
    const bt = getCurrentInstallationActionForComponentOnDevice(b.component, b.device);
    return Comparator.lexicographicalComparison(at?.executor.name ?? '', bt?.executor.name ?? '');
  };

  const installDateSorter = (a: ComponentForDevice, b: ComponentForDevice) => {
    const at = getCurrentInstallationActionForComponentOnDevice(a.component, a.device);
    const bt = getCurrentInstallationActionForComponentOnDevice(b.component, b.device);
    return new Date(at?.timestamp ?? '0').getTime() - new Date(bt?.timestamp ?? '0').getTime();
  };

  const selectedRowKeys = useMemo(() => selectedRows.map((row) => getRowKey(row)), [selectedRows]);

  const drawerTitle = `${props.component.component.name} ${props.component.component.version}`;

  const hasInstallationsLeft = !props.component.allInstalled;

  const dataSource = props.component.targets.map((t) => ({
    device: t.device,
    component: props.component.component,
    isInstalled: t.isInstalled
  }));

  const permissions = usePermissions({
    projectId: props.projectId.toString(),
    environmentId: props.envId,
    deploymentPlanId: props.plan.id
  });

  const hasInstallPermissions = permissions.deploymentSvc$patchDeploymentPlan || permissions.deploymentSvc$updateDeployment;
  const installationEnabled = hasInstallationsLeft && hasInstallPermissions;

  const limitReached = selectedRowKeys.length >= installationLimit;

  const deviceListColumn: TableColumnProps<ComponentForDevice>[] = [
    {
      title: 'Device',
      align: 'left',
      key: 'rds',
      width: 240,
      sorter: rdsSorter,
      ...rdsSearch,
      render: (d: ComponentForDevice) => {
        return d.device.rds;
      }
    },
    {
      title: 'Status',
      key: 'status',
      align: 'left',
      sorter: installedSorter,
      ...installedFilter,
      render: (d: ComponentForDevice) => {
        return d.isInstalled ? (
          'Installed'
        ) : (
          <UpdateDeploymentPlanDeploymentButton
            envId={props.envId}
            projectId={props.projectId}
            device={d.device}
            plan={props.plan}
            softwareComponent={d.component}
            onDone={() => {
              // remove component from selection if individually installed
              setSelectedRows(selectedRows.filter((r) => getRowKey(r) !== getRowKey(d)));
            }}
          />
        );
      }
    },
    {
      title: 'Installed by',
      align: 'left',
      key: 'installedBy',
      sorter: executorSorter,
      ...installerSearch,
      render: (d: ComponentForDevice) => {
        const action = getCurrentInstallationActionForComponentOnDevice(d.component, d.device);
        return `${action ? action.executor.name : '-'}`;
      }
    },
    {
      title: 'Installation date',
      align: 'left',
      key: 'installDate',
      sorter: installDateSorter,
      render: (d: ComponentForDevice) => {
        const action = getCurrentInstallationActionForComponentOnDevice(d.component, d.device);
        return `${action ? formatDateTime(new Date(action.timestamp)) : '-'}`;
      }
    },
    {
      title: 'Installation notes',
      align: 'center',
      key: 'installNotes',
      render: (d: ComponentForDevice) => {
        return (
          <Space>
            <SoftwareComponentInstallationNotes component={props.component.component} device={d.device} />
          </Space>
        );
      }
    }
  ];

  return (
    <>
      <Button style={{ width: '180px' }} onClick={() => setIsDrawerOpen(true)} type={installationEnabled ? 'primary' : 'default'}>
        {installationEnabled ? 'Manage Installations' : 'View installations'}
      </Button>
      <Drawer
        open={isDrawerOpen}
        title={drawerTitle}
        width={960}
        onClose={() => setIsDrawerOpen(false)}
        extra={
          <UpdateDeploymentPlanMultiDeploymentButton
            envId={props.envId}
            plan={props.plan}
            projectId={props.projectId}
            selectedRows={selectedRows}
            onReset={() => {
              setSelectedRows([]);
            }}
          />
        }
      >
        <Table
          rowSelection={
            installationEnabled
              ? {
                  type: 'checkbox',
                  onChange: (_, newSelectedRows) => setSelectedRows(newSelectedRows),
                  selectedRowKeys,
                  getCheckboxProps: (swa: ComponentForDevice) => ({
                    // Prevent selecting more if the limit has been reached
                    disabled: swa.isInstalled || (!selectedRowKeys.includes(getRowKey(swa)) && limitReached)
                  })
                }
              : undefined
          }
          pagination={false}
          size="small"
          rowKey={getRowKey}
          columns={deviceListColumn}
          dataSource={dataSource}
        />
      </Drawer>
    </>
  );
};

export default ComponentInstallations;
