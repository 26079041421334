import { BulbFilled, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { NotificationMenuButton } from '../../notifications/components/NotificationMenuButton';
import { useCurrentUser } from '../../session/hooks/useCurrentUser';
import { useLogout } from '../../session/hooks/useLogout';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useTheme, THEMES } from '../../theme/ThemeProvider';
import { InfoMenu } from './InfoMenu';
import { useSession } from '../../../contexts/session/hooks/useSession';
import styled from 'styled-components';
import { ExternalTokenDialog } from '../../../contexts/token/ExternalTokenDialog';
import { useContext } from 'react';
import { PacTSContext } from '../../../state/store';

const UserMenuExpandableMenu = styled(ExpandableMenu)`
  color: white;
  width: 50px;
  height: 64px;
`;

const UserMenuIcon = styled(UserOutlined)`
  color: white;
`;

const NotificationMenuWrapper = styled.span`
  margin-right: 10px;
`;

export const UserMenu = () => {
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const logout = useLogout();
  const currentUser = useCurrentUser();
  const [theme, switchTheme] = useTheme();

  const loggedIn = session.state === 'loggedIn';

  const items = loggedIn
    ? [
        <NotificationMenuWrapper>
          <NotificationMenuButton />
        </NotificationMenuWrapper>,
        <InfoMenu />,
        <UserMenuExpandableMenu id="user-menu" textType icon={<UserMenuIcon />} enabledTooltip={currentUser.name}>
          {[
            <ExpandableMenuItem
              key="theme"
              icon={theme === THEMES.DARK ? <BulbFilled style={{ color: 'white' }} /> : <BulbFilled />}
              onClick={() => {
                switchTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK);
              }}
            >
              {`Use ${theme === THEMES.DARK ? 'Light' : 'Dark'} Theme`}
            </ExpandableMenuItem>,
            <ExternalTokenDialog key="token" />,
            <ExpandableMenuItem key="logout" icon={<LogoutOutlined />} onClick={logout}>
              Logout
            </ExpandableMenuItem>
          ]}
        </UserMenuExpandableMenu>
      ]
    : [];

  return (
    <Row gutter={[8, 0]}>
      {items.filter(Boolean).map((item, idx) => (
        <Col key={idx} sm={540} md={720} lg={960} xl={1140}>
          {item}
        </Col>
      ))}
    </Row>
  );
};
