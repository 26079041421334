import { Empty } from 'antd';
import { useTheme, THEMES } from '../../theme';
import { EmptyIconLight } from './icons/EmptyIconLight';
import { EmptyIconDark } from './icons/EmptyIconDark';

export const EmptyMessage = (props: { description: string; imageStyle?: React.CSSProperties }) => {
  const [theme] = useTheme();

  const isLightTheme = theme === THEMES.LIGHT;

  const emptyImageSrc = isLightTheme ? <EmptyIconLight /> : <EmptyIconDark />;

  return <Empty imageStyle={props.imageStyle ?? { height: 240, marginBottom: 30 }} image={emptyImageSrc} description={props.description} />;
};
