import { useQuery } from '@tanstack/react-query';
import { ProjectRoleWithPermissions } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useProjectRoles() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();
  return useQuery<ProjectRoleWithPermissions[], [string]>(
    ['projectRoles'],
    (): Promise<ProjectRoleWithPermissions[]> => {
      return backend.queryProjectRoles();
    },
    { enabled: permissions.engineeringSvc$getProjectRoles }
  );
}

export default useProjectRoles;
