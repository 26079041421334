import { SearchOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Input, Menu, MenuProps, Row, Select, Space, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Project, ProjectSolutionType } from '../../../api/engineering/domain/types';
import { useProjects, useProjectSolutionTypes } from '../hooks';
import { ProjectPin } from './ProjectPin';
import { allSolutionTypeKey, useSelectedProjectSolutionType } from '../hooks/useSelectedProjectSolutionType';
import styled from 'styled-components';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';
import { uniqBy } from 'lodash';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
};

const StyledMenu = styled(Menu)`
  max-height: calc(100% - 110px);
  min-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;

  .ant-menu-item-selected .ant-btn-icon {
    color: white;
  }

  .ant-menu-item:not(.ant-menu-item-selected) .ant-btn-icon {
    color: ${({ theme }) => theme.colorText};
  }
`;

const archivedSolutionTypeKey = 'archived';
const inputMargin = 12;

export const ProjectsMenu = (props: { selectableProjects: Project[] }) => {
  const projects = useProjects();
  const allSolutionTypes = useProjectSolutionTypes();
  const [active, setActive] = useSearchParameter('active');

  const [search, setSearch] = useState('');

  const [solutionType, setSolutionType] = useSelectedProjectSolutionType();

  const loading = projects.isLoading;

  const activeProject = projects.data?.find((p) => p.idProject.toString() === active);
  const activeSolutionType = activeProject?.projectSolutionType;

  // in case a user is not entitled to load all project types
  const availableSolutionTypes = useMemo(() => {
    return uniqBy([...(allSolutionTypes.data ?? []), activeProject?.projectSolutionType].filter(Boolean), (s) => s!.id) as ProjectSolutionType[];
  }, [activeProject, allSolutionTypes.data]);

  // Reset solution type storage if invalid
  useEffect(() => {
    const isNonDefaultType = solutionType !== allSolutionTypeKey;
    const isNonArchivedType = solutionType !== archivedSolutionTypeKey;
    const idIsExisting = availableSolutionTypes?.find((st) => st.id.toString() === solutionType) && true;
    const hasData = availableSolutionTypes && true;
    if (isNonDefaultType && isNonArchivedType && hasData && !idIsExisting) {
      setSolutionType(allSolutionTypeKey);
    }
  }, [solutionType, availableSolutionTypes, setSolutionType]);

  const solutionTypeFilteredProjects = props.selectableProjects
    .filter((p) => {
      if (solutionType === 'archived') {
        return p.isArchived;
      }
      if (p.isArchived) return false;
      if (!solutionType) return true;
      if (solutionType === allSolutionTypeKey) return true;
      return p.projectSolutionType.id.toString() === solutionType;
    })
    .filter((p) => {
      if (!search) return true;
      return p.name.toLowerCase().includes(search.toLowerCase());
    });

  const defaultOpenKeys = activeSolutionType ? [`st-${activeSolutionType.id}`] : [];

  const items: MenuItem[] = solutionTypeFilteredProjects.map((stp) => {
    const label = (
      <Row wrap={false}>
        <Col flex="auto">
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: 4 }}>{stp.name}</div>
        </Col>
        <Col flex="24px">
          <ProjectPin projectId={stp.idProject.toString()} />
        </Col>
      </Row>
    );

    return getItem(label, stp.idProject, undefined, undefined, undefined);
  });

  const onClick: MenuProps['onClick'] = (e) => {
    setActive(e.key);
  };

  const nonArchivedProjects = props.selectableProjects.filter((p) => !p.isArchived);
  const archivedProjects = props.selectableProjects.filter((p) => p.isArchived);

  const solutionTypeFilter =
    availableSolutionTypes?.map((st) => {
      const stProjects = nonArchivedProjects.filter((p) => p.projectSolutionType.id === st.id) || [];
      return (
        <Select.Option key={st.id?.toString()} title={st.name} value={st.id!.toString()}>
          {`${st.name} (${stProjects.length})`}
        </Select.Option>
      );
    }) || [];

  solutionTypeFilter.push(
    <Select.Option key={archivedSolutionTypeKey} title={'Archived'} value={archivedSolutionTypeKey}>
      {`Archived (${archivedProjects.length})`}
    </Select.Option>
  );

  solutionTypeFilter.unshift(
    <Select.Option key={allSolutionTypeKey} value={allSolutionTypeKey}>
      All project types ({nonArchivedProjects.length})
    </Select.Option>
  );
  const menu = (
    <Card style={{ width: '100%', height: '100%', margin: 0, padding: 0 }} styles={{ body: { margin: 0, padding: 0, display: 'inline' } }}>
      <div style={{ width: '100%', display: 'inline' }}>
        <Space direction="vertical" style={{ height: '110px', width: '100%', marginTop: 0 }} size="small">
          <Select
            placeholder="Select Type"
            style={{ width: `calc(100% - ${2 * inputMargin}px)`, margin: 0, marginLeft: inputMargin, marginRight: inputMargin, marginTop: 10 }}
            value={solutionType}
            showSearch
            bordered={false}
            defaultActiveFirstOption
            onChange={(s) => {
              setSolutionType(s);
            }}
            filterOption={(inputValue, option) => {
              return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
            }}
          >
            {solutionTypeFilter}
          </Select>
          <Divider style={{ margin: 4, marginLeft: 0 }} />
          <div style={{ marginLeft: inputMargin, marginRight: inputMargin, marginTop: 0, marginBottom: 0 }}>
            <Input
              bordered={false}
              value={search}
              allowClear
              placeholder="Search Name"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              suffix={
                <Typography.Text type="secondary">
                  <SearchOutlined />
                </Typography.Text>
              }
            />
          </div>
          <Divider style={{ margin: 4, marginLeft: 0 }} />
        </Space>
        <StyledMenu onClick={onClick} defaultOpenKeys={defaultOpenKeys} selectedKeys={[active || '']} mode="inline" items={items} />
      </div>
    </Card>
  );

  return loading ? (
    <div style={{ marginTop: 32 }}>
      <AnimatedLoadingIcon style={{ marginLeft: 'calc(50% - 8px)' }} />
    </div>
  ) : (
    menu
  );
};
