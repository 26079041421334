import { Col, List, Row } from 'antd';
import _, { uniqBy } from 'lodash';
import { useCurrentUser } from '../../session/hooks/useCurrentUser';
import { usePermissions, useRestrictedUseCasePermissions } from '../../session/hooks/usePermissions';
import { ToolIcon } from '../../shared/components/ToolIcon';
import { CenterMenuElement } from '../../shared/components/CenterMenuElement';
import { PageLoading } from '../../shared/components/PageLoading';
import { PendingUserNotification } from '../../administration/components/PendingUserNotification';
// eslint-disable-next-line import/no-cycle
import { ProjectTypeIcon } from '../../projects/components/ProjectIcon';
import { PinnedProjects } from './PinnedProjects';
import { CenteredLayout } from '../../layout';
import { UsefulLinks } from './UsefulLinks';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { useProjects } from '../../../contexts/projects/hooks';
import { Comparator } from '../../../domain';

export const Home = () => {
  const projects = useProjects();
  const navigate = useInAppNavigate();
  const currentUser = useCurrentUser();
  const permissions = usePermissions();
  const { hasProjectsPermission, hasPlatformAppsPermissions, hasPlatformToolsPermissions } = useRestrictedUseCasePermissions();

  const loading = projects.isLoading;

  const projectTypes = uniqBy(projects.data?.map((p) => p.projectType) ?? [], (p) => p.idProjectType).sort((a, b) =>
    Comparator.lexicographicalComparison(a.name, b.name)
  );

  const listItems = _.flatten([
    projectTypes.map((pt) => {
      return (
        <CenterMenuElement
          icon={<ProjectTypeIcon projectType={pt} />}
          title={pt.name}
          subtitle={pt.description}
          onClick={() => {
            navigate(`/projects?type=${pt.idProjectType}`);
          }}
        />
      );
    }),
    hasPlatformToolsPermissions ? (
      <CenterMenuElement
        icon={<ToolIcon />}
        title="Engineering Platform"
        subtitle="Global Engineering Tools"
        onClick={() => {
          navigate('/tools');
        }}
      />
    ) : null,
    hasPlatformAppsPermissions ? (
      <CenterMenuElement
        icon={<ToolIcon />}
        title="Software Platform"
        subtitle="Global Software Apps"
        onClick={() => {
          navigate('/apps');
        }}
      />
    ) : null
  ]).filter(Boolean);

  return (
    <CenteredLayout title={`Welcome ${currentUser.name}`} style={{ overflow: 'hidden' }}>
      <Row gutter={[0, 16]}>
        {permissions.userSvc$updateRolesOfUser && permissions.userSvc$getUsers && (
          <Col span={24}>
            <PendingUserNotification />
          </Col>
        )}
        {loading && (
          <Col span={24}>
            <PageLoading />
          </Col>
        )}
        {!loading && (
          <Col span={24}>
            <Row gutter={[0, 16]}>
              {hasProjectsPermission && (
                <Col span={24}>
                  <PinnedProjects />
                </Col>
              )}
              <Col span={24}>
                <List
                  style={{ marginBottom: -16, maxWidth: '100%' }}
                  grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
                  dataSource={listItems}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Col>
              <Col span={24}>
                <UsefulLinks />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </CenteredLayout>
  );
};

export default Home;
