import { useQuery } from '@tanstack/react-query';
import { ToolUsage } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useEngineeringToolUsage(engineeringToolId: string) {
  const { backend } = useEngineeringBackend();
  return useQuery<ToolUsage[], [string, string]>(['engineeringToolUsage', engineeringToolId], (): Promise<ToolUsage[]> => {
    return backend.queryToolUsage(engineeringToolId);
  });
}
