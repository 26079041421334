import { useQuery } from '@tanstack/react-query';
import { ProjectPhase } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProjectPhases() {
  const permissions = usePermissions();
  const { backend } = useEngineeringBackend();
  return useQuery<ProjectPhase[], [string]>(
    ['projectPhases'],
    (): Promise<ProjectPhase[]> => {
      return backend.queryProjectPhases();
    },
    { enabled: permissions.engineeringSvc$getProjectPhases }
  );
}
