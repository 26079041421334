import { useMemo } from 'react';
import EventEmitter from 'eventemitter3';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Configuration, DoctreeApi } from '@pacts/reportservice-api';
import { ReportBackend, ReportBackendError } from '../service/reportBackend';
import {
  ReportRevisionCreateResponse,
  Document,
  ReportRevision,
  RevisionMeta,
  SourceMeta,
  Column,
  ColumnsWithMvccId,
  ColumnsWithFrom
} from '../domain/types';
import { useRestBackendConfig } from '../../shared/useBackendConfiguration';
import { SharedAxiosInstance } from '../../shared/sharedAxiosInstance';
import { GlobalState } from '../../../state/globalState';

class RestReportBackend implements ReportBackend {
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly dApi: DoctreeApi;

  constructor(
    public readonly config: Configuration,
    instance: AxiosInstance
  ) {
    this.dApi = new DoctreeApi(config, undefined, instance);
  }

  createRevision(): Promise<ReportRevisionCreateResponse> {
    throw new Error('Method not implemented.');
  }

  deleteFolderColumns(projectId: number, sourceId: string, folderId: string, mvccId: number): Promise<void> {
    return this.genericPromise(
      this.dApi.deleteReportFolderColumns(projectId, sourceId, folderId, {
        mvccId: mvccId
      })
    );
  }

  deleteRevision(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  deleteSystemColumns(): Promise<void> {
    return this.genericPromise(this.dApi.deleteReportSystemColumns());
  }

  getDocuments(): Promise<Document[]> {
    throw new Error('Method not implemented.');
  }

  getLatestRevision(projectId: number, sourceId: string): Promise<ReportRevision> {
    return this.genericPromise(this.dApi.getReportLatestRevision(projectId, sourceId));
  }

  getRevision(projectId: number, sourceId: string, revisionId: number): Promise<ReportRevision> {
    return this.genericPromise(this.dApi.getReportRevision(projectId, sourceId, revisionId));
  }

  getCalculatedFolderColumns(projectId: number, sourceId: string, folderId: string): Promise<ColumnsWithFrom> {
    return this.genericPromise(this.dApi.getReportCalculatedFolderColumns(projectId, sourceId, folderId));
  }

  getRevisions(projectId: number, sourceId: string): Promise<RevisionMeta[]> {
    return this.genericPromise(this.dApi.getReportRevisions(projectId, sourceId));
  }

  getSources(projectId: number): Promise<SourceMeta[]> {
    return this.genericPromise(this.dApi.getReportSources(projectId));
  }

  getSystemColumns(): Promise<{ [key: string]: Column[] }> {
    return this.genericPromise(this.dApi.getReportSystemColumns());
  }

  upsertFolderColumns(projectId: number, sourceId: string, folderId: string, body: ColumnsWithMvccId): Promise<void> {
    return this.genericPromise(this.dApi.putReportFolderColumns(projectId, sourceId, folderId, body));
  }

  upsertSystemColumns(sourceId: string, body: { columns: Column[] }): Promise<void> {
    const { columns } = body;

    return this.genericPromise(this.dApi.putReportSystemColumns({ [sourceId]: columns }));
  }

  onError(handler: (error: ReportBackendError) => any): void {
    this.emitter.on('error', handler);
  }

  private errorHandler(rejector: (error: ReportBackendError) => any): (error: AxiosError) => any {
    return (error: AxiosError) => {
      const resData = error.response?.data as { message: string; details: { field: string; info: string; value: string }[] | undefined } | undefined | null;
      const resMessage = resData?.message ?? error.message;
      const resDetails: { path: string; message: string; value: string }[] =
        resData?.details?.map((d) => {
          return { message: d.info, path: d.field, value: d.value };
        }) || [];
      const err = new ReportBackendError(resMessage, error.response?.status || 999, resDetails);
      this.emitter.emit('error', err);
      rejector(err);
    };
  }

  private genericPromise<T>(promise: Promise<AxiosResponse<T>>) {
    return new Promise<T>((rs, rj) => {
      promise.then((r) => rs(r.data)).catch(this.errorHandler(rj).bind(this));
    });
  }
}

export const useRestReportBackend = (state: GlobalState) => {
  const config = useRestBackendConfig(state.reportServiceBasePath);
  const backend = useMemo(() => new RestReportBackend(new Configuration(config), SharedAxiosInstance.instance()), [config]);
  return backend;
};
