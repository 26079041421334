import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SoftwareApp, SoftwareAppUpdate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateProjectSoftwareApp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareApp, any, [string, SoftwareAppUpdate]>(([projectId, softwareApp]) => {
    return new Promise<SoftwareApp>((resolve, reject) => {
      backend
        .updateProjectApp(projectId, softwareApp)
        .then((swa) => {
          presentSuccess(`Successfully updated Software App ${softwareApp.name}`);
          queryClient.invalidateQueries(['softwareapps', projectId]);
          queryClient.invalidateQueries(['softwareapp', projectId, swa.idSoftwareApp?.toString()]);
          return resolve(swa);
        })
        .catch(reject);
    });
  });
}
