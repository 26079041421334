import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { WS_QUERY_OPTIONS } from '../../../api/shared/messaging/queryOptions';
import { usePactsFormationBackend } from '../../../api/pactsformation/hooks/usePactsFormationBackend';

const queryKey = ['pactsformation', 'heartbeat'];

export const usePactsFormationHeartbeat = () => {
  const { backend, state } = usePactsFormationBackend();

  return useQuery<any>(
    queryKey,
    (): Promise<any> => {
      return new Promise((resolve, reject) => {
        backend
          .ping({ timeout: 2000 })
          .then((data: any) => {
            resolve(data);
          })
          .catch((err) => {
            backend.reconnect();
            reject(err);
          });
      });
    },
    { ...WS_QUERY_OPTIONS, enabled: state.isReady, retry: 2 }
  );
};

export const useInvalidatePactsFormationHeartbeat = () => {
  const queryClient = useQueryClient();
  const invalidate = useMemo(() => {
    return () => {
      queryClient.invalidateQueries(queryKey);
    };
  }, [queryClient]);
  return invalidate;
};
