import { ToolVersion } from '../../../api/engineering/domain/types';
import { ComparisonTag, ComparisonResult } from '../../comparison/components/ComparisonTag';

export type EngineeringToolVersionComparisonResultProps = {
  a: ToolVersion | undefined;
  b: ToolVersion | undefined;
};

export function engineeringToolVersionComparisonResultValue(a: ToolVersion | undefined, b: ToolVersion | undefined) {
  if (a && b) {
    if (a.idToolVersion === b.idToolVersion) {
      return ComparisonResult.EQUAL;
    }
    return ComparisonResult.VERSION_DIFFERS;
  }
  return ComparisonResult.SELECTION_DIFFERS;
}

export function EngineeringToolVersionComparisonResult(props: EngineeringToolVersionComparisonResultProps) {
  return <ComparisonTag comparisonResult={engineeringToolVersionComparisonResultValue(props.a, props.b)} />;
}
