import { Card, Col, Image, Row, Space, Typography, Grid } from 'antd';
import 'setimmediate';
import { InfoLayout } from '../../layout/components/InfoLayout';
import { PacTSFooter } from '../../layout/components/Footer';
import { THEMES, useTheme } from '../../theme/ThemeProvider';
import React from 'react';

const { useBreakpoint } = Grid;

export const LoginLayout = (props: { children: React.ReactNode[] | React.ReactNode }) => {
  const br = useBreakpoint();
  const isLg = br.lg === true;
  const borderRadius = isLg ? 40 : 0;
  const [currentTheme] = useTheme();

  const siemensEnergyPurple = '#1A1434';
  const image = './assets/dotsphere1-light.svg';

  return (
    <>
      <InfoLayout header support brandTheme={THEMES.DARK}>
        <div>
          <Row
            // hardcode light mode background color here because it is not part of the standard colors hence not present in css
            style={{ height: '100%', minHeight: '100vh', backgroundColor: currentTheme === THEMES.LIGHT ? siemensEnergyPurple : undefined }}
            className={currentTheme === THEMES.DARK ? 'bg-page' : undefined}
          >
            {isLg && (
              <Col xs={0} lg={14} style={{ overflow: 'hidden', display: 'flex', flexFlow: 'column' }}>
                <Row justify="center" style={{ marginTop: '15vh', flex: '0 1 auto' }}>
                  <Col>
                    <Typography.Title style={{ maxWidth: 700, textAlign: 'center', color: 'white' }}>
                      <p>Making the Digital Engineering landscape accessible.</p>
                    </Typography.Title>
                  </Col>
                </Row>
                <Row justify="center" style={{ flex: '1 1 auto' }}>
                  <Image
                    src={image}
                    preview={false}
                    wrapperStyle={{ position: 'relative', width: '100%', maxHeight: 'calc(100% - 56px)', marginTop: '12vh' }}
                    style={{ position: 'absolute', bottom: 0, left: 0, paddingLeft: '20%', paddingRight: '20%' }}
                    height="100%"
                    width="100%"
                  />
                </Row>
              </Col>
            )}
            <Col xs={24} lg={10}>
              <Card
                style={{ minWidth: '100%', margin: 'auto', height: '100%', borderRadius: `${borderRadius}px 0 0 ${borderRadius}px` }}
                bodyStyle={{ height: '100%' }}
              >
                <Space align="center" direction="vertical" style={{ width: '100%' }}>
                  <div style={{ height: '15vh' }} />
                  <Typography.Title level={1}>Welcome to PacTS</Typography.Title>
                </Space>
                {props.children}
                <div style={{ height: 32 }} />
              </Card>
              <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <PacTSFooter />
              </div>
            </Col>
          </Row>
        </div>
      </InfoLayout>
    </>
  );
};
