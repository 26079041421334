import { useGrafanaUrl } from '../hooks/useGrafanaUrl';
import { AxiosError } from 'axios';
import { Button, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useStyles } from '../../theme/useStyles';
import { LinkExternal } from '../../shared/components/icons/LinkExternal';
import { useIsDevVersion } from '../../../contexts/navigation/hooks/useIsDevVersion';
import styled from 'styled-components';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

const StyledExternalLink = styled(LinkExternal)<{ color?: string }>`
  margin-left: 8px;
  color: ${({ theme, color }) => color ?? theme.colorLink};
`;

export const DashboardLink = (props: { target: { id: string; name: string } }) => {
  const isDev = useIsDevVersion();
  const grafanaUrl = useGrafanaUrl(props.target.id, props.target.name);
  const error = grafanaUrl.error ? (grafanaUrl.error as AxiosError) : undefined;
  const token = useStyles();

  const [errorState, setErrorState] = useState(error);

  useEffect(() => {
    if (grafanaUrl.error) {
      setErrorState(grafanaUrl.error as AxiosError);
    }
  }, [grafanaUrl.error, setErrorState]);

  useEffect(() => {
    setErrorState(undefined);
  }, [props.target, setErrorState]);

  const disabledColor = token.colorTextDisabled;

  const GoToGrafanaButton = () => {
    return (
      <span>
        (<Typography.Text>In order to access the page</Typography.Text>{' '}
        <Button style={{ padding: 0 }} size="small" type="link" onClick={() => window.open(`https://${isDev ? 'dev.' : ''}kpi.pacts.cloud/grafana`)}>
          login to dashboards
        </Button>
        )
      </span>
    );
  };

  const DashboardNotExisting = () => {
    return (
      <span>
        <Typography.Text>(Temporarily not available)</Typography.Text>
      </span>
    );
  };

  const dashboardUrl = grafanaUrl.data || '';
  const disabled = !grafanaUrl.isSuccess || dashboardUrl === '';
  const hasError = errorState !== undefined;
  const isGrafanaLoginRequiredError = disabled && errorState?.response?.status === 406;
  const isDashboardNotExistingError = disabled && errorState?.response?.status === 404;
  const isGenericError = disabled && hasError && !isGrafanaLoginRequiredError && !isDashboardNotExistingError;

  const linkContent = (
    <>
      <span>Check Engineering Infrastructure Status</span>
      <StyledExternalLink color={disabled ? disabledColor : undefined} />
    </>
  );

  return (
    <Space>
      {disabled ? (
        <Typography.Text type="secondary" style={{ cursor: 'not-allowed' }}>
          {linkContent}
        </Typography.Text>
      ) : (
        <Typography.Link style={{ color: token.colorText }} target="_blank" href={dashboardUrl}>
          {linkContent}
        </Typography.Link>
      )}
      {isGrafanaLoginRequiredError && <GoToGrafanaButton />}
      {isDashboardNotExistingError && <DashboardNotExisting />}
      {isGenericError && <DashboardNotExisting />}
      {grafanaUrl.isLoading && <AnimatedLoadingIcon style={{ color: disabledColor }} />}
    </Space>
  );
};
