import { SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../api/engineering/domain/types';
import { usePermissions } from '../../../../../contexts/session/hooks/usePermissions';

export const useDownloadPermissions = (artifact: Tool | SoftwareApp | null, version: ToolVersion | SoftwareAppVersion | null, projectId?: number) => {
  const permissions = usePermissions({
    projectId: projectId?.toString(),
    toolId: (artifact as Tool)?.id?.toString(),
    softwareAppId: (artifact as SoftwareApp)?.idSoftwareApp?.toString(),
    idToolVersion: (version as ToolVersion)?.idToolVersion?.toString(),
    idSoftwareAppVersion: (version as SoftwareAppVersion)?.idSoftwareAppVersion?.toString()
  });

  if (!artifact || !version) return false;

  if ((artifact as Tool).id) {
    return permissions.engineeringSvc$getToolVersionDownload && permissions.engineeringSvc$headToolVersionDownload;
  }
  if (projectId) {
    return permissions.engineeringSvc$getProjectSoftwareAppVersionTargetDownload && permissions.engineeringSvc$headProjectSoftwareAppVersionTargetDownload;
  }
  return permissions.engineeringSvc$getCommonSoftwareAppVersionTargetDownload && permissions.engineeringSvc$headCommonSoftwareAppVersionTargetDownload;
};
