import { Row, Col, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { FunctionComponent, ReactNode } from 'react';
import { useStyles } from '../../theme/useStyles';

const { Title, Text } = Typography;

type CenteredLayoutProps = {
  title?: string | ReactNode;
  subtitle?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  extra?: React.ReactNode[];
  fixed?: boolean;
  transparent?: boolean;
  wide?: boolean;
};

export const CenteredLayout: FunctionComponent<CenteredLayoutProps> = ({ children, title, subtitle, extra, fixed, transparent, style, wide, ...rest }) => {
  const styles = useStyles();
  const headerPaddingTop = 32;
  return (
    <Row justify="center" {...rest} style={{ ...style, marginTop: 0 }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={wide ? 24 : 16}>
        {title ? (
          <PageHeader
            style={{
              position: fixed ? 'sticky' : 'initial',
              top: fixed ? 64 : 0,
              zIndex: 4,
              paddingTop: headerPaddingTop,
              backgroundColor: transparent ? undefined : styles.colorBgLayout
            }}
            title={
              <Title level={4} style={{ margin: 0, marginBottom: 4 }}>
                {title || 'test'}
              </Title>
            }
            subTitle={<Text type="secondary">{subtitle}</Text>}
            extra={extra}
          />
        ) : (
          <div style={{ height: headerPaddingTop }} />
        )}
        {children}
      </Col>
    </Row>
  );
};
