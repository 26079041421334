import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { PactsFormationBackend } from '../service/pactsFormationBackend';

type PactsFormationBackendState = {
  isReady: boolean;
};

type PactsFormationBackendHookType = {
  backend: PactsFormationBackend;
  state: PactsFormationBackendState;
  setBackend: (args: { backend: PactsFormationBackend; state: PactsFormationBackendState }) => any;
};
const initData: PactsFormationBackendHookType = {
  backend: undefined as any as PactsFormationBackend,
  state: {
    isReady: false
  },
  setBackend: () => {
    /* */
  }
};
export const useSetInitialPactsFormationBackend = (args: { backend: PactsFormationBackend; state: PactsFormationBackendState }) => {
  initData.backend = args.backend;
  initData.state = args.state;
};
export const usePactsFormationBackend = singletonHook<PactsFormationBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<{ backend: PactsFormationBackend; state: PactsFormationBackendState }>({
    backend: initData.backend,
    state: initData.state
  });
  return {
    backend: backendState.backend,
    state: backendState.state,
    setBackend
  };
});
