import { Alert, Button } from 'antd';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { useUsersWithRoles } from '../hooks/useUsersWithRoles';
import { INITIAL_FILTER_NONE_SEARCH_PARAM } from './UserAdministration';

export const PendingUserNotification = () => {
  const usersWithRoles = useUsersWithRoles();
  const navigate = useInAppNavigate();

  const selectedUsers = usersWithRoles.data?.filter((u) => u.functionalAccount !== true) || [];

  const penddingUserCount = selectedUsers.filter((user) => user.roles && user.roles.length < 1).length;

  return penddingUserCount > 0 ? (
    <Alert
      message="Action Required!"
      description={`${penddingUserCount} ${penddingUserCount > 1 ? 'users are' : 'user is'} waiting for access.`}
      type="info"
      showIcon
      action={
        <Button
          size="middle"
          type="primary"
          onClick={() => {
            navigate(`/user-administration?${INITIAL_FILTER_NONE_SEARCH_PARAM}`);
          }}
        >
          Go to User Administration
        </Button>
      }
    />
  ) : null;
};

export default PendingUserNotification;
