import { Button, Drawer, Flex } from 'antd';
import { useState } from 'react';
import { useCreateDevice } from '../hooks/useCreateDevice';
import { DeviceForm } from './DeviceForm';
import { Device } from '../../../api';
import { useParams } from 'react-router-dom';

const initial: Partial<Device> & { otc: Device['otc'] } = {
  actionLog: [],
  description: '',
  id: '',
  mvccId: 0,
  otc: [],
  rds: ''
};

export const CreateDevice = (props: { projectId: number }) => {
  const params = useParams();
  const envId = params?.envId || '';
  const createDevice = useCreateDevice(props.projectId, envId);
  const [modalVisible, setModalVisible] = useState(false);
  let okHandler: () => any;
  let resetHandler: () => any;

  const handleCloseDrawer = () => {
    resetHandler();
    setModalVisible(false);
  };

  return (
    <>
      <Button
        loading={createDevice.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
        type="primary"
      >
        New Device
      </Button>
      <Drawer
        title="Create Device"
        width={780}
        destroyOnClose
        onClose={handleCloseDrawer}
        footer={
          <Flex justify="end">
            <Button style={{ width: '125px', marginRight: '10px' }} onClick={handleCloseDrawer} loading={createDevice.isLoading}>
              Cancel
            </Button>
            <Button style={{ width: '125px' }} type="primary" onClick={() => okHandler()} loading={createDevice.isLoading}>
              Save
            </Button>
          </Flex>
        }
        open={modalVisible}
      >
        <DeviceForm
          envId={envId}
          projectId={props.projectId}
          initial={initial}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          onFinish={async (c) => {
            await createDevice.mutateAsync([c]);
            setModalVisible(false);
            resetHandler();
          }}
        />
      </Drawer>
    </>
  );
};

export default CreateDevice;
