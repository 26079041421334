import React from 'react';
import { Col, Layout, Row } from 'antd';
import { TopMenuTitle } from '../contexts/navigation/components/TopMenuTitle';
import { TopMenu } from '../contexts/navigation/components/TopMenu';
import { UserMenu } from '../contexts/navigation/components/UserMenu';
import { PacTSFooter } from '../contexts/layout/components/Footer';
import { ScrollRestoration } from 'react-router-dom';
import { useTheme, THEMES } from '../contexts/theme';
import styled from 'styled-components';

const StyledHeader = styled(Layout.Header)<{ isLightTheme: boolean }>`
  position: fixed;
  z-index: 5;
  width: 100%;
  margin-top: 0;
  background: ${({ theme, isLightTheme }) => (isLightTheme ? theme.pageHeaderLightBg : theme.pageHeaderDarkBg)};
`;

const StyledContent = styled(Layout.Content)`
  padding: 0;
  margin: 64px 0 0 0;
`;

export const AppLayout = (props: { children: React.ReactNode }) => {
  const [theme] = useTheme();

  const isLightTheme = theme === THEMES.LIGHT;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname;
        }}
      />
      <StyledHeader isLightTheme={isLightTheme}>
        <Row wrap={false} align="bottom">
          <Col flex="none">
            <TopMenuTitle />
          </Col>
          <Col flex="auto">
            <TopMenu />
          </Col>
          <Col className="icons-col" flex="none">
            <UserMenu />
          </Col>
        </Row>
      </StyledHeader>
      <StyledContent>{props.children}</StyledContent>
      <PacTSFooter />
    </Layout>
  );
};
