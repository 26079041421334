import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateDeploymentEnvironments() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();

  return useMutation<void, any, [number, string, number, string, string]>(async ([projectId, envId, mvccId, name, description]) => {
    backend.updateDeploymentEnvironment(projectId, envId, mvccId, name, description).then((env) => {
      presentSuccess(`Deployment environment ${env.name} successfully updated`);
      queryClient.invalidateQueries(['deploymentEnvironments', projectId]);
    });
  });
}
