import { Tooltip } from 'antd';
import { useParams } from 'react-router-dom';

import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useDeleteOtc } from '../hooks/useDeleteOtc';
import { Otc } from '../../../api';


export const DeleteOtcButton = (props: { otc: Otc; projectId: number }) => {
  const params = useParams();
  const envId = params?.envId || '';
  const deleteOtc = useDeleteOtc(envId);
  
  return (
    <Tooltip title="Delete">
      <ConfirmationButton
        caption="Delete Otc"
        loading={deleteOtc.isLoading}
        onOk={() => deleteOtc.mutate([{ id: props.otc.id, mvccId: props.otc.mvccId, name: props.otc.name }, props.projectId])}
        buttonType='regular'
      />
    </Tooltip>
  );
};

export default DeleteOtcButton;
