import { Button, Form, Input } from 'antd';

import { useMemo, useState } from 'react';
import { ProjectRoleWithPermissions } from '../../../../api/engineering/domain/types';
import { DescriptionValidator, NameValidator } from '../../../../domain';
import { ConfirmationButton } from '../../../shared/components/ConfirmationButton';
import { MarkdownFormItem } from '../../../shared/components/MarkdownFormItem';
import { useSharedState } from '../../../shared/hooks/useSharedState';
import { useCreateProjectRole, useDeleteProjectRole, useProjectRoles, useUpdateProjectRole } from '../../hooks';
import { EntityAdministrationEditLayout } from './EntityAdministrationEditLayout';
import { PermissionsFormItem } from './PermissionsFormItem';

type UsedType = ProjectRoleWithPermissions;
const entityName = 'Project Role';
const entityKey = 'entity-admin-project-role';

export const ProjectRolesEdit = () => {
  const entities = useProjectRoles();
  const entityCreate = useCreateProjectRole();
  const entityUpdate = useUpdateProjectRole();
  const entityDelete = useDeleteProjectRole();

  const [selected, setSelected, resetSelected] = useSharedState<UsedType>(entityKey);
  const [isTouched, setIsTouched] = useState(false);

  const [form] = Form.useForm();

  const existing = entities.data?.find((st) => st.idProjectRole === selected?.idProjectRole);
  const hasSelection = selected !== null;
  // eslint-disable-next-line no-nested-ternary
  const state: 'editing' | 'creating' | 'idle' = hasSelection ? (existing ? 'editing' : 'creating') : 'idle';

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  const initial = useMemo(() => {
    const init = entities.data?.find((st) => st.idProjectRole === selected?.idProjectRole);
    setIsTouched(false);
    setImmediate(() => {
      form.resetFields();
    });
    return init;
  }, [entities.data, selected?.idProjectRole, form]);

  const reset = () => {
    form.resetFields();
    setIsTouched(false);
  };

  const cancel = () => {
    reset();
    if (!existing) {
      resetSelected();
    }
  };

  const formNode = (
    <Form
      {...layout}
      name="basic"
      form={form}
      style={{ maxWidth: 860, marginTop: 32 }}
      initialValues={initial}
      onFinish={async (value) => {
        try {
          const enriched = { ...initial, ...value } as UsedType;
          if (state === 'creating') {
            setSelected(await entityCreate.mutateAsync([enriched]));
          } else {
            setSelected(await entityUpdate.mutateAsync([enriched]));
          }
          reset();
        } catch {
          /* do nothing on error */
        }
      }}
      onFieldsChange={() => {
        setIsTouched(true);
      }}
    >
      <Form.Item labelAlign="left" label="Name" name="name" rules={[NameValidator.rule()]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        labelAlign="left"
        label="Description"
        field={['description']}
        rules={[DescriptionValidator.rule()]}
        shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
      />
      <Form.Item labelAlign="left" label="Permissions" name="permissions" rules={[{ required: true, message: 'Requires valid JSON' }]}>
        <PermissionsFormItem />
      </Form.Item>
    </Form>
  );

  const submitNode = (
    <Button
      disabled={!isTouched}
      type="primary"
      loading={entityCreate.isLoading || entityUpdate.isLoading}
      onClick={() => {
        form.submit();
      }}
    >
      Save
    </Button>
  );

  const deleteNode = (
    <ConfirmationButton
      danger
      buttonType='regular'
      regularButtonType='default'
      disabled={!existing}
      loading={entityDelete.isLoading}
      key="delete"
      onOk={async () => {
        await entityDelete.mutateAsync([selected!]);
        resetSelected();
      }}
    >
      Delete
    </ConfirmationButton>
  );

  return (
    <>
      <EntityAdministrationEditLayout
        titlePrefix={entityName}
        state={state}
        titleElement={state === 'editing' ? selected!.name : 'Create New'}
        form={formNode}
        isTouched={isTouched}
        onCancel={() => {
          cancel();
        }}
        submit={submitNode}
        delete={deleteNode}
      />
    </>
  );
};
