import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { Otc } from '../../../api';

export function useUpdateOtc(projectId: number, envId: string) {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<Otc, any, [Otc]>(([otc]) => {
    return new Promise<Otc>((resolve, reject) => {
      backend
        .updateOtc(projectId, envId, otc.id, otc)
        .then((dev) => {
          presentSuccess(`Successfully updated OTC ${otc.name}`);
          queryClient.invalidateQueries(['otc', projectId, envId]);
          queryClient.invalidateQueries(['device', projectId, envId]);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
