import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Project, ProjectUpdate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateProject() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<Project, any, [ProjectUpdate]>(([project]) => {
    return new Promise<Project>((resolve, reject) => {
      backend
        .updateProject(project)
        .then((updatedProject) => {
          queryClient.invalidateQueries(['projects']);
          presentSuccess(`Successfully updated Project ${project.name}`);
          return resolve(updatedProject);
        })
        .catch(reject);
    });
  });
}
