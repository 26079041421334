import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ScopedSoftwareApp } from './scopedSoftwareApp';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { SoftwareApp } from '../../../api/engineering/domain/types';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useCommonSoftwareApps() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();

  const permissionsList = permissions.engineeringSvc$getCommonSoftwareApps;
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getCommonSoftwareApp$specific() : [];

  return useQuery<SoftwareApp[], [string, string]>(['commonSoftwareapps'], (): Promise<SoftwareApp[]> => {
    if (permissionsList) return backend.queryCommonApps();
    return Promise.all(specificPermissions.map((s) => backend.queryCommonApp(s.softwareAppId)));
  });
}

export function useCommonSoftwareAppsScoped() {
  const softwareApps = useCommonSoftwareApps();

  const data = useMemo(() => {
    let scopedApps: ScopedSoftwareApp[] = [];
    if (softwareApps.data) {
      scopedApps = [...softwareApps.data].map((a) => {
        const scopedapp: ScopedSoftwareApp = {
          ...a,
          scope: 'common'
        };
        return scopedapp;
      });
    }
    return scopedApps;
  }, [softwareApps.data]);
  return {
    ...softwareApps,
    data
  };
}
