// eslint-disable-next-line import/no-cycle
import { GlobalStateAction } from '../globalState';

export enum TokenActions {
  SET_TOKEN = 'SET_TOKEN',
}

export type Token = {
  token: string,
  refreshToken: string,
};

export interface SetTokenAction extends GlobalStateAction {
  type: typeof TokenActions.SET_TOKEN,
  payload: Token,
}

export type TokenActionTypes = SetTokenAction;
