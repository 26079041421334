import { BundleContext } from './bundleContext';
import { GenericSoftwareComponentType, VersionedGenericSoftwareComponent } from './softwareComponent';

export type OpenContextArgs = {
  bundleContext: BundleContext;
  projectType: {
    projectTypeId: string;
    projectType: string;
  };
  type: GenericSoftwareComponentType;
};

export type OpenComponentArgs = {
  component: VersionedGenericSoftwareComponent;
};

export type LoginArgs = {
  code: string;
};

export type ToolManagerArgs = {
  action: 'openComponent' | 'openContext' | 'login';
  payload: VersionedGenericSoftwareComponent | OpenContextArgs | LoginArgs | any;
};

export abstract class ToolManagerLink {
  public static open(args: ToolManagerArgs) {
    const base64 = btoa(JSON.stringify(args));
    window.open(`/impacts?link=${base64}`);
  }
}
