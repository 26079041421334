import { useCallback, useContext } from 'react';
import { useLogout } from './useLogout';
import { presentInfo } from '../../../api/shared/infoPresenter';
import { PacTSContext } from '../../../state/store';
import { TokenActions } from '../../../state/actions';
import { TokenResponse } from '../../../api/user/domain/users';
import { useAuthBackend } from '../../../api';

export const useTokenRefresh = () => {
  const [state, dispatch] = useContext(PacTSContext);
  const logout = useLogout();
  const { backend } = useAuthBackend();

  const query = useCallback((): Promise<TokenResponse> => {
    return new Promise((resolve, reject) => {
      backend
        .tokenRefresh({
          refreshToken: state.token.refreshToken
        })
        .then((res) => {
          dispatch({
            type: TokenActions.SET_TOKEN,
            payload: {
              token: res.accessToken,
              refreshToken: res.refreshToken
            }
          });
          return resolve(res);
        })
        .catch((err) => {
          console.error('logout due to refresh error', err);
          logout();
          presentInfo('Please log in again');
          return reject(err);
        });
    });
  }, [state.token.refreshToken, logout, dispatch, backend]);

  return query;
};
