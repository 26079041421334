import { useQuery } from '@tanstack/react-query';
import { ProjectSolutionType } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProjectSolutionTypes() {
  const permissions = usePermissions();
  const { backend } = useEngineeringBackend();
  return useQuery<ProjectSolutionType[], [string]>(
    ['projectSolutionTypes'],
    (): Promise<ProjectSolutionType[]> => {
      return backend.queryProjectSolutionTypes();
    },
    { enabled: permissions.engineeringSvc$getProjectSolutionTypes }
  );
}
