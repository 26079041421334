import { useEffect } from 'react';
import { useAuthBackend, setInitialAuthBackend } from './useAuthBackend';
import { useRestAuthBacked } from './useRestAuthBackend';
import { GlobalState } from '../../../state/globalState';

export const useRestAuthBackendProvider = (state: GlobalState) => {
  const backend = useRestAuthBacked(state);
  setInitialAuthBackend(backend);
  const { setBackend } = useAuthBackend();
  useEffect(() => {
    setBackend(backend);
  }, [backend, setBackend]);
};
