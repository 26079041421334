import { useQuery } from '@tanstack/react-query';
import { SourceMeta } from '../../../api/report/domain/types';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useSources(projectId: number) {
  const { backend } = useReportBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery<SourceMeta[], [string, string]>(
    ['projectSources', projectId],
    (): Promise<SourceMeta[]> => {
      return backend.getSources(projectId);
    },
    {
      enabled: projectId > -1 && permissions.reportSvc$getReportSources
    }
  );
}
