import { useEffect } from 'react';
import { useDeploymentBackend, useSetInitialDeploymentBackend } from './useDeploymentBackend';
import { useRestDeploymentBackend } from './useRestDeplyomentBackend';
import { presentApiError } from '../../shared/errorPresenter';
import { GlobalState } from '../../../state/globalState';

export const useRestDeploymentBackendProvider = (state: GlobalState) => {
  const backend = useRestDeploymentBackend(state);
  useSetInitialDeploymentBackend(backend);
  const { setBackend } = useDeploymentBackend();
  useEffect(() => {
    backend.onError(presentApiError);
    setBackend(backend);
  }, [backend, setBackend]);
};
