/* eslint-disable */
import { ThemePropsProvider } from '../theme';
import { theme } from 'antd';
import { AliasToken, MapToken, OverrideToken, SeedToken } from 'antd/es/theme/interface';
import { TinyColor } from '@ctrl/tinycolor';
import { primitivesLight, opacityToHex } from './common';

export const colorSecondaryLight = primitivesLight.teal[6];
export const colorTextReversedLight = primitivesLight.grey.solidReversed;
export const colorSecondaryBgContainerLight = primitivesLight.teal[8];

export const quarteryContrastText = 'rgba(50, 50, 50, 0.1)';
export const colorBgSpotlight = '#fff';
export const pageHeaderLightBg = primitivesLight.violet[10];
export const selectedBg = '';

export const light: Partial<AliasToken> = {
  fontSize: 15,
  wireframe: false,
  borderRadius: 5,

  colorPrimary: primitivesLight.purple[6],
  
  colorText: primitivesLight.grey[4],
  colorTextBase: primitivesLight.grey.solid,
  colorTextDescription: primitivesLight.grey.solid + opacityToHex(.75),
  colorTextSecondary: primitivesLight.grey.solid + opacityToHex(.75),
  colorTextTertiary: primitivesLight.grey.solid + opacityToHex(.65),
  colorTextPlaceholder: primitivesLight.grey.solid + opacityToHex(.45),
  colorTextDisabled: primitivesLight.grey.solid + opacityToHex(.55),
  colorTextLabel: primitivesLight.grey.solid,

  colorLink: primitivesLight.SEpurple[300],
  colorLinkActive: primitivesLight.SEpurple[600],
  colorLinkHover: primitivesLight.SEpurple[600],

  colorInfo: primitivesLight.teal[7],

  colorError: primitivesLight.red[6],
  colorErrorHover: primitivesLight.red[7],
  colorErrorText: primitivesLight.red[9],
  colorErrorTextHover: primitivesLight.red[6],

  colorSuccess: primitivesLight.green[5],
  colorSuccessText: primitivesLight.green[9],
  colorSuccessTextHover: primitivesLight.green[7],
  colorSuccessBg: primitivesLight.green[6] + opacityToHex(.15), // TO BE CHECKED

  // colorWarning: primitivesLight.orange[7],
  colorWarningText: primitivesLight.orange[8],
  // colorWarning: primitivesLight.yellow[10],
  // colorWarning: primitivesLight.orange[6],
  colorWarningTextHover: primitivesLight.orange[5],
  colorWarningBg: primitivesLight.orange[5], // TO BE CHECKED

  colorBgLayout: primitivesLight.neutral[3],
  colorBgElevated: primitivesLight.neutral[1],
  colorBgContainerDisabled: primitivesLight.neutral[6]
};

export const lightTheme = (): ThemePropsProvider => {
  const algorithm = (token: SeedToken): MapToken => {
    const res = theme.defaultAlgorithm(token);
    const custom = { ...res, ...light };
    return custom;
  };
  const tokens: (token: SeedToken) => OverrideToken = (token: SeedToken) => {
    const res = algorithm(token);
    return {
      Alert: {
        colorInfoBg: primitivesLight.teal[5] + opacityToHex(.15),
        // colorWarningBg: primitivesLight.orange[4]
        colorWarning: primitivesLight.orange[8],
        colorWarningBg: primitivesLight.orange[5] + opacityToHex(.4),
        colorWarningBorder: primitivesLight.orange[7] + ' !important'
      },
      Avatar: {
        groupBorderColor: primitivesLight.purple[6],
        colorText: primitivesLight.grey.solidReversed,
        colorTextLightSolid: primitivesLight.grey.solidReversed,
        colorTextPlaceholder: primitivesLight.purple[2]
      },
      Badge: {
        colorBgContainer: primitivesLight.grey.solidReversed
      },
      Button: {
        colorTextDisabled: primitivesLight.grey.solid + opacityToHex(.6),
        borderColorDisabled: 'transparent',
        colorBgContainerDisabled: primitivesLight.neutral[4],
        primaryShadow: 'none'
      },
      Card: {
        colorBorderSecondary: primitivesLight.neutral[5]
      },
      Checkbox: {
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryHover: primitivesLight.teal[7],
        colorBgContainerDisabled: primitivesLight.neutral[4],
        colorWhite: primitivesLight.grey.solidReversed,
        colorBgContainer: 'transparent'
      },
      DatePicker: {
        colorPrimary: primitivesLight.teal[8],
        hoverBorderColor: primitivesLight.teal[7],
        controlOutline: primitivesLight.neutral[2],
        controlItemBgHover: primitivesLight.teal[7] + opacityToHex(.2),
        activeShadow: 'none'
      },
      Dropdown: {
        controlItemBgHover: primitivesLight.teal[7] + opacityToHex(.2),
        activeShadow: 'none'
      },
      FloatButton: {
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryHover: new TinyColor(primitivesLight.teal[7]).darken(5).toHslString()
      },
      Input: {
        colorBgContainer: 'transparent',
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryActive: primitivesLight.teal[9],
        colorPrimaryHover: primitivesLight.teal[7],
        colorBgContainerDisabled: primitivesLight.neutral[4],
        colorTextDisabled: primitivesLight.grey.solid + opacityToHex(.6),
        activeShadow: 'none'
      },
      InputNumber: {
        colorBgContainer: 'transparent',
        colorBgContainerDisabled: primitivesLight.neutral[4],
        colorTextDisabled: primitivesLight.grey.solid + opacityToHex(.6),
        colorPrimary: primitivesLight.teal[8],
        hoverBorderColor: primitivesLight.teal[7],
        activeBorderColor: primitivesLight.teal[7],
        handleHoverColor: primitivesLight.teal[7],
        controlOutline: primitivesLight.neutral[2],
        controlItemBgHover: primitivesLight.teal[7] + opacityToHex(.2),
        activeShadow: 'none'
      },
      Layout: {
        headerBg: primitivesLight.neutral[1],
        siderBg: primitivesLight.neutral[1],
        triggerBg: primitivesLight.neutral[1],
        triggerColor: light.colorText
      },
      Mentions: {
        colorPrimary: primitivesLight.teal[8],
        hoverBorderColor: primitivesLight.teal[7],
        activeBorderColor: primitivesLight.teal[8]
      },
      Menu: {
        itemBg: 'transparent',
        itemActiveBg: primitivesLight.teal[8] + opacityToHex(.3), // when clicking
        itemColor: primitivesLight.grey.solid,
        itemSelectedBg: primitivesLight.teal[8],
        itemSelectedColor: primitivesLight.grey.solidReversed,
        itemHoverBg: primitivesLight.teal[8] + opacityToHex(.3),
        horizontalItemSelectedBg: primitivesLight.teal[8],
        horizontalItemHoverBg: primitivesLight.teal[8],
        activeBarBorderWidth: 0,
        activeBarHeight: 0
      },
      Pagination: {
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryHover: primitivesLight.teal[7]
      },
      Radio: {
        colorBgContainerDisabled: primitivesLight.neutral[4],
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryActive: primitivesLight.teal[9],
        colorPrimaryHover: primitivesLight.teal[7],
        colorPrimaryBorder: primitivesLight.teal[7]
      },
      Segmented: {
        itemSelectedBg: primitivesLight.teal[8],
        itemSelectedColor: primitivesLight.grey.solidReversed,
        itemColor: primitivesLight.grey.solid
      },
      Select: {
        colorBgContainerDisabled: primitivesLight.neutral[4],
        colorTextDisabled: primitivesLight.grey.solid + opacityToHex(.6),
        colorBgContainer: 'transparent',
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryHover: primitivesLight.teal[7],
        optionSelectedBg: primitivesLight.teal[8],
        optionSelectedColor: primitivesLight.grey.solidReversed,
        controlOutline: primitivesLight.neutral[2],
        controlItemBgHover: primitivesLight.teal[7] + opacityToHex(.2),
        activeShadow: 'none'
      },
      Slider: {
        handleActiveColor: primitivesLight.teal[8],
        handleColor: primitivesLight.teal[7],
        trackBg: primitivesLight.teal[8],
        trackHoverBg: primitivesLight.teal[7],
        colorPrimaryBorderHover: primitivesLight.teal[6]
      },
      Spin: {
        colorPrimary: primitivesLight.purple[6]
      },
      Steps: {
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryBorder: primitivesLight.teal[7]
      },
      Switch: {
        colorPrimary: primitivesLight.teal[8],
        colorPrimaryHover: primitivesLight.teal[7],
        colorPrimaryBorder: 'transparent'
      },
      Tabs: {
        colorPrimaryBorder: primitivesLight.teal[8],
        inkBarColor: primitivesLight.teal[8],
        itemActiveColor: primitivesLight.teal[9],
        itemSelectedColor: primitivesLight.teal[9],
        // Temporarily removed due to readability issues
        // Related bug #2493
        // colorBgContainer: primitivesLight.teal[8],
        // itemSelectedColor: primitivesLight.grey.solidReversed,
        itemHoverColor: primitivesLight.teal[8],
        colorBorderSecondary: primitivesLight.neutral[6]
      },
      Tag: {
        colorSuccess: primitivesLight.green[8],
        colorSuccessBg: primitivesLight.green[1],
        colorWarning: primitivesLight.orange[6],
        colorWarningBg: primitivesLight.yellow[3],
        colorWarningBorder: primitivesLight.yellow[5],
        colorInfo: primitivesLight.violet[8],
        colorInfoText: primitivesLight.grey.solidReversed,
        colorInfoBg: primitivesLight.violet[1],
        colorInfoBorder: primitivesLight.violet[3] + opacityToHex(.45)
      },
      Table: {
        colorPrimary: primitivesLight.teal[7], // '- this is for sorting arrow, when one is selected'
        colorFillSecondary: primitivesLight.teal[8] + opacityToHex(.1), //for the selected column's header
        headerBg: primitivesLight.neutral[2],
        headerSortActiveBg: primitivesLight.neutral[3],
        rowSelectedBg: 'transparent',
        rowSelectedHoverBg: primitivesLight.teal[1], // primitivesLight.teal[8] + opacityToHex(.1),
        rowHoverBg: primitivesLight.teal[1], //primitivesLight.teal[8] + opacityToHex(.1),
        bodySortBg: primitivesLight.neutral[2] + ' !important',
        controlItemBgActive: 'transparent', // disable highlight row
        controlItemBgHover: primitivesLight.teal[8] + opacityToHex(.1),
        controlItemBgActiveHover:primitivesLight.teal[8] + opacityToHex(.1)
      },
      Tooltip: {
        colorTextLightSolid: 'black',
        colorText: 'black',
        colorBgSpotlight
      },
      Tree: {
        colorPrimary: primitivesLight.teal[4],
        colorPrimaryHover: primitivesLight.teal[6],
        colorPrimaryBorder: primitivesLight.teal[4]
      },
      Typography: {
        linkHoverDecoration: 'underline'
      }
    };
  };
  return { algorithm, tokens, colors: light };
};
