import { useQuery } from '@tanstack/react-query';
import { ProjectDto, usePactsFormationBackend } from '../../../api';

export function useProjectStatus(projectId: string) {
  const { backend } = usePactsFormationBackend();

  return useQuery<ProjectDto, [string, string]>(['projectStatus', projectId], async () => {
    const result = await backend.getProjectStatus(projectId);

    return result;
  });
}
