import { ComponentProps } from 'react';

import { VulnerabilityIconButton } from '../../shared/components/VulnerabilityIconButton';

import { useCommonSoftwareAppVersionVulnerabilities } from '../hooks/useCommonSoftwareAppVersionVulnerabilities';

import type { SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';

/**
 * Returns an icon button which opens a drawer that displays
 * the vulnerability notifications.
 */
export const CommonSoftwareAppVersionVulnerabilityButton = (props: {
  app: SoftwareApp;
  version: SoftwareAppVersion;
  getVulnerabilityDrawerContainer?: ComponentProps<typeof VulnerabilityIconButton>['getDrawerContainer'];
}) => {
  const versionVulnerabilities = useCommonSoftwareAppVersionVulnerabilities(props.app.idSoftwareApp, props.version.idSoftwareAppVersion);

  if (versionVulnerabilities.data?.length === 0) {
    return null;
  }

  const notificationsExist = !!versionVulnerabilities.data?.some((v) => v.notifications && v.notifications.length > 0);

  return (
    <VulnerabilityIconButton
      appName={props.app.name}
      versionName={props.version.version}
      isLoading={versionVulnerabilities.isLoading}
      isError={versionVulnerabilities.isError}
      error={versionVulnerabilities.error}
      notificationsExist={notificationsExist}
      vulnerabilities={versionVulnerabilities.data}
      appVersion={props.version}
      getDrawerContainer={props.getVulnerabilityDrawerContainer}
    />
  );
};
