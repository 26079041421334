import { Space } from 'antd';
import { RevisionMeta } from '../../../api';
import { LatestTag } from '../../../contexts/shared/components/LatestTag';


export const RevisionSelectorDropdownLabel = (props: { revision: RevisionMeta; latestRevision?: number }) => (
  <Space>
    <span>
      {props.revision.buildVersion ? props.revision.buildVersion : `Revision ${props.revision.revision}`}
    </span>

    {props.latestRevision === props.revision.revision ? <LatestTag /> : null}
  </Space>
);
