import { useMemo } from 'react';
import { useUserInfo } from './useUserInfo';
import { UserInfo } from '../../../api/user/domain/users';
import { GlobalState } from '../../../state/globalState';

type SessionState = 'loggedOut' | 'initial' | 'loggedIn';
type SessionData = { state: SessionState; userInfo?: UserInfo; fetching: boolean };

export const useSession = (state: GlobalState) => {
  const userInfo = useUserInfo(state);
  const hasToken = !!state.token.token;

  return useMemo<SessionData>((): SessionData => {
    let loginState: SessionState = 'loggedOut';
    if (!userInfo.data && !userInfo.error && hasToken) loginState = 'initial';
    if (userInfo.data) loginState = 'loggedIn';
    if (!hasToken) loginState = 'loggedOut';

    return {
      state: loginState,
      userInfo: userInfo.data,
      fetching: userInfo.isFetching
    };
  }, [userInfo.data, userInfo.error, userInfo.isFetching, hasToken]);
};
