import { useMemo, useState, useRef } from 'react';
import { ProductTourContextProviderProps, ProductTourState, StepsWithNumber, ToursStepsState } from './types';
import { ProductTourContext } from './ProductTourContext';
import { Tour, theme } from 'antd';
import { PASSED_TOURS_STORAGE_KEY } from './utils';
import { useLocalStorageState } from '../shared/hooks/useLocalStorageState';

const { useToken } = theme;

export const ProductTourContextProvider: React.FC<ProductTourContextProviderProps> = (props) => {
  const [toursState, setToursState] = useState<ProductTourState>({
    selectedTourId: null,
    step: 0
  });
  const [stepsState, setStepsState] = useState<ToursStepsState>({});
  const [passedTours, setPassedTours] = useLocalStorageState<Record<string, boolean>>(PASSED_TOURS_STORAGE_KEY);
  const isTourStateOpen = useMemo(() => toursState.selectedTourId != null, [toursState]);
  const selectedTourSteps = stepsState[toursState.selectedTourId ?? ''] ?? [];
  const { token } = useToken();
  const addedStepsRef = useRef<Record<string, number[]>>({});

  const handleNextStep = () => {
    setToursState((prevState) => ({ ...toursState, step: prevState.step + 1 }));
  };

  const handlePrevStep = () => {
    setToursState((prevState) => ({ ...toursState, step: prevState.step + 1 }));
  };

  const handleReset = () => {
    setToursState((prevState) => ({ ...prevState, step: 0, selectedTourId: null }));

    if (toursState.selectedTourId == null) {
      return;
    }

    const newPassedTours = {
      ...(passedTours ?? {}),
      [toursState.selectedTourId]: true
    };

    setPassedTours(newPassedTours);
  };

  const handleOpenTour = (id: string) => {
    setToursState({ selectedTourId: id, step: 1 });
  };

  const handleAddSteps = (newSteps: StepsWithNumber, id: string, shouldOverride: boolean = false) => {
    // This checks for duplicates due to unnecessary re-renders
    if (addedStepsRef.current[id]?.includes(newSteps?.[0]?.step) && !shouldOverride) {
      return;
    }

    const newStepsNums = newSteps.map((stepObj) => stepObj.step);
    addedStepsRef.current = {
      ...addedStepsRef.current,
      [id]: [...(addedStepsRef.current[id] ?? []), ...newStepsNums]
    };

    setStepsState((prevSteps) => {
      const selectedPrevSteps = shouldOverride ? prevSteps[id]?.filter((step) => !newStepsNums.includes(step.step)) ?? [] : prevSteps[id] ?? [];
      const newSortedSteps = [...selectedPrevSteps, ...newSteps].sort((a, b) => a.step - b.step);

      return {
        ...prevSteps,
        [id]: newSortedSteps
      };
    });

    if (newSteps.some((step) => step?.shouldStartAutomatically)) {
      handleOpenTour(id);
    }
  };

  const handleFinishTour = () => {
    if (toursState.selectedTourId == null) {
      return;
    }

    const newPassedTours = {
      ...(passedTours ?? {}),
      [toursState.selectedTourId]: true
    };

    setPassedTours(newPassedTours);
  };

  const handleSetStepsState = (newState: React.SetStateAction<ToursStepsState>) => {
    addedStepsRef.current = {};
    setStepsState(newState);
  };

  return (
    <ProductTourContext.Provider
      value={{
        toursState,
        stepsState,
        setToursState,
        handleNextStep,
        handlePrevStep,
        handleOpenTour,
        handleReset,
        handleAddSteps,
        setStepsState: handleSetStepsState
      }}
    >
      {props.children}
      {/* 
      Conditionally render tour component.
      Prevents rendering conflicts when subsequently open tours with different lengths.
      */}
      {isTourStateOpen ? (
        <Tour
          open={isTourStateOpen}
          onFinish={handleFinishTour}
          onClose={handleReset}
          steps={selectedTourSteps}
          indicatorsRender={(current, total) => {
            if (current === 0) {
              return <span></span>;
            }

            return (
              <span style={{ color: token.colorTextTertiary }}>
                {current}/{total - 1}
              </span>
            );
          }}
        />
      ) : null}
    </ProductTourContext.Provider>
  );
};

export default ProductTourContextProvider;
