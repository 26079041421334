import { useQuery } from '@tanstack/react-query';
import { SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../domain';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useCommonSoftwareAppVersions(softwareAppId: string) {
  const { backend } = useEngineeringBackend();
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  const permissions = usePermissions({ softwareAppId });

  return useQuery<SoftwareAppVersion[], [string, string, string]>(
    ['commonSoftwareappVersions', softwareAppId],
    (): Promise<SoftwareAppVersion[]> => {
      return new Promise((resolve, reject) => {
        backend
          .queryCommonAppVersions(softwareAppId)
          .then((versions) => {
            const sorted = versions.sort((a, b) => SemanticVersion.sorter(a.version, b.version));
            resolve(sorted);
          })
          .catch(reject);
      });
    },
    {
      enabled: parsedSoftwareAppId > -1 && permissions.engineeringSvc$getCommonSoftwareAppVersions,
      refetchInterval: (data) => {
        const hasInconsistent = data?.some((d) => d.state !== 'consistent');
        return hasInconsistent ? 5000 : false;
      }
    }
  );
}
