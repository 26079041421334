import { List, Space } from 'antd';
import _ from 'lodash';
import { useSubscriptions } from '../hooks/useSubscriptions';
import { SubscriptionPopup } from './SubscriptionPopup';

export const SubscriptionsList = () => {
  const subscriptions = useSubscriptions();
  const groupedRaw = _.groupBy(subscriptions.data || [], (g) => g.topicPattern);
  const groupedList = Object.keys(groupedRaw).map((k) => {
    return { topic: k, means: groupedRaw[k] };
  });
  return (
    <List
      dataSource={groupedList}
      renderItem={(item) => {
        return (
          <List.Item key={item.topic}>
            <Space>
              {`${item.topic}: ${item.means.map((m) => m.transportMean).join(', ')}`}
              <SubscriptionPopup topics={[{ key: item.topic, title: item.topic }]} />
            </Space>
          </List.Item>
        );
      }}
    />
  );
};
