import { useQuery } from '@tanstack/react-query';
import { BundleConfiguration } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useBundleConfigurations(projectId: string, bundleId: string) {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  const permissions = usePermissions({ projectId });

  return useQuery<BundleConfiguration[], [string, string, string]>(
    ['bundleConfigurations', projectId, bundleId],
    (): Promise<BundleConfiguration[]> => {
      return backend.queryBundleConfigurations(projectId, bundleId);
    },
    {
      enabled: parsedProjectId > 0 && parsedBundleId > 0 && permissions.engineeringSvc$getProjectBundleConfigurations
    }
  );
}

export default useBundleConfigurations;
