import { useInfiniteQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ProjectsResponse, usePactsFormationBackend } from '../../../api';
import { useEffect } from 'react';

export function useProjectsResources() {
  const permissions = usePermissions();
  const { backend } = usePactsFormationBackend();

  return useInfiniteQuery<ProjectsResponse>(
    ['projectsResources'],
    async ({ pageParam = undefined }) => {
      return backend.getAllProjects(pageParam);
    },
    {
      enabled: permissions.pactsFormationSvc$unrestrictedAdministration,
      getNextPageParam: (p) => {
        return p.nextPaginationToken;
      }
    }
  );
}

export const useAllProjectsResources = () => {
  const projects = useProjectsResources();
  useEffect(() => {
    if (projects.hasNextPage && !projects.isFetchingNextPage) {
      projects.fetchNextPage();
    }
  }, [projects]);

  const sourceData = projects.data?.pages.map((p) => p.projects).flat();
  return {
    ...projects,
    data: sourceData
  };
};
