/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';


type IconProps = {
    style?: React.CSSProperties;
    className?: string;
};

export const SafetyError = (props: IconProps) => {
    const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
        <svg height="1em" width="1em" viewBox="64 64 896 896" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M376.862 398.485C372.047 393.352 372.047 385.03 376.862 379.897L429.174 324.13C433.989 318.997 441.796 318.997 446.611 324.13L647.139 537.902C651.955 543.035 651.955 551.357 647.139 556.49L594.828 612.257C590.013 617.39 582.206 617.39 577.39 612.257L376.862 398.485Z" fill="currentColor" />
            <path d="M577.39 324.132C582.205 318.998 590.012 318.998 594.827 324.132L647.139 379.898C651.954 385.031 651.954 393.354 647.139 398.487L446.61 612.258C441.795 617.392 433.988 617.392 429.173 612.258L376.861 556.492C372.046 551.359 372.046 543.036 376.861 537.903L577.39 324.132Z" fill="currentColor" />
            <path d="M866.996 169.85L527.198 54.0499C523.098 52.6499 517.598 51.95 512.098 51.95C506.598 51.95 501.098 52.6499 496.998 54.0499L157.2 169.85C148.9 172.65 142.1 182.25 142.1 191.05V673.45C142.1 682.25 147.8 693.85 154.7 699.35L499.398 967.95C502.898 970.65 507.398 972.05 511.998 972.05C516.598 972.05 521.198 970.65 524.598 967.95L869.296 699.35C876.196 693.95 881.896 682.35 881.896 673.45V191.05C882.096 182.25 875.296 172.75 866.996 169.85ZM810.096 654.25L512.098 886.45L214.1 654.25V226.65L512.098 125.05L810.096 226.65V654.25Z" fill="currentColor" />
        </svg>
    );

    return <Icon {...props} component={svg} />;
};
