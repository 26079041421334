import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { UserBackend } from '../service/userBackend';

type UserBackendHookType = {
  backend: UserBackend;
  setBackend: (newBackend: UserBackend) => any;
};
const initData: UserBackendHookType = {
  backend: undefined as any as UserBackend,
  setBackend: () => {
    /* */
  }
};
export const useSetInitialUserBackend = (backend: UserBackend) => {
  initData.backend = backend;
};
export const useUserBackend = singletonHook<UserBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<UserBackend>(initData.backend);
  return {
    backend: backendState,
    setBackend
  };
});
