import { useQuery } from '@tanstack/react-query';
import { useInvalidateUserInfo } from './useUserInfo';
import { useTokenRefresh } from './useTokenRefresh';

// Used for unauthorized page to automatically refresh user info
export const useUserInfoRefreshQueryHook = () => {
  const refresh = useInvalidateUserInfo();
  const refreshToken = useTokenRefresh();
  return useQuery(
    ['user-info-refresh'],
    () => {
      refresh();
      refreshToken().catch(() => {
        /* ignore error here */
      });
    },
    {
      refetchInterval: 120000,
      refetchIntervalInBackground: true,
      staleTime: 10000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true
    }
  );
};
