import { DownloadOutlined } from '@ant-design/icons';
import { SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../api';
import { ExpandableMenuItem } from '../../ExpandableMenuItem';
import { useState } from 'react';
import { useArtifactoryLinkPreFlight } from '../hooks/useArtifactoryLinkPreFlightCheck';
import { DownloadDialog } from './DownloadDialog';
import { useDownloadPermissions } from '../hooks/useDownloadPermission';

export const DownloadContextMenuButton = (props: { artifact: Tool | SoftwareApp; version: ToolVersion | SoftwareAppVersion; projectId?: number }) => {
  const [visible, setVisible] = useState(false);
  const canDownload = useArtifactoryLinkPreFlight(props.version);
  const hasPermission = useDownloadPermissions(props.artifact, props.version, props.projectId);

  if (!hasPermission) return null;

  return (
    <>
      <DownloadDialog artifact={props.artifact} version={props.version} visible={visible} onClose={() => setVisible(false)} projectId={props.projectId} />
      <ExpandableMenuItem
        icon={<DownloadOutlined />}
        disabled={!canDownload}
        onClick={() => {
          setVisible(true);
        }}
      >
        Download
      </ExpandableMenuItem>
    </>
  );
};
