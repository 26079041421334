import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';
import { presentSuccess } from '../../../api';

export const useDeleteFolderColumns = (projectId: number, sourceId: string, folderId: string) => {
  const { backend } = useReportBackend();
  const queryClient = useQueryClient();
  const permissions = usePermissions({ projectId: projectId.toString() });

  return useMutation<void, any, number>(async (mvccId: number) => {
    try {
      if (permissions.reportSvc$deleteReportFolderColumns) {
        await backend.deleteFolderColumns(projectId, sourceId, folderId, mvccId);
        queryClient.invalidateQueries(['projectSources', projectId]);
        queryClient.invalidateQueries(['computedFolderColumns', projectId, folderId, sourceId]);

        presentSuccess('Successfully deleted default columns');
      }
    } catch (error) {
      /* nothing to do here */
    }
  });
};
