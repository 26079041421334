import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToolCategory } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

type UsedType = ToolCategory;
export function useUpdateToolCategory() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<UsedType, any, [UsedType]>(([ent]) => {
    return new Promise<UsedType>((resolve, reject) => {
      backend
        .updateToolCategory(ent)
        .then((dev) => {
          presentSuccess(`Successfully updated Tool Category ${ent.name}`);
          queryClient.invalidateQueries(['engineeringToolCategories']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
