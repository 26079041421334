import { Link } from 'react-router-dom';
import { PacTSNotification } from '../../../../api/notifications/domain/types';
import { useStyles } from '../../../theme/useStyles';
import { LinkExternal } from '../../../../contexts/shared/components/icons/LinkExternal';


export const NotificationLinkTitle = (props: {
  notification: PacTSNotification;
  withLink?: boolean;
  linkHandler?: (href: string) => { preventDefault: boolean };
}) => {
  const link = `/notification/${props.notification.id}`;
  const token = useStyles();
  return (
    <Link
      style={{ fontWeight: 'bold', color: token.colorText }}
      to={link}
      onClick={(ev) => {
        if (props.linkHandler && props.linkHandler(link).preventDefault) {
          ev.preventDefault();
        }
      }}
    >
      <span style={{ marginRight: 8, wordBreak: 'normal' }}>{props.notification?.rendered?.title || `Unknown: ${props.notification.topic}`}</span>
      <LinkExternal style={{ color: token.colorLink }} />
    </Link>
  );
};
