export function secondsToHMS(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += hours < 10 ? '0' + hours : hours;
    formattedTime += ':';
  }

  formattedTime += minutes < 10 ? '0' + minutes : minutes;
  formattedTime += ':';
  formattedTime += remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

  return formattedTime;
}
