import { Segmented as AntSegmented } from 'antd';
import styled from 'styled-components';

export const Segmented = styled(AntSegmented)`
  margin: 5px 0;

  .ant-segmented-item-selected {
    .ant-typography {
      color: ${({ theme }) => theme.colorWhite};
      transition: 0.1s linear;
    }
  }

  .ant-segmented-item-label {
    padding: 8px 14px;
  }
` as typeof AntSegmented;
