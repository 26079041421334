import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectMember, ProjectMemberChange } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUnassignProjectMember() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [string, ProjectMemberChange], ProjectMember[]>(
    ([projectId, projectMember]) => {
      return new Promise<void>((resolve, reject) => {
        if (projectMember.member.idMember === undefined) {
          return reject(new Error('Invalid project member id'));
        }
        return backend.unassignProjectMember(projectId, projectMember.member.idMember.toString()).then(resolve).catch(reject);
      });
    },
    {
      onMutate: ([projectId, projectMember]) => {
        const previous = queryClient.getQueryData(['projectMembers', projectId]) as ProjectMember[];
        const copy = [...previous];
        const idx = copy.findIndex((pm) => pm.member.idMember === projectMember.member.idMember);
        copy.splice(idx, 1);
        queryClient.setQueryData(['projectMembers', projectId], copy);
        return previous;
      },
      onError: (_err, [projectId], snapshot) => {
        queryClient.setQueryData(['projectMembers', projectId], snapshot);
      },
      onSettled: (_projectMember, _err, [projectId]) => {
        queryClient.invalidateQueries(['projectMembers', projectId]);
        presentSuccess('Successfully unassigned Project Member');
      }
    }
  );
}

export default useUnassignProjectMember;
