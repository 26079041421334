import { PauseOutlined, ExclamationOutlined, CheckOutlined } from '@ant-design/icons';
import { List, Avatar, Result, Skeleton, Divider } from 'antd';
import { Project } from '../../../api/engineering/domain/types';
import { usePactsFormationProject } from '../hooks/useResources';
import { ImportProject } from './ImportProject';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

export const PacTSFormationResources = (props: { project: Project }) => {
  const pactsFormationData = usePactsFormationProject(props.project.idProject.toString());
  const needsImport = pactsFormationData.isError;
  const resources =
    pactsFormationData.data?.infrastructureState.resources
      .map((r) => r.resourceGroups)
      .flat()
      .map((rg) => {
        return rg.resources.map((res) => ({
          g: rg,
          res
        }));
      })
      .flat() || [];

  return (
    <>
      <Skeleton loading={pactsFormationData.isLoading}>
        {needsImport ? <Result title="Import Required" extra={<ImportProject project={props.project} />} /> : null}
        {!needsImport && pactsFormationData.isSuccess ? (
          <>
            <List
              dataSource={resources}
              renderItem={(item) => {
                let icon = <PauseOutlined />;
                let cName = '';
                if (item.res.status === 'CREATING' || item.res.status === 'DELETING') {
                  icon = <AnimatedLoadingIcon />;
                }
                if (item.res.status === 'CREATION_FAILED' || item.res.status === 'DELETING_FAILED') {
                  icon = <ExclamationOutlined />;
                  cName = 'bg-error';
                }
                if (item.res.status === 'CREATED' || item.res.status === 'DELETED') {
                  icon = <CheckOutlined />;
                  cName = 'bg-success';
                }
                return (
                  <List.Item key={item.res.id}>
                    <List.Item.Meta
                      avatar={<Avatar icon={icon} className={cName} />}
                      title={`${item.g.name}: ${item.res.name}`}
                      description={`${item.res.status} - ${item.res.id}`}
                    />
                    {item.res.error && <div>{`${item.res.error}`}</div>}
                  </List.Item>
                );
              }}
            />
            {pactsFormationData.data.isImported ? (
              <>
                <Divider />
                <ImportProject project={props.project} />
              </>
            ) : null}
          </>
        ) : null}
      </Skeleton>
    </>
  );
};
