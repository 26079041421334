import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectType, ProjectTypeCreate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useCreateProjectType() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectType, any, [ProjectTypeCreate]>(([create]) => {
    return new Promise<ProjectType>((resolve, reject) => {
      backend
        .createProjectType(create)
        .then((dev) => {
          presentSuccess(`Successfully created Project Type ${create.name}`);
          queryClient.invalidateQueries(['projectTypes']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
