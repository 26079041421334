import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css';
import './style/font.less';
import './style/fixes.less';
import './style/animations.less';
import './style/scrollbar.less';
import './style/fonts/SiemensSans_Prof_Roman.ttf';
import './style/fonts/SiemensSans_Prof_Roman.woff';
import './style/fonts/SiemensSans_Prof_Roman.eot';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
