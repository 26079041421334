import { Typography } from 'antd';
import { StepWithNumber } from './types';
import styled from 'styled-components';

const StepHeaderWrapper = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const productTourStep = (args: {
  title: string;
  paragraph1: string;
  paragraph2: string;
  step: number;
  target: string | HTMLElement | null;
  autoStart?: boolean;
}): StepWithNumber => {
  let target: (() => HTMLElement) | (() => null) | HTMLElement | null = null;

  if (typeof args.target === 'string') {
    target = () => {
      return document.querySelector(args.target as string) as HTMLElement;
    };
  }

  if (typeof args.target === 'object') {
    target = args.target as HTMLElement;
  }

  return {
    step: args.step,
    title: <Typography.Text strong>{args.title}</Typography.Text>,
    description: (
      <div>
        <StepHeaderWrapper>
          <Typography.Text>{args.paragraph1}</Typography.Text>
        </StepHeaderWrapper>
        <Typography.Text>{args.paragraph2}</Typography.Text>
      </div>
    ),
    target,
    shouldStartAutomatically: args.autoStart
  };
};
