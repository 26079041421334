import { useLocation } from 'react-router-dom';

export const usePactsReferrer = () => {
  const routerLocation = useLocation();
  if (routerLocation.state) {
    const ref = routerLocation.state?.ref?.replace(location.origin, '');
    return ref;
  }
  return undefined;
};
