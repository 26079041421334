import { WarningOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Switch, Typography } from 'antd';
import { useMemo } from 'react';
import { TransportMean } from '../../../api/notifications/domain/types';
import { usePermissions } from '../../session/hooks/usePermissions';
import { isRestrictedTopic } from '../domain/restricted';
import { useSubscriptionRegex, useSendSubscriptions, useDeleteSubscription } from '../hooks/useSubscriptions';
import { useStyles } from '../../theme/useStyles';

export const SubscriptionTransportMean = (props: { topic: string; title: string; description: string; mean: TransportMean }) => {
  const subscriptions = useSubscriptionRegex();
  const permissions = usePermissions();
  const restricted = useMemo(() => isRestrictedTopic(props.topic), [props.topic]);
  const subscribe = useSendSubscriptions(restricted);
  const unsubscribe = useDeleteSubscription(restricted);
  const token = useStyles();

  const hasPermissionsForRestricted = permissions.notificationSvc$restrictedNotificationsSendRequest && permissions.notificationSvc$restrictedUserSubscriptionsDeleteRequest;
  const hasPermissionsForUnrestricted =
    (permissions.notificationSvc$userSubscriptionAddRequest && permissions.notificationSvc$userSubscriptionsDeleteRequest);

  const lacksPermissions = restricted ? !hasPermissionsForRestricted : !hasPermissionsForUnrestricted;

  const match = subscriptions.data
    ?.filter((r) => r.transportMean === props.mean)
    .filter((r) => r.topicPatternRegex.test(props.topic))
    .sort((a, b) => a.levels.length - b.levels.length)[0];
  const hasMatch = match !== undefined;

  const directMatch = hasMatch && match.directlyMatches(props.topic);
  const indirectMatch = hasMatch && !directMatch;

  const onClick = () => {
    if (indirectMatch) {
      throw new Error('cannot unsubscribe indirect match');
    }
    if (hasMatch) {
      unsubscribe.mutateAsync([{ topicPattern: match.topicPattern, transportMean: props.mean }]);
    } else {
      subscribe.mutateAsync([{ topicPattern: props.topic, transportMean: props.mean }]);
    }
  };

  const titlePrefix = hasMatch ? 'Unsubscribe' : 'Subscribe';
  const titleSuffix = props.title ? props.title : '';
  let titleConnect = hasMatch ? ' from ' : ' to ';
  if (!props.title) {
    titleConnect = '';
  }
  let title = `${titlePrefix}${titleConnect}${titleSuffix}`;
  if (indirectMatch) {
    title = 'Already subscribed to parent topic. Unsubscribe there first to enable fine-grained subscriptions.';
  }
  if (lacksPermissions) {
    title = 'You do not have the required permissions to change this subscription.';
  }

  const loading = subscriptions.isLoading;

  return (
    <Row wrap={false} style={{ width: '100%' }} gutter={[4, 4]}>
      <Col flex={16}></Col>
      <Col flex="auto">
        <Tooltip title={props.description}>
          <Typography.Text style={{ whiteSpace: 'nowrap', marginRight: 8 }}>{props.title}</Typography.Text>
        </Tooltip>
      </Col>
      <Col flex="28px">
        <Tooltip title={title}>
          <Switch size="small" checked={directMatch || indirectMatch} disabled={indirectMatch || lacksPermissions} loading={loading} onChange={onClick} />
        </Tooltip>
      </Col>
      {restricted ? (
        <Col flex="16px" style={{ marginLeft: 8 }}>
          <Tooltip title="This is a restricted topic. Elevated permissions are required to change the subscription.">
            <WarningOutlined style={{ color: token.colorWarning }} />
          </Tooltip>
        </Col>
      ) : null}
    </Row>
  );
};
