import { ExternalTokenCreatePage } from './ExternalTokenCreatePage';
import { ExternalTokenErrorPage } from './ExternalTokenErrorPage';
import { ExternalTokenResultPage } from './ExternalTokenResultPage';
import { useDeviceFlow } from './hooks/useDeviceFlow';

export const ExternalTokenDialogContent = () => {
  const deviceFlowParams = useDeviceFlow();

  const steps = [
    {
      title: 'First',
      content: (
        <ExternalTokenCreatePage
          verificationUri={deviceFlowParams?.deviceCode?.verification_uri}
          isLoading={deviceFlowParams.isLoading}
          tokenResponse={deviceFlowParams.deviceCode}
        />
      )
    },
    {
      title: 'Second',
      content: <ExternalTokenResultPage tokenResponse={deviceFlowParams.tokenResponse} />
    }
  ];
  const current = deviceFlowParams.tokenResponse ? 1 : 0;
  return (
    <>
      {deviceFlowParams.err ? (
        <ExternalTokenErrorPage title={deviceFlowParams.err.title} description={deviceFlowParams.err.description} />
      ) : (
        <div>{steps[current].content}</div>
      )}
    </>
  );
};
