import { Form, Input } from 'antd';
import { formItemLayout } from '../../layout/components/formLayout';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { Otc } from '../../../api';

export const OtcForm = (props: {
  initial: Otc;
  projectId: number;
  onFinish: (data: Otc) => Promise<void>;
  ok: (ok: () => any) => any;
  reset: (cancel: () => any) => any;
}) => {
  const [form] = Form.useForm();

  props.ok(form.submit);
  props.reset(form.resetFields);

  return (
    <Form
      {...formItemLayout}
      labelAlign="left"
      name="basic"
      initialValues={{ ...props.initial }}
      form={form}
      onFinish={async (value) => {
        const copy = { ...props.initial, ...value } as Otc;
        await props.onFinish(copy);
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            min: 4,
            max: 64
          }
        ]}
        label="Name"
        required
        name={['name']}
      >
        <Input placeholder="OTC" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            min: 1,
            max: 1024
          }
        ]}
        label="Description"
        required
        name={['description']}
      >
        <DescriptionTextAreaInput placeholder="Description" />
      </Form.Item>
    </Form>
  );
};

export default OtcForm;
