import { ClearOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useArchiveNotification } from '../hooks/useNotifications';

export const ArchiveAllNotifications = (props: { ids: string[] }) => {
  const archive = useArchiveNotification();
  const permissions = usePermissions();
  const hasPermission = permissions.notificationSvc$userArchiveNotificationsRequest;
  return (
    <Tooltip title="Clear all Notifications" placement="left">
      <Button
        type="text"
        size="small"
        disabled={props.ids.length < 1 || !hasPermission}
        onClick={() => {
          archive.mutate(props.ids);
        }}
        loading={archive.isLoading}
        icon={<ClearOutlined />}
      />
    </Tooltip>
  );
};
