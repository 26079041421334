import { useMemo } from 'react';
import { Skeleton } from 'antd';
import { useProjectSoftwareAppsScoped } from '../hooks/useProjectSoftwareapps';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { useCommonSoftwareAppsScoped } from '../hooks/useCommonSoftwareApps';
import { SoftwareAppsSubTableProvider } from './SoftwareAppsSubTableProvider';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { Project } from '../../../api/engineering/domain/types';
import { ScopedSoftwareApp } from '../hooks';
import { uniqBy } from 'lodash';

type SoftwareAppsListProps = {
  project: Project;
  selected?: SoftwareAppSelection[];
  initiallySelected: SoftwareAppSelection[];
  showBundleItemsOnly?: boolean;
  loading?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareAppSelection[]) => any;
};

export const ProjectSoftwareAppsList = (props: SoftwareAppsListProps) => {
  const projectSoftwareApps = useProjectSoftwareAppsScoped(props.project.idProject.toString());
  const commonSoftwareApps = useCommonSoftwareAppsScoped();

  const isLoading = projectSoftwareApps.isLoading || commonSoftwareApps.isLoading || props.loading || !props.selected;
  const loadingDebounced = useDebounce(isLoading, 100, true);
  const isSuccess = projectSoftwareApps.isSuccess && commonSoftwareApps.isSuccess && !loadingDebounced;

  // Include initially selected apps as well, since a user might
  // not have access to common apps/tools but shall see the bundle content
  const apps: ScopedSoftwareApp[] = useMemo(() => {
    const ret: ScopedSoftwareApp[] = [];
    if (projectSoftwareApps.data && projectSoftwareApps.data.length > 0) {
      ret.push(...projectSoftwareApps.data);
    }
    if (commonSoftwareApps.data && commonSoftwareApps.data.length > 0) {
      ret.push(...commonSoftwareApps.data);
    }
    ret.push(...props.initiallySelected.map((i) => ({ ...i.app, latestVersion: i.version })));
    // Remove duplicates
    return uniqBy(ret, (r) => `${r.idSoftwareApp}-${r.scope}`);
  }, [projectSoftwareApps.data, commonSoftwareApps.data, props.initiallySelected]);

  return (
    <div>
      {loadingDebounced && <Skeleton active />}
      {isSuccess && props.selected && <SoftwareAppsSubTableProvider apps={apps} {...props} selected={props.selected} />}
    </div>
  );
};
