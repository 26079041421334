import { v4 } from 'uuid';
import { invokeUiNotification } from '../../contexts/shared/hooks/userNotificationProvider';

// TODO: Error 403 -> logout

export const presentSuccess = (message: string, title?: string) => {
  invokeUiNotification({
    type: 'success',
    message: title ?? 'Success',
    key: v4(),
    description: message
  });
};

export default presentSuccess;
