import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToolCategory, ToolCategoryCreate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useCreateToolCategory() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ToolCategory, any, [ToolCategoryCreate]>(([ent]) => {
    return new Promise<ToolCategory>((resolve, reject) => {
      backend
        .createToolCategory(ent)
        .then((dev) => {
          presentSuccess(`Successfully created Tool Category ${ent.name}`);
          queryClient.invalidateQueries(['engineeringToolCategories']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
