import { DownloadOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import { useEngineeringTools } from '../../engineeringTools/hooks/useEngineeringTools';
import { usePermissions } from '../../session/hooks/usePermissions';
import { DownloadButton } from '../../../contexts/shared/components/Download/components/DownloadButton';
export const ImpactsDownloadButton = (props: ButtonProps) => {
  const permissions = usePermissions();
  const tools = useEngineeringTools();

  const hasPermissions = permissions.engineeringSvc$getToolVersionDownload && permissions.engineeringSvc$headToolVersionDownload;
  const tool = (tools.data || []).find((t) => t.name.toLowerCase() === 'impacts');
  const hasTool = !!tool;
  const enabled = hasPermissions && hasTool;
  const isLoading = tools.isLoading;

  return (
    <DownloadButton
      {...props}
      icon={props.type === 'link' ? null : <DownloadOutlined />}
      loading={isLoading || props.loading}
      disabled={!enabled || props.disabled}
      artifact={tool ?? null}
      version={tool?.latestVersion ?? null}
    />
  );
};
