import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SoftwareApp, SoftwareAppUpdate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateCommonSoftwareApp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareApp, any, [SoftwareAppUpdate]>(([softwareApp]) => {
    return new Promise<SoftwareApp>((resolve, reject) => {
      backend.updateCommonApp(softwareApp)
        .then((swa) => {
          presentSuccess(`Successfully updated Software App ${softwareApp.name}`);
          queryClient.invalidateQueries(['commonSoftwareapps']);
          queryClient.invalidateQueries(['commonSoftwareapp', swa.idSoftwareApp?.toString()]);
          return resolve(swa);
        })
        .catch(reject);
    });
  });
}
