import { Space, Typography } from 'antd';
import { MarkdownPreview } from '../../../shared/components/MarkdownPreview';
import { PacTSNotification } from '../../../../api/notifications/domain/types';
import { NotificationLinkTitle } from './NotificationLinkTitle';

export const DefaultNotification = (props: {
  notification: PacTSNotification;
  withLink?: boolean;
  linkHandler?: (href: string) => { preventDefault: boolean };
  showCreator?: boolean;
}) => {
  const creator = props.notification.meta?.user?.name;
  return (
    <Space direction="vertical" style={{ marginTop: 0 }}>
      {props.withLink && <NotificationLinkTitle {...props} />}
      {!props.withLink && (
        <Typography.Text style={{ fontWeight: 'bold' }}>{props.notification?.rendered?.title || `Unknown: ${props.notification.topic}`}</Typography.Text>
      )}
      {props.notification?.rendered?.text && (
        <MarkdownPreview content={props.notification.rendered.text} title={props.notification.rendered.title} maxHeight={231} />
      )}
      {props.showCreator && creator && <Typography.Text type="secondary">{`from: ${creator}`}</Typography.Text>}
    </Space>
  );
};
