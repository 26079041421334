import { useMutation, useQueryClient } from '@tanstack/react-query';
import presentSuccess from '../../../api/shared/successPresenter';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';

export const useTerminateUserSession = () => {
  const { backend } = useUserBackend();
  const qc = useQueryClient();
  return useMutation<void, any, [number, string]>(([userId, tokenFamily]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .terminateUserSession(userId, tokenFamily)
        .then((newRoles) => {
          presentSuccess(`Successfully terminated session ${tokenFamily}`);
          qc.invalidateQueries(['userSessions']);
          return resolve(newRoles);
        })
        .catch(reject);
    });
  });
};
