import { useQuery } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { usePermissions } from '../../session';
import { Device } from '../../../api';

export const useDevices = (envId: string, projectId: number) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({ projectId: projectId.toString(), environmentId: envId });
  return useQuery<Device[]>(
    ['device', projectId, envId],
    (): Promise<Device[]> => {
      return backend.queryDevices(projectId ?? 0, envId);
    },
    {
      enabled: projectId !== undefined && envId !== undefined && permissions.deploymentSvc$getDevices
    }
  );
};
