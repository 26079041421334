import { Row, Col, List, Button, Card, Typography } from 'antd';
import { Comparator } from '../../../../domain';
import styled from 'styled-components';

type Indexable = { [key: string]: any };

const AlignedBlockButton = styled(Button)`
  justify-content: start;
`;

export const EntityAdministrationListLayout = <T extends unknown>(props: {
  isLoading: boolean;
  data: T[];
  idAttribute: string;
  titleAttribute: string;
  onSelect: (val: T) => void;
  selected: T | undefined;
  onReset: () => void;
  entityName: string;
  creatingNew: boolean;
  sortListAttribute?: string;
}) => {
  const selectedId = props.selected ? (props.selected as Indexable)[props.idAttribute] : -1;
  const sorted = props.data.sort((a, b) =>
    Comparator.lexicographicalComparison((a as Indexable)[props.sortListAttribute ?? 'name'] ?? '', (b as Indexable)[props.sortListAttribute ?? 'name'] ?? '')
  );
  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 0, backgroundColor: 'rgba(255,255,255, 0.05)' }}
      actions={[
        <Row style={{ width: '100%' }} justify="end">
          <Col>
            <Button
              disabled={props.creatingNew}
              type="primary"
              onClick={() => {
                props.onReset();
              }}
            >
              {`Add ${props.entityName}`}
            </Button>
          </Col>
        </Row>
      ]}
    >
      <Row style={{ width: '100%' }}>
        <Col flex="auto">
          <div style={{ height: 'calc(100vh - 360px)', overflow: 'auto' }}>
            <List
              size="small"
              style={{ height: '100%' }}
              bordered
              loading={props.isLoading}
              dataSource={sorted}
              renderItem={(item: T) => {
                const isActive = selectedId === (item as any)[props.idAttribute] ? 'bg-primary' : undefined;
                return (
                  <List.Item
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.onSelect(item);
                    }}
                  >
                    <AlignedBlockButton block type={isActive ? 'primary' : 'text'}>
                      <Typography.Text ellipsis={{ tooltip: true }}>{(item as any)[props.titleAttribute]}</Typography.Text>
                    </AlignedBlockButton>
                  </List.Item>
                );
              }}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};
