import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectMember, ProjectMemberChange } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useProjectRoles } from '.';

export function useUpdateProjectMember() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  const roles = useProjectRoles();
  return useMutation<ProjectMember, any, [string, ProjectMemberChange], ProjectMember[]>(
    ([projectId, projectMember]) => {
      return backend.updateProjectMember(projectId, projectMember);
    },
    {
      onMutate: ([projectId, projectMember]) => {
        const previous = queryClient.getQueryData(['projectMembers', projectId]) as ProjectMember[];
        const copy = [...previous];
        const idx = copy.findIndex((pm) => pm.member.idMember === projectMember.member.idMember);
        const newRoles = roles.data?.filter((r) => projectMember.roles.map((pmR) => pmR.idProjectRole).includes(r.idProjectRole)) ?? [];
        copy[idx] = { ...previous[idx], roles: newRoles };
        queryClient.setQueryData(['projectMembers', projectId], copy);
        return previous;
      },
      onError: (_err, [projectId], snapshot) => {
        queryClient.setQueryData(['projectMembers', projectId], snapshot);
      },
      onSettled: (_projectMember, _err, [projectId]) => {
        presentSuccess(`Successfully updated Member ${_projectMember?.member.name}`);
        queryClient.invalidateQueries(['projectMembers', projectId]);
      }
    }
  );
}

export default useUpdateProjectMember;
