import { useState, useEffect, useMemo } from 'react';
import { Form, Input, Radio, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useEngineeringToolCategories } from '../hooks/useEngineeringToolCategories';
import { useEngineeringToolVersions } from '../hooks/useEngineeringToolVersions';
import { useUpdateEngineeringTool } from '../hooks/useUpdateEngineeringTool';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { DescriptionValidator } from '../../../domain/validation/descriptionValidator';
import { NameValidator } from '../../../domain/validation/nameValidator';
import { DocumentationLinkValidator } from '../../../domain/validation/documentationLinkValidator';
import { Tool, ToolPackageKindEnum, ToolUpdate } from '../../../api/engineering/domain/types';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

const { Option } = Select;

export type UpdateEngineeringToolProps = {
  detailView?: boolean;
  tool: ToolUpdate;
};

export const UpdateEngineeringTool = (props: UpdateEngineeringToolProps) => {
  const permissions = usePermissions({ toolId: props.tool.id.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const categories = useEngineeringToolCategories();
  const updateEngineeringTool = useUpdateEngineeringTool();
  const versions = useEngineeringToolVersions(props.tool.id?.toString() || '');

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  const setCategories = useMemo(() => {
    return () => {
      form.setFieldsValue({
        category: props.tool.category?.map((c) => c.id)
      });
    };
  }, [form, props.tool]);

  useEffect(() => {
    if (updateEngineeringTool.isSuccess) {
      setModalVisible(false);
      form.resetFields();
      setCategories();
    }
  }, [form, setCategories, setModalVisible, updateEngineeringTool.isSuccess]);

  useEffect(() => {
    setCategories();
  }, [props.tool, setCategories]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permissions.engineeringSvc$updateTool) return null;

  // const selectedCategory = newApp.categories && newApp.categories.length > 0 ? newApp.categories[0].id?.toString() || "" : "";
  return (
    <>
      <ExpandableMenuItem
        icon={<EditOutlined />}
        loading={updateEngineeringTool.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
        buttonMode={props.detailView}
      >
        {props.detailView ? 'Edit details' : 'Edit'}
      </ExpandableMenuItem>
      <ScrollDialog
        title="Edit Engineering Tool"
        destroyOnClose
        confirmLoading={updateEngineeringTool.isLoading}
        open={modalVisible}
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
          setCategories();
        }}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ ...props.tool }}
          form={form}
          onFinish={(value) => {
            const copy = Object.assign(props.tool, { ...value }) as Tool;
            const cats = categories.data?.filter((c) => (copy.category as any as number[]).find((cat) => cat === c.id));
            copy.category = cats || [];
            if (versions && versions.data) {
              const id = copy.latestVersion!.idToolVersion;
              const vf = versions.data.find((v) => v.idToolVersion === id);
              copy.latestVersion = vf!;
            }
            updateEngineeringTool.mutate([copy]);
          }}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            label="Description"
            field={['description']}
            rules={[DescriptionValidator.rule()]}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          <MarkdownFormItem
            optional
            rules={[DocumentationLinkValidator.rule()]}
            label="Documentation"
            field={['documentationLink']}
            shouldUpdate={(prevValues, currentValues) => prevValues.documentationLink !== currentValues.documentationLink}
          />
          <Form.Item label="Package Kind" name="packageKind" rules={[{ required: true, message: 'Required' }]}>
            <Radio.Group>
              <Radio value={ToolPackageKindEnum.Custom}>Custom</Radio>
              <Radio value={ToolPackageKindEnum.Msi}>Msi</Radio>
              <Radio value={ToolPackageKindEnum.Nsis}>Nsis</Radio>
              <Radio value={ToolPackageKindEnum.Zip}>Zip</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Latest Version" name={['latestVersion', 'idToolVersion']} rules={[{ required: true, message: 'Required' }]}>
            <Select
              loading={versions.isLoading}
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              {versions.data?.map((version) => {
                return (
                  <Select.Option key={version.idToolVersion || -1} value={version.idToolVersion || -1} title={version.version}>
                    {version.version}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Categories" name="category" rules={[{ required: true, message: 'Please select at least one category!', type: 'array' }]}>
            <Select
              mode="multiple"
              loading={categories.isLoading}
              style={{ width: '100%' }}
              placeholder="Please select"
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              {categories.data?.map((category) => {
                return (
                  <Option key={category.id} value={category.id || -1} title={category.name}>
                    {category.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </ScrollDialog>
    </>
  );
};
