/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

export type PacTSIconDarkProps = {
  style?: React.CSSProperties;
};

export const PacTSIconDark: React.FunctionComponent<PacTSIconDarkProps> = (props: PacTSIconDarkProps) => {
  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg style={props.style} viewBox="0 0 256 256" width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>PacTS</title>
      <path
        d="M262.32 124.29L201.961 63.9304C201.317 63.2871 200.217 63.7427 200.217 64.6525V87.3375C200.217 87.8792 200.433 88.3987 200.816 88.7818L236.324 124.29C238.717 126.683 238.717 130.562 236.324 132.955L200.816 168.463C200.433 168.846 200.217 169.366 200.217 169.907V192.592C200.217 193.502 201.317 193.958 201.961 193.315L262.32 132.955C264.713 130.562 264.713 126.683 262.32 124.29Z"
        fill="#19D2FF"
      />
      <path
        d="M119.34 62.1902C124.125 57.4044 131.885 57.4044 136.671 62.1902L152.557 78.0769C157.343 82.8628 157.343 90.6221 152.557 95.4079L130.894 117.072C129.298 118.667 126.712 118.667 125.117 117.072L103.453 95.4079C98.667 90.6221 98.667 82.8628 103.453 78.0769L119.34 62.1902Z"
        fill="#19D2FF"
      />
      <path
        d="M161.223 104.073C166.009 99.2871 173.768 99.2871 178.554 104.073L194.44 119.96C199.226 124.745 199.226 132.505 194.44 137.291L178.554 153.177C173.768 157.963 166.009 157.963 161.223 153.177L139.559 131.514C137.964 129.918 137.964 127.332 139.559 125.737L161.223 104.073Z"
        fill="#3C345B"
      />
      <path
        d="M77.4563 104.073C82.2421 99.2871 90.0015 99.2871 94.7873 104.073L116.451 125.737C118.046 127.332 118.046 129.918 116.451 131.514L94.7873 153.177C90.0015 157.963 82.2421 157.963 77.4563 153.177L61.5696 137.291C56.7837 132.505 56.7837 124.745 61.5696 119.96L77.4563 104.073Z"
        fill="#CB75FF"
      />
      <path
        d="M126.561 138.734C127.358 137.937 128.652 137.937 129.449 138.734L152.557 161.842C157.343 166.628 157.343 174.387 152.557 179.173L136.671 195.06C131.885 199.846 124.125 199.846 119.34 195.06L103.453 179.173C98.667 174.387 98.667 166.628 103.453 161.842L126.561 138.734Z"
        fill="#3C345B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151.113 247.051C138.351 259.813 117.659 259.813 104.897 247.051L9.57664 151.73C-3.18554 138.968 -3.18554 118.277 9.57664 105.514L104.897 10.1941C117.659 -2.56811 138.351 -2.56811 151.113 10.1941L187.219 46.3003C191.207 50.2885 191.207 56.7546 187.219 60.7428C183.231 64.7309 176.765 64.7309 172.777 60.7428L136.671 24.6366C131.885 19.8507 124.125 19.8507 119.34 24.6366L24.0191 119.957C19.2333 124.743 19.2333 132.502 24.0191 137.288L119.34 232.608C124.125 237.394 131.885 237.394 136.671 232.608L172.777 196.502C176.765 192.514 183.231 192.514 187.219 196.502C191.207 200.49 191.207 206.956 187.219 210.945L151.113 247.051Z"
        fill="#3C345B"
      />
    </svg>
  );

  return <Icon {...props} component={svg} />;
};
