import { Typography } from 'antd';
import { Timeout } from '../shared/components/icons/Timeout';
import { PACTS_WIKI_LINK } from '../../constants/links';
import styled from 'styled-components';
import { useStyles } from '../theme/useStyles';

const CenteredParagraph = styled(Typography.Paragraph)<{ mt?: number; mb?: number }>`
  text-align: center;
  ${(props) =>
    props.mt != null
      ? `
    margin-top: ${props.mt}px !important;
  `
      : ''}
  ${(props) =>
    props.mb != null
      ? `
    margin-bottom: ${props.mb}px !important;
  `
      : ''}
`;

const ErrorCenteredParagraph = styled(Typography.Paragraph)<{ mt?: number; mb?: number }>`
  text-align: center;
  color: ${({ theme }) => theme.colorError};
  ${(props) =>
    props.mt != null
      ? `
    margin-top: ${props.mt}px !important;
  `
      : ''}
  ${(props) =>
    props.mb != null
      ? `
    margin-bottom: ${props.mb}px !important;
  `
      : ''}
`;

const Container = styled.div`
  width: 100%;
`;

export const ExternalTokenErrorPage = (props: { title: string; description: string }) => {
  const token = useStyles();

  return (
    <Container>
      <CenteredParagraph mt={38} mb={74}>
        Generate tokens in order to access to PacTS using functional accounts. For details check our{' '}
        <Typography.Link href={PACTS_WIKI_LINK}>Wiki page</Typography.Link>.
      </CenteredParagraph>
      <Timeout
        size={76}
        // This style is a prop to a component not an inline style
        style={{
          color: token.colorError,
          fontSize: '76px',
          display: 'flex',
          justifyContent: 'center'
        }}
      />
      <ErrorCenteredParagraph mt={21}>{props.title}</ErrorCenteredParagraph>
      <CenteredParagraph mt={74}>{props.description}</CenteredParagraph>
    </Container>
  );
};
