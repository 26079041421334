import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, List, Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { Project, ProjectMember, ProjectMemberAccessRequest } from '../../../api';
import useAssignProjectMember from '../../administration/hooks/useAssignProjectMember';
import useProjectRoles from '../../administration/hooks/useProjectRoles';
import { useDeleteProjectAccessRequest } from '../hooks/useDeleteProjectAccessRequest';

export const ProjectAccessRequestListItem = (props: { project: Project; accessRequest: ProjectMemberAccessRequest }) => {
  const decline = useDeleteProjectAccessRequest();
  const roles = useProjectRoles();
  const add = useAssignProjectMember();
  const [selectedRole, setSelectedRole] = useState(undefined);

  const Selector = () => {
    return (
      <Select
        className={`project-access-request-role-selector-${props.project.idProject}-${props.accessRequest.idMember}`}
        loading={!roles.isSuccess}
        style={{ width: 240 }}
        value={selectedRole}
        onChange={setSelectedRole}
        placeholder="Select Role..."
        options={roles.data?.map((r) => {
          return { value: r.idProjectRole, label: r.name };
        })}
      />
    );
  };

  const SaveRole = () => {
    const role = roles.data?.find((r) => r.idProjectRole === selectedRole);
    const pmToAssign: ProjectMember = {
      member: {
        idMember: props.accessRequest.idMember,
        email: props.accessRequest.email,
        name: props.accessRequest.name
      },
      roles: role ? [role] : []
    };
    return (
      <Button
        disabled={!role}
        loading={add.isLoading}
        onClick={() => {
          add.mutate([props.project.idProject.toString(), pmToAssign]);
        }}
        icon={<CheckOutlined />}
      >
        Approve
      </Button>
    );
  };
  const DeclineUser = () => {
    return (
      <Button
        danger
        onClick={() => {
          decline.mutate([props.project, props.accessRequest.idMember]);
        }}
        loading={decline.isLoading}
        icon={<CloseOutlined />}
        key="dismiss"
      >
        Decline
      </Button>
    );
  };
  return (
    <List.Item
      extra={[
        <Space key="actions">
          <Selector />
          <SaveRole />
          <DeclineUser />
        </Space>
      ]}
    >
      <Typography.Text>{`${props.accessRequest.name}`}</Typography.Text>
    </List.Item>
  );
};
