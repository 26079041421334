import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { DeploymentPlanPatchDeployment } from '../../../api';

export function useUpdateMultipleDeploymentPlanDeployments() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [DeploymentPlanPatchDeployment, string, number, string]>(async ([update, deploymentPlanId, projectId, envId]) => {
    const newPlan = await backend.patchDeploymentPlanDeployment(projectId, envId, deploymentPlanId, update);

    queryClient.setQueryData(['deploymentplan', projectId, envId, deploymentPlanId], newPlan);

    await Promise.all([queryClient.invalidateQueries(['deploymentplanMetas', projectId, envId]), queryClient.invalidateQueries(['device', projectId, envId])]);

    presentSuccess('Successfully updated Deployment');
  });
}
