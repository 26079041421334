import { Button, Card, Space, Typography } from 'antd';
import styled from 'styled-components';

import type { VulnerabilityNotification } from '../../../api/engineering/domain/types';
import { formatDateTime } from './formatDate';

type ScoreType = 'Critical' | 'High' | 'Medium' | 'Low';

const CardTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const Score = styled(Typography.Text)<{ scoreType?: ScoreType }>`
  color: ${({ scoreType, theme }) => {
    if (!scoreType) return theme.colorText;

    if (scoreType === 'Critical') return theme.colorError;
    else if (scoreType === 'High' || scoreType === 'Medium') return theme.colorWarning;

    return theme.colorSuccess;
  }};
`;

export function VulnerabilityNotificationCard(props: { notification: VulnerabilityNotification; link?: string; target?: string | null }) {
  let scoreType: ScoreType | undefined;

  if (!props.notification.cvss?.overallScore) {
    scoreType = undefined;
  } else if (props.notification.cvss?.overallScore >= 9) {
    scoreType = 'Critical';
  } else if (props.notification.cvss?.overallScore >= 7) {
    scoreType = 'High';
  } else if (props.notification.cvss?.overallScore >= 4) {
    scoreType = 'Medium';
  } else {
    scoreType = 'Low';
  }

  const hasScore = !!props.notification.cvss && !!props.notification.cvss.overallScore;

  const title = (
    <CardTitle level={5} ellipsis={{ tooltip: props.notification.title }}>
      {props.notification.title}
    </CardTitle>
  );

  return (
    <Card title={title}>
      {props.target && <Typography.Paragraph>Target: {props.target}</Typography.Paragraph>}

      <Typography.Paragraph>
        Severity:{' '}
        {hasScore ? (
          <Score strong scoreType={scoreType}>
            {scoreType} ({props.notification.cvss?.overallScore})
          </Score>
        ) : (
          '-'
        )}
      </Typography.Paragraph>

      <Typography.Paragraph>Published: {formatDateTime(new Date(props.notification.publishDate))}</Typography.Paragraph>

      <Typography.Paragraph>
        Updated: {props.notification.lastUpdateDate ? formatDateTime(new Date(props.notification.lastUpdateDate)) : '-'}
      </Typography.Paragraph>

      {props.link && (
        <Space direction="vertical" align="end" style={{ display: 'flex' }}>
          <Button type="primary" href={props.link} target="_blank">
            Go to ICS Portal
          </Button>
        </Space>
      )}
    </Card>
  );
}
