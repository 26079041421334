import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { BundleConfiguration } from '../../../api/engineering/domain/types';

export function useCreateBundleConfiguration() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<BundleConfiguration, any, [string, string, BundleConfiguration]>(([projectId, bundleId, bundleConfiguration]) => {
    return new Promise<BundleConfiguration>((resolve, reject) => {
      backend
        .createBundleConfiguration(projectId, bundleId, bundleConfiguration)
        .then((bc) => {
          const existing = queryClient.getQueryData<BundleConfiguration[]>(['bundleConfigurations', projectId, bundleId]);
          if (existing) {
            queryClient.setQueriesData(['bundleConfigurations', projectId, bundleId], [...existing, bc]);
          } else {
            queryClient.setQueriesData(['bundleConfigurations', projectId, bundleId], [bc]);
          }
          queryClient.invalidateQueries(['bundleConfigurations', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId]);
          presentSuccess(`Successfully created Bundle Configuration ${bc.name}`);
          return resolve(bc);
        })
        .catch(reject);
    });
  });
}

export default useCreateBundleConfiguration;
