import { Col, Result, Row } from 'antd';
import styled from 'styled-components';
import { AnimatedLoadingIcon } from './icons/AnimatedLoadingIcon';

const WrapperRow = styled(Row)`
  height: 100%;
`;

export const PageLoading = (props: { title?: string; description?: string }) => {
  return (
    <WrapperRow justify="center" align="middle">
      <Col>
        <Result title={props.title} subTitle={props.description} icon={<AnimatedLoadingIcon style={{ fontSize: 24 }} />} />
      </Col>
    </WrapperRow>
  );
};
