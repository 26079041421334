import { useEffect, useRef } from 'react';
import useSubmitMetric from '../hooks/useMetrics';
import { useIsVisible } from '../../shared/hooks/useIsVisible';
import { MetricViewIds } from '../constants';
import { MetricTag } from '../../../api/metrics/domain/types';

export const MetricOnVisible = (props: { view: MetricViewIds; payload?: MetricTag[] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);
  const submit = useSubmitMetric();

  const { view, payload } = props;

  // Use is visible using intersection observer instead of just dom hook
  // because some dialogs / popovers stay in dom once initially rendered
  // and this hook shall capture opening them again as well
  useEffect(() => {
    if (isVisible) {
      submit.submitView({ view, payload });
    }
  }, [submit, view, payload, isVisible]);

  return <div ref={ref} />;
};
