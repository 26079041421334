import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { ProjectMember, ProjectMemberChange } from '../../../api/engineering/domain/types';

export function useAssignProjectMember() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectMember, [string, ProjectMemberChange], any, ProjectMember[]>(([projectId, projectMember]) => {
    return new Promise<ProjectMember>((resolve, reject) => {
      backend
        .assignProjectMember(projectId, projectMember)
        .then((res) => {
          presentSuccess(`Successfully updated Project Member ${res.member.name}`);
          queryClient.invalidateQueries(['projectMembers', projectId]);
          queryClient.invalidateQueries(['projectAccessRequest', parseInt(projectId, 10)]);
          queryClient.invalidateQueries(['projectAccessRequests', parseInt(projectId, 10)]);
          resolve(projectMember);
        })
        .catch(reject);
    });
  });
}

export default useAssignProjectMember;
