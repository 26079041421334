import { Skeleton, TableColumnProps } from 'antd';

import { usePermissions } from '../../session/hooks/usePermissions';
import { scopeToProject, useDeleteProjectSoftwareAppVersion, useProjectSoftwareAppVersions } from '../hooks';

import { UpdateProjectSoftwareAppVersion } from './UpdateProjectSoftwareAppVersion';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import Table from '../../../contexts/shared/components/Table/Table';
import { ExpandableMenu, formatDateTime, useTableSearch, ConfirmationButton } from '../../../contexts/shared/components';
import { ComponentVersionListEntry } from '../../../contexts/shared/components/ComponentVersionListEntry';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { SoftwareAppVersionListTitle } from './SoftwareAppVersionListTitle';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useCallback, useMemo } from 'react';

export type SoftwareAppVersionsListProps = {
  project: Project;
  softwareApp: SoftwareApp;
};

export const ProjectSoftwareAppVersionsList = (props: SoftwareAppVersionsListProps) => {
  const permissions = usePermissions({ projectId: props.project.idProject.toString(), softwareAppId: props.softwareApp.idSoftwareApp.toString() });
  const versionSearch = useTableSearch({ searchValueProvider: 'version', searchParamId: 'version' });

  const releaseNotesSearch = useTableSearch({
    searchValueProvider: (v: SoftwareAppVersion) => {
      return [v.version, v.releaseNotes, formatDateTime(new Date(v.createdAt || 0)), v.createdBy, formatDateTime(new Date(v.updatedAt || 0)), v.updatedBy]
        .filter(Boolean)
        .join(' ');
    },
    searchParamId: 'releaseNotes'
  });

  const versions = useProjectSoftwareAppVersions(props.project.idProject!.toString(), props.softwareApp.idSoftwareApp?.toString() || '');
  const deleteSoftwareAppVersion = useDeleteProjectSoftwareAppVersion();

  const latestVersion = props.softwareApp?.latestVersion?.idSoftwareAppVersion;
  const versionSorter = useCallback(
    (a: SoftwareAppVersion, b: SoftwareAppVersion) => {
      if (a.idSoftwareAppVersion === latestVersion) return -1;
      if (b.idSoftwareAppVersion === latestVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    },
    [latestVersion]
  );

  const data = useMemo(() => {
    return (versions.data ?? []).sort(versionSorter);
  }, [versions.data, versionSorter]);

  const columns: TableColumnProps<SoftwareAppVersion>[] = [
    {
      title: 'Version',
      key: 'version',
      ...versionSearch,
      width: 160,
      render: (record: SoftwareAppVersion) => {
        return (
          <SoftwareAppVersionListTitle
            project={props.project}
            app={props.softwareApp}
            version={record}
            hasPermissions={permissions.all$unrestrictedAdministration}
          />
        );
      }
    },
    {
      title: 'Release Notes',
      key: 'releaseNotes',
      ...releaseNotesSearch,
      showSorterTooltip: { title: 'Sort by creation date' },
      sorter: {
        compare: (a: SoftwareAppVersion, b: SoftwareAppVersion): number => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        }
      },
      render: (record: SoftwareAppVersion) => <ComponentVersionListEntry record={record} showAudit={permissions.webui$showComponentDetails} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: SoftwareAppVersion) => {
        return (
          <>
            <ExpandableMenu
              textType
              id={`project-app-version-actions-${props.project.idProject}-${props.softwareApp.idSoftwareApp}-${record.idSoftwareAppVersion}`}
            >
              <DownloadContextMenuButton artifact={props.softwareApp} version={record} projectId={props.project.idProject} />
              <SoftwareAppsOpenInToolManagerButton selection={{ app: scopeToProject(props.softwareApp), version: record }} project={props.project} />
              <UpdateProjectSoftwareAppVersion projectId={props.project.idProject!.toString()} parentApp={props.softwareApp} softwareAppVersion={record} />
              {versions.data && versions.data.length > 1 && permissions.engineeringSvc$deleteProjectSoftwareAppVersion && (
                <ConfirmationButton
                  caption="Delete"
                  danger
                  disabled={record.idSoftwareAppVersion === props.softwareApp.latestVersion?.idSoftwareAppVersion}
                  onOk={() => {
                    deleteSoftwareAppVersion.mutate([
                      props.project.idProject!.toString(),
                      props.softwareApp.idSoftwareApp?.toString() || '',
                      record.idSoftwareAppVersion?.toString() || ''
                    ]);
                  }}
                />
              )}
            </ExpandableMenu>
          </>
        );
      },
      width: 100
    }
  ];

  return (
    <Skeleton loading={versions.isLoading}>
      <Table
        tableLayout="fixed"
        columns={columns}
        rowKey={(record: SoftwareAppVersion) => record.idSoftwareAppVersion?.toString() || ''}
        dataSource={data}
        sticky={{
          offsetHeader: 128 + 14
        }}
      />
    </Skeleton>
  );
};
