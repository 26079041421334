import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteDeploymentEnvironment() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();

  return useMutation<void, any, [number, string, number]>(async ([projectId, envId, mvccId]) => {
    backend.deleteDeploymentEnvironment(projectId, envId, mvccId).then(() => {
      presentSuccess('Deployment environment successfully deleted');
      queryClient.invalidateQueries(['deploymentEnvironments', projectId]);
    });
  });
}
