import { ReactNode, CSSProperties } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

import { useTheme, THEMES } from '../../theme/ThemeProvider';


type IconProps = {
    text: string | ReactNode;
    overlayStyle?: Record<string, string | undefined>;
    iconStyle?: CSSProperties;
};

const StyledInfoIcon = styled(InfoCircleOutlined)<{ isLightTheme?: boolean }>`
    color: ${({ theme, isLightTheme }) => isLightTheme ? theme.colorSecondaryLight : theme.colorSecondaryDark};
`;

export const InfoTooltip = (props: IconProps) => {
    const [theme] = useTheme();

    const isLightTheme = theme === THEMES.LIGHT;

    return (
        <Tooltip
            title={props.text}
            overlayStyle={props.overlayStyle}
            trigger={['click', 'hover']}
        >
            <StyledInfoIcon isLightTheme={isLightTheme} style={props.iconStyle} />
        </Tooltip>
    );
};
