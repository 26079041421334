import { AliasToken } from 'antd/es/theme/internal';

export const common: Partial<AliasToken> = {
	borderRadius: 4,
	fontWeightStrong: 300,
	fontSize: 15,
	fontFamily: 'SiemensSansRoman, Arial, Helvetica, sans-serif'
};

/**
 * Converts an opacity value to a hex value.
 * Example: 0.5 -> 7f.
 * 
 * @param a The opacity, as a value between 0 and 1.
 * @returns The hex value.
 */
export const opacityToHex = (a: number) => ((a * 255) | 1 << 8).toString(16).slice(1);

export const primitivesLight = {
	SEpurple:{
		100: '#ADA9C2',
		300: '#8A00E5',
		600: '#641E8C',
		900: '#1B1534'
	},
	purple: {
		1: "#f1e9f6",
		2: "#e4d3ee",
		3: "#c9a7dd",
		4: "#ae7bcc",
		5: "#934fbb",
		6: "#723993",
		7: "#673384",
		8: "#582c72",
		9: "#442258",
		10: "#361b46",
		11: "#251330"
	},
	teal: {
		1: "#e5faff",
		2: "#d1f6ff",
		3: "#a3edff",
		4: "#75e3ff",
		5: "#47daff",
		6: "#19d2ff",
		7: "#00b4e0",
		8: "#0087a8",
		9: "#10607a",
		10: "#125067",
		11: "#053447"
	},
	violet: {
		1: "#d1d0ed",
		2: "#b0b1e0",
		3: "#8c8ad0",
		4: "#766ec3",
		5: "#695bb5",
		6: "#6250a5",
		7: "#554687",
		8: "#3d345f",
		9: "#2f2843",
		10: "#292340",
		11: "1b172b"
	},
	red: {
		1: "#fcf3f4",
		2: "#f9e7ea",
		3: "#f2cad1",
		4: "#eaa9b5",
		5: "#de7c8e",
		6: "#c4314b",
		7: "#b42d46",
		8: "#9f283e",
		9: "#832133",
		10: "#5e1724",
		11: "#46121c"
	},
	green: {
		1: "#ebfde3",
		2: "#cffabd",
		3: "#8ef263",
		4: "#51e213",
		5: "#45c110",
		6: "#389e0d",
		7: "#338d0c",
		8: "#2c7b0a",
		9: "#256809",
		10: "#194706",
		11: "#143905"
	},
	orange: {
		1: "#ffece0",
		2: "#ffd8c2",
		3: "#ffb185",
		4: "#ff8b47",
		5: "#ff640a",
		6: "#cc4b00",
		7: "#b84300",
		8: "#a33c00",
		9: "#853100",
		10: "#662500",
		11: "#471a00"
	},
	yellow: {
		1: "#fffcf0",
		2: "#fff8d6",
		3: "#ffefa8",
		4: "#ffe77a",
		5: "#ffdc42",
		6: "#ffcf00",
		7: "#e6bb00",
		8: "#cca700",
		9: "#a88900",
		10: "#7a6400",
		11: "#5c4b00"
	},
	grey: {
		solid: "#0F1016",
		solidReversed: "#ffffff",
		1: "#141414",
		2: "#1d1d1d",
		3: "#272727",
		4: "#3b3b3b",
		5: "#515151",
		6: "#676767",
		7: "#979797",
		8: "#b0b0b0",
		9: "#cacaca",
		10: "#e4e4e4"
	},
	neutral: {
        1: "#ffffff",
        2: "#fafafa",
        3: "#f0f2f5",
        4: "#e5e5e5",
        5: "#d7d7d8",
        6: "#c8c8c9",
        7: "#b7b7b9",
        8: "#a5a5a7",
        9: "#919194",
        10: "#797a7d",
        11: "#5b5c61"
	}
} as const;

export const primitivesDark = {
	SEpurple:{
		300: '#B96CFF',
		600: '#CF9BFF',
		800: '#5D596E'
	},
	teal: {
		1: "#093243",
		2: "#174e62",
		3: "#165d74",
		4: "#09819f",
		5: "#0cadd4",
		6: "#2ac7ee",
		7: "#55d2f1",
		8: "#7fddf5",
		9: "#aae9f8",
		10: "#deeff6",
		11: "#eefbfb"
	},
	purple: {
		1: "#f1e9f6",
		2: "#e4d3ee",
		3: "#c9a7dd",
		4: "#ae7bcc",
		5: "#934fbb",
		6: "#723993",
		7: "#673384",
		8: "#582c72",
		9: "#442258",
		10: "#361b46",
		11: "#251330"
	},
	red: {
		1: "#f9e7ea",
		2: "#f2cad1",
		3: "#eaa9b5",
		4: "#de7c8e",
		5: "#ff637f",
		6: "#c4314b",
		7: "#b42d46",
		8: "#9f283e",
		9: "#832133",
		10: "#5e1724",
		11: "#46121c"
	},
	green: {
		1: "#1B3F08",
		2: "#265A0C",
		3: "#378311",
		4: "#429E15",
		5: "#4AB017",
		6: "#52C41A",
		7: "#5DDD1D",
		8: "#8CEA5D",
		9: "#BCF2A0",
		10: "#DFF9D2",
		11: "#46121c"
	},
	orange: {
		1: "#471a00",
		2: "#662500",
		3: "#853100",
		4: "#a33c00",
		5: "#b84300",
		6: "#cc4b00",
		7: "#ff640a",
		8: "#ff8b47",
		9: "#ffb185",
		10: "#ffd8c2",
		11: "#ffece0"
	},
	violet: {
		1: "#28223f",
		2: "#3d345f",
		3: "#4f4379",
		4: "#5f5193",
		5: "#7263a9",
		6: "#8a7db8",
		7: "#a197c6",
		8: "#b9b1d4",
		9: "#d0cbe2",
		10: "#e8e5f1",
		11: "#ecebef"
	},
	yellow: {
		1: "#724111",
		2: "#874f0c",
		3: "#a36505",
		4: "#cd8f01",
		5: "#edb905",
		6: "#fdd008",
		7: "#ffe545",
		8: "#fff388",
		9: "#fffbc2",
		10: "#fefce8"
	},
	grey: {
		solid: "#ffffff",
		solidReversed: "#0F1016",
		1: "#f2f2f3",
		2: "#e5e5e5",
		3: "#d9d9d9",
		4: "#c8c8c9",
		5: "#b7b7b9",
		6: "#a5a6a7",
		7: "#919193",
		8: "#797a7d",
		9: "#5c5d61",
		10: "#494a4f"
	},
	neutral: {
        1: "#09090d",
        2: "#0f1016",
        3: "#15161f",
        4: "#171821",
        5: "#1a1c27",
        6: "#1b1c26",
        7: "#1e1f2a",
        8: "#21222e",
        9: "#232532",
        10: "#262736",
        11: "#333647" // "#292b3a"
	}
} as const;