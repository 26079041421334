import { useQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useProjectSoftwareAppVersionVulnerabilities(projectId: number, appId: number, appVersionId: number) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId: projectId.toString(), softwareAppId: appId.toString(), idSoftwareAppVersion: appVersionId.toString() });

  return useQuery(
    ['projectSoftwareAppVersionVulnerabilities', projectId.toString(), appId.toString(), appVersionId.toString()],
    () => {
      return backend.queryProjectAppVersionVulnerabilities(projectId, appId, appVersionId);
    },
    {
      enabled: permissions.engineeringSvc$getProjectSoftwareAppVersionVulnerabilities
    }
  );
}
