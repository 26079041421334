import { Button, Drawer, Flex } from 'antd';
import { useState } from 'react';
import { useCreateOtc } from '../hooks/useCreateOtc';
import { OtcForm } from './OtcForm';
import { Otc } from '../../../api';
import { useParams } from 'react-router-dom';

const initial: Otc = {
  description: '',
  id: '',
  mvccId: 0,
  name: '',
  createdBy: 0,
  createdAt: '',
  createdByName: '',
  updatedAt: '',
  updatedBy: 0,
  updatedByName: ''
};

export const CreateOtc = (props: { projectId: number }) => {
  const params = useParams();
  const envId = params?.envId ?? '';
  const updateOtc = useCreateOtc(props.projectId, envId);
  const [modalVisible, setModalVisible] = useState(false);
  let okHandler: () => any;
  let resetHandler: () => any;

  const handleCloseDrawer = () => {
    resetHandler();
    setModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        loading={updateOtc.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        New OTC
      </Button>
      <Drawer
        title="Create OTC"
        width={780}
        destroyOnClose
        onClose={handleCloseDrawer}
        footer={
          <Flex justify="end">
            <Button style={{ width: '125px', marginRight: '10px' }} onClick={handleCloseDrawer} loading={updateOtc.isLoading}>
              Cancel
            </Button>
            <Button style={{ width: '125px' }} type="primary" onClick={() => okHandler()} loading={updateOtc.isLoading}>
              Save
            </Button>
          </Flex>
        }
        open={modalVisible}
      >
        <OtcForm
          projectId={props.projectId}
          initial={{ ...initial }}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          onFinish={async (c) => {
            await updateOtc.mutateAsync([c]);
            setModalVisible(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default CreateOtc;
