import { SoftwareAppSelection } from '../../../domain';
import { useCreateDeploymentPlan } from '../hooks/useCreateDeploymentPlan';
import { DeploymentIcon } from '../../shared/components/DeploymentIcon';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
export const CreateDeploymentPlan = (props: {
  disabled?: boolean;
  projectId: number;
  bundleId: number;
  bundleConfigId: number;
  bundleConfigVersionId: number;
  selection: SoftwareAppSelection[];
}) => {
  const navigate = useInAppNavigate();
  const createDeploymentPlan = useCreateDeploymentPlan();

  return (
    <>
      <ExpandableMenuItem
        icon={<DeploymentIcon />}
        disabled={props.disabled}
        loading={createDeploymentPlan.isLoading}
        onClick={() => {
          navigate(
            `/projects/${props.projectId}/deployments/create` +
              `?bundleId=${props.bundleId}&bundleConfigId=${props.bundleConfigId}&bundleConfigVersionId=${props.bundleConfigVersionId}`
          );
        }}
      >
        Create Deployment Plan
      </ExpandableMenuItem>
    </>
  );
};
