import { Checkbox, DatePicker, Form, FormInstance, Input, Select } from 'antd';
import { useEffect } from 'react';
import { ProjectCreate, ProjectUpdate } from '../../../api/engineering/domain/types';
import { NameValidator, DescriptionValidator } from '../../../domain';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { useProjectPhases } from '../hooks/useProjectPhases';
import { useProjectSolutionTypes } from '../hooks/useProjectSolutionTypes';
import { useProjectTypes } from '../hooks/useProjectTypes';
import { ReferenceProjectFormItem } from './ReferenceProjectFormItem';

export const ProjectForm = (props: {
  initial: ProjectCreate | ProjectUpdate;
  onFinish: (data: ProjectCreate | ProjectUpdate) => Promise<void>;
  ok: (ok: () => any) => any;
  reset: (cancel: () => any) => any;
  form?: FormInstance;
}) => {
  const [form] = Form.useForm<ProjectCreate | ProjectUpdate>(props.form);
  props.ok(form.submit);
  props.reset(form.resetFields);

  const projectTypes = useProjectTypes();
  const projectSolutionTypes = useProjectSolutionTypes();
  const projectPhases = useProjectPhases();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.initial]);

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ ...props.initial }}
      form={form}
      onFinish={(value) => {
        const copy = { ...props.initial, ...value } as any as ProjectCreate | ProjectUpdate;
        copy.projectType = projectTypes.data!.find((c) => ((copy as any).projectType as number) === c.idProjectType)!;
        copy.projectSolutionType = projectSolutionTypes.data!.find((c) => ((copy as any).projectSolutionType as number) === c.id)!;
        copy.projectPhase = projectPhases.data!.find((c) => ((copy as any).projectPhase as number) === c.id)!;
        copy.startDate = (copy.startDate as any).toISOString();
        copy.endDate = (copy.endDate as any).toISOString();
        props.onFinish(copy);
      }}
    >
      <Form.Item label="Name" name="name" rules={[NameValidator.rule]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        label="Description"
        field={['description']}
        rules={[DescriptionValidator.rule()]}
        shouldUpdate={(prevValues, currentValues) => prevValues.description !== currentValues.description}
      />
      <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Start Date" name="startDate" rules={[{ required: true, message: 'Required' }]}>
        <DatePicker />
      </Form.Item>
      <Form.Item label="End Date" name="endDate" rules={[{ required: true, message: 'Required' }]}>
        <DatePicker />
      </Form.Item>
      <Form.Item label="Project Type" name={['projectType']} rules={[{ required: true, message: 'Required' }]}>
        <Select loading={projectTypes.isLoading}>
          {projectTypes.data?.map((projectType) => {
            return (
              <Select.Option key={projectType.idProjectType || -1} value={projectType.idProjectType || -1} title={projectType.name}>
                {projectType.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Project Solution Type" name={['projectSolutionType']} rules={[{ required: true, message: 'Required' }]}>
        <Select loading={projectSolutionTypes.isLoading}>
          {projectSolutionTypes.data?.map((pst) => {
            return (
              <Select.Option key={pst.id || -1} value={pst.id || -1} title={pst.name}>
                {pst.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Project Phase" name={['projectPhase']} rules={[{ required: true, message: 'Required' }]}>
        <Select loading={projectPhases.isLoading}>
          {projectPhases.data?.map((phase) => {
            return (
              <Select.Option key={phase.id || -1} value={phase.id || -1} title={phase.name}>
                {phase.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <ReferenceProjectFormItem />
      <Form.Item
        name="isArchived"
        label="Is Archived"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
    </Form>
  );
};
