import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BundleRelease, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateProjectSoftwareAppVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareAppVersion, any, [string, string, SoftwareAppVersion], SoftwareAppVersion[]>(([projectId, softwareAppId, softwareAppVersion]) => {
    return new Promise<SoftwareAppVersion>((resolve, reject) => {
      backend
        .updateProjectAppVersion(projectId, softwareAppId, softwareAppVersion)
        .then((swa) => {
          queryClient.invalidateQueries(['softwareappVersions', projectId, softwareAppId]);
          queryClient.invalidateQueries(['softwareapp', projectId, softwareAppId]);
          queryClient.invalidateQueries(['componentVersion', 'app', 'project',  softwareAppId.toString(), softwareAppVersion.idSoftwareAppVersion.toString()]);

          // hard reset to force update
          queryClient.resetQueries([
            'projectSoftwareAppVersionVulnerabilities',
            projectId,
            softwareAppId.toString(),
            softwareAppVersion.idSoftwareAppVersion.toString()
          ]);

          // Hack to make sure the Project Apps list is updated and rerendered
          // Required since latest versions of tool might be affected
          queryClient.invalidateQueries(['softwareapps', projectId]);

          // Invalidate bundle config versions also since they contain the sw app releases
          const queries = queryClient.getQueriesData<BundleRelease>(['bundleConfigurationVersion', projectId]) ?? [];
          const toInvalidate = queries.filter((q) => q[1]?.projectSoftwareApps?.some((a) => a.idSoftwareApp.toString() === softwareAppId));

          toInvalidate.forEach((q) => queryClient.invalidateQueries([...q[0]]));

          presentSuccess(`Successfully updated Version ${softwareAppVersion.version}`);
          return resolve(swa);
        })
        .catch(reject);
    });
  });
}
