import { Table, Skeleton, Tag, Card } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { useTableSearch } from '../../shared/components/TableSearch';
import { useDeleteUser } from '../hooks/useDeleteUser';
import { useUserRoles } from '../hooks/useUserRoles';
import { UserRolesDropdown } from './UserRolesDropdown';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useUsersWithRoles } from '../hooks/useUsersWithRoles';
import { UserStats } from './UserStats';
import { Comparator } from '../../../domain/extensions/comparison';
import { User, UserRole, UserWithRoles } from '../../../api/user/domain/users';
import { useDeleteNotificationUser } from '../../notifications/hooks/useDeleteNotificationUser';
import { usePermissions } from '../../session/hooks/usePermissions';
import { formatDateTime, useTableFilter } from '../../../contexts/shared/components';
import { ColumnType } from 'antd/es/table';

const rolesSearchParamName = 'roles';
export const INITIAL_FILTER_NONE_SEARCH_PARAM = `${rolesSearchParamName}=none`;

export const UserAdministration = () => {
  const usersWithRoles = useUsersWithRoles();
  const userRoles = useUserRoles();
  const deleteUser = useDeleteUser();
  const deleteNotificationUser = useDeleteNotificationUser();
  const search = useTableSearch({ searchValueProvider: 'name', searchParamId: 'name' });
  const gidSearch = useTableSearch({ searchValueProvider: 'gid', searchParamId: 'gid' });
  const idSearch = useTableSearch({ searchValueProvider: 'id', searchParamId: 'id' });
  const queryClient = useQueryClient();

  const permissions = usePermissions();

  const nameSorter = (a: User, b: User) => Comparator.lexicographicalComparison(a.name, b.name);
  const gidSorter = (a: User, b: User) => Comparator.lexicographicalComparison(a.gid || '', b.gid || '');
  const lastLoginSorter = (a: User, b: User) => new Date(a.lastLogin ?? '0').getTime() - new Date(b.lastLogin ?? '0').getTime();
  const roleSorter = (a: User, b: User) => {
    const aRoles = (queryClient.getQueryData<UserRole[]>(['userRoles', a.id!.toString()]) || [])
      .map((ur) => ur.name)
      .sort()
      .join('');
    const bRoles = (queryClient.getQueryData<UserRole[]>(['userRoles', b.id!.toString()]) || [])
      .map((ur) => ur.name)
      .sort()
      .join('');
    return Comparator.lexicographicalComparison(aRoles, bRoles);
  };

  const userRoleFilter = useMemo(
    () =>
      userRoles.data?.map((o) => {
        return { text: o.name, value: o.id.toString() };
      }) || [],
    [userRoles.data]
  );

  const roleFilter = useTableFilter<UserWithRoles>({
    key: 'roles',
    onFilter: (data, val) => {
      if (val === 'none') return data.roles.length === 0;
      return data.roles.map((r) => r.id.toString()).includes(val);
    },
    values: [
      ...userRoleFilter,
      {
        text: 'No Roles',
        value: 'none'
      }
    ],
    searchParamId: rolesSearchParamName
  });

  const columns: ColumnType<UserWithRoles>[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: ['id'],
      width: 40,
      fixed: 'left',
      ...idSearch,
      sorter: nameSorter
    },
    {
      title: 'Gid',
      key: 'gid',
      width: 60,
      fixed: 'left',
      sorter: gidSorter,
      ...gidSearch,
      render: (u: User) => (
        <>
          {u.gid && u.gid}
          {!u.gid && (
            <Tag color="warning" icon={<CloseCircleOutlined />}>
              missing
            </Tag>
          )}
        </>
      )
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: ['name'],
      width: 300,
      fixed: 'left',
      ...search,
      sorter: nameSorter
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: ['email'],
      width: 300
    },
    {
      title: 'Last Login',
      key: 'lastLogin',
      width: 300,
      sorter: lastLoginSorter,
      render: (u: User) => {
        return u.lastLogin ? formatDateTime(new Date(u.lastLogin)) : 'N/A';
      }
    },
    {
      title: 'Roles',
      key: 'roles',
      width: 300,
      sorter: roleSorter,
      ...roleFilter,
      render: (u: User) => {
        return <UserRolesDropdown user={u} />;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 55,
      render: (u: User) => {
        return (
          <ExpandableMenu id={`user-administration-${u.id}`}>
            <ConfirmationButton danger caption="Delete User" key="deleteuser" onOk={() => deleteUser.mutate([u])} />
            {permissions.notificationSvc$userDeleteRequest && (
              <ConfirmationButton
                danger
                loading={deleteNotificationUser.isLoading}
                caption="Delete Notification User"
                key="deleteNotificationUser"
                onOk={() => deleteNotificationUser.mutate(u.id.toString())}
              />
            )}
          </ExpandableMenu>
        );
      }
    }
  ];

  const table = (
    <Card bordered={false} size="small">
      <Table
        size="small"
        scroll={{ x: true }}
        columns={columns}
        rowKey={(record: UserWithRoles) => record.id?.toString() || ''}
        dataSource={usersWithRoles.data}
        pagination={{ showSizeChanger: true }}
      />
    </Card>
  );

  return (
    <>
      {usersWithRoles.isLoading && <Skeleton active />}
      {usersWithRoles.isSuccess && (
        <div>
          <UserStats usersWithRoles={usersWithRoles.data} />
          {table}
        </div>
      )}
    </>
  );
};
