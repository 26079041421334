import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { Device, DeviceCreate } from '../../../api';

export function useCreateDevice(projectId: number, envId: string) {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<DeviceCreate, any, [Device]>(([device]) => {
    return new Promise<DeviceCreate>((resolve, reject) => {
      backend
        .createDevice(projectId, envId, device)
        .then((dev) => {
          presentSuccess(`Successfully created Device ${device.rds}`);
          queryClient.invalidateQueries(['device', projectId, envId]);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
