import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectPhase, ProjectPhaseCreate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useCreateProjectPhase() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectPhase, any, [ProjectPhaseCreate]>(([create]) => {
    return new Promise<ProjectPhase>((resolve, reject) => {
      backend
        .createProjectPhase(create)
        .then((dev) => {
          presentSuccess(`Successfully created Project Phase ${create.name}`);
          queryClient.invalidateQueries(['projectPhases']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
