import { Typography } from 'antd';
import React from 'react';

export const PrimaryHeader = (props: { children: React.ReactNode }) => {
  return (
    <Typography.Title level={5} style={{ margin: 0 }}>
      {props.children}
    </Typography.Title>
  );
};
