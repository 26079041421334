import { groupBy } from 'lodash';
import { DeviceAction, SoftwareComponent, SoftwareComponentDeployment, Device } from '../../../api';
import { ComponentForDevices } from './deploymentTypes';

const getAffectedSoftwareComponentVersionIdentifier = (component: SoftwareComponent) => {
  return `${component.id}-${component.versionId}-${component.type}-${component.scope}`;
};

export const getLatestInstallations = (actions: DeviceAction[]): DeviceAction[] => {
  return actions.filter((a) => !!a.latest);
};

export const getLatestInstallationsForDeployment = (deployment: SoftwareComponentDeployment): DeviceAction[] => {
  return getLatestInstallations(deployment.device.actionLog);
};

export const getLatestComponentsForDeployment = (deployment: SoftwareComponentDeployment): SoftwareComponent[] => {
  const newest = getLatestInstallationsForDeployment(deployment).map((a) => a!.affectedSoftwareComponent);
  return newest;
};

export const getCurrentInstallationActionForComponent = (component: SoftwareComponent, deployment: SoftwareComponentDeployment): DeviceAction | undefined => {
  const newest = getLatestInstallationsForDeployment(deployment);
  const componentId = getAffectedSoftwareComponentVersionIdentifier(component);
  return newest.find((l) => getAffectedSoftwareComponentVersionIdentifier(l.affectedSoftwareComponent) === componentId);
};

export const getCurrentInstallationActionForComponentOnDevice = (component: SoftwareComponent, device: Device): DeviceAction | undefined => {
  const newest = getLatestInstallations(device.actionLog);
  const componentId = getAffectedSoftwareComponentVersionIdentifier(component);
  return newest.find((l) => getAffectedSoftwareComponentVersionIdentifier(l.affectedSoftwareComponent) === componentId);
};

export const componentIsPartOfList = (component: SoftwareComponent, list: SoftwareComponent[]) => {
  return list.map(getAffectedSoftwareComponentVersionIdentifier).includes(getAffectedSoftwareComponentVersionIdentifier(component));
};

export const groupDeploymentsByComponent = (input: SoftwareComponentDeployment[]): ComponentForDevices[] => {
  const expanded = input
    .map((i) => {
      return i.components.map((c) => {
        return {
          component: c,
          device: i.device,
          isInstalled: componentIsPartOfList(
            c,
            getLatestInstallations(i.device.actionLog).map((a) => a.affectedSoftwareComponent)
          )
        };
      });
    })
    .flat();

  const groupedByComponent = groupBy(expanded, (i) => getAffectedSoftwareComponentVersionIdentifier(i.component));
  const ret = Object.keys(groupedByComponent).map((componentKey) => {
    const component = groupedByComponent[componentKey][0].component;
    const targets = groupedByComponent[componentKey].map((d) => ({ device: d.device, isInstalled: d.isInstalled }));
    return {
      identifier: componentKey,
      targets,
      component,
      allInstalled: targets.every((t) => t.isInstalled)
    };
  });
  return ret;
};
