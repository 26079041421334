import { useQuery } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { usePermissions } from '../../session';
import { DeploymentPlanMeta } from '../../../api';

export const useDeploymentPlans = (projectId: number, envId: string) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({ projectId: projectId.toString(), environmentId: envId });
  return useQuery(
    ['deploymentplanMetas', projectId, envId],
    (): Promise<DeploymentPlanMeta[]> => {
      return backend.queryDeploymentPlans(projectId, envId);
    },
    {
      //  TODO
      enabled: permissions.deploymentSvc$getDeploymentPlans && !!envId,
      staleTime: 60 * 1000
    }
  );
};
