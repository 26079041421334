import { Tooltip } from 'antd';

import { Device } from '../../../api';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useDeleteDevice } from '../hooks/useDeleteDevice';


export const DeleteDeviceButton = (props: { device: Device; projectId: number; envId: string }) => {
  const deleteDevice = useDeleteDevice();
  return (
    <Tooltip title="Delete">
      <ConfirmationButton
        loading={deleteDevice.isLoading}
        onOk={() => deleteDevice.mutate([{ id: props.device.id, mvccId: props.device.mvccId, rds: props.device.rds }, props.projectId, props.envId])}
        buttonType='regular'
      /> 
    </Tooltip>
  );
};

export default DeleteDeviceButton;
