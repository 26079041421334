import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useInAppNavigate } from '../hooks/useInAppNavigate';
import { usePactsReferrer } from '../hooks/usePactsReferrer';
import { Project } from '../../../api';
import { useMemo } from 'react';

interface BackToProjectOrEngineeringPlatformButtonProps {
  project?: Project | null;
  isLoading: boolean;
}

export const BackToProjectOrEngineeringPlatformButton: React.FC<BackToProjectOrEngineeringPlatformButtonProps> = (props) => {
  const { isLoading, project } = props;

  const navigate = useInAppNavigate();
  const pactsRef = usePactsReferrer();
  const projectName = project?.name || null;
  const projectId = project?.idProject || null;

  const onClick = () => {
    if (pactsRef) return navigate(-1);
    if (projectId != null) return navigate(`/projects/${projectId}/tools`, { replace: true });
    navigate('/tools', { replace: true });
  };

  const label = useMemo(() => {
    if (isLoading) {
      return 'Loading';
    }

    if (projectName != null) {
      return 'Back to project';
    }

    return 'Back to Platform';
  }, [projectName, isLoading]);

  return (
    <Button loading={isLoading} icon={<ArrowLeftOutlined />} onClick={onClick}>
      {label}
    </Button>
  );
};
