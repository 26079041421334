import { Typography } from 'antd';
import styled from 'styled-components';
import { AnimatedLoadingIcon } from '../shared/components/icons/AnimatedLoadingIcon';

type ContentType = 'text' | 'link';

interface CopyableProps {
  content?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
  disableCopy?: boolean;
  contentType?: ContentType;
}

const LoadingIcon = () => <AnimatedLoadingIcon />;

const Wrapper = styled.div`
  margin: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.colorFillAlter};
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.colorBorder};
  border-style: dashed;
`;

const Text = styled(Typography.Text)`
  font-size: 18px;
`;

const Link = styled(Typography.Link)`
  font-size: 18px;
`;

export const Copyable: React.FC<CopyableProps> = ({ style, content, contentType = 'text', disableCopy = false, isLoading = false }) => {
  const contentByType = {
    text: (
      <Text ellipsis={(content?.length ?? 0) > 32} copyable={!disableCopy}>
        {content}
      </Text>
    ),
    link: (
      <Link target="_blank" href={content} ellipsis={(content?.length ?? 0) > 32}>
        {content}
      </Link>
    )
  };

  return <Wrapper style={style}>{isLoading ? <LoadingIcon /> : contentByType[contentType]}</Wrapper>;
};
