import { SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../api';
import { useState } from 'react';
import { useArtifactoryLinkPreFlight } from '../hooks/useArtifactoryLinkPreFlightCheck';
import { Button, ButtonProps } from 'antd';
import { DownloadDialog } from './DownloadDialog';
import { useDownloadPermissions } from '../hooks/useDownloadPermission';

export const DownloadButton = (
  props: { artifact: Tool | SoftwareApp | null; version: ToolVersion | SoftwareAppVersion | null; projectId?: number } & ButtonProps
) => {
  const [visible, setVisible] = useState(false);
  const canDownload = useArtifactoryLinkPreFlight(props.version);

  const hasPermission = useDownloadPermissions(props.artifact, props.version, props.projectId);

  if (!hasPermission) return null;

  return (
    <>
      <DownloadDialog artifact={props.artifact} version={props.version} visible={visible} onClose={() => setVisible(false)} projectId={props.projectId} />
      <Button
        {...props}
        disabled={!canDownload || props.download}
        onClick={(e) => {
          setVisible(true);
          props.onClick?.(e);
        }}
      >
        {props.children}
      </Button>
    </>
  );
};
