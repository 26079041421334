import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useDeleteProjectSoftwareAppVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [string, string, string]>(([projectId, softwareAppId, versionId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteProjectSoftwareAppVersion(projectId, softwareAppId, versionId)
        .then(() => {
          queryClient.invalidateQueries(['softwareappVersions', projectId, softwareAppId]);
          presentSuccess('Successfully deleted Software App Version');
          return resolve();
        })
        .catch(reject);
    });
  });
}
