import { Card, Row, Col, Typography, Result, Button } from 'antd';
import React from 'react';
import { useStyles } from '../../../theme/useStyles';

export const EntityAdministrationEditLayout = (props: {
  titlePrefix: string;
  titleElement: string;
  form: React.ReactNode;
  submit: React.ReactNode;
  delete: React.ReactNode;
  state: 'creating' | 'editing' | 'idle';
  onCancel: () => void;
  isTouched: boolean;
}) => {
  const showForm = props.state !== 'idle';
  const token = useStyles();
  return (
    <Row style={{ minHeight: '100%' }}>
      <Col flex="auto" style={{ display: 'flex', minHeight: '100%' }}>
        {showForm && (
          <Card
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            bodyStyle={{ flexGrow: '1' }}
            actions={[
              <Row gutter={[16, 16]} justify="center">
                <Col>{props.submit}</Col>
                <Col>
                  <Button
                    disabled={!props.isTouched && props.state === 'editing'}
                    onClick={() => {
                      props.onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            ]}
          >
            <Row style={{ width: '100%', height: '100%' }}>
              <Col flex="auto">
                <Row>
                  <Col span={24}>
                    <Typography.Title level={4}>
                      {props.titlePrefix}
                      {' - '}
                      <span style={{ color: props.state === 'creating' ? token.colorWarning : token.colorPrimaryText }}>{props.titleElement}</span>
                    </Typography.Title>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>{props.form}</Col>
                </Row>
              </Col>
              <Col flex="128px">{props.delete}</Col>
            </Row>
          </Card>
        )}
        {!showForm && (
          <Col span={24}>
            <Result title="Nothing selected" subTitle="Select entity from list or add new." />
          </Col>
        )}
      </Col>
    </Row>
  );
};
