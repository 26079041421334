import { Col, Row, Typography } from 'antd';
import { DUMMY_CHECKSUM } from './Download/constants';

type Sha256Props = { checksums: { sha256?: string; target?: string }[] };

export const Sha256 = ({ checksums }: Sha256Props) => {
  const validChecksums = checksums.filter((c) => !!c.sha256).filter((c) => c.sha256 !== DUMMY_CHECKSUM);
  const showTarget = validChecksums.length > 1;

  return validChecksums.length > 0 ? (
    <Row>
      {validChecksums.map((c, idx) => {
        const targetString = `${!!c.target && showTarget ? ` ${c.target}` : ''}`;
        return (
          <Col key={idx} span={24}>
            <Typography.Text strong>{`SHA256${targetString}:`}</Typography.Text>
            <Typography.Text key={idx} copyable={{ text: c.sha256 }}>{` ${c.sha256}`}</Typography.Text>
          </Col>
        );
      })}
    </Row>
  ) : null;
};
