import { LinkOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Rate, Row, Space, Typography } from 'antd';
import { useState } from 'react';
import { useIsDevVersion } from '../../navigation/hooks/useIsDevVersion';
import { useCurrentUser } from '../../session';
import { Emoji } from '../../shared/components/Emoji';
import { useSubmitUserSatisfaction } from '../hooks/useSubmitUserSatisfaction';
import { USER_SATISFACTION_DEV, USER_SATISFACTION_PROD } from '../../../constants';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { InfoTooltip } from '../../shared/components/InfoTooltip';

export type RatingProps = {
  showModal: boolean;
  onFinish: (showRating: boolean) => void;
};

export const UserSatisfaction = (props: RatingProps) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [anonymous, setAnonymous] = useState(false);
  const submit = useSubmitUserSatisfaction();
  const currentUser = useCurrentUser();
  const isDev = useIsDevVersion();

  const close = () => {
    setFeedback('');
    setRating(0);
    props.onFinish(false);
  };
  return (
    <ScrollDialog width="40%" centered footer={null} closable={false} open={props.showModal}>
      <Card
        size="small"
        bordered={false}
        style={{ background: 'transparent' }}
        title={
          <Row justify="center">
            <Space align="baseline">
              <Typography.Title level={5}> We are looking forward to your feedback</Typography.Title>
              <Emoji symbol="❤️" label="heart" />
            </Space>
          </Row>
        }
      >
        <DescriptionTextAreaInput placeholder="I think PacTS... (optional)" value={feedback} onChange={(v) => setFeedback(v.target.value)} />
        <Space direction="vertical" style={{ width: '100%', marginTop: '15px' }}>
          <Typography.Title level={5}> Give us a rating</Typography.Title>
          <Rate
            value={rating}
            allowClear={false}
            onChange={(value) => {
              setRating(value);
              props.onFinish(true);
            }}
          />
        </Space>
        <Space direction="vertical" style={{ width: '100%', marginTop: '15px' }}>
          <Row>
            <Checkbox
              style={{ marginTop: 8 }}
              checked={anonymous}
              onChange={() => {
                setAnonymous(!anonymous);
              }}
            >
              <Space>
                <Typography.Text>
                  Anonymous
                </Typography.Text>
                <InfoTooltip
                  text="If 'Anonymous' is not checked, your email address is included in the feedback."
                />
              </Space>
            </Checkbox>
          </Row>
          <Row justify="end" style={{ marginTop: 8 }}>
            <Col flex="*">
              <Button
                icon={<LinkOutlined />}
                block
                onClick={() => {
                  window.open(isDev ? USER_SATISFACTION_DEV : USER_SATISFACTION_PROD);
                }}
              >
                Go To Results
              </Button>
            </Col>
            <Col flex="auto" />
            <Col>
              <Space>
                <Button
                  block
                  disabled={submit.isLoading}
                  onClick={() => {
                    close();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  block
                  loading={submit.isLoading}
                  onClick={() => {
                    const user = anonymous ? 'anonymous' : currentUser.email ?? 'unknown email';
                    submit
                      .mutateAsync({
                        feedback,
                        rating,
                        user
                      })
                      .then(() => {
                        close();
                      })
                      .catch(() => {});
                  }}
                >
                  Send
                </Button>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    </ScrollDialog>
  );
};
