import { PApp } from '../pluginInterface';

const name = 'AIS Force';
const description = 'AIS Force Calculations';
const route = 'ais-force';

const aisForceConfig: PApp = {
  name: name,
  description: description,
  route: route
};

export { aisForceConfig };
