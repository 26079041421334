import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Project } from '../../../api/engineering/domain/types';

export function useDeleteProject() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [Project]>(async ([project]) => {
    // to delete a project, the bundles have to be deleted first
    // since bundles are not modelled as distinct entities on the UI
    // we implicitly delete them while deleting the project
    const bundles = await backend.queryBundles(project.idProject.toString());
    await Promise.all(bundles.map((bundle) => backend.deleteBundle(project.idProject, bundle.idBundle)));

    await backend.deleteProject(project.idProject.toString());
    presentSuccess('Successfully deleted Project');

    await queryClient.invalidateQueries(['projects']);
  });
}
