import { useQuery } from '@tanstack/react-query';
import { SoftwareApp, SoftwareAppVersion, SoftwareComponent, Tool, ToolVersion, useEngineeringBackend } from '../../../api';
import { usePermissions } from '../../../contexts/session';

type Ret = {
  component: Tool | SoftwareApp;
  version: ToolVersion | SoftwareAppVersion;
};

export const useComponentVersion = (projectId: number, component: SoftwareComponent, enabled: boolean) => {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({
    projectId: projectId.toString(),
    toolId: component.id.toString(),
    softwareAppId: component.id.toString(),
    idToolVersion: component.versionId.toString(),
    idSoftwareAppVersion: component.versionId.toString()
  });

  const isProjectApp = component.scope === 'project' && component.type === 'app';
  const isCommonApp = component.scope === 'common' && component.type === 'app';
  const isCommonTool = component.scope === 'common' && component.type === 'tool';

  let hasPermission = false;

  if (isProjectApp) {
    hasPermission = permissions.engineeringSvc$getProjectSoftwareApp && permissions.engineeringSvc$getProjectSoftwareAppVersion;
  } else if (isCommonApp) {
    hasPermission = permissions.engineeringSvc$getCommonSoftwareApp && permissions.engineeringSvc$getCommonSoftwareAppVersion;
  } else if (isCommonTool) {
    hasPermission = permissions.engineeringSvc$getTool && permissions.engineeringSvc$getToolVersion;
  }

  const query = useQuery<Ret>(
    ['componentVersion', component.type, component.scope, component.id, component.versionId],
    async () => {
      let retComponent: Tool | SoftwareApp | undefined;
      let retVersion: ToolVersion | SoftwareAppVersion | undefined;

      const hasPermissions =
        (isProjectApp &&
          permissions.engineeringSvc$getProjectSoftwareAppVersionTargetDownload &&
          permissions.engineeringSvc$headProjectSoftwareAppVersionTargetDownload) ||
        (isCommonApp &&
          permissions.engineeringSvc$getCommonSoftwareAppVersionTargetDownload &&
          permissions.engineeringSvc$headCommonSoftwareAppVersionTargetDownload) ||
        (isCommonTool && permissions.engineeringSvc$getToolVersionDownload && permissions.engineeringSvc$headToolVersionDownload);

      if (!hasPermissions) {
        throw new Error('missing permissions');
      }

      if (isProjectApp) {
        [retComponent, retVersion] = await Promise.all([
          backend.queryProjectApp(projectId.toString(), component.id.toString()),
          backend.queryProjectAppVersion(projectId, component.id, component.versionId)
        ]);
      }
      if (isCommonApp) {
        [retComponent, retVersion] = await Promise.all([
          backend.queryCommonApp(component.id.toString()),
          backend.queryCommonAppVersion(component.id, component.versionId)
        ]);
      }
      if (isCommonTool) {
        [retComponent, retVersion] = await Promise.all([
          backend.queryTool(component.id.toString()),
          backend.queryToolVersion(component.id, component.versionId)
        ]);
      }
      if (!retComponent || !retVersion) {
        throw new Error('illegal parameters');
      }

      return {
        component: retComponent,
        version: retVersion
      };
    },
    {
      refetchInterval: (data) => {
        const hasInconsistent = !!data?.version && data.version.state !== 'consistent';
        return hasInconsistent ? 5000 : false;
      },
      enabled: hasPermission && enabled
    }
  );

  return { query, hasPermission };
};
