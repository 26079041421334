import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectRoleWithPermissions } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateProjectRole() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectRoleWithPermissions, any, [ProjectRoleWithPermissions]>(([role]) => {
    return new Promise<ProjectRoleWithPermissions>((resolve, reject) => {
      backend
        .updateProjectRole(role)
        .then((newRoles) => {
          presentSuccess(`Successfully updated Project Role ${role.name}`);
          queryClient.invalidateQueries(['projectRoles']);
          return resolve(newRoles);
        })
        .catch(reject);
    });
  });
}

export default useUpdateProjectRole;
