import { useState, useEffect } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useCreateBundleConfiguration } from '../hooks/useCreateBundleConfiguration';
import { BundleConfiguration } from '../../../api/engineering/domain/types';
import { DescriptionValidator, NameValidator } from '../../../domain';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';

export type AddBundleConfigurationProps = {
  projectId: string;
  bundleId: string;
  disabled?: boolean;
  onAddBundleConfiguration?: (bundleConfiguration: BundleConfiguration) => any;
};

const initialBundleConfig: BundleConfiguration = {
  idBundleConfiguration: 0,
  name: '',
  description: '',
  latestBundleReleaseId: -1
};

export const AddBundleConfiguration = (props: AddBundleConfigurationProps) => {
  const permissions = usePermissions({ projectId: props.projectId });
  const [modalVisible, setModalVisible] = useState(false);
  const createBundleConfiguration = useCreateBundleConfiguration();
  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createBundleConfiguration.isSuccess) {
      setModalVisible(false);
    }
  }, [createBundleConfiguration.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };
  return (
    <>
      {permissions.engineeringSvc$addProjectBundleConfiguration && (
        <Tooltip title="Add Bundle Configuration">
          <ExpandableMenuItem
            disabled={props.disabled}
            icon={<PlusOutlined />}
            loading={createBundleConfiguration.isLoading}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Add Bundle Configuration
          </ExpandableMenuItem>
        </Tooltip>
      )}
      <ScrollDialog
        destroyOnClose
        title="Add Bundle Configuration"
        confirmLoading={createBundleConfiguration.isLoading}
        open={modalVisible}
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          initialValues={() => {
            return { ...initialBundleConfig };
          }}
          onFinish={(value) => {
            const copy = Object.assign(initialBundleConfig, value);
            createBundleConfiguration.mutateAsync([props.projectId, props.bundleId, copy]).then((bc: any) => {
              if (props.onAddBundleConfiguration && bc) {
                props.onAddBundleConfiguration(bc);
              }
            });
          }}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[DescriptionValidator.rule()]}>
            <DescriptionTextAreaInput />
          </Form.Item>
        </Form>
      </ScrollDialog>
    </>
  );
};
