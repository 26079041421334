import { useEffect } from 'react';
import { useMetricsBackend, useSetInitialMetricsBackend } from './useMetricsBackend';
import { useRestMetricsBackend } from './useRestMetricsBackend';
import { GlobalState } from '../../../state/globalState';

export const useRestMetricsBackendProvider = (state: GlobalState) => {
  const backend = useRestMetricsBackend(state);
  useSetInitialMetricsBackend(backend);
  const { setBackend } = useMetricsBackend();
  useEffect(() => {
    setBackend(backend);
  }, [backend, setBackend]);
};
