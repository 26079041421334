import { useInfiniteQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ArtifactResponse, usePactsFormationBackend } from '../../../api';
import { useEffect } from 'react';

export function useArtifacts() {
  const permissions = usePermissions();
  const { backend } = usePactsFormationBackend();

  return useInfiniteQuery<ArtifactResponse>(['artifacts'], ({ pageParam = undefined }) => backend.getAllArtifactVersions(pageParam), {
    enabled: permissions.pactsFormationSvc$unrestrictedAdministration,
    getNextPageParam: (p) => {
      return p.nextPaginationToken;
    }
  });
}

export const useAllArtifacts = () => {
  const artifacts = useArtifacts();
  useEffect(() => {
    if (artifacts.hasNextPage && !artifacts.isFetchingNextPage) {
      artifacts.fetchNextPage();
    }
  }, [artifacts]);

  const sourceData = artifacts.data?.pages.map((p) => p.versions).flat();
  return {
    ...artifacts,
    data: sourceData
  };
};
