import { Alert } from 'antd';

const DeploymentPlanFormAlert: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Alert
      style={{ marginBottom: '20px', position: 'absolute', top: '110px', width: 'calc(100% - 48px)', zIndex: 5 }}
      message={message}
      type="error"
      showIcon
    />
  );
};

export default DeploymentPlanFormAlert;
