import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BundleRelease, Tool, ToolVersion } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateEngineeringToolVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ToolVersion, any, [Tool, ToolVersion]>(([tool, toolVersion]) => {
    return new Promise<ToolVersion>((resolve, reject) => {
      backend
        .updateToolVersion(tool.id.toString(), toolVersion)
        .then((updatedTool) => {
          queryClient.invalidateQueries(['engineeringToolVersions', tool.id.toString()]);
          queryClient.invalidateQueries(['engineeringTool', tool.id.toString()]);
          queryClient.invalidateQueries(['componentVersion', 'tool', 'common',  tool.id.toString(), toolVersion.idToolVersion.toString()]);

          // hard reset vulnerability query data to force reloading
          queryClient.resetQueries(['toolAppVersionVulnerabilities', tool.id.toString(), toolVersion.idToolVersion.toString()]);

          // Hack to make sure the Tools list is updated and rerendered
          // Required since latest versions of tool might be affected
          queryClient.invalidateQueries(['engineeringTools']);

          // Invalidate bundle config versions also since they contain the sw app releases
          const queries = queryClient.getQueriesData<BundleRelease>(['bundleConfigurationVersion']) ?? [];
          const toInvalidate = queries.filter((q) => q[1]?.engineeringTools?.some((t) => t.id === tool.id));
          toInvalidate.forEach((q) => queryClient.invalidateQueries(q[0]));

          presentSuccess(`Successfully updated Version ${toolVersion.version}`);
          return resolve(updatedTool);
        })
        .catch(reject);
    });
  });
}
