import { Checkbox, Divider, Input, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Fragment } from 'react';

export type Value = {
  text: string;
  id: string;
  group: string;
  idHash: string;
};

type ValueGroup = {
  group: string;
  values: Value[];
};

interface ColumnsContentProps {
  isSelectAllActive: boolean;
  filterSearch: string;
  selectedKeys: string[];
  values: Value[];
  valueGroups: ValueGroup[];
  onSetFilterSearch: (value: string) => void;
  children: React.ReactNode;
  onCheckboxChange: (e: CheckboxChangeEvent, val: Value) => void;
  onSelectAllChange: (e: CheckboxChangeEvent) => void;
}

const ColumnsContent: React.FC<ColumnsContentProps> = (props) => {
  const { isSelectAllActive, filterSearch, values, valueGroups, selectedKeys, onSetFilterSearch, onCheckboxChange, onSelectAllChange } = props;

  return (
    <Space direction="vertical" style={{ width: '100%', minWidth: '400px' }}>
      {values.length > 10 && <Input value={filterSearch} onChange={(e) => onSetFilterSearch(e.target.value)} placeholder="Search" />}
      <Checkbox style={{ marginTop: '10px' }} checked={isSelectAllActive} onChange={(e) => onSelectAllChange(e)}>
        <Typography.Text strong>Select All</Typography.Text>
      </Checkbox>
      <Space direction="vertical" style={{ maxHeight: 'calc(100vh - 460px)', width: '100%', overflowY: 'auto', paddingTop: 4 }}>
        {valueGroups.map((vg) => {
          const vgFiltered = vg.values.map((v) => ({ ...v, show: filterSearch === '' || v.text.toLowerCase().includes(filterSearch.toLowerCase()) }));
          const hasVisibleValues = vgFiltered.some((v) => v.show);
          return (
            <Fragment key={vg.group}>
              {vg.group && hasVisibleValues ? (
                <Divider key={`spacer-${vg.group}`} orientation="left">
                  {vg.group}
                </Divider>
              ) : null}
              {vgFiltered
                .filter((v) => v.show)
                .map((val) => {
                  const checked = selectedKeys.includes(val.id);
                  return (
                    <Checkbox disabled={selectedKeys.length < 2 && checked} key={val.id} checked={checked} onChange={(e) => onCheckboxChange(e, val)}>
                      {val.text}
                    </Checkbox>
                  );
                })}
            </Fragment>
          );
        })}
      </Space>
      {props.children}
    </Space>
  );
};

export default ColumnsContent;
