import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectType } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

type UsedType = ProjectType;
export function useUpdateProjectType() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<UsedType, any, [UsedType]>(([update]) => {
    return new Promise<UsedType>((resolve, reject) => {
      backend
        .updateProjectType(update)
        .then((dev) => {
          presentSuccess(`Successfully updated Project Type ${update.name}`);
          queryClient.invalidateQueries(['projectTypes']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
