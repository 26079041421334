import { useEffect } from 'react';
import { usePactsFormationBackend, useSetInitialPactsFormationBackend } from './usePactsFormationBackend';
import { useWebsocketPactsFormationBackend } from './useWebsocketPactsFormationBackend';

export const useWebsocketPactsFormationBackendProvider = () => {
  const backend = useWebsocketPactsFormationBackend();
  useSetInitialPactsFormationBackend(backend);
  const { setBackend } = usePactsFormationBackend();
  useEffect(() => {
    setBackend(backend);
  }, [backend, setBackend]);
};
