import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Skeleton, Space, TableColumnType, Typography } from 'antd';
import { StyledModal, useTableFilter, useTableSearch } from '../../../contexts/shared/components';
import { Comparator } from '../../../domain';
import Table from '../../../contexts/shared/components/Table/Table';
import { ProjectResource, ResourceError } from '../../../api';
import styled from 'styled-components';
import { useAllProjectsResources } from '../hooks/useProjectsResources';
import { valueFromObjectPath } from '../../../contexts/reports';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import ProjectDetails from './ProjectDetails';

export interface DataType {
  key: React.Key;
  name: string;
  status: string;
}

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export const AllProjectResources = () => {
  const projectResources = useAllProjectsResources();
  const isLoading = useDebounce(projectResources.isLoading || projectResources.isFetchingNextPage, 10, true);

  const statuses: string[] = uniq(projectResources.data?.map((project) => project?.infrastructureStatus) || []);
  const [errorModal, setErrorModal] = useState<null | ResourceError>(null);
  const columns: TableColumnType<DataType>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...useTableSearch({
        searchParamId: 'name',
        searchValueProvider: valueFromObjectPath(['name'])
      })
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...useTableFilter({
        searchParamId: 'status',
        values: statuses.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const value = valueFromObjectPath<string>(['status'])(data);
          return value.includes(filterVal);
        },
        key: statuses.join('+')
      })
    }
  ];
  const data: DataType[] = useMemo(
    () =>
      projectResources.data
        ?.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
        .map((project) => {
          return {
            key: project.id,
            name: project.name,
            status: project.infrastructureStatus
          };
        }) || [],
    [projectResources.data]
  );

  return (
    <FullWidthSpace direction="vertical">
      <StyledModal title={errorModal?.summary} open={errorModal != null} destroyOnClose onCancel={() => setErrorModal(null)} onOk={() => setErrorModal(null)}>
        <Typography.Paragraph>Error Code: {errorModal?.errorCode}</Typography.Paragraph>
        <Typography.Paragraph>Internal Error code: {errorModal?.technicalDetails?.internalErrorCode}</Typography.Paragraph>
        <Typography.Paragraph>Summary: {errorModal?.technicalDetails?.description}</Typography.Paragraph>
      </StyledModal>
      <Skeleton loading={isLoading} active>
        <Table<DataType>
          columns={columns}
          dataSource={data}
          expandable={{
            rowExpandable: (project) => (project as unknown as ProjectResource).infrastructureStatus !== 'DELETED',
            expandedRowRender: (project: DataType) => <ProjectDetails onSetErrorModal={setErrorModal} project={project} />
          }}
        />
      </Skeleton>
    </FullWidthSpace>
  );
};
