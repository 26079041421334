import { QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { PacTSNotification, PacTSNotificationPage } from '../../../api/notifications/domain/types';
import { useCurrentUser } from '../../session/hooks/useCurrentUser';
import { QK_NOTIFICATIONS } from './queryKeys';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useNotificationsBackend } from '../../../api/notifications/hooks/useNotificationsBackend';
import { MarkdownRenderer } from '../../shared/components/MarkdownRenderer';
import { presentSuccess } from '../../../api';
import { invokeUiNotification } from '../../shared/hooks/userNotificationProvider';

let archiveDebounceTimeout: NodeJS.Timeout | undefined;
const notificationsToArchive: string[] = [];

const sort = (a: PacTSNotification, b: PacTSNotification): number => {
  return b.received.getTime() - a.received.getTime();
};

// Filter out double and marked for archive notifications
const filterDoubleAndArchived = (n: PacTSNotification[]): PacTSNotification[] => {
  return _.uniqBy(n, (e) => e.id).filter((e) => !notificationsToArchive.includes(e.id));
};

const upsertHandler = (queryClient: QueryClient) => (n: PacTSNotification) => {
  const stateItems = { ...(queryClient.getQueryData(QK_NOTIFICATIONS) as { pages: PacTSNotificationPage[]; pageParams: any[] }) };
  if (!queryClient.getQueryData(QK_NOTIFICATIONS)) {
    console.warn('notifications have not been fetched initially');
    return;
  }
  const existing = stateItems.pages[0].content.findIndex((u) => u.id === n.id);
  if (existing > -1) {
    stateItems.pages[0].content[existing] = n;
  } else {
    stateItems.pages[0].content.push(n);
  }
  queryClient.setQueryData(QK_NOTIFICATIONS, stateItems);
};

const invokeNotification = (n: PacTSNotification) => {
  // Do not show the notification if containing rendered
  if (!n.rendered?.title || n.rendered?.title?.trim() === '') {
    return;
  }
  invokeUiNotification({
    key: n.id,
    type: 'info',
    message: n.rendered.title,
    duration: 20,
    description: n.rendered.text
      ? MarkdownRenderer({
        children: n.rendered.text
      })
      : undefined,
    onClick: () => {
      window.location.href = `/notification/${n.id}`;
    }
  });
};

const upsertNotifyHandler = (queryClient: QueryClient) => (n: PacTSNotification) => {
  if (!n) {
    return;
  }
  const stateItems = { ...(queryClient.getQueryData(QK_NOTIFICATIONS) as { pages: PacTSNotificationPage[]; pageParams: any[] }) };
  if (!queryClient.getQueryData(QK_NOTIFICATIONS)) {
    console.warn('notifications have not been fetched initially');
    return;
  }
  const existing = stateItems.pages[0].content.findIndex((u) => u.id === n.id);
  if (existing > -1) {
    /* ignore */
  } else {
    invokeNotification(n);
  }
  upsertHandler(queryClient)(n);
};

const deleteHandler = (queryClient: QueryClient) => (notificationId: string[]) => {
  const stateItems = { ...(queryClient.getQueryData(QK_NOTIFICATIONS) as { pages: PacTSNotificationPage[]; pageParams: any[] }) };
  if (!queryClient.getQueryData(QK_NOTIFICATIONS)) {
    console.warn('notifications have not been fetched initially');
    return;
  }
  stateItems.pages.forEach((page) => {
    notificationId.forEach((id) => {
      const index = page.content.findIndex((u) => u.id === id);
      if (index > -1) {
        page.content.splice(index, 1);
      }
    });
  });
  queryClient.setQueryData(QK_NOTIFICATIONS, stateItems);
};

const useNotificationEvents = singletonHook(undefined, () => {
  const queryClient = useQueryClient();
  const { backend } = useNotificationsBackend();

  useEffect(() => {
    const upsertInstance = upsertNotifyHandler(queryClient);
    const deleteInstance = deleteHandler(queryClient);
    backend.onNotification(upsertInstance);
    backend.onNotificationArchived(deleteInstance);
    backend.onNotificationDeleted(deleteInstance);
    return () => {
      backend.onNotification(upsertInstance);
      backend.onNotificationArchived(deleteInstance);
      backend.onNotificationDeleted(deleteInstance);
    };
  }, [queryClient, backend]);
});

export const useNotifications = () => {
  const permissions = usePermissions();
  const { backend } = useNotificationsBackend();
  useNotificationEvents();
  const enabled = permissions.notificationSvc$pushNotification;
  return useInfiniteQuery<PacTSNotificationPage>(
    QK_NOTIFICATIONS,
    ({ pageParam = undefined }): Promise<PacTSNotificationPage> => {
      return new Promise<PacTSNotificationPage>((resolve, reject) => {
        const isoDate = pageParam ? (pageParam as Date) : undefined;
        const pageQuery = {
          before: isoDate
        };
        backend
          .queryNotificationsPage(pageQuery)
          .then((notifications: PacTSNotificationPage) => {
            resolve({
              content: filterDoubleAndArchived(notifications.content.sort(sort)),
              page: notifications.page
            });
          })
          .catch((err) => reject(err));
      });
    },
    {
      enabled,
      getNextPageParam: (lp) => {
        if (lp.page.count === 0) return undefined;
        if (lp.page.count >= lp.page.totalCount) return undefined;
        return lp.page.oldest;
      },
      select: (data) => {
        const joined = filterDoubleAndArchived(data.pages.map((p) => p.content).flat()).sort(sort);
        const firstPage = data.pages[0].page;
        const lastPage = data.pages[data.pages.length - 1].page;
        const singlePage: PacTSNotificationPage = {
          content: joined,
          page: {
            count: data.pages.map((p) => p.page.count).reduce((a, b) => a + b, 0),
            oldest: lastPage.oldest,
            latest: firstPage.latest,
            totalCount: lastPage.totalCount
          }
        };
        return {
          pages: [singlePage],
          pageParams: data.pageParams
        };
      }
    }
  );
};

export const useAllNotifications = () => {
  const notifications = useNotifications();
  useEffect(() => {
    if (notifications.hasNextPage && !notifications.isFetchingNextPage) {
      notifications.fetchNextPage();
    }
  }, [notifications]);

  const sourceData = notifications.data?.pages.map((p) => p.content).flat();
  return {
    ...notifications,
    data: sourceData
  };
};

export const useFilteredNotifications = (regexp?: RegExp) => {
  const notifications = useNotifications();
  useEffect(() => {
    if (notifications.hasNextPage && !notifications.isFetchingNextPage && regexp) {
      notifications.fetchNextPage();
    }
  }, [notifications, regexp]);

  const sourceData = notifications.data?.pages.map((p) => p.content).flat();
  return {
    ...notifications,
    data: regexp ? sourceData?.filter((n) => regexp.test(n.topic)) : sourceData
  };
};

export function useNotification(notificationId: string) {
  const permissions = usePermissions();
  const { backend } = useNotificationsBackend();
  const enabled = (permissions.notificationSvc$notificationsGetRequest) && notificationId !== ''; // managedWebsocket[1].isReady &&
  return useQuery<PacTSNotification, [string, string]>(
    ['notification', notificationId],
    (): Promise<PacTSNotification> => {
      return backend.queryNotification(notificationId);
    },
    {
      enabled
    }
  );
}

export const useArchiveNotification = () => {
  const { backend } = useNotificationsBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, string[]>(
    (notificationId) => {
      return backend.archiveNotifications(notificationId);
    },
    {
      onMutate: async (notificationId) => {
        await queryClient.cancelQueries(QK_NOTIFICATIONS);
        const previousStateItems = { ...(queryClient.getQueryData(QK_NOTIFICATIONS) as { pages: PacTSNotificationPage[]; pageParams: any[] }) };
        deleteHandler(queryClient)(notificationId);
        return { previousStateItems };
      },
      onError: (err, vars, ctx) => {
        queryClient.setQueryData(QK_NOTIFICATIONS, (ctx as any).previousStateItems);
      },
      onSettled: () => {
        queryClient.invalidateQueries(QK_NOTIFICATIONS);
      }
    }
  );
};

export const useArchiveNotificationDebounced = () => {
  const queryClient = useQueryClient();
  const archive = useArchiveNotification();

  return useCallback(
    (notificationId: string) => {
      deleteHandler(queryClient)([notificationId]);
      notificationsToArchive.push(notificationId);
      if (archiveDebounceTimeout) {
        clearTimeout(archiveDebounceTimeout);
        archiveDebounceTimeout = undefined;
      }
      archiveDebounceTimeout = setTimeout(() => {
        const copy = [...notificationsToArchive];
        notificationsToArchive.length = 0;
        archive.mutateAsync(copy).catch(console.error);
      }, 1000);
    },
    [queryClient, archive]
  );
};

export const useDeleteNotification = () => {
  const { backend } = useNotificationsBackend();
  // const managedWebsocket = useNotificationWebsocket();
  const queryClient = useQueryClient();
  return useMutation<any, any, string[]>(
    (notificationId) => {
      return backend.deleteNotifications(notificationId);
    },
    {
      onMutate: async (notificationId) => {
        await queryClient.cancelQueries(QK_NOTIFICATIONS);
        const previousStateItems = { ...(queryClient.getQueryData(QK_NOTIFICATIONS) as { pages: PacTSNotificationPage[]; pageParams: any[] }) };
        deleteHandler(queryClient)(notificationId);
        return { previousStateItems };
      },
      onError: (err, vars, ctx) => {
        queryClient.setQueryData(QK_NOTIFICATIONS, (ctx as any).previousStateItems);
      },
      onSettled: () => {
        queryClient.invalidateQueries(QK_NOTIFICATIONS);
      }
    }
  );
};

type SendNotificationContent = { title: string; content?: string; topic: string; expiry?: string; trigger?: { tokenRefresh?: boolean } };
export const useSendNotification = (restricted = false) => {
  const currentUser = useCurrentUser();
  const { backend } = useNotificationsBackend();
  return useMutation<any, any, SendNotificationContent>(
    (data) => {
      return backend.sendNotification(data, currentUser.name, restricted);
    },
    {
      onSettled: () => {
        presentSuccess('Successfully sent notification');
      }
    }
  );
};
