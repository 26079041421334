import { PageHeader } from '@ant-design/pro-layout';
import { Row, Col, Typography, Card } from 'antd';
import { FunctionComponent } from 'react';
import { useStyles } from '../../theme/useStyles';
import styled from 'styled-components';

type FullWidthLayoutProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  style?: any;
  extra?: React.ReactNode[];
  padding?: boolean;
  fixed?: boolean;
  transparent?: boolean;
  children: React.ReactNode;
  cardInnerHeader?: React.ReactNode;
  cardInnerHeaderExtra?: React.ReactNode;
};

const CardInnerHeaderRow = styled(Row)`
  margin-bottom: 12px;
`;

export const FullWidthLayout: FunctionComponent<FullWidthLayoutProps> = ({
  children,
  title,
  subtitle,
  extra,
  padding,
  fixed,
  style,
  transparent,
  cardInnerHeader,
  cardInnerHeaderExtra,
  ...rest
}) => {
  const pad = padding ? 16 : 0;
  const styles = useStyles();
  return (
    <Row justify="center" {...rest} style={{ marginTop: 0, paddingLeft: pad, paddingRight: pad, ...style }}>
      <Col span={24}>
        {title && (
          <PageHeader
            // style={{ paddingLeft: 0, paddingRight: 0 }}
            style={{
              position: fixed ? 'sticky' : 'initial',
              top: fixed ? 64 : 0,
              zIndex: 4,
              paddingTop: 32,
              backgroundColor: transparent ? undefined : styles.colorBgLayout
            }}
            className="site-page-header"
            title={
              <Typography.Title level={4} style={{ margin: 0, marginBottom: 4 }} ellipsis>
                {title}
              </Typography.Title>
            }
            subTitle={<Typography.Text type="secondary">{subtitle}</Typography.Text>}
            extra={extra}
          />
        )}
        <Card size="small">
          {cardInnerHeader || cardInnerHeaderExtra ? (
            <CardInnerHeaderRow gutter={[8, 16]}>
              <Col flex="auto">{cardInnerHeader}</Col>
              <Col flex="none">{cardInnerHeaderExtra}</Col>
            </CardInnerHeaderRow>
          ) : null}
          {children}
        </Card>
      </Col>
    </Row>
  );
};
