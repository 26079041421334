import { DeleteOutlined, InfoOutlined } from '@ant-design/icons';
import { Space, Typography, Button, Popover } from 'antd';
import { PacTSNotification } from '../../../api/notifications/domain/types';
import { useDeleteNotification } from '../hooks/useNotifications';
import ReactJson from 'react-json-view';
import { usePermissions } from '../../session/hooks/usePermissions';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';

export const NotificationDetails = (props: { notification: PacTSNotification }) => {
  const deleteNotification = useDeleteNotification();
  const permissions = usePermissions();
  const tooltipTitle = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <span>VISIBLE FOR ADMINS ONLY</span>
      <span>
        Id:
        <Typography.Text code copyable style={{ overflowWrap: 'anywhere', color: 'inherit' }}>{`${props.notification.id}`}</Typography.Text>
      </span>
      <span>
        Topic:
        <Typography.Text code copyable style={{ overflowWrap: 'anywhere', color: 'inherit' }}>{`${props.notification.topic}`}</Typography.Text>
      </span>
      <span>{`Creator: ${props.notification.meta?.user?.name}`}</span>
      <span>{`Received: ${formatDateTime(props.notification.received)}`}</span>
      <span>{`Expiry: ${formatDateTime(props.notification.expiry)}`}</span>
      <span>
        Payload
        <ReactJson
          theme="twilight"
          src={props.notification}
          collapseStringsAfterLength={120}
          groupArraysAfterLength={100}
          shouldCollapse={(field) => field.type === 'array'}
        />
      </span>
      <Button
        type="text"
        block
        size="small"
        disabled={!permissions.notificationSvc$notificationsDeleteRequest}
        danger
        onClick={() => {
          deleteNotification.mutate([props.notification.id]);
        }}
        loading={deleteNotification.isLoading}
        icon={<DeleteOutlined />}
      >
        Delete
      </Button>
    </Space>
  );

  return (
    <Popover title={tooltipTitle} placement="bottomLeft" overlayStyle={{ width: 680 }} trigger="click">
      <Button type="text" size="small" icon={<InfoOutlined style={{ fontSize: 8, opacity: 0.8 }} />} />
    </Popover>
  );
};
