import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserWithRoles } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useUsersWithRoles() {
  const { backend } = useUserBackend();
  const permissions = usePermissions();
  const cache = useQueryClient();
  return useQuery<UserWithRoles[], [string]>(
    ['usersWithRoles'],
    async (): Promise<UserWithRoles[]> => {
      const usersWithRoles = await backend.queryUsers(true);

      usersWithRoles
        .filter((u) => u.roles)
        .forEach((user) => {
          cache.setQueryData(['userRoles', user.id!.toString()], user.roles);
        });

      return usersWithRoles;
    },
    { enabled: permissions.userSvc$getUsers }
  );
}
