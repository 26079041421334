import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export function VersionDiffersTag() {
  return (
    <Tag icon={<ExclamationCircleOutlined />} color="warning">
      Version Differs
    </Tag>
  );
}

export default VersionDiffersTag;
