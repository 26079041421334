import { useMemo, useState } from 'react';
import { presentAppError } from '../../../api/shared/errorPresenter';
import { b64toBlob } from '../utils/base64ToBlob';
import jszip from 'jszip';
import { useRestBackendConfig } from '../../../api/shared/useBackendConfiguration';

type RequestedHeaders = { [key: string]: string | undefined };

export type DownloaderStatus = {
  loading: boolean;
  progress: number;
};

type ExtraFileProvider = (args: { headers: RequestedHeaders }) => Promise<{ filename: string; content: Blob }[]>;

type DownloadFunc = (
  url: string,
  filename: string,
  expectedSize?: number,
  base64?: boolean,
  requestedHeaders?: string[],
  addFiles?: ExtraFileProvider
) => Promise<{ headers: RequestedHeaders }>;

// This is used, because axios does not support data streaming
export const useDownloader = (): [DownloadFunc, DownloaderStatus] => {
  const defaultInstallerSize = 10856108;
  const [status, setStatus] = useState<DownloaderStatus>({ loading: false, progress: 0 });
  const config = useRestBackendConfig('');

  const download = useMemo(() => {
    return async (url: string, filename: string, expectedSize?: number, base64?: boolean, requestedHeaders?: string[], addFiles?: ExtraFileProvider) => {
      setStatus((s) => {
        return { ...s, loading: true, progress: 0 };
      });
      const requestHeaders = new Headers();

      requestHeaders.append('Authorization', await config.apiKey());
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: requestHeaders
      };
      const headers: RequestedHeaders = {};

      try {
        const response = await fetch(url, requestOptions);
        if (response && response.body && response.headers) {
          const reader = response.body!.getReader();
          const contentLengthHeader = response.headers.get('Content-Length');

          requestedHeaders?.forEach((h) => (headers[h] = response.headers.get(h) ?? undefined));

          let contentLength = expectedSize ?? defaultInstallerSize;
          if (contentLengthHeader) {
            contentLength = parseInt(contentLengthHeader, 10);
          }
          let receivedLength = 0;
          const chunks: Uint8Array[] = [];

          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();

            if (done) {
              break;
            }
            if (value) {
              chunks.push(value);
              receivedLength += value!.length;
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              setStatus((s) => {
                return { ...s, loading: true, progress: receivedLength / contentLength };
              });
            }
          }
          let blob: Blob;

          if (base64) {
            let base64String = '';
            chunks.forEach((c) => {
              c.forEach((v) => {
                base64String += String.fromCharCode(v);
              });
            });
            blob = b64toBlob(base64String);
          } else {
            blob = new Blob(chunks);
          }

          let actualFilename = filename;
          let actualDownloadContent = blob;

          // If additional files are added, compress into archive and add .zip extension
          if (addFiles) {
            const addedFiles = await addFiles({ headers });
            if (addedFiles.length > 0) {
              const zip = new jszip();
              zip.file(filename, blob);
              addedFiles.forEach((f) => zip.file(f.filename, f.content));
              actualDownloadContent = await zip.generateAsync({ type: 'blob' });
              actualFilename = `${actualFilename}.zip`;
            }
          }

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(actualDownloadContent);
          link.setAttribute('download', actualFilename);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.error('downloader error', error);
        presentAppError(error as Error);
        throw error;
      } finally {
        setStatus((s) => {
          return { ...s, loading: false, progress: 0 };
        });
      }

      return { headers };
    };
  }, [config]);
  return [download, status];
};
