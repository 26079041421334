import { Card, Space, Switch } from 'antd';
import { useState } from 'react';
import { Project } from '../../../api/engineering/domain/types';
import { usePermissions } from '../../session/hooks/usePermissions';
import { PacTSFormationResources } from './PacTSFormationResources';
import { PrimaryHeader } from '../../../contexts/shared/components/PrimaryHeader';

export const ProjectResources = (props: { project: Project; showTitle?: boolean }) => {
  const permissions = usePermissions();
  const [show, setShow] = useState(false);
  return (
    <>
      {permissions.pactsFormationSvc$unrestrictedAdministration && (
        <Card
          title={<PrimaryHeader>{props.showTitle ? `${props.project.name} Resources` : 'Project Resources (admin only)'}</PrimaryHeader>}
          bodyStyle={{ display: show ? undefined : 'none' }}
          extra={[
            <Space key="resourceExtra" style={{ float: 'right' }}>
              <span key="title">Show Project Resources</span>
              <Switch key="setShow" checked={show} onChange={setShow} />
            </Space>
          ]}
        >
          {show ? <PacTSFormationResources project={props.project} /> : null}
        </Card>
      )}
    </>
  );
};
