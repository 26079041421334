import { Button, Input } from 'antd';
import { useState } from 'react';
import { presentAppError, Project } from '../../../api';
import { useImportProject } from '../hooks/useImportProject';
import { ScrollDialog } from '../../layout/components/ScrollDialog';

export const ImportProject = (props: { project: Project }) => {
  const doImport = useImportProject();
  const [visible, setIsVisible] = useState(false);
  const [gitlabGroupId, setGitlabGroupId] = useState('');
  const actualGitlabGroupId = gitlabGroupId.trim();
  const canSend = actualGitlabGroupId !== '';

  return (
    <>
      <Button type="primary" onClick={() => setIsVisible(true)}>
        Set Gitlab Root Group Id
      </Button>
      <ScrollDialog
        okButtonProps={{ disabled: !canSend }}
        title={`PacTS.Formation Import for ${props.project.name}`}
        open={visible}
        onCancel={() => {
          setIsVisible(false);
          setGitlabGroupId('');
        }}
        onOk={() => {
          doImport
            .mutateAsync({
              description: props.project.description,
              gitlabData: {
                rootGroupId: gitlabGroupId
              },
              id: props.project.idProject.toString(),
              name: props.project.name,
              type: props.project.projectType.name.toString()
            })
            .catch((err) => {
              console.error(err);
              presentAppError(err);
            })
            .then(() => {
              setGitlabGroupId('');
              setIsVisible(false);
            });
        }}
      >
        <Input placeholder="Gitlab Root Group Id" value={actualGitlabGroupId} onChange={(e) => setGitlabGroupId(e.target.value)} />
      </ScrollDialog>
    </>
  );
};
