import { Space } from 'antd';
import { InfoTooltip } from './InfoTooltip';


export const TableVersionHelp = () => {
  return (
    <Space>
      <span>Version</span>
      <InfoTooltip text="You can search for versions and targets by entering the search value right into the dropdown." />
    </Space>
  )
};
