import { Radio, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { useSoftwareApp } from '../hooks/useSoftwareApp';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { useProject } from '../../projects/hooks/useProject';
import { CommonSoftwareAppVersionsList } from './CommonSoftwareAppVersionsList';
import { ProjectSoftwareAppVersionsList } from './ProjectSoftwareAppVersionsList';
import { CommonSoftwareAppUsageList } from './CommonSoftwareAppUsageList';
import { SoftwareAppScope } from '../hooks/scopedSoftwareApp';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AddCommonSoftwareAppVersion } from './AddCommonSoftwareAppVersion';
import { AddProjectSoftwareAppVersion } from './AddProjectSoftwareAppVersion';
import { BackToProjectOrSoftwarePlatformButton } from '../../navigation/components/BackToProjectOrSoftwarePlatformButton';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';
import { useSearchParameters } from '../../../contexts/navigation/hooks/useSearchParameters';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';

export const SoftwareAppVersions = () => {
  const params = useParams();
  const searchParam = useSearchParameters();
  const id = params.id || '';
  const projectId = params.projectid || searchParam.projectId || '';
  const appScope = searchParam.scope as SoftwareAppScope | undefined;
  const hasProjectId = projectId !== '' && parseInt(projectId) > -1;

  const isProjectScope = appScope === 'project';
  const fetchId = isProjectScope ? projectId : '';
  const appFetch = useSoftwareApp(id, fetchId);
  const project = useProject(projectId);
  const permissions = usePermissions({ projectId, softwareAppId: id });
  const navigate = useInAppNavigate();
  const app = appFetch.data;
  const isLoading = appFetch.isLoading || (hasProjectId && project.isLoading);
  const [usageViewSearchParam, setUsageViewSearchParam] = useSearchParameter('view');
  const tab = usageViewSearchParam || 'versions';

  const hasComparePermissions = permissions.engineeringSvc$getCommonSoftwareAppUsage;

  const showCompareRadio = app != null && !isProjectScope && hasComparePermissions;

  if (!appScope || !id) {
    navigate('/404');
  }

  const options = [
    { label: 'Version details', value: 'versions' },
    { label: 'Project integration', value: 'usage' }
  ];

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Skeleton loading={isLoading}>
      <CenteredLayout
        fixed
        // In order to avoid an ant-design inteface issue
        // we have to override the prop to string using
        // TS Type casting
        title={
          (
            <div>
              {`Versions overview:  ${app?.name ?? ''}`}
              {showCompareRadio ? (
                <>
                  <br />
                  <Radio.Group
                    style={{ marginTop: 8 }}
                    options={options}
                    onChange={(e) => setUsageViewSearchParam(e.target.value)}
                    value={tab}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </>
              ) : null}
            </div>
          ) as unknown as string
        }
        extra={[
          permissions.engineeringSvc$addCommonSoftwareAppVersion && !isProjectScope && (
            <AddCommonSoftwareAppVersion key="addVersion" existingSoftwareAppVersion={app?.latestVersion} parentApp={app!} />
          ),
          permissions.engineeringSvc$addProjectSoftwareAppVersion && isProjectScope && (
            <AddProjectSoftwareAppVersion key="addProjectVersion" existingSoftwareAppVersion={app?.latestVersion} parentApp={app!} projectId={projectId} />
          ),
          <BackToProjectOrSoftwarePlatformButton key="back" project={project.data ?? null} isLoading={project.isLoading && projectId} />
        ]}
      >
        {app &&
          (isProjectScope ? (
            <Skeleton loading={isLoading}>{project.data && <ProjectSoftwareAppVersionsList softwareApp={app} project={project.data} />}</Skeleton>
          ) : (
            <>
              {tab === 'versions' && (
                <Skeleton loading={isLoading}>
                  {app && <CommonSoftwareAppVersionsList extraHeaderOffset={showCompareRadio ? 43 : 0} softwareApp={app} project={project.data} />}
                </Skeleton>
              )}
              {tab === 'usage' && <Skeleton loading={isLoading}>{app && <CommonSoftwareAppUsageList softwareApp={app} />}</Skeleton>}
            </>
          ))}
      </CenteredLayout>
    </Skeleton>
  );
};
