import { createContext, useEffect, useReducer, useRef } from 'react';
import { Reducer } from './reducer';
import { GlobalState } from './globalState';
import { Token, TokenActionTypes, BasePathActionTypes, TokenActions } from './actions';
import { useInvalidateUserInfo } from './../contexts/session/hooks/useUserInfo';

export type ActionTypes = TokenActionTypes | BasePathActionTypes;

export const TOKEN_LOCAL_STORAGE_KEY = 'pacts-token';

export type PacTSContextStoreProps = {
  initialState?: Partial<GlobalState>;
  children?: React.ReactNode;
};

export const readTokenFromStore = (prime?: Token): Token => {
  const existingToken = JSON.parse(window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) ?? '{}');
  const existingTokenValid = !!existingToken.token; // Check if the existing token has a non empty string token attribute
  const parsedToken = existingTokenValid ? existingToken : prime ?? { refreshToken: '', token: '' };
  return parsedToken;
};

// access and refresh tokens are always updated at the same time,
// we can ignore the always changing access token and focus on the refresh token
// this prevents ping pong while updating multiple tabs in leeway
export const refreshTokenDiffers = (a: Token, b: Token): boolean => {
  // if header and payload of refresh token differ, return true
  // ignore signature because depending on the signing algorithm this will be randomized
  const refreshPartsA = a?.refreshToken?.split('.') ?? [];
  const refreshPartsB = b?.refreshToken?.split('.') ?? [];

  const lenA = refreshPartsA.length;
  const lenB = refreshPartsB.length;

  //iIf the length differs, one of the tokens is likely invalid
  if (lenA !== lenB) return true;

  // both tokens are invalid
  if (lenA < 3) return false;

  const signatureDiffers = refreshPartsA[0] !== refreshPartsB[0];
  const payloadDiffers = refreshPartsA[1] !== refreshPartsB[1];
  return signatureDiffers || payloadDiffers;
};

const initialState = (initialStateOverride?: Partial<GlobalState>) => {
  const parsedToken = readTokenFromStore(initialStateOverride?.token);
  return {
    ...initialStateOverride,
    token: parsedToken
  } as GlobalState;
};

export const PacTSContext = createContext<[GlobalState, React.Dispatch<ActionTypes>]>([
  initialState(),
  () => {
    /* */
  }
]);

export const PacTSContextStore = (props: PacTSContextStoreProps) => {
  const propsCopy = { ...props };
  const invalidateUserInfo = useInvalidateUserInfo();
  delete propsCopy.children;
  const mergedInitialState = props ? { ...initialState(props.initialState), ...propsCopy } : initialState();
  const [state, dispatch] = useReducer(Reducer, mergedInitialState);

  // Keep last emitted token to prevent redundant user info invalidations
  const lastEmittedToken = useRef(state.token);

  useEffect(() => {
    // We create a new token object to individually attach the effect on token and refresh token deps not the reference
    const currentToken: Token = { token: state.token.token, refreshToken: state.token.refreshToken };

    // Invalidate only when the token strings actually changed
    if (refreshTokenDiffers(currentToken, lastEmittedToken.current)) {
      // On token updates invalidate user info
      invalidateUserInfo();
    }

    lastEmittedToken.current = currentToken;

    // Set persistent token to state on update if differs
    if (refreshTokenDiffers(readTokenFromStore(), currentToken)) {
      const stringified = JSON.stringify(currentToken);
      window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, stringified);
    }

    // Attach to storage event for token updates
    // if multiple windows / processes are active, we have to keep them in sync
    const handler = (event: StorageEvent) => {
      if (event.key === TOKEN_LOCAL_STORAGE_KEY) {
        const persistentToken = readTokenFromStore();
        if (refreshTokenDiffers(currentToken, persistentToken)) {
          dispatch({
            type: TokenActions.SET_TOKEN,
            payload: {
              token: persistentToken.token,
              refreshToken: persistentToken.refreshToken
            }
          });
        }
      }
    };
    window.addEventListener('storage', handler);
    return () => window.removeEventListener('storage', handler);
  }, [state.token.token, state.token.refreshToken, dispatch, invalidateUserInfo]);

  return <PacTSContext.Provider value={[state, dispatch]}>{props.children}</PacTSContext.Provider>;
};
