import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { MetricsBackend } from '../service/metricsBackend';

type MetricsBackendHookType = {
  backend: MetricsBackend;
  setBackend: (newBackend: MetricsBackend) => any;
};
const initData: MetricsBackendHookType = {
  backend: undefined as any as MetricsBackend,
  setBackend: () => {
    /* */
  }
};
export const useSetInitialMetricsBackend = (backend: MetricsBackend) => {
  initData.backend = backend;
};
export const useMetricsBackend = singletonHook<MetricsBackendHookType>(initData, () => {
  const [backendState, setBackend] = useState<MetricsBackend>(initData.backend);
  return {
    backend: backendState,
    setBackend
  };
});
