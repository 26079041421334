import { jwtDecode } from 'jwt-decode';

/**
 * Checks if the given JWT token is not expired
 * @param {string} jwt token to test
 * @param {number=1} gracePeriod number of seconds subtracted from the expiry of the token. Default: 1s
 * @returns {boolean} true if the token is still valid
 */
export const jwtIsNotExpired = (jwt: string, gracePeriod: number = 1): boolean => {
  try {
    const now = Math.floor(Date.now() / 1000);
    const decoded = jwtDecode<{ exp?: number }>(jwt);
    return now < (decoded.exp ?? 0) - gracePeriod;
  } catch (error) {
    return false;
  }
};
