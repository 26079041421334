import { WarningOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { PacTSNotification } from '../../../../api/notifications/domain/types';
import { DefaultNotification } from './DefaultNotification';
import styled from 'styled-components';

const Icon = styled(WarningOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colorWarning};
`;

export const SystemWarning = (props: { notification: PacTSNotification }) => {
  return (
    <Space size="large">
      <Icon />
      <DefaultNotification notification={props.notification} />
    </Space>
  );
};
