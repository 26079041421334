import { Row, Col, Card } from 'antd';
import { useState } from 'react';
import { SoftwareAppSelection } from '../../../domain';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AddCommonSoftwareApp } from './AddCommonSoftwareApp';
import { CommonSoftwareAppsList } from './CommonSoftwareAppsList';

export const CommonAppsView = () => {
  const [selected, setSelected] = useState([] as SoftwareAppSelection[]);
  const [initialSelection] = useState([] as SoftwareAppSelection[]);

  const permissions = usePermissions();

  return (
    <CenteredLayout fixed title="Software Platform Overview" extra={[permissions.engineeringSvc$addCommonSoftwareApp && <AddCommonSoftwareApp key='add' />]}>
      <Card>
        <Row>
          <Col span={24}>
            <CommonSoftwareAppsList selected={selected} onSelect={setSelected} initiallySelected={initialSelection} />
          </Col>
        </Row>
      </Card>
    </CenteredLayout>
  );
};
