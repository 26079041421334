import { Button, Col, Popover, Row, Tabs } from 'antd';
import { FlatifiedResult } from '../hooks/useFlatifiedReports';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Column } from '../../../api/report/domain/types';
import { debounce, groupBy } from 'lodash';
import { hashString } from '../shared/util';
import { useSources } from '../hooks/useSources';
import { usePermissions } from '../../session/hooks/usePermissions';
import ColumnsContent, { Value } from './ColumnsContent';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useSystemColumns } from '../hooks/useSystemColumns';
import { useComputedFolderColumns } from '../hooks/useComputedFolderColumns';
import { useQueryClient } from '@tanstack/react-query';
import { useUpsertFolderColumns } from '../hooks/useUpsertFolderColumns';
import { useUpsertSystemColumns } from '../hooks/useUpsertSystemColumns';
import { useDeleteSystemColumns } from '../hooks/useDeleteSystemColumns';
import { useDeleteFolderColumns } from '../hooks/useDeleteFolderColumns';
import styled from 'styled-components';
import { ColumnSelectButton } from './ColumnSelectButton';
import { useLocalStorageState } from '../../../contexts/shared/hooks/useLocalStorageState';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';

interface ColumnSelectProps {
  data: FlatifiedResult;
  loading: boolean;
  onSelect: (val: string[]) => void;
  projectId: number;
  sourceId: string;
  localStorageKey: string;
  disabled?: boolean;
}

type SearchState = {
  searchText: string[];
  isReset: boolean;
  shouldRefetch: boolean;
};

enum UpsertType {
  Default = 'default',
  System = 'system'
}

enum TabKeys {
  TableColumns = 'table-columns',
  FolderColumns = 'folder-columns',
  SystemColumns = 'system-columns'
}

const ButtonWithRightMargin = styled(Button)`
  margin-right: 5px;
`;

const PaddedDiv = styled.div`
  padding: 8px;
`;

export const ColumnSelectData: React.FC<ColumnSelectProps> = (props) => {
  const { onSelect, data, localStorageKey } = props;
  const [searchParam, setSearchParam] = useSearchParameter('c');

  const upsertFolderColumns = useUpsertFolderColumns(props.projectId, props.sourceId, props.sourceId);
  const upsertSystemColumns = useUpsertSystemColumns(props.projectId, props.sourceId);
  const deleteSystemColumns = useDeleteSystemColumns(props.projectId);
  const deleteFolderColumns = useDeleteFolderColumns(props.projectId, props.sourceId, props.sourceId);

  const selectedProjectPreferredColumnsKey = `${props.projectId}-${props.sourceId}`;

  const { data: computedColumns } = useComputedFolderColumns(props.projectId, props.sourceId, props.sourceId);

  const [preferredColumns, setPreferredColumns] = useLocalStorageState<Record<string, Column[]> | undefined>(localStorageKey);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetPreferredColumns = useCallback(debounce(setPreferredColumns, 3000), [setPreferredColumns]);

  const values = data.columns.map((c) => ({ text: c.name, id: c.id, group: c.group, idHash: hashString(c.id) }));

  const valueIds = useMemo(() => values.map((value) => value.id), [values]);
  const sources = useSources(props.projectId);
  const permissions = usePermissions({ projectId: props.projectId.toString() });
  const source = sources.data?.find((s) => s.id === props.sourceId);
  const sourceId = source?.id ?? '';
  const { data: systemColumnsBySourceId } = useSystemColumns();

  const folderColumns = (source?.defaultColumns?.[sourceId] ?? []) as Column[];
  const systemColumns = useMemo(() => (systemColumnsBySourceId?.[sourceId] ?? []) as Column[], [systemColumnsBySourceId, sourceId]);
  const searchParamTextArray = (searchParam?.split(',') ?? [])
    .map((p) => values.find((v) => v.idHash === p))
    .filter(Boolean)
    .map((e) => e!.id);

  const defaultColumns = useMemo(() => computedColumns?.columns ?? [], [computedColumns]);

  const preferredDefaultColumns = useMemo(() => {
    if (preferredColumns?.[selectedProjectPreferredColumnsKey ?? '']?.length ?? 0 > 0) {
      return preferredColumns?.[selectedProjectPreferredColumnsKey ?? ''] ?? [];
    }

    return defaultColumns;
  }, [defaultColumns, preferredColumns, selectedProjectPreferredColumnsKey]);

  const [searchState, setSearchState] = useState<SearchState>({
    searchText: !!searchParam ? searchParamTextArray : [],
    isReset: false,
    shouldRefetch: false
  });

  const [folderColsSearchState, setFolderColsSearchState] = useState<SearchState>({
    searchText: folderColumns.map((col) => col.key),
    isReset: false,
    shouldRefetch: false
  });

  const [systemColsSearchState, setSystemColsSearchState] = useState<SearchState>({
    searchText: systemColumns.map((col) => col.key),
    isReset: false,
    shouldRefetch: false
  });

  // Initially, if no search param is set
  // update the search state when the preffered default columns are loaded
  // effect dependencies are ignored so the effect only renders initially
  useEffect(() => {
    if (!searchParam) {
      setSearchState({ ...searchState, searchText: preferredDefaultColumns.map((col) => col.key) ?? [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredDefaultColumns]);

  const [filterSearch, setFilterSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [tabsSelectedKey, setTabsSelectedKey] = useState(TabKeys.TableColumns);

  const saveToLocalStorage = useCallback(
    (newSelectedColumns: string[]) => {
      if (newSelectedColumns.some((newColumn) => newColumn == null)) {
        return;
      }
      const formattedNewSelectedColumns = newSelectedColumns.map((columnStr) => ({ key: columnStr, name: '' }));

      if (selectedProjectPreferredColumnsKey != null) {
        const newPreferredColumns = {
          ...preferredColumns,
          [selectedProjectPreferredColumnsKey]: formattedNewSelectedColumns
        };
        debouncedSetPreferredColumns(newPreferredColumns);
      }
    },
    [debouncedSetPreferredColumns, preferredColumns, selectedProjectPreferredColumnsKey]
  );

  // Synchronize search state changes with external component and search parameter
  useEffect(() => {
    onSelect(searchState.searchText);
    const param =
      searchState.searchText
        .map((t) => {
          return hashString(t);
        })
        .join(',') || '';
    setSearchParam(param);
  }, [searchState, setSearchParam, onSelect]);

  // Synchronize search state with latest API request
  useEffect(() => {
    setSystemColsSearchState({
      searchText: systemColumns.map((col) => col.key),
      isReset: false,
      shouldRefetch: false
    });
  }, [systemColumns]);

  // Synchronize default column changes with search state
  useEffect(() => {
    if (!searchState.shouldRefetch) {
      return;
    }

    const mappedColumns = searchState.isReset ? defaultColumns.map((d) => d.key) : preferredDefaultColumns.map((d) => d.key);
    if (searchState.searchText.join('.') === preferredDefaultColumns.join('.')) return;
    setSearchState({ searchText: mappedColumns.filter((columnId) => valueIds.includes(columnId)), isReset: false, shouldRefetch: false });
  }, [preferredDefaultColumns, setSearchState, searchState, defaultColumns, valueIds]);

  const groupedValues = groupBy(values, (v) => v.group);
  const valueGroups = Object.keys(groupedValues).map((k) => ({ group: k, values: groupedValues[k] }));

  const selectedKeys = searchState.searchText;
  const folderTabSelectedKeys = folderColsSearchState.searchText;
  const systemTabSelectedKeys = systemColsSearchState.searchText;
  const queryClient = useQueryClient();

  const selectedKeysByTab = {
    [TabKeys.TableColumns]: selectedKeys,
    [TabKeys.FolderColumns]: folderTabSelectedKeys,
    [TabKeys.SystemColumns]: systemTabSelectedKeys
  };
  const activeSelectedKeys = selectedKeysByTab[tabsSelectedKey];
  const isAllActiveKeysSelected = values.every((val) => activeSelectedKeys.includes(val.id));

  const clearFilters = () => setSearchState({ searchText: [], isReset: false, shouldRefetch: false });
  const handleReset = async (clear?: () => void) => {
    clear?.();
    await queryClient.invalidateQueries(['computedFolderColumns', props.projectId, sourceId, sourceId]);
    setSearchState({ ...searchState, isReset: true, shouldRefetch: true });
    saveToLocalStorage([]);
    setOpen(false);
  };
  const selectAll = (onSet: (searchState: SearchState) => void) => {
    onSet({ ...searchState, searchText: [...values.map((v) => v.id)] });
  };

  const unselectAll = (onSet: (searchState: SearchState) => void) => {
    onSet({ ...searchState, searchText: [values[0]?.id] });
  };

  const upsert = (type: UpsertType) => {
    const columnsToUpsert = activeSelectedKeys.map((key) => {
      const ret: Column = {
        key: key,
        name: values.find((v) => v.id === key)?.text || key
      };
      return ret;
    });

    if (source == null) {
      return;
    }

    switch (type) {
      case UpsertType.Default:
        const columnsPayload = {
          mvccId: source.mvccId,
          columns: columnsToUpsert
        };

        upsertFolderColumns.mutate(columnsPayload);
        break;
      case UpsertType.System:
        const sysColumnsPayload = {
          columns: columnsToUpsert
        };

        upsertSystemColumns.mutate(sysColumnsPayload);
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent, val: Value, onSet: (searchState: SearchState) => void, persistLocally: boolean) => {
    const checked = activeSelectedKeys.includes(val.id);
    const copy = [...activeSelectedKeys];
    if (e.target.checked) {
      if (!checked) copy.push(val.id);
    } else {
      copy.splice(copy.indexOf(val.id), 1);
    }

    onSet({ searchText: copy, isReset: false, shouldRefetch: false });

    if (persistLocally) {
      saveToLocalStorage(copy);
    }
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent, onSet: (searchState: SearchState) => void, persistLocally: boolean) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      selectAll(onSet);
    } else {
      unselectAll(onSet);
    }

    if (persistLocally) {
      saveToLocalStorage(isChecked ? [...values.map((v) => v.id)] : []);
    }
  };

  const handleDeleteColumns = (columnsType: UpsertType) => {
    switch (columnsType) {
      case UpsertType.System:
        deleteSystemColumns.mutate();
        unselectAll(setSystemColsSearchState);
        break;
      case UpsertType.Default:
        deleteFolderColumns.mutate(source?.mvccId ?? 1);
        unselectAll(setFolderColsSearchState);
        break;
    }
  };

  const tableColumnsContent = (
    <ColumnsContent
      isSelectAllActive={isAllActiveKeysSelected}
      filterSearch={filterSearch}
      selectedKeys={selectedKeys}
      valueGroups={valueGroups}
      onCheckboxChange={(e, val) => handleCheckboxChange(e, val, setSearchState, true)}
      onSetFilterSearch={(value) => setFilterSearch(value)}
      onSelectAllChange={(e) => handleSelectAllChange(e, setSearchState, true)}
      values={values}
    >
      <Row justify="end">
        {permissions.reportSvc$getReportRevisions && (
          <Col>
            <Button
              onClick={() => {
                handleReset(clearFilters);
              }}
              size="small"
            >
              Reset to defaults
            </Button>
          </Col>
        )}
      </Row>
    </ColumnsContent>
  );

  // check for put folder columns as this is potentially the lowest required permission
  const filterDialog = permissions.reportSvc$putReportFolderColumns ? (
    <PaddedDiv>
      <Tabs activeKey={tabsSelectedKey} type="line" centered onChange={(key) => setTabsSelectedKey(key as TabKeys)}>
        <Tabs.TabPane tab={<span>Table Columns</span>} disabled={false} key={TabKeys.TableColumns}>
          {tableColumnsContent}
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Project Columns</span>} disabled={false} key={TabKeys.FolderColumns}>
          <ColumnsContent
            isSelectAllActive={isAllActiveKeysSelected}
            filterSearch={filterSearch}
            selectedKeys={folderTabSelectedKeys}
            valueGroups={valueGroups}
            onCheckboxChange={(e, val) => handleCheckboxChange(e, val, setFolderColsSearchState, false)}
            onSelectAllChange={(e) => handleSelectAllChange(e, setFolderColsSearchState, false)}
            onSetFilterSearch={(value) => setFilterSearch(value)}
            values={values}
          >
            <Row justify="end">
              {permissions.reportSvc$deleteReportFolderColumns && (
                <Col>
                  <ButtonWithRightMargin
                    onClick={() => {
                      handleDeleteColumns(UpsertType.Default);
                    }}
                    size="small"
                  >
                    Clear all
                  </ButtonWithRightMargin>
                </Col>
              )}
              {permissions.reportSvc$putReportFolderColumns && (
                <Col>
                  <Button
                    onClick={() => {
                      upsert(UpsertType.Default);
                    }}
                    size="small"
                  >
                    Save as default
                  </Button>
                </Col>
              )}
            </Row>
          </ColumnsContent>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>System Columns</span>} disabled={false} key={TabKeys.SystemColumns}>
          <ColumnsContent
            filterSearch={filterSearch}
            selectedKeys={systemTabSelectedKeys}
            valueGroups={valueGroups}
            isSelectAllActive={isAllActiveKeysSelected}
            onCheckboxChange={(e, val) => handleCheckboxChange(e, val, setSystemColsSearchState, false)}
            onSelectAllChange={(e) => handleSelectAllChange(e, setSystemColsSearchState, false)}
            onSetFilterSearch={(value) => setFilterSearch(value)}
            values={values}
          >
            <Row justify="end">
              {permissions.reportSvc$deleteReportSystemColumns && (
                <Col>
                  <ButtonWithRightMargin
                    onClick={() => {
                      handleDeleteColumns(UpsertType.System);
                    }}
                    size="small"
                  >
                    Clear all
                  </ButtonWithRightMargin>
                </Col>
              )}
              {permissions.reportSvc$putReportSystemColumns && (
                <Col>
                  <Button
                    onClick={() => {
                      upsert(UpsertType.System);
                    }}
                    size="small"
                  >
                    Save as default
                  </Button>
                </Col>
              )}
            </Row>
          </ColumnsContent>
        </Tabs.TabPane>
      </Tabs>
    </PaddedDiv>
  ) : (
    tableColumnsContent
  );

  return (
    <Popover content={filterDialog} trigger="click" open={open} onOpenChange={setOpen} placement="bottomRight">
      <ColumnSelectButton loading={props.loading} onClick={() => setOpen(!open)} disabled={props.disabled} />
    </Popover>
  );
};
