import { DownloadData } from './DownloadData';
import { SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../api/engineering/domain/types';
import { ScrollDialog } from '../../../../../contexts/layout/components/ScrollDialog';

export const DownloadDialog = (props: {
  artifact: Tool | SoftwareApp | null;
  version: ToolVersion | SoftwareAppVersion | null;
  projectId?: number;
  visible: boolean;
  onClose: () => void;
}) => {
  if (!props.version || !props.artifact) return null;

  return (
    <>
      <ScrollDialog
        title="Download File"
        width={750}
        destroyOnClose
        cancelText="Close"
        open={props.visible}
        okButtonProps={{ hidden: true }}
        onCancel={() => {
          props.onClose();
        }}
      >
        <DownloadData version={props.version} component={props.artifact} projectId={props.projectId} />
      </ScrollDialog>
    </>
  );
};
