import { Navigate, useParams } from 'react-router-dom';
import { notificationMatchers } from '../domain/extractor';
import { useNotification } from '../hooks/useNotifications';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';

export const NotificationLink = () => {
  const { id } = useParams<{ id: string }>();
  const notification = useNotification(id || '');
  if (id === '') {
    console.warn('invalid notification id');
    return <Navigate to="/404" replace />;
  }
  if (notification.isLoading || notification.isFetching) {
    return <PageLoading />;
  }
  if (!notification.data || notification.isError) {
    console.warn('notification could not be retrieved', notification);
    return <Navigate to="/404" replace />;
  }

  const match = notificationMatchers.find((n) => n.regex.test(notification.data.topic || ''));
  if (match) {
    const link = match?.extractor(notification.data).link;
    return <Navigate to={link.link} replace />;
  }

  // if no match found navigate to home
  // with open notifications panel
  return <Navigate to="/?showNotifications=true" replace />;
};
