import { useEffect } from 'react';
import { useWebsocketNotificationsBackend } from './useWebsocketNotificationsBackend';
import { useNotificationsBackend, useSetInitialNotificationsBackend } from './useNotificationsBackend';

export const useWebsocketNotificationsBackendProvider = () => {
  const backend = useWebsocketNotificationsBackend();
  useSetInitialNotificationsBackend(backend);
  const { setBackend } = useNotificationsBackend();
  useEffect(() => {
    setBackend(backend);
  }, [backend, setBackend]);
};
