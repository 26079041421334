import { useParams } from 'react-router-dom';
import { EngineeringToolsBundleView } from '../../engineeringTools/components/EngineeringToolsBundleView';
import { ProjectSoftwareAppsBundleView } from '../../softwareApps/components/ProjectSoftwareAppsBundleView';
import { useProject } from '../hooks/useProject';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { BackToProjectButton } from '../../navigation/components/BackToProjectButton';

type ProjectBundleViewParams = {
  projectId: string;
  bundleName: 'apps' | 'tools';
};

export const ProjectBundleView = () => {
  const params = useParams() as ProjectBundleViewParams;
  const project = useProject(params.projectId);

  return (
    <CenteredLayout
      fixed
      title={`${project.data?.name || ''} ${params.bundleName === 'apps' ? 'Software Apps' : 'Engineering Tools'}`}
      extra={[<BackToProjectButton key="btp" projectId={params.projectId} />]}
    >
      {params.bundleName === 'apps' && <ProjectSoftwareAppsBundleView projectId={params.projectId} />}
      {params.bundleName === 'tools' && <EngineeringToolsBundleView projectId={params.projectId} />}
    </CenteredLayout>
  );
};
