import { Checkbox, Form, FormInstance, Select } from 'antd';
import { ProjectBundleRelease, ProjectCreate, ProjectUpdate } from '../../../api/engineering/domain/types';
import { useProjects, useProjectTypes } from '../hooks';
import { useBundleConfigurations, useBundleConfigurationVersions, useBundles } from '../../../contexts/bundles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useDebounce } from '../../../contexts/shared/hooks';

type SupportedData = ProjectCreate | ProjectUpdate;
const formFieldKey = 'referenceProjectRelease';

export const ReferenceProjectFormItemInner = (props: { form: FormInstance<SupportedData> }) => {
  const projects = useProjects();
  const projectTypes = useProjectTypes();

  const currentReference = props.form.getFieldValue(formFieldKey) as ProjectBundleRelease | undefined;
  const currentProjectType = props.form.getFieldValue('projectType') as number;

  // set as reference so rerendering for the form while
  // entering data does not disable input fields
  const hasInitialData = useRef(!!currentReference);

  // make reference project selectable
  // to explicitly let an admin select/deselect if a project
  // shall be created based on an rpl
  const [hasReferenceProject, setHasReferenceProject] = useState(hasInitialData.current);

  const currentProjectId = (currentReference?.projectId ?? -1).toString();
  const currentBundleId = (currentReference?.bundleId ?? -1).toString();
  const currentBundleConfigId = (currentReference?.configId ?? -1).toString();

  // only selected with type isReferenceProject = true can be selected
  const filteredProjects = (projects.data ?? []).filter((p) => p.projectType.isReferenceProject);

  // only project types with isReferenceProject = false are allowed to have reference
  const projectTypeIsNotReferenceProject = useMemo(
    () => (projectTypes.data ?? []).find((pt) => pt.idProjectType === currentProjectType)?.isReferenceProject === false,
    [projectTypes.data, currentProjectType]
  );

  // if project type selected which is of type isReferenceProject = true (not projectTypeIsNotReferenceProject)
  // reset hasReferenceProject to false which will trigger reset of the selected values
  // and set the checkbox back to unchecked
  useEffect(() => {
    // if project types are not loaded, ignore
    if (!projectTypes.isSuccess) return;

    // selected project type is reference project
    if (!projectTypeIsNotReferenceProject) {
      setHasReferenceProject(false);
    }
  }, [projectTypeIsNotReferenceProject, setHasReferenceProject, projectTypes.isSuccess]);

  // debounce project, bundle and config ids to make sure the configuration
  // is reset, before the new data is queried and invalid information
  // is shown in the selection dropdown
  // debouncing by 0 (one event cycle) is sufficient here to keep up with effects
  const debouncedProjectId = useDebounce(currentProjectId, 0);
  const debouncedBundleId = useDebounce(currentBundleId, 0);
  const debouncedConfigId = useDebounce(currentBundleConfigId, 0);

  const bundles = useBundles(debouncedProjectId);
  const bundleConfigs = useBundleConfigurations(debouncedProjectId, debouncedBundleId);
  const bundleConfigReleases = useBundleConfigurationVersions(debouncedProjectId, debouncedBundleId, debouncedConfigId);

  // when reference project is deselected reset forms value
  useEffect(() => {
    if (!hasReferenceProject) {
      props.form.setFieldValue(formFieldKey, undefined);
    }
  }, [hasReferenceProject, props.form]);

  // Reset dependent fields
  // reset all but project id when project is changed
  // if has initial data, do not run the reset since changes are not allowed
  useEffect(() => {
    if (hasInitialData.current) return;

    props.form.setFieldValue(formFieldKey, { projectId: currentReference?.projectId });
    // only listen for currentProjectId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId]);

  // reset all but project id and bundle id when bundle is changed
  // if has initial data, do not run the reset since changes are not allowed
  useEffect(() => {
    if (hasInitialData.current) return;

    props.form.setFieldValue(formFieldKey, { projectId: currentReference?.projectId, bundleId: currentReference?.bundleId });
    // only listen for currentBundleId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBundleId]);

  // reset all but project id, bundle id and config id when config is changed
  // if has initial data, do not run the reset since changes are not allowed
  useEffect(() => {
    if (hasInitialData.current) return;

    props.form.setFieldValue(formFieldKey, {
      projectId: currentReference?.projectId,
      bundleId: currentReference?.bundleId,
      configId: currentReference?.configId
    });
    // only listen for currentBundleConfigId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBundleConfigId]);

  // when initial data is available or is reference project, all inputs shall be shown but disabled
  // a rpl cannot be changed after set once
  const inputDisabled = hasInitialData.current || !projectTypeIsNotReferenceProject;

  const ReferenceProjectFormElements = () => (
    <>
      <Form.Item label="Project" name={[formFieldKey, 'projectId']} required rules={[{ required: true, message: 'Required' }]}>
        <Select loading={projects.isLoading} disabled={inputDisabled}>
          {filteredProjects.map((project) => {
            return (
              <Select.Option key={project.idProject} value={project.idProject} title={project.name}>
                {project.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Bundle" name={[formFieldKey, 'bundleId']} required rules={[{ required: true, message: 'Required' }]}>
        <Select loading={bundles.isLoading && !!currentReference?.projectId} disabled={!currentReference?.projectId || inputDisabled}>
          {bundles.data?.map((bundle) => {
            return (
              <Select.Option key={bundle.idBundle} value={bundle.idBundle} title={bundle.name}>
                {bundle.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Configuration" name={[formFieldKey, 'configId']} required rules={[{ required: true, message: 'Required' }]}>
        <Select loading={bundles.isLoading && !!currentReference?.bundleId} disabled={!currentReference?.bundleId || inputDisabled} filterOption={false}>
          {bundleConfigs.data?.map((config) => {
            return (
              <Select.Option key={config.idBundleConfiguration} value={config.idBundleConfiguration} title={config.name}>
                {config.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Release" name={[formFieldKey, 'releaseId']} required rules={[{ required: true, message: 'Required' }]}>
        <Select loading={bundles.isLoading && !!currentReference?.configId} disabled={!currentReference?.configId || inputDisabled}>
          {bundleConfigReleases.data?.map((config) => {
            return (
              <Select.Option key={config.idBundleRelease} value={config.idBundleRelease} title={config.version}>
                {config.version}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );

  return (
    <>
      <Form.Item label="Is based on RPL">
        <Checkbox checked={hasReferenceProject} onChange={(v) => setHasReferenceProject(v.target.checked)} disabled={inputDisabled} />
      </Form.Item>
      {hasReferenceProject ? <ReferenceProjectFormElements /> : null}
    </>
  );
};

export const ReferenceProjectFormItem = () => (
  <Form.Item
    noStyle
    shouldUpdate={(prevValues: SupportedData, curValues: SupportedData) =>
      !isEqual(prevValues.referenceProjectRelease, curValues.referenceProjectRelease) || !isEqual(prevValues.projectType, curValues.projectType)
    }
  >
    {(form) => <ReferenceProjectFormItemInner form={form} />}
  </Form.Item>
);
