import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

export const PlainRouteLayout = (props: { children: React.ReactNode }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: 0, margin: 0 }}>{props.children}</Content>
    </Layout>
  );
};
