import { useContext, useMemo } from 'react';
import { PacTSContext } from '../../../state/store';

export const useIsDevVersion = () => {
  const [state] = useContext(PacTSContext);

  return useMemo(() => {
    // It happened that the state was undefined during development reload
    // prevent a crash by adding a ? operator just to make sure
    return state.engineeringServicePath?.split('.').length !== 4;
  }, [state.engineeringServicePath]);
};
