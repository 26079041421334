import { useCallback, useMemo } from 'react';
import { useMetricsBackend } from '../../../api/metrics/hooks/useMetricsBackend';
import { Project, RevisionMeta, SoftwareApp, SoftwareAppVersion, SourceMeta, Tool, ToolVersion, Document, SoftwareComponent } from '../../../api';
import { MetricsBackend } from '../../../api/metrics/service/metricsBackend';
import { MetricTag, SubmitMetricEvent } from '../../../api/metrics/domain/types';
import { MetricClickIds, MetricViewIds } from '../constants';
import {
  toolToTags,
  typeTag,
  scopeTag,
  toolVersionToTags,
  appToTags,
  projectToTags,
  appVersionToTags,
  reportMetaToTags,
  reportDocumentToTags,
  RawTagType,
  rawToTags,
  componentToTags
} from '../utils/mapping';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

const disabledBackend: MetricsBackend = {
  submitEvent() {
    /* */
  }
};

const submitClickBuilder = (backend: MetricsBackend) => (operationId: string) => {
  const event: SubmitMetricEvent = {
    name: 'click',
    operationId: operationId,
    tags: []
  };

  return {
    withToolContext: (tool: Tool) => {
      event.tags.push(...toolToTags(tool), ...typeTag('tool'), ...scopeTag('common'));
      backend.submitEvent(event);
    },
    withToolVersionContext: (tool: Tool, version: ToolVersion) => {
      event.tags.push(...toolToTags(tool), ...toolVersionToTags(version), ...typeTag('tool'), ...scopeTag('common'));
      backend.submitEvent(event);
    },
    withAppContext: (app: SoftwareApp, project: Project) => {
      event.tags.push(...appToTags(app), ...projectToTags(project), ...typeTag('app'), ...scopeTag('project'));
      backend.submitEvent(event);
    },
    withAppVersionContext: (app: SoftwareApp, version: SoftwareAppVersion, project: Project) => {
      event.tags.push(...appToTags(app), ...appVersionToTags(version), ...projectToTags(project), ...typeTag('app'), ...scopeTag('project'));
      backend.submitEvent(event);
    },
    withCommonAppContext: (app: SoftwareApp) => {
      event.tags.push(...appToTags(app), ...typeTag('app'), ...scopeTag('common'));
      backend.submitEvent(event);
    },
    withCommonAppVersionContext: (app: SoftwareApp, version: SoftwareAppVersion) => {
      event.tags.push(...appToTags(app), ...appVersionToTags(version), ...typeTag('app'), ...scopeTag('common'));
      backend.submitEvent(event);
    },
    withReportDocumentContext: (source: SourceMeta, revision: RevisionMeta, document: Document, project: Project) => {
      event.tags.push(
        ...reportMetaToTags(source, revision),
        ...reportDocumentToTags(document),
        ...projectToTags(project),
        ...typeTag('report'),
        ...scopeTag('project')
      );
      backend.submitEvent(event);
    },
    withReportMetaContext: (source: SourceMeta, revision: RevisionMeta, project: Project) => {
      event.tags.push(...reportMetaToTags(source, revision), ...projectToTags(project), ...typeTag('report'), ...scopeTag('project'));
      backend.submitEvent(event);
    },
    withSoftwareComponent: (data: SoftwareComponent, project?: Project) => {
      event.tags.push(...componentToTags(data));
      if (project) {
        event.tags.push(...projectToTags(project));
      }
      backend.submitEvent(event);
    },
    withProjectContext: (project: Project) => {
      event.tags.push(...projectToTags(project));
      backend.submitEvent(event);
    },
    withRawData: (data: RawTagType) => {
      event.tags.push(...rawToTags(data));
      backend.submitEvent(event);
    },
    withoutContext() {
      backend.submitEvent(event);
    }
  };
};

const useSubmitMetric = () => {
  const permissions = usePermissions();
  const { backend } = useMetricsBackend();
  const hasPermissions = permissions.metricsSvc$submitEvents && permissions.metricsSvc$submitMeasurements;

  const submitClick = useCallback(
    (args: { operationId: MetricClickIds }) => {
      if (!hasPermissions) return submitClickBuilder(disabledBackend)(args.operationId);
      return submitClickBuilder(backend)(args.operationId);
    },
    [backend, hasPermissions]
  );

  const submitNavigate = useCallback(() => {
    if (!hasPermissions) return;
    const event: SubmitMetricEvent = {
      name: 'navigate',
      operationId: 'navigate',
      tags: []
    };
    backend.submitEvent(event);
  }, [backend, hasPermissions]);

  const submitView = useCallback(
    (args: { view: MetricViewIds; payload?: MetricTag[] }) => {
      if (!hasPermissions) return;
      const event: SubmitMetricEvent = {
        name: 'view',
        operationId: args.view,
        tags: args.payload ?? []
      };
      backend.submitEvent(event);
    },
    [backend, hasPermissions]
  );

  return useMemo(() => ({ submitClick, submitNavigate, submitView }), [submitClick, submitNavigate, submitView]);
};

export default useSubmitMetric;
