import { useQuery } from '@tanstack/react-query';
import { RevisionMeta } from '../../../api/report/domain/types';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useReportRevisions(projectId: number, sourceId: string) {
  const { backend } = useReportBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery<RevisionMeta[], [string, string, number]>(
    ['projectReportRevisions', projectId.toString(), sourceId],
    (): Promise<RevisionMeta[]> => {
      return backend.getRevisions(projectId, sourceId);
    },
    {
      enabled: projectId > -1 && !!sourceId && permissions.reportSvc$getReportRevision
    }
  );
}
