import { AxiosResponse, AxiosError } from 'axios';
import { presentAxiosError } from './errorPresenter';

export function unwrapAxiosQuery<T, E>(wrapped: Promise<AxiosResponse<T, E>>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    wrapped
      .then((dat) => resolve(dat.data))
      .catch((err: AxiosError) => {
        presentAxiosError(err);
        console.warn('api error', JSON.stringify(err));
        reject(err.message);
      });
  });
}

export default unwrapAxiosQuery;
