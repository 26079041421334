import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { User } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';

export function useDeleteUser() {
  const { backend } = useUserBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [User]>(([user]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteUser(user.id!)
        .then(() => {
          queryClient.invalidateQueries(['users']);
          queryClient.invalidateQueries(['usersWithRoles']);
          presentSuccess(`Successfully deleted User ${user.name}`);
          return resolve();
        })
        .catch(reject);
    });
  });
}

export default useDeleteUser;
