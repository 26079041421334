import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../domain';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useDebounce } from '../../shared/hooks/useDebounce';

export function useProjectSoftwareAppVersions(projectId: string, softwareAppId: string) {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  const permissions = usePermissions({ projectId, softwareAppId });

  const toDebounceConfig = useMemo(() => {
    return { projectId, softwareAppId };
  }, [projectId, softwareAppId]);

  const db = useDebounce(toDebounceConfig);
  return useQuery<SoftwareAppVersion[], [string, string, string]>(
    ['softwareappVersions', db.projectId, db.softwareAppId],
    (): Promise<SoftwareAppVersion[]> => {
      return new Promise((resolve, reject) => {
        backend
          .queryProjectAppVersions(projectId, softwareAppId)
          .then((versions) => {
            const sorted = versions.sort((a, b) => SemanticVersion.sorter(a.version, b.version));
            resolve(sorted);
          })
          .catch(reject);
      });
    },
    {
      enabled: parsedProjectId > -1 && parsedSoftwareAppId > -1 && permissions.engineeringSvc$getProjectSoftwareAppVersions,
      refetchInterval: (data) => {
        const hasInconsistent = data?.some((d) => d.state !== 'consistent');
        return hasInconsistent ? 5000 : false;
      }
    }
  );
}
