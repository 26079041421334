import { useProjectTypes } from '../../../../contexts/projects/hooks/useProjectTypes';
import { ProjectType } from '../../../../api/engineering/domain/types';
import { useSharedState } from '../../../shared/hooks/useSharedState';
import { EntityAdministrationListLayout } from './EntityAdministrationListLayout';

type UsedType = ProjectType;
const entityName = 'Project Type';
const entityKey = 'entity-admin-project-type';
const idAttribute = 'idProjectType';
const titleAttribute = 'name';
export const ProjectTypesList = () => {
  const entities = useProjectTypes();
  const [selected, setSelected] = useSharedState<UsedType>(entityKey);
  const creatingNew = selected !== null && entities.data?.find((e) => e[idAttribute] === (selected ? selected[idAttribute] : null)) === null;
  return (
    <>
      <EntityAdministrationListLayout
        isLoading={entities.isLoading}
        data={entities.data || []}
        idAttribute={idAttribute}
        titleAttribute={titleAttribute}
        onSelect={(val) => setSelected(val!)}
        selected={selected}
        onReset={() => setSelected({ description: '', idProjectType: -1, name: '', isReferenceProject: false })}
        entityName={entityName}
        creatingNew={creatingNew}
      />
    </>
  );
};
