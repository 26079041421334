import { Card, Typography, Space, Avatar, Badge, Row, Col } from 'antd';
import { useStyles } from '../../theme/useStyles';
import { PresetColorType } from 'antd/es/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { dark } from '../../../contexts/theme/themes/dark';
import styled from 'styled-components';
import { AnimatedLoadingIcon } from './icons/AnimatedLoadingIcon';

const StyledAvatar = styled(Avatar)<{ disabled?: boolean }>`
  background-color: ${({ theme, disabled }) => disabled ? theme.colorBgContainerDisabled : theme.colorPrimary};
  ${({ disabled }) => disabled ? `color: rgb(255, 255, 255, 0.65);` : ''}
`;

const HoverCard = styled(Card)<{ disabled?: boolean }>`
  width: 100%;
  padding: 4px;

  ${({ theme, disabled }) => disabled ? `border: 1px solid ${theme.colorBorderSecondary};` : '' }

  &:hover {
    ${({theme, disabled}) => disabled ? '' : `border: 1px solid ${theme.colorLinkHover};`}
    box-shadow: none;
  }
`;

const { Title, Text } = Typography;

const StyledTitle = styled(Title)<{ disabled?: boolean }>`
  margin: 0 !important;
  color: ${({ theme, disabled}) => disabled ? theme.colorTextDisabled : theme.colorText};
`;

const StyledText = styled(Text)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => disabled ? theme.colorTextDisabled : theme.colorText};
`;

export type CenterMenuElementProps = {
  icon?: React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  content?: React.ReactNode;
  extra?: React.ReactNode;
  disabled?: boolean;
  beta?: boolean;
  loading?: boolean;
  badgeColor?: LiteralUnion<PresetColorType>;
  badgeLabel?: string;
  onClick?: () => any;
};

export const CenterMenuElement = (props: CenterMenuElementProps) => {
  const { badgeColor, badgeLabel = null } = props;

  const disabled = props.disabled || props.loading;
  const token = useStyles();

  const onClick = () => {
    if (props.onClick && !disabled) {
      props.onClick();
    }
  };
  const clickableAttributes =
    props.onClick !== undefined
      ? {
          hoverable: props.onClick !== undefined && !disabled,
          role: 'button',
          tabIndex: disabled ? -1 : 0,
          onClick
        }
      : {};
  const title = (
    <div style={{ margin: 0, maxWidth: '100%' }}>
      <StyledTitle ellipsis level={5} className={disabled ? 'text-disabled' : ''} disabled={disabled}>
        {props.title}
      </StyledTitle>
    </div>
  );

  const card = (
    <HoverCard
      className="centerMenuElement"
      disabled={disabled}
      {...clickableAttributes}
      onKeyDown={(ev) => {
        // space or enter
        if (ev.key === " " || ev.key === 'Enter') {
          if (props.onClick) {
            props.onClick();
          }
        }
      }}
    >
      <Row wrap={false} align="middle">
        <Col flex="72px">
          {props.icon && <StyledAvatar disabled={disabled} size={42} icon={props.icon} />}
        </Col>
        <Col flex="auto">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space style={{ width: '100%', overflow: 'hidden' }}>
              {title}
              {props.loading && <AnimatedLoadingIcon style={{ fontSize: 16 }} />}
            </Space>
            {props.subtitle !== '' && (
              <StyledText className={disabled ? 'text-disabled' : ''} type="secondary" disabled={disabled}>
                {props.subtitle}
              </StyledText>
            )}
          </Space>
          {props.extra}
        </Col>
      </Row>
      {/* <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space align="center" size="large" style={{ float: 'left', width: '100%' }}></Space>
      </Space> */}
    </HoverCard>
  );
  const shouldShowBadge = props.beta || badgeLabel != null;
  const badgeLabelToShow = props.beta ? 'Beta version' : badgeLabel;
  const badgeColorToShow = props.beta ? token.orange6 : badgeColor;

  return shouldShowBadge ? (
    <Badge.Ribbon color={badgeColorToShow} text={<span style={{ fontWeight: 600, color: dark.colorBgContainer }}>{badgeLabelToShow}</span>}>
      {card}
    </Badge.Ribbon>
  ) : (
    card
  );
};
