import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { SoftwareApp, SoftwareAppCreate } from '../../../api/engineering/domain/types';

export function useCreateProjectSoftwareapp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareApp, any, [string, SoftwareAppCreate]>(([projectId, softwareApp]) => {
    return new Promise<SoftwareApp>((resolve, reject) => {
      backend
        .createProjectApp(projectId, softwareApp)
        .then((swa) => {
          queryClient.invalidateQueries(['softwareapps', projectId]);
          presentSuccess(`Successfully created Software Application ${swa.name}`);
          return resolve(swa);
        })
        .catch(reject);
    });
  });
}
