import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { User, UserRole, UserWithRoles } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';
import _ from 'lodash';

export function useUpdateUserRolesOfUser() {
  const { backend } = useUserBackend();
  const queryClient = useQueryClient();
  return useMutation<UserRole[], any, [User, UserRole[]]>(([user, roles]) => {
    return new Promise<UserRole[]>((resolve, reject) => {
      backend
        .updateUserRoles(user.id, roles)
        .then((newRoles) => {
          presentSuccess(`Successfully updated User Roles of ${user.name}`);
          queryClient.setQueryData(['userRoles', user.id!.toString()], newRoles);
          // We need to deep clone the state here in order to trigger the correct rendering updates
          const usersWithRoles = _.cloneDeep([...(queryClient.getQueryData<UserWithRoles[]>(['usersWithRoles']) || [])]);
          if (usersWithRoles) {
            const idx = usersWithRoles.findIndex((u) => u.id === user.id);
            if (idx > -1) {
              usersWithRoles[idx].roles = newRoles;
            }
            queryClient.setQueryData(['usersWithRoles'], usersWithRoles);
          }
          return resolve(newRoles);
        })
        .catch(reject);
    });
  });
}

export default useUpdateUserRolesOfUser;
