import React, { useEffect } from 'react';
import { useTick } from '../shared/hooks/useTick';
import { secondsToHMS } from './utils';
import { Typography } from 'antd';

interface ExpirationTimerProps {
  expiresIn: number;
}

const ExpirationTimer: React.FC<ExpirationTimerProps> = ({ expiresIn }) => {
  const [tick, clearTick] = useTick(expiresIn);
  const formattedTimeout = secondsToHMS(tick);
  const timeoutSuffix = tick >= 3600 ? 'h' : 'min';

  useEffect(() => {
    if (tick === 0) {
      clearTick();
    }
    // We only want this side effect to be triggered when tick changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick]);

  return (
    <Typography.Text>
      Code expiration time:{' '}
      <Typography.Text strong>
        {formattedTimeout} {timeoutSuffix}
      </Typography.Text>
    </Typography.Text>
  );
};

export default React.memo(ExpirationTimer);
