import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectSolutionType, ProjectSolutionTypeCreate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useCreateProjectSolutionType() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectSolutionType, any, [ProjectSolutionTypeCreate]>(([create]) => {
    return new Promise<ProjectSolutionType>((resolve, reject) => {
      backend
        .createProjectSolutionType(create)
        .then((dev) => {
          presentSuccess(`Successfully created Project Solution Type ${create.name}`);
          queryClient.invalidateQueries(['projectSolutionTypes']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
