import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useDeleteCommonSoftwareApp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [string]>(([softwareAppId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteCommonSoftwareApp(softwareAppId)
        .then(() => {
          queryClient.invalidateQueries(['commonSoftwareapps']);
          presentSuccess('Successfully deleted Software App');
          return resolve();
        })
        .catch(reject);
    });
  });
}
