import { Input } from 'antd';

interface PermissionsFormItemProps {
  autoSize?: {
    minRows?: number;
    maxRows?: number;
  };
  placeholder?: string;
  styles?: React.CSSProperties;
  value?: string | any;
  contentEditable?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const PermissionsFormItem: React.FC<PermissionsFormItemProps> = ({
  autoSize = { minRows: 6, maxRows: 20 },
  placeholder,
  value,
  contentEditable,
  onChange
}) => {
  const transformedValue = JSON.stringify(value, null, 4);
  return (
    <Input.TextArea
      autoSize={autoSize}
      placeholder={placeholder}
      value={transformedValue}
      contentEditable={contentEditable}
      onChange={(val) => {
        try {
          const parsed = JSON.parse(val.target.value);
          onChange!({ ...val, target: { ...val.target, value: parsed } });
        } catch (error) {
          console.error('illegal json input, ignoring', error);
        }
      }}
    />
  );
};
