import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { UserRoleWithPermissions, UserRoleWithPermissionsCreate } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';

export function useCreateUserRole() {
  const { backend } = useUserBackend();
  const queryClient = useQueryClient();
  return useMutation<UserRoleWithPermissions, any, [UserRoleWithPermissionsCreate]>(([role]) => {
    return new Promise<UserRoleWithPermissions>((resolve, reject) => {
      backend
        .createRole(role)
        .then((newRoles) => {
          presentSuccess(`Successfully created User Role ${role.name}`);
          queryClient.invalidateQueries(['userRole', newRoles.id]);
          queryClient.invalidateQueries(['userRoles']);
          return resolve(newRoles);
        })
        .catch(reject);
    });
  });
}

export default useCreateUserRole;
