import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Project } from '../../../api/engineering/domain/types';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { SubscriptionPopup } from '../../notifications/components/SubscriptionPopup';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { scopedAppHash } from '../utils/scopedAppHash';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const CommonSoftwareAppsActionMenu = (props: { swa: ScopedSoftwareApp; currentSelection: SoftwareAppSelection | undefined; project?: Project }) => {
  const metrics = useSubmitMetric();
  const navigate = useInAppNavigate();
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    softwareAppId: props.currentSelection?.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.currentSelection?.version.idSoftwareAppVersion.toString()
  });

  const canGetVersions =
    permissions.engineeringSvc$getCommonSoftwareAppVersions || permissions.engineeringSvc$getCommonSoftwareAppVersions$specific().length > 0;

  return (
    <Space>
      <SubscriptionPopup title={props.swa.name} topics={[{ key: `apps/${props.swa.idSoftwareApp}/**`, title: props.swa.name }]} type="text" />
      <ExpandableMenu textType id={`common-app-actions-${props.swa.idSoftwareApp}`}>
        {permissions.webui$showComponentDetails && (
          <ExpandableMenuItem
            icon={<InfoCircleOutlined />}
            onClick={() => {
              metrics
                .submitClick({
                  operationId: MetricClickIds.componentDetails
                })
                .withCommonAppContext(props.swa);
              if (props.project) {
                navigate(`/apps/${props.swa.idSoftwareApp}/details?projectId=${props.project.idProject}&scope=common`, {}, scopedAppHash(props.swa));
              } else {
                navigate(`/apps/${props.swa.idSoftwareApp}/details?scope=common`, {}, scopedAppHash(props.swa));
              }
            }}
          >
            Details
          </ExpandableMenuItem>
        )}
        {canGetVersions && (
          <ExpandableMenuItem
            icon={<OrderedListOutlined />}
            onClick={() => {
              metrics
                .submitClick({
                  operationId: MetricClickIds.componentVersions
                })
                .withCommonAppContext(props.swa);
              if (props.project) {
                navigate(`/apps/${props.swa.idSoftwareApp}/versions?projectId=${props.project.idProject}&scope=common`, {}, scopedAppHash(props.swa));
              } else {
                navigate(`/apps/${props.swa.idSoftwareApp}/versions?scope=common`, {}, scopedAppHash(props.swa));
              }
            }}
          >
            Versions
          </ExpandableMenuItem>
        )}
        {props.currentSelection && <DownloadContextMenuButton artifact={props.currentSelection.app} version={props.currentSelection.version} />}
        {props.currentSelection && <SoftwareAppsOpenInToolManagerButton selection={props.currentSelection} project={props.project} />}
      </ExpandableMenu>
    </Space>
  );
};
