import React, { ComponentProps, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { SafetyWarning } from './icons/SafetyWarning';
import { SafetyError } from './icons/SafetyError';
import { SafetyInfo } from './icons/SafetyInfo';

import { VulnerabilityNotificationsDrawer } from '../../../contexts/shared/components/VulnerabilityNotificationsDrawer';

import type { SoftwareAppVersion, SoftwareAppVersionVulnerability, ToolVersionVulnerability } from '../../../api/engineering/domain/types';

const StyledSafetyCertificateOutlined = styled(SafetyCertificateOutlined)`
  color: ${({ theme }) => theme.colorSuccess};
`;

const StyledSafetyError = styled(SafetyError)`
  color: ${({ theme }) => theme.colorWarning};
`;

const StyledSafetyWarning = styled(SafetyWarning)`
  color: ${({ theme }) => theme.colorError};
`;

const StyledSafetyInfo = styled(SafetyInfo)`
  color: ${({ theme }) => theme.colorInfo};
`;

/**
 * Returns an icon button which opens a drawer that displays
 * the vulnerability notifications.
 */
export const VulnerabilityIconButton = (props: {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  notificationsExist: boolean;
  appName: string;
  versionName: string;
  vulnerabilities: ToolVersionVulnerability[] | SoftwareAppVersionVulnerability[] | undefined;
  appVersion?: SoftwareAppVersion;
  getDrawerContainer?: ComponentProps<typeof VulnerabilityNotificationsDrawer>['getDrawerContainer'];
}) => {
  const [open, setOpen] = useState(false);

  function openDrawer() {
    setOpen(true);
  }

  function closeDrawer() {
    setOpen(false);
  }

  const severitiesExist = props.vulnerabilities?.some((v) => v.notifications?.some((n) => {
    if (n.cvss && n.cvss.overallScore && n.cvss.overallScore > 0) {
      return true;
    }

    return false;
  }));

  let icon: React.ReactElement | undefined;
  let tooltip: string | undefined;

  if (props.isLoading) {
    /* nothing to do here */
  } else if (props.isError) {
    tooltip = 'Error loading vulnerabilities';
    icon = <StyledSafetyError />;
  } else if (props.notificationsExist && severitiesExist) {
    tooltip = 'Vulnerabilities found';
    icon = <StyledSafetyWarning />;
  } else if (props.notificationsExist) {
    tooltip = 'No vulnerabilities with Severity > 0 found.';
    icon = <StyledSafetyInfo />;
  } else {
    tooltip = 'No vulnerabilities found';
    icon = <StyledSafetyCertificateOutlined />;
  }

  return (
    <>
      <Tooltip trigger={['click', 'hover']} title={tooltip}>
        <Button type="text" loading={props.isLoading} onClick={openDrawer} icon={icon} />
      </Tooltip>

      <VulnerabilityNotificationsDrawer
        title={`${props.appName} (${props.versionName}) version vulnerabilities`}
        open={open}
        isLoading={props.isLoading}
        notificationsExist={props.notificationsExist}
        vulnerabilities={props.vulnerabilities}
        onClose={closeDrawer}
        isError={props.isError}
        error={props.error}
        appVersion={props.appVersion}
        getDrawerContainer={props.getDrawerContainer}
      />
    </>
  );
};
