import { useLocalStorageState } from '../../../contexts/shared/hooks/useLocalStorageState';
import { PASSED_TOURS_STORAGE_KEY } from '../utils';

const useIsTourPassed = (id: string) => {
  const [passedTours = {}] = useLocalStorageState<Record<string, boolean>>(PASSED_TOURS_STORAGE_KEY);

  return passedTours?.[id] ?? false;
};

export default useIsTourPassed;
