import { useQuery } from '@tanstack/react-query';
import { ProjectType } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProjectTypes() {
  const permissions = usePermissions();
  const { backend } = useEngineeringBackend();
  return useQuery<ProjectType[], [string]>(
    ['projectTypes'],
    (): Promise<ProjectType[]> => {
      return backend.queryProjectTypes();
    },
    { enabled: permissions.engineeringSvc$getProjectTypes }
  );
}
