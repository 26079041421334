import { useState, useEffect } from 'react';

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true }
};

/**
 * Attaches a mutation observer to the target element, and calls the given callback function
 * everytime a mutation occurs.
 * 
 * @param targetEl A node element that should be observed.
 * @param callback A callback function (useCallback()) that should be called when the observed element changes.
 * @param options Options that are passed to the MutationObserver. See: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#options
 */
export function useMutationObservable(targetEl: HTMLElement | null, callback: () => any | void, options = DEFAULT_OPTIONS) {
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    const obs = new MutationObserver(callback);
    setObserver(obs);
  }, [callback, setObserver]);

  useEffect(() => {
    if (!observer || !targetEl) return;
    
    const { config } = options;
    observer.observe(targetEl, config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, options]);
}