import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';
import { usePermissions } from '../../session/hooks/usePermissions';
import { presentSuccess } from '../../../api';

export const useDeleteSystemColumns = (projectId: number) => {
  const { backend } = useReportBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  const queryClient = useQueryClient();

  return useMutation<void, any, void>(async () => {
    try {
      if (permissions.reportSvc$deleteReportSystemColumns) {
        await backend.deleteSystemColumns();
        queryClient.invalidateQueries(['systemColumns']);
        queryClient.invalidateQueries(['projectSources', projectId]);
        queryClient.invalidateQueries(['computedFolderColumns', projectId]);

        presentSuccess('Successfully saved default columns');
      }
    } catch (error) {
      presentSuccess('Something went wrong');
    }
  });
};
