import { GlobalStateAction } from '../globalState';

export enum BasePathActions {
  SET_BASEPATH = 'SET_BASEPATH',
}

export interface SetBasePathAction extends GlobalStateAction {
  type: typeof BasePathActions.SET_BASEPATH,
  payload: string,
}

export type BasePathActionTypes = SetBasePathAction;
