import { useQuery } from '@tanstack/react-query';
import { Project } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProjects() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();

  const permissionsList = permissions.engineeringSvc$getProjects ?? permissions.engineeringSvc$getProject$specific();
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getProject$specific() : [];

  return useQuery<Project[], [string]>(
    ['projects'],
    (): Promise<Project[]> => {
      if (permissionsList) return backend.queryProjects();
      return Promise.all(specificPermissions.map((s) => backend.queryProject(s.projectId)));
    },
    {
      enabled: permissionsList || specificPermissions.length > 0
    }
  );
}
