import { Button, Space } from 'antd';
import { useCreateDevice } from '../hooks/useCreateDevice';
import { DeviceForm } from './DeviceForm';
import { Device } from '../../../api';
import { useParams } from 'react-router-dom';

export const CreateDeviceListEntry = (props: { initial: Device; projectId: number }) => {
  const params = useParams();
  const envId = params?.envId || '';
  const createDevice = useCreateDevice(props.projectId, envId);
  let okHandler: () => any;
  let resetHandler: () => any;
  return (
    <>
      {!createDevice.isSuccess && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <DeviceForm
            envId={envId}
            projectId={props.projectId}
            initial={{ ...props.initial }}
            ok={(ok) => {
              okHandler = ok;
            }}
            reset={(cancel) => {
              resetHandler = cancel;
            }}
            onFinish={async (c) => {
              await createDevice.mutateAsync([c]);
              resetHandler();
            }}
          />
          <Button
            loading={createDevice.isLoading}
            disabled={createDevice.isSuccess}
            onClick={() => {
              okHandler();
            }}
          >
            Create Device
          </Button>
        </Space>
      )}
      {createDevice.isSuccess && `Created ${props.initial.rds}`}
    </>
  );
};

export default CreateDeviceListEntry;
