import { Flex, Skeleton, Space, TableColumnProps, Typography } from 'antd';
import { useMemo } from 'react';
import { Comparator } from '../../../domain/extensions/comparison';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useTableFilter } from '../../shared/components/TableFilter';
import { useTableSearch } from '../../shared/components/TableSearch';
import { useDevices } from '../hooks/useDevices';
import { useOtcs } from '../hooks/useOtcs';
import { CreateDevice } from './CreateDevice';
import { DeviceCsvExport } from './DeviceCsvExport';
import { DeviceCsvPopover } from './DeviceCsvPopover';
import { DeleteDeviceButton } from './DeviceDeleteButton';
import { DeviceInstallations } from './DeviceInstallations';
import { UpdateDevice } from './UpdateDevice';
import Table from '../../../contexts/shared/components/Table/Table';
import { Device } from '../../../api';
import { useParams } from 'react-router-dom';
import { useSearchParameter } from '../../../contexts/navigation/hooks';

export const Devices = (props: { projectId: number }) => {
  const params = useParams();
  const envId = params?.envId || '';
  const devices = useDevices(envId, props.projectId);
  const otcs = useOtcs(props.projectId, envId);
  const permissions = usePermissions({ projectId: props.projectId.toString(), environmentId: envId ?? undefined });
  const deviceSearch = useTableSearch({ searchValueProvider: 'rds', searchParamId: 'device_rds' });
  const descriptionSearch = useTableSearch({ searchValueProvider: 'description', searchParamId: 'device_desc' });

  const [tablePage, setTablePage] = useSearchParameter('device_p', '1');
  const tablePageNumber = parseInt(tablePage || '1');
  const [pageSize, setPageSize] = useSearchParameter('device_ps', '50');
  const pageSizeNumber = parseInt(pageSize || '50');

  const deviceSorter = (a: Device, b: Device) => Comparator.lexicographicalComparison(a.rds, b.rds);

  const descriptionSorter = (a: Device, b: Device) => Comparator.lexicographicalComparison(a.description, b.description);

  const installedSorter = (a: Device, b: Device) => a.actionLog.length - b.actionLog.length;

  const isLoading = !otcs.isSuccess || !devices.isSuccess;

  const otcFilter = useMemo(
    () =>
      otcs.data
        ?.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
        .map((o) => {
          return { text: o.name, value: o.id };
        }) || [],
    [otcs.data]
  );

  const otcFilterProps = useTableFilter<Device>({
    key: 'otc-filter',
    onFilter: (dev: Device, value: string) => dev.otc.map((o) => o.id).includes(value),
    values: otcFilter,
    searchParamId: 'device_otc'
  });

  const installationsFilterProps = useTableFilter<Device>({
    key: 'otc-filter',
    onFilter: (dev: Device, value: string) => !value || dev.actionLog.length === 0,
    values: [
      {
        text: 'Hide devices with installations',
        value: 'true'
      }
    ],
    searchParamId: 'device_hide_with_installs'
  });

  const columns: TableColumnProps<Device>[] = [
    {
      title: 'Device name (RDS)',
      key: 'rds',
      dataIndex: 'rds',
      width: 300,
      fixed: 'left',
      defaultSortOrder: 'ascend',
      ...deviceSearch,
      sorter: deviceSorter
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      sorter: descriptionSorter,
      ...descriptionSearch,
      fixed: 'left'
    },
    {
      title: 'OTC',
      key: 'otc',
      fixed: 'left',
      ...otcFilterProps,
      render: (dev: Device) => <span>{dev.otc.map((otc) => otc.name).join(', ')}</span>
    },
    {
      title: 'Status',
      key: 'installations',
      fixed: 'left',
      width: 200,
      sorter: installedSorter,
      ...installationsFilterProps,
      render: (dev: Device) => {
        const hasInstallations = dev.actionLog.length > 0;
        return (
          <Typography.Text type={hasInstallations ? 'success' : 'warning'}>{hasInstallations ? 'Has installations' : 'Has no installations'}</Typography.Text>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 55,
      render: (p: Device) => (
        <Space>
          <DeviceInstallations device={p} />

          {permissions.deploymentSvc$updateDevice && <UpdateDevice key="update" device={p} projectId={props.projectId} envId={envId} />}
          
          {permissions.deploymentSvc$deleteDevice && <DeleteDeviceButton key="delete" device={p} projectId={props.projectId} envId={envId} />}
        </Space>
      )
    }
  ];

  const table = (
    <>
      <Table
        scroll={{ x: true }}
        size="small"
        columns={columns}
        rowKey={(record: Device) => record?.id.toString() || ''}
        dataSource={devices.data || []}
        pagination={{
          showSizeChanger: true,
          current: tablePageNumber,
          onChange: (n) => setTablePage(n.toString()),
          defaultPageSize: 50,
          pageSize: pageSizeNumber,
          onShowSizeChange: (_, n) => setPageSize(n.toString()),
          pageSizeOptions: ['50', '100', '200']
        }}
      />
    </>
  );

  return (
    <>
      <Flex justify="end" gap="8px" style={{ marginBottom: '15px' }}>
        {permissions.deploymentSvc$addDevice && <DeviceCsvPopover projectId={props.projectId} />}
        {permissions.deploymentSvc$addDevice && <DeviceCsvExport projectId={props.projectId} />}
        {permissions.deploymentSvc$addDevice && <CreateDevice projectId={props.projectId} />}
      </Flex>
      {isLoading && <Skeleton active />}
      {!isLoading && <>{table}</>}
    </>
  );
};
