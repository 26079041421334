import { useState, useEffect } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SemanticVersion } from '../../../domain';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useBundleConfigurationVersions } from '../hooks/useBundleConfigurationVersions';
import { useCreateBundleConfigurationVersion } from '../hooks/useCreateBundleConfigurationVersion';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { ReleaseNotesValidator } from '../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../domain/validation/versionValidator';
import { BundleReleaseLite } from '../../../api/engineering/domain/types';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../constants/texts';

export type AddBundleConfigurationVersionProps = {
  projectId: string;
  bundleId: string;
  bundleConfigurationId: string;
  selectedEngineeringToolVersionIds: number[];
  selectedSoftwareAppVersionIds: number[];
  selectedProjectSoftwareAppVersionIds: number[];
  onAddBundleConfigurationVersion?: (bundleConfigurationVersion: BundleReleaseLite) => any;
};

const initialBcv: BundleReleaseLite = {
  idBundleRelease: 0,
  version: '',
  releaseNotes: '',
  engineeringToolVersionIds: [],
  softwareAppVersionIds: [],
  projectSoftwareAppVersionIds: []
};

export const AddBundleConfigurationVersion = (props: AddBundleConfigurationVersionProps) => {
  const permissions = usePermissions({ projectId: props.projectId });
  const [modalVisible, setModalVisible] = useState(false);
  const createBundleConfigurationVersion = useCreateBundleConfigurationVersion();
  // TODO: Switch to actual latest as soon as fixed in backend
  // const bundleConfiguration = useBundleConfiguration(props.projectId, props.bundleId, props.bundleConfigurationId);
  // const latestBundleConfigurationVersion = useBundleConfigurationVersion(
  //     props.projectId,
  //     props.bundleId,
  //     props.bundleConfigurationId,
  //     bundleConfiguration.data?.latestBundleReleaseId?.toString() || "",
  // );
  const bundleConfigurationVersions = useBundleConfigurationVersions(props.projectId, props.bundleId, props.bundleConfigurationId);
  const [form] = Form.useForm();

  const disabled = props.bundleConfigurationId === '' || props.bundleId === '' || props.projectId === '' || !bundleConfigurationVersions.isSuccess;

  useEffect(() => {
    if (modalVisible) {
      if (bundleConfigurationVersions.data && bundleConfigurationVersions.data.length > 0) {
        const sortedVersions = bundleConfigurationVersions?.data.sort((a, b) =>
          SemanticVersion.fromString(a.version).compareTo(SemanticVersion.fromString(b.version))
        );
        form.setFieldsValue({
          version: SemanticVersion.fromUnsafeString(sortedVersions[0].version || '')
            .increment()
            .toString(),
          releaseNotes: ''
        });
      } else {
        form.setFieldsValue({
          version: SemanticVersion.initial().toString(),
          releaseNotes: ''
        });
      }
    }
  }, [bundleConfigurationVersions.data, form, modalVisible]);

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createBundleConfigurationVersion.isSuccess) {
      setModalVisible(false);
    }
  }, [createBundleConfigurationVersion.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };
  return (
    <>
      {permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
        <Tooltip title="Add Bundle Configuration Version">
          <ExpandableMenuItem
            icon={<PlusOutlined />}
            loading={createBundleConfigurationVersion.isLoading || bundleConfigurationVersions.isLoading}
            disabled={disabled}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Add Version
          </ExpandableMenuItem>
        </Tooltip>
      )}
      <ScrollDialog
        title="Add Bundle Configuration Version"
        destroyOnClose
        open={modalVisible}
        confirmLoading={createBundleConfigurationVersion.isLoading}
        onOk={onSubmit}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          initialValues={() => {
            return { ...initialBcv };
          }}
          onFinish={(value) => {
            const copy = Object.assign(initialBcv, value);
            copy.engineeringToolVersionIds = props.selectedEngineeringToolVersionIds;
            copy.softwareAppVersionIds = props.selectedSoftwareAppVersionIds;
            copy.projectSoftwareAppVersionIds = props.selectedProjectSoftwareAppVersionIds;
            createBundleConfigurationVersion.mutateAsync([props.projectId, props.bundleId, props.bundleConfigurationId, copy]).then((bcv: any) => {
              if (props.onAddBundleConfigurationVersion && bcv) {
                props.onAddBundleConfigurationVersion(bcv);
              }
            });
          }}
        >
          <Form.Item label="Version" name="version" rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            label="Release Notes"
            field={['releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            rules={[ReleaseNotesValidator.rule()]}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
        </Form>
      </ScrollDialog>
    </>
  );
};
