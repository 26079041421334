import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ImportProjectRequestPayload } from '../../../api/pactsformation/domain/types';
import { usePactsFormationBackend } from '../../../api/pactsformation/hooks/usePactsFormationBackend';

export const useImportProject = () => {
  const { backend } = usePactsFormationBackend();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ImportProjectRequestPayload) => {
      return backend.importProject(data);
    },
    {
      onSettled: (res, err, data) => {
        queryClient.invalidateQueries(['pactsformation', 'resources', 'projects', data.id.toString()]);
      }
    }
  );
};
