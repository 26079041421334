import { Space } from 'antd';
import { PacTSNotification } from '../../../../api/notifications/domain/types';
import { DirectMessage } from '../../../shared/components/icons/DirectMessage';
import { DefaultNotification } from './DefaultNotification';
import { useStyles } from '../../../theme/useStyles';

export const Direct = (props: { notification: PacTSNotification }) => {
  const token = useStyles();
  return (
    <Space size="large">
      <DirectMessage style={{ fontSize: '24px', color: token.colorPrimary }} />
      <DefaultNotification notification={props.notification} showCreator />
    </Space>
  );
};
