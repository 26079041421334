/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

type IconProps = {
  style?: React.CSSProperties;
  className?: string;
};
export const DirectMessage = (props: IconProps) => {
  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg width="1em" height="1em" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M23.5 1H4.5C3.57255 1.00264 2.68385 1.37223 2.02804 2.02804C1.37223 2.68385 1.00264 3.57255 1 4.5V16.5C1.00264 17.4274 1.37223 18.3162 2.02804 18.972C2.68385 19.6278 3.57255 19.9974 4.5 20H7V25L12.8575 20.1162C12.9474 20.0411 13.0609 20 13.1781 20H23.5C24.4274 19.9974 25.3162 19.6278 25.972 18.972C26.6278 18.3162 26.9974 17.4274 27 16.5V4.5C26.9974 3.57255 26.6278 2.68385 25.972 2.02804C25.3162 1.37223 24.4274 1.00264 23.5 1Z"
        stroke="currentColor"
        strokeWidth="2" 
        strokeLinejoin="round"
      />
    </svg>
  );
  return <Icon {...props} component={svg} />;
};
