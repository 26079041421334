import { jwtDecode } from 'jwt-decode';
import { useContext, useMemo } from 'react';
import { PacTSContext } from '../../../state/store';

export const useTokenIssueDate = (): Date | undefined => {
  const [state] = useContext(PacTSContext);

  const tokenIaT = useMemo(() => {
    try {
      const decoded = jwtDecode<{ iat: number }>(state?.token?.token);
      const iat = decoded.iat;
      return new Date(iat * 1000);
    } catch (error) {
      return undefined;
    }
  }, [state?.token]);
  return tokenIaT;
};
