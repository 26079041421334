import { Checkbox as AntCheckbox, Flex, Form as AntForm, FormInstance, Select, Typography } from 'antd';
import DeploymentPlanFormAlert from './DeploymentPlanFormAlert';
import { FormProps } from 'antd/lib/form';
import { DeploymentPlanPrototype } from './DeploymentPlanForm';
import { UseQueryResult } from '@tanstack/react-query';
import { Device } from '../../../api';
import { ScopedSoftwareApp } from '../../../contexts/softwareApps';
import styled from 'styled-components';

interface DeploymentSelectionFormProps {
  formItemLayout: Partial<FormProps>;
  setSubmitValue: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  redirectToFollowingStep: () => void;
  secondFormInitials: DeploymentPlanPrototype | undefined;
  secondForm: FormInstance<DeploymentPlanPrototype>;
  selectedComponents: number[];
  isSecondFormAlert: boolean;
  setSelectedComponents: React.Dispatch<React.SetStateAction<number[]>>;
  toggleAlertCheck: (formErrors: Array<{ errors?: string[] }>) => void;
  devices: UseQueryResult<Device[], unknown>;
  sortedDevices: Device[];
}

const Form = styled(AntForm)`
  .ant-form-item-label {
    overflow: visible !important;
  }
` as typeof AntForm;

const Checkbox = styled(AntCheckbox)`
  margin-right: 15px;
  ${(props) =>
    !props.checked
      ? `
    .ant-checkbox-inner {
      border-color: ${props.theme.colorWarning};
    }
  `
      : ''};
`;

const StyledFormItem = styled(Form.Item)`
  /* Compensate for form height difference when validation error is shown */
  .ant-form-item-margin-offset {
    margin-bottom: -6px !important;
  }
`;

const DeploymentSelectionForm: React.FC<DeploymentSelectionFormProps> = ({
  formItemLayout,
  setSubmitValue,
  redirectToFollowingStep,
  secondFormInitials,
  secondForm,
  selectedComponents,
  isSecondFormAlert,
  setSelectedComponents,
  toggleAlertCheck,
  devices,
  sortedDevices
}) => {
  return (
    <Form
      {...formItemLayout}
      labelAlign="left"
      colon={false}
      name="basic"
      onFinish={async (value: Record<string, any>) => {
        setSubmitValue((prev) => ({
          ...prev,
          ...value
        }));

        redirectToFollowingStep();
      }}
      initialValues={{ ...secondFormInitials }}
      form={secondForm}
    >
      <Form.List name={['deployments']}>
        {(fields) => (
          <>
            {!selectedComponents.length && <DeploymentPlanFormAlert message="At least one software must be selected." />}
            {isSecondFormAlert && (
              <DeploymentPlanFormAlert message="Before proceeding to the next step, please make sure all mandatory fields are completed." />
            )}
            {fields.map((field) => {
              const app = secondForm.getFieldValue(['deployments', field.name, 'selection', 'app']) as ScopedSoftwareApp;
              const isChecked = selectedComponents.includes(app.idSoftwareApp);

              return (
                <StyledFormItem
                  label={
                    <Flex align="center" style={{ overflowX: 'hidden', width: '300px' }}>
                      <Checkbox
                        checked={isChecked}
                        onChange={(e) => {
                          const newChecked = e.target.checked;

                          if (newChecked) {
                            setSelectedComponents((prev) => [...prev, app.idSoftwareApp]);
                          } else {
                            setSelectedComponents((prev) => prev.filter((val) => val != app.idSoftwareApp));
                          }
                          secondForm
                            .validateFields()
                            .then(() => {
                              toggleAlertCheck([]);
                            })
                            .catch((errorFields) => {
                              toggleAlertCheck(errorFields);
                            });
                        }}
                      />
                      <div style={{ overflow: 'hidden' }}>
                        <Typography.Text style={{ width: '100%', textOverflow: 'ellipsis' }} ellipsis={{ tooltip: app.name }}>
                          {app.name}
                        </Typography.Text>
                        <Typography.Text type="secondary" ellipsis={{ tooltip: app.latestVersion!.version }}>
                          {app.latestVersion!.version}
                        </Typography.Text>
                      </div>
                    </Flex>
                  }
                  key={field.key}
                  rules={[
                    {
                      required: isChecked,
                      message: 'Device is Required'
                    }
                  ]}
                  required
                  name={[field.name, 'device']}
                >
                  {isChecked ? (
                    <Form.Item noStyle style={{ width: 'calc(100% - 300px) !important' }}>
                      <Select
                        autoClearSearchValue={false}
                        mode="multiple"
                        style={{ width: '100%' }}
                        loading={devices.isLoading}
                        value={(secondForm.getFieldValue(['deployments', field.name, 'device']) as Device[]).map((d) => d.id)}
                        onChange={(allSelected) => {
                          const formValue = secondForm.getFieldsValue();
                          const formErrors = secondForm.getFieldsError();
                          const isErrorsPropagated = formErrors.some((err) => err.errors.length);
                          const device = allSelected.map((deviceId) => sortedDevices?.find((i) => i.id === deviceId)!);
                          if (device) {
                            formValue.deployments[field.name].device = device;
                            secondForm.setFieldsValue({ ...formValue });

                            if (isErrorsPropagated) {
                              secondForm
                                .validateFields()
                                .then(() => {
                                  toggleAlertCheck([]);
                                })
                                .catch((errorFields) => {
                                  toggleAlertCheck(errorFields);
                                });
                            }
                          }
                        }}
                        placeholder="Devices"
                        filterOption={(inputValue, option) => {
                          return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {sortedDevices?.map((option: Device) => {
                          return (
                            <Select.Option title={`${option.rds} (${option.description})`} key={option.id} value={option.id}>
                              {`${option.rds} (${option.description})`}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Typography.Text strong type="warning">
                      Will not be deployed.
                    </Typography.Text>
                  )}
                </StyledFormItem>
              );
            })}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default DeploymentSelectionForm;
