import { ReactElement, useEffect } from 'react';
import { useNotificationWebsocket } from './useNotificationWebsocket';
import { usePactsFormationWebsocket } from './pactsFormationWebsocket';
import { usePactsFormationHandler } from '../../../contexts/pactsformation/hooks/useResources';
import { useInvalidatePactsFormationHeartbeat, usePactsFormationHeartbeat } from '../../../contexts/pactsformation/hooks/usePactsFormationHeartbeat';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { useInvalidateNotificationHeartbeat, useNotificationHeartbeat } from '../../../contexts/notifications/hooks/useNotificationHeartbeat';
import { usePacTSFormationAvailable } from '../../../contexts/session/hooks/usePacTSFormationAvailable';

const pollingInterval = 60000;

const PactsFormationHooks = (): ReactElement | null => {
  const [, pactsFormationWebsocketState] = usePactsFormationWebsocket();
  usePactsFormationHeartbeat();
  usePactsFormationHandler();
  const invalidatePacTSFormationHeartbeat = useInvalidatePactsFormationHeartbeat();
  useEffect(() => {
    const poller = setInterval(() => {
      if (pactsFormationWebsocketState.isReady) {
        invalidatePacTSFormationHeartbeat();
      }
    }, pollingInterval);
    return () => {
      clearInterval(poller);
    };
  }, [pactsFormationWebsocketState, invalidatePacTSFormationHeartbeat]);
  return null;
};

const NotificationHooks = (): ReactElement | null => {
  const [, notificationWebsocketState] = useNotificationWebsocket();
  useNotificationHeartbeat();

  const invalidateNotificationHeartbeat = useInvalidateNotificationHeartbeat();
  useEffect(() => {
    const poller = setInterval(() => {
      if (notificationWebsocketState.isReady) {
        invalidateNotificationHeartbeat();
      }
    }, pollingInterval);
    return () => {
      clearInterval(poller);
    };
  }, [notificationWebsocketState, invalidateNotificationHeartbeat]);

  return null;
};

export const WebsocketPoller = (props: { children: React.ReactNode }) => {
  const permissions = usePermissions();
  const pacTSFormationEnabled = usePacTSFormationAvailable();

  return (
    <>
      {pacTSFormationEnabled && <PactsFormationHooks />}
      {permissions.notificationSvc$pushNotification && <NotificationHooks />}
      {props.children}
    </>
  );
};
