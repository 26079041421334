/* eslint-disable */

import { useEffect, useState } from 'react';
import { Folder, MetaArgument } from '../../../api/report/domain/types';
import { ReportRevision, Document } from '../../../api/report/domain/types';
import _ from 'lodash';
import { Comparator } from '../../../domain/extensions/comparison';
import { camelCaseToName, hashString, valueFromObjectPath } from '../shared/util';
import { MetaGroup } from '@pacts/reportservice-api';

// Name of attribute group that does not belong to extra metadata
const generalGroupName = 'General';

export type ColumnType = {
  path: string[];
  name: string;
  type: 'filter' | 'search' | 'none';
  filterValues?: string[];
  id: string;
  group: string;
};

type FlatMetaField = {
  key: string;
  path: string[];
  value: string;
  name: string;
  group: string;
};

export type FlatDocument = Document & {
  reportPath: string[];
};

export type FlatifiedResult = {
  documents: FlatDocument[];
  columns: ColumnType[];
  folders: string[][];
  digest: string;
};

const searchParamIdFromPath = (path: string[]): string => {
  const searchParamIdArray = [...path];
  searchParamIdArray.shift(); // remove .meta

  let valueIndex = searchParamIdArray.findIndex((v) => v === 'value');
  if (valueIndex > -1) {
    searchParamIdArray.splice(valueIndex, 1);
  }

  const searchParamId = searchParamIdArray.join('.');
  return searchParamId;
};

const defaultColumns: ColumnType[] = [
  { name: 'Name', path: ['meta', 'name'], type: 'filter', id: searchParamIdFromPath(['meta', 'name']), group: generalGroupName },
  { name: 'Version', path: ['meta', 'version'], type: 'search', id: searchParamIdFromPath(['meta', 'version']), group: generalGroupName }
];

// Prioritize order of certain columns, higher numbers are more important
const columnPriorities: { [index: string]: number } = {
  name: 1,
  report: 2
};

const getColumnPriority = (col: string): number => {
  return columnPriorities[col] ?? 0;
};

// Get keys of extra meta groups
const getExtraGroupKeys = (doc: Document) => Object.keys(doc.meta.extra);

// Get keys of "default" metadata. Remove extra attribute as this is handled separately
const getDefaultGroupKeys = (doc: Document) => Object.keys(doc.meta).filter((k) => k !== 'extra');

// Retrieve meta group and transform to intermediate group with path
const toMetaGroup = (doc: Document) => (key: string) => {
  const value = valueFromObjectPath<MetaGroup>(['meta', 'extra', key])(doc);
  return {
    name: value.name,
    path: ['meta', 'extra', key],
    group: value
  };
};

// Transform intermediate group to flat meta argument result
const toMetaGroupValues = (group: { name: string; path: string[]; group: MetaGroup }): FlatMetaField[] =>
  Object.keys(group.group.meta).map((k) => {
    const metaArg: MetaArgument = valueFromObjectPath<MetaArgument>(['meta', k])(group.group);
    return {
      key: k,
      path: [...group.path, 'meta', k, 'value'],
      value: metaArg.value,
      name: metaArg.name,
      group: group.group.name
    };
  });

// Transform default meta argument to flat meta argument result
const toDefaultMetaGroupValue =
  (doc: Document) =>
  (propertyKey: string): FlatMetaField => {
    const value = valueFromObjectPath<string>(['meta', propertyKey])(doc);
    return { name: camelCaseToName(propertyKey), path: ['meta', propertyKey], key: propertyKey, value: value, group: generalGroupName };
  };

// Extract meta fields from a document in
const getColumnsFromDocument = (doc: Document): FlatMetaField[] => {
  const extraGroups = getExtraGroupKeys(doc).map(toMetaGroup(doc)).map(toMetaGroupValues).flat();
  const defaultGroup = getDefaultGroupKeys(doc).map(toDefaultMetaGroupValue(doc));
  return [...extraGroups, ...defaultGroup];
};

const extractFoldersAndDocuments = (revision?: ReportRevision): { folders: string[][]; documents: FlatDocument[] } => {
  const folders: string[][] = [];
  const documents: FlatDocument[] = [...(revision?.documents || []).map((d) => ({ ...d, reportPath: [] }))];
  const add = (folder: Folder, pathSoFar: string[]) => {
    const currentPath = [...pathSoFar, folder.meta.name];
    folders.push(currentPath);
    documents.push(...folder.documents.map((d) => ({ ...d, reportPath: currentPath })));
    folder.folders.forEach((d) => add(d, currentPath));
  };
  revision?.folders.forEach((d) => add(d, []));
  return { folders, documents };
};

// Extract column types from document. Note that those column types do not yet contain column values for performance optimizations.
const extractColumnsFromDocument = (doc: Document): ColumnType[] => {
  const documentColumns = getColumnsFromDocument(doc);
  return [
    ...defaultColumns,
    ...documentColumns
      .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
      .map((k) => {
        const entry: ColumnType = {
          path: k.path,
          name: k.name,
          type: 'none',
          group: k.group,
          id: searchParamIdFromPath(k.path)
        };
        return entry;
      })
  ];
};

const columnTypeJsonPath = (c: ColumnType) => c.path.join('.');

export const useFlatifiedReports = (revision?: ReportRevision) => {
  const [retState, setRetState] = useState<FlatifiedResult | undefined>();

  useEffect(() => {
    // run in timeout to change render cycle
    const timeout = setTimeout(() => {
      const { folders, documents } = extractFoldersAndDocuments(revision);

      // For each document, add columns
      // Here we get an array of all entries with all columns
      const columnsWithDuplicates = documents.map(extractColumnsFromDocument).flat();
      // Remove duplicate columns, since we currently have all columns of all entries
      const columns = _.uniqBy(columnsWithDuplicates, columnTypeJsonPath);
      const typedColumns: ColumnType[] =
        //
        columns
          .map((t) => {
            return {
              col: t,
              // map each document to value of given column, take unique values and filter out empty values
              values: _.uniq(documents.map(valueFromObjectPath<string>(t.path)).filter(Boolean))
            };
          })
          // filter columns, that are empty for all documents
          .filter((t) => t.values.length > 0)
          // map to either filter or search type column based on value length
          .map((t) => {
            let type: 'none' | 'filter' | 'search' = 'none';
            let filterValues: string[] | undefined;
            if (t.values.length > 0) {
              type = 'filter';
              filterValues = t.values;
            }
            if (t.values.length > 50) {
              type = 'search';
              filterValues = undefined;
            }
            return {
              path: t.col.path,
              name: t.col.name,
              id: t.col.id,
              type,
              group: t.col.group,
              filterValues
            };
          })
          // sort column to prioritize default columns and sort rest alphabetically
          .sort((a, b) => {
            const priorityComparisonResult = getColumnPriority(b.id) - getColumnPriority(a.id);
            if (priorityComparisonResult !== 0) return priorityComparisonResult;
            return Comparator.lexicographicalComparison(a.name, b.name);
          });

      const hasData = !!revision?.meta;
      const jsonForDigest = JSON.stringify([revision?.meta, documents, typedColumns, folders]);
      const digest = hasData ? hashString(jsonForDigest).toString() : '';

      setRetState({
        documents: documents.sort((a, b) => Comparator.lexicographicalComparison(a.meta.name, b.meta.name)),
        columns: typedColumns,
        folders,
        digest
      });
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [revision, setRetState]);
  return retState;
};
