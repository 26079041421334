import { VersionPart } from './versionPart';

export class VersionNumber implements VersionPart {
  private static readonly DELIMITER = '.';

  private readonly versionParts: number[];

  private readonly raw: string;

  public constructor(s: string) {
    const splitted = s.split(VersionNumber.DELIMITER);
    this.raw = s;
    this.versionParts = splitted.map((v) => parseInt(v, 10)).filter((p) => !Number.isNaN(p));
  }

  equals(part: VersionPart): boolean {
    if (part instanceof VersionNumber) {
      return this.toString() === part.toString();
    }
    return false;
  }

  compareTo(part: VersionPart): number {
    if (part instanceof VersionNumber) {
      const partAsVersion = part as VersionNumber;
      const minPartsLength = Math.min(partAsVersion.numberOfParts(), this.numberOfParts());
      for (let i = 0; i < minPartsLength; i++) {
        if (this.versionParts[i] !== partAsVersion.versionParts[i]) {
          return partAsVersion.versionParts[i] - this.versionParts[i];
        }
      }
      return partAsVersion.numberOfParts() - this.numberOfParts();
    }
    return -1;
  }

  public increment() {
    const toAdd = Math.max(0, 3 - this.versionParts.length);
    for (let i = 0; i < toAdd; i++) {
      this.versionParts.push(0);
    }
    this.versionParts[this.versionParts.length - 1]++;
  }

  public numberOfParts() {
    return this.versionParts.length;
  }

  public toString(): string {
    return this.versionParts.join(VersionNumber.DELIMITER);
  }

  public inputString(): string {
    return this.raw;
  }
}
