import { Skeleton } from 'antd';
import { useCommonSoftwareAppsScoped } from '../hooks/useCommonSoftwareApps';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { SoftwareAppsSubTableProvider } from './SoftwareAppsSubTableProvider';

export const CommonSoftwareAppsList = (props: {
  selected: SoftwareAppSelection[];
  initiallySelected: SoftwareAppSelection[];
  showBundleItemsOnly?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareAppSelection[]) => any;
}) => {
  const commonSoftwareApps = useCommonSoftwareAppsScoped();

  const isLoading = commonSoftwareApps.isLoading;
  const isSuccess = commonSoftwareApps.isSuccess;

  return (
    <div>
      {isLoading && <Skeleton active />}
      {isSuccess && <SoftwareAppsSubTableProvider apps={commonSoftwareApps.data} {...props} hideCheckboxes />}
    </div>
  );
};
