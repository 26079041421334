import { Select } from 'antd';
import { ProjectMember, ProjectMemberChange, ProjectRole, ProjectRoleWithPermissions } from '../../../api/engineering/domain/types';
import { User } from '../../../api/user/domain/users';
import { useProjectMembers } from '../../projects/hooks/useProjectMembers';
import { useAssignProjectMember } from '../hooks/useAssignProjectMember';
import { useProjectRoles } from '../hooks/useProjectRoles';
import { useUnassignProjectMember } from '../hooks/useUnassingProjectMember';
import { useUpdateProjectMember } from '../hooks/useUpdateProjectMember';
import { useEffect, useMemo } from 'react';
import { Comparator } from '../../../domain';

const { Option } = Select;

export type ProjectMemberRolesDropdownProps = {
  projectId: string;
  user: User;
  onRolesUpdated?: (roles: ProjectRole[]) => any;
};

export const ProjectMemberRolesDropdown = (props: ProjectMemberRolesDropdownProps) => {
  const projectMembers = useProjectMembers(props.projectId);
  const projectRoles = useProjectRoles();
  const assignProjectMember = useAssignProjectMember();
  const updateProjectMember = useUpdateProjectMember();
  const unassignProjectMember = useUnassignProjectMember();

  const userRoles = useMemo(() => {
    return projectMembers.data?.find((member: ProjectMember) => member.member.idMember?.toString() === props.user.id?.toString())?.roles || [];
  }, [projectMembers.data, props.user.id]);

  useEffect(() => {
    if (props.onRolesUpdated) {
      props.onRolesUpdated(userRoles);
    }
  }, [userRoles, props]);

  const isLoading = projectMembers.isLoading || projectRoles.isLoading;

  const onChange = (selectedRoleIds: number[]) => {
    const selectedRoles = selectedRoleIds
      .map((sId) => projectRoles.data?.find((pr) => pr.idProjectRole === sId))
      .filter((r) => r !== undefined) as ProjectRoleWithPermissions[];

    const userId = props.user.id;
    const pmToAssign: ProjectMemberChange = {
      member: {
        idMember: userId
      },
      roles: selectedRoles
    };

    if (userRoles.length < 1) {
      assignProjectMember.mutate([props.projectId, pmToAssign]);
    } else if (selectedRoles.length < 1) {
      unassignProjectMember.mutate([props.projectId, pmToAssign]);
    } else {
      updateProjectMember.mutate([props.projectId, pmToAssign]);
    }
  };

  return (
    <>
      <Select
        loading={isLoading}
        className={`project-member-roles-selector-${props.projectId}-${props.user.id}`}
        mode="multiple"
        style={{ width: '100%' }}
        value={isLoading ? [] : userRoles.map((ur: any) => ur.idProjectRole || -1)}
        placeholder="Not a Project Member"
        onChange={onChange}
        filterOption={(input, option) => (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
      >
        {(projectRoles.data ?? [])
          .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
          .map((pr) => {
            return (
              <Option key={pr.idProjectRole || -1} value={pr.idProjectRole || -1}>
                {pr.name}
              </Option>
            );
          })}
      </Select>
    </>
  );
};
