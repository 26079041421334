import { useMemo } from 'react';
import EventEmitter from 'eventemitter3';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Configuration, MetricsApi } from '@pacts/metrics-api';
import { MetricsBackend, MetricsBackendError } from '../service/metricsBackend';
import { useRestBackendConfig } from '../../shared/useBackendConfiguration';
import { MetricEvent, SubmitMetricEvent, METRIC_TAGS } from '../domain/types';
import { RequestBatcher } from '../../shared/requestBatcher';
import { v4 } from 'uuid';
import { SharedAxiosInstance } from '../../shared/sharedAxiosInstance';
import { GlobalState } from '../../../state/globalState';

const defaultProject = 'pacts';
const defaultCollection = 'web-ui';
const sessionCorrelationId = v4();

class RestMetricsBackend implements MetricsBackend {
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly mApi: MetricsApi;

  private readonly eventBatcher: RequestBatcher<MetricEvent>;

  constructor(
    public readonly config: Configuration,
    private readonly project: string,
    private readonly collection: string,
    private readonly correlationId: string,
    instance: AxiosInstance
  ) {
    this.mApi = new MetricsApi(config, undefined, instance);
    this.eventBatcher = new RequestBatcher(10, 1000, this.sendEventsToBackend.bind(this));
  }

  submitEvent(event: SubmitMetricEvent): void {
    if (window.location.pathname) {
      event.tags.push({ key: METRIC_TAGS.pathname, value: window.location.pathname });
    }
    if (window.location.search) {
      event.tags.push({ key: METRIC_TAGS.search, value: window.location.search });
    }
    this.eventBatcher.submit({
      project: this.project,
      collection: this.collection,
      correlationId: this.correlationId,
      timestamp: new Date().toISOString(),
      ...event
    });
  }

  private sendEventsToBackend(events: MetricEvent[]): void {
    this.requestIgnoringResponse(this.mApi.submitEvents(events));
  }

  onError(handler: (error: MetricsBackendError) => any): void {
    this.emitter.on('error', handler);
  }

  private errorHandler(rejector: (error: MetricsBackendError) => any): (error: AxiosError) => any {
    return (error: AxiosError) => {
      const resData = error.response?.data as { message: string; details: { field: string; info: string; value: string }[] | undefined } | undefined | null;
      const resMessage = resData?.message ?? error.message;
      const resDetails: { path: string; message: string; value: string }[] =
        resData?.details?.map((d) => {
          return { message: d.info, path: d.field, value: d.value };
        }) || [];
      const err = new MetricsBackendError(resMessage, error.response?.status || 999, resDetails);
      this.emitter.emit('error', err);
      rejector(err);
    };
  }

  private requestIgnoringResponse<T>(promise: Promise<AxiosResponse<T>>) {
    promise.catch(
      this.errorHandler(() => {
        /* */
      }).bind(this)
    );
  }
}

export const useRestMetricsBackend = (state: GlobalState) => {
  const config = useRestBackendConfig(state.metricsServiceBasePath);
  const backend = useMemo(
    () => new RestMetricsBackend(new Configuration(config), defaultProject, defaultCollection, sessionCorrelationId, SharedAxiosInstance.instance()),
    [config]
  );
  return backend;
};
