import { useQuery } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { usePermissions } from '../../session';
import { Otc } from '../../../api';

export const useOtcs = (projectId: number, envId: string) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({ projectId: projectId.toString(), environmentId: envId });
  return useQuery<Otc[], [string, string, string]>(
    ['otc', projectId, envId],
    (): Promise<Otc[]> => {
      return backend.queryOtc(projectId, envId);
    },
    {
      enabled: permissions.deploymentSvc$getOtcs
    }
  );
};
