import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Tooltip } from 'antd';

import { useUpdateDevice } from '../hooks/useUpdateDevice';
import { DeviceForm } from './DeviceForm';
import { Device } from '../../../api';


export const UpdateDevice = (props: { device: Device; projectId: number; envId: string }) => {
  const updateDevice = useUpdateDevice(props.projectId, props.envId);
  const [modalVisible, setModalVisible] = useState(false);
  let okHandler: () => any;
  let resetHandler: () => any;

  const handleCloseDrawer = () => {
    resetHandler();
    setModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Edit">
        <Button
          loading={updateDevice.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
          icon={<EditOutlined />}
          type="text"
        />
      </Tooltip>

      <Drawer
        title="Edit Device"
        width={780}
        destroyOnClose
        onClose={handleCloseDrawer}
        footer={
          <Flex justify="end">
            <Button style={{ width: '125px', marginRight: '10px' }} onClick={handleCloseDrawer} loading={updateDevice.isLoading}>
              Cancel
            </Button>
            <Button style={{ width: '125px' }} type="primary" onClick={() => okHandler()} loading={updateDevice.isLoading}>
              Save
            </Button>
          </Flex>
        }
        open={modalVisible}
      >
        <DeviceForm
          envId={props.envId}
          projectId={props.projectId}
          initial={{ ...props.device }}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          onFinish={async (c) => {
            await updateDevice.mutateAsync([c]);
            setModalVisible(false);
          }}
        />
      </Drawer>
    </>
  );
};
