/* eslint-disable max-len */
import { PacTSIconLight } from './PacTSIconLight';
import { PacTSIconDark } from './PacTSIconDark';
import { useTheme, THEMES } from '../../../../contexts/theme';

export type PacTSIconProps = {
  style?: React.CSSProperties;
};

export const PacTSIcon: React.FunctionComponent<PacTSIconProps> = (props: PacTSIconProps) => {
  const [theme] = useTheme();

  const isLightTheme = theme === THEMES.LIGHT;

  return isLightTheme ? <PacTSIconDark {...props} /> : <PacTSIconLight {...props} />;
};
