import { useQuery } from '@tanstack/react-query';
import { useReportBackend } from '../../../api/report/hooks/useReportBackend';

export function useSystemColumns() {
  const { backend } = useReportBackend();
  return useQuery<{ [key: string]: object[]; }, [string]>(
    ['systemColumns'],
    (): Promise<{ [key: string]: object[]; }> => {
      return backend.getSystemColumns();
    }
  );
}
