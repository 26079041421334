import styled, { keyframes } from 'styled-components';
import { PacTSIconSimple } from './PacTSIconSimple';
import { CSSProperties } from 'react';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateTwice = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ConstantRotateAnimator = styled.span`
  animation: ${rotate} 1.2s linear infinite;
  transform-origin: center center;
  display: inline-block !important;
  line-height: 1em;
`;

const BouncyRotateAnimator = styled.span`
  animation: ${rotateTwice} 1.6s ease-in-out infinite;
  transform-origin: center center;
  display: inline-block !important;
  line-height: 1em;
`;

export const AnimatedLoadingIcon = (props: { style?: CSSProperties }) => {
  return (
    <span style={{ ...props.style, display: 'inline-blocky' }}>
      <BouncyRotateAnimator>
        <ConstantRotateAnimator>
          <PacTSIconSimple style={props.style} />
        </ConstantRotateAnimator>
      </BouncyRotateAnimator>
    </span>
  );
};
