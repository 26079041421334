import { Input } from 'antd';

interface DescriptionTextAreaInputProps {
  autoSize?: {
    minRows?: number;
    maxRows?: number;
  };
  placeholder?: string;
  styles?: React.CSSProperties;
  value?: string | any;
  contentEditable?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const DescriptionTextAreaInput: React.FC<DescriptionTextAreaInputProps> = ({
  autoSize = { minRows: 6, maxRows: 20 },
  placeholder,
  styles,
  value,
  contentEditable,
  onChange
}) => {
  return <Input.TextArea autoSize={autoSize} placeholder={placeholder} style={styles} value={value} contentEditable={contentEditable} onChange={onChange} />;
};
