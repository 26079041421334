import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

/* eslint-disable max-len */

type IconProps = {
    style?: React.CSSProperties;
    className?: string;
};

export const InstallationNotesIcon = (props: IconProps) => {
    const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
        <svg width="1em" height="1em" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.63636 6.2381H11.9091M4.63636 11H10.0909M4.63636 15.7619H11.9091M1.90909 21H15.0909C15.593 21 16 20.5736 16 20.0476V1.95238C16 1.4264 15.593 1 15.0909 1H1.90909C1.40701 1 1 1.4264 1 1.95238V20.0476C1 20.5736 1.40701 21 1.90909 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
        </svg>
    );

    return <Icon {...props} component={svg} />;
};
