import { Typography } from 'antd';
import { Project } from '../../../api/engineering/domain/types';
import { MarkdownRenderer } from '../../../contexts/shared/components/MarkdownRenderer';

export type ProjectDetailsProps = {
  project: Project;
};

export const ProjectDetails = (props: ProjectDetailsProps) => {
  return (
    <div style={{ maxWidth: '100%' }}>
      <Typography.Title level={4} style={{ marginBottom: 16 }}>
        Description
      </Typography.Title>
      <MarkdownRenderer maxImageWith="50%">{props.project.description}</MarkdownRenderer>
    </div>
  );
};
