import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectSolutionType } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateProjectSolutionType() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ProjectSolutionType, any, [ProjectSolutionType]>(([update]) => {
    return new Promise<ProjectSolutionType>((resolve, reject) => {
      backend
        .updateProjectSolutionType(update)
        .then((dev) => {
          presentSuccess(`Successfully updated Project Solution Type ${update.name}`);
          queryClient.invalidateQueries(['projectSolutionTypes']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
