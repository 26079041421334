import { Result } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Navigate, useLocation } from 'react-router-dom';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { METRIC_TAGS } from '../../../api/metrics/domain/types';

export const NotFound = () => {
  const location = useLocation();
  const ref = `${location.state?.from?.pathname ?? ''}${location.state?.from?.search ?? ''}${location.state?.from?.hash ?? ''}`;

  // If 404 has no referrer, the user intentionally went there -> redirect to home.
  // In case a user is not logged in or active this will further redirect to login.
  if (!ref) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      {!!ref && <MetricOnVisible view={MetricViewIds.notFound} payload={[{ key: METRIC_TAGS.context, value: ref }]} />}
      <Result icon={<QuestionCircleOutlined />} title="404" subTitle="Sorry, the page you visited does not exist." />
    </>
  );
};
