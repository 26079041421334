import { LockOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { useState } from 'react';
import { DeploymentPlanMeta } from '../../../api/deployment/domain/deployments';
import { PermanentActionWarningLabel } from '../../shared/components/PermanentActionWarningLabel';
import { useLockDeploymentPlan } from '../hooks/useLockDeploymentPlan';
import { ScrollDialog } from '../../layout/components/ScrollDialog';

export const LockDeploymentPlanDeploymentButton = (props: { deploymentPlan: DeploymentPlanMeta; envId: string; projectId: number }) => {
  const lock = useLockDeploymentPlan();
  const [updating, setUpdating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <ScrollDialog
        title="Close deployment"
        width={512}
        confirmLoading={updating}
        open={isVisible}
        okButtonProps={{ disabled: props.deploymentPlan.locked }}
        cancelButtonProps={{ disabled: updating }}
        onOk={() => {
          setUpdating(true);
          lock
            .mutateAsync([props.projectId, props.envId, props.deploymentPlan.id, props.deploymentPlan.mvccId])
            .finally(() => {
              setUpdating(false);
            })
            .then(() => {
              setIsVisible(false);
            });
        }}
        onCancel={() => {
          setIsVisible(false);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Typography.Text>Please confirm that you want to close the deployment plan </Typography.Text>
            <Typography.Text strong type="warning">
              {props.deploymentPlan.name}.
            </Typography.Text>
            <Typography.Text> Closed plans are archived and can neither be updated nor reopened.</Typography.Text>
          </div>
          <PermanentActionWarningLabel />
        </Space>
      </ScrollDialog>
      <Button
        style={{ width: '100px' }}
        loading={updating}
        onClick={() => {
          setIsVisible(true);
        }}
        key="install"
        icon={<LockOutlined />}
        disabled={props.deploymentPlan.locked}
      >
        Close
      </Button>
    </>
  );
};
