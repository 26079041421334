import { useQuery } from '@tanstack/react-query';
import { User, UserRole } from '../../../api/user/domain/users';
import { useUserBackend } from '../../../api/user/hooks/useUserBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useUserRolesOfUser(user: User) {
  const { backend } = useUserBackend();
  const permissions = usePermissions();
  return useQuery<UserRole[], [string, string]>(
    ['userRoles', user.id!.toString()],
    (): Promise<UserRole[]> => {
      return backend.queryUserRoles(user.id);
    },
    { enabled: permissions.userSvc$getRolesOfUser }
  );
}

export default useUserRolesOfUser;
