import { ExportOutlined } from '@ant-design/icons';
import { Button, Popover, Space } from 'antd';
import { useState } from 'react';
import { useDevices } from '../hooks/useDevices';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { useParams } from 'react-router-dom';

export const DeviceCsvExport = (props: { projectId: number }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const params = useParams();
  const envId = params?.envId || '';
  const devices = useDevices(envId, props.projectId);
  const [input, setInput] = useState('');

  const close = () => {
    setPopoverVisible(false);
    setInput('');
  };
  return (
    <>
      <Popover
        content={
          <>
            <DescriptionTextAreaInput value={input} contentEditable={false} />
            <Space style={{ width: '100%' }}>
              <Button
                block
                type="link"
                onClick={() => {
                  close();
                }}
              >
                Close
              </Button>
            </Space>
          </>
        }
        title={
          <Space direction="vertical">
            <span>Device List CSV, can be imported in other Environments.</span>
          </Space>
        }
        trigger="click"
        open={popoverVisible}
      >
        <Button
          disabled={!devices.isSuccess}
          onClick={() => {
            const csvLines =
              devices.data?.map((d) => {
                const line = [d.otc.map((o) => o.name).join(';')];
                line.push(d.rds.trim().replace(',', ''));
                line.push(d.description.trim().replace(',', ' /'));
                return line.join(',');
              }) || [];
            const fullCsv = csvLines?.join('\n');
            setInput(fullCsv);
            setPopoverVisible(true);
          }}
          icon={<ExportOutlined />}
        >
          Export CSV
        </Button>
      </Popover>
    </>
  );
};

export default DeviceCsvExport;
