export const PACTS_WIKI_LINK = 'https://wiki.siemens-energy.com/x/7QJxFQ';
export const PACTS_CREATE_DEPLOYMENT_WIKI_LINK = 'https://wiki.siemens-energy.com/x/YzsoFQ';
export const IMPACTS_WIKI_LINK = 'https://wiki.siemens-energy.com/x/laYUF';
export const ISSUE_LINK = 'https://code.siemens-energy.com/emts_engineering_platform/digital-services/pacts/issue-reporting/-/issues/new';
export const TEAMS_LINK =
  'https://teams.microsoft.com/l/channel/19%3a390087794e8245279a72bac624751276%40thread.tacv2/User%2520Support?groupId=3ef81507-6c93-41a2-8b05-34ee11965e23&tenantId=254ba93e-1f6f-48f3-90e6-e2766664b477';
export const USER_SATISFACTION_PROD = 'https://kpi.pacts.cloud/grafana/d/ERwrGlq7z/pacts-user-satisfaction?orgId=11';
export const USER_SATISFACTION_DEV = 'https://dev.kpi.pacts.cloud/grafana/d/ERwrGlq7z/pacts-user-satisfaction?orgId=18';
export const HOW_TO_RELEASE_NOTES_LINK = 'https://wiki.siemens-energy.com/x/bboUF';
export const TOKEN_GENERATION_WIKI_LINK = 'https://wiki.siemens-energy.com/x/cbFPGg';
export const CHECKSUM_WIKI_LINK = 'https://wiki.siemens-energy.com/x/zSz9Gw';
export const PUBLIC_SIGNING_KEY = '/pacts-report-signing-ecdsa-sha512.pem';
export const ICS_PORTAL_LINK = 'https://ics-portal.mosaic.siemens-energy.cloud/component-library';

export const SE_IMPRINT = 'https://www.siemens-energy.com/global/en/general/corporate-information.html';
export const SE_PRIVACY_NOTICE = 'https://www.siemens-energy.com/global/en/general/privacy-notice.html';
export const SE_TERMS_OF_USE = 'https://www.siemens-energy.com/global/en/general/terms-of-use.html';
