/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';


type IconProps = {
    style?: React.CSSProperties;
    className?: string;
};

export const SafetyInfo = (props: IconProps) => {
    const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
        <svg width="1em" height="1em" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M217.114 42.471L131.815 13.5239C130.786 13.174 129.405 12.999 128.024 12.999C126.644 12.999 125.263 13.174 124.234 13.5239L38.9346 42.471C36.851 43.171 35.144 45.5707 35.144 47.7705V168.358C35.144 170.558 36.5749 173.458 38.307 174.833L124.836 241.976C125.715 242.651 126.845 243.001 127.999 243.001C129.154 243.001 130.309 242.651 131.162 241.976L217.692 174.833C219.424 173.483 220.855 170.583 220.855 168.358V47.7705C220.905 45.5707 219.198 43.196 217.114 42.471ZM202.831 163.559L128.024 221.603L53.2181 163.559V56.6696L128.024 31.2722L202.831 56.6696V163.559Z" fill="currentColor"/>
            <path d="M136.56 171.477C136.56 173.686 134.762 175.477 132.544 175.477H122.502C120.284 175.477 118.486 173.686 118.486 171.477V105.482C118.486 103.273 120.284 101.482 122.502 101.482H132.544C134.762 101.482 136.56 103.273 136.56 105.482V171.477Z" fill="currentColor"/>
            <path d="M115.474 73.9942C115.474 67.3673 120.868 61.9952 127.523 61.9952C134.178 61.9952 139.572 67.3673 139.572 73.9942C139.572 80.6211 134.178 85.9933 127.523 85.9933C120.868 85.9933 115.474 80.6211 115.474 73.9942Z" fill="currentColor"/>
        </svg>
    );

    return <Icon {...props} component={svg} />;
};
