import type { ReactElement } from 'react';

import { LatestTag } from '../../../contexts/shared/components/LatestTag';
import { CommonSoftwareAppVersionVulnerabilityButton } from './CommonSoftwareAppVersionVulnerabilityButton';
import { ProjectSoftwareAppVersionVulnerabilityButton } from './ProjectSoftwareAppVersionVulnerabilityButton';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';

/**
 * Used inside the version name column of version list tables. 
 * It displays the name, along with a latest tag if it is the 
 * the latest version. Additionally it checks if an ICS Portal
 * ID exists for the version. If yes, it displays an extra button
 * which can show version vulnerability information.
 */
export function SoftwareAppVersionListTitle(
    props: {
        project?: Project,
        app: SoftwareApp,
        version: SoftwareAppVersion,
        hasPermissions: boolean
    }
) {
    const { project, app, version, hasPermissions } = props;

    const hasICSPortalId = version.targets.some((t) => !!t.icsPortalComponentId);

    let vulnerabilityButton: ReactElement | null = null;

    if (hasPermissions && hasICSPortalId && !!project) {
        vulnerabilityButton = (
            <ProjectSoftwareAppVersionVulnerabilityButton
                project={project}
                app={app}
                version={version}
            />
        );
    } else if (hasPermissions && hasICSPortalId) {
        vulnerabilityButton = (
            <CommonSoftwareAppVersionVulnerabilityButton 
                app={app} 
                version={version} 
            />
        );
    }

    return (
        <>
            {version.idSoftwareAppVersion === app.latestVersion?.idSoftwareAppVersion && <LatestTag />}
            {version.version}

            {vulnerabilityButton}
        </>
    );
}