import { Card, Row, Typography } from 'antd';
import { StyledResult, StyledCol } from '../base/Components.styled';
import styled from 'styled-components';
import { CHECKSUM_WIKI_LINK, PUBLIC_SIGNING_KEY } from '../../../constants/links';

const CardWithBackground = styled(Card)`
  background-color: ${({ theme }) => theme.colorBgContainerDisabled};
`;

const Spacer = styled.div<{ height: number }>`
  margin-top: ${({ height }) => height}px;
`;

export const ReportResult = (props: { signature?: string }) => {
  const hasSignature = !!props.signature;
  const signatureSuccess = hasSignature;

  return (
    <StyledResult status="success" padding="8px 16px 8px 16px" contentStyle={{ backgroundColor: 'transparent', padding: '0' }}>
      <Row justify="center">
        <StyledCol span={24} textAlign="center">
          <Typography.Paragraph strong>{`The report  ${
            signatureSuccess ? ' and its signature have been downloaded' : ' has been downloaded'
          }.`}</Typography.Paragraph>
          <Spacer height={36} />
          {signatureSuccess ? (
            <>
              <CardWithBackground size="small">
                <Spacer height={14} />
                <Typography.Paragraph>
                  Keep this signature file together with the report to verify the report's integrity. Note that any change to the report{' '}
                  <Typography.Text strong>even opening and saving without any changes made might render the signature invalid.</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Use the{' '}
                  <Typography.Link href={PUBLIC_SIGNING_KEY} target="_blank">
                    PacTS public key
                  </Typography.Link>{' '}
                  to validate the signature.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Learn more about integrity, checksums and signatures{' '}
                  <Typography.Link href={CHECKSUM_WIKI_LINK} target="_blank">
                    here
                  </Typography.Link>
                  .
                </Typography.Paragraph>
              </CardWithBackground>
              <Spacer height={24} />
            </>
          ) : null}
        </StyledCol>
      </Row>
    </StyledResult>
  );
};
