import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { getCurrentInstallationActionForComponentOnDevice } from '../helper/latestComponents';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { MarkdownRenderer } from '../../shared/components/MarkdownRenderer';
import { InstallationNotesIcon } from '../../shared/components/icons/InstallationNotesIcon';

import type { Device, SoftwareComponent } from '../../../api';


const ContentContainer = styled.div`
  min-height: 500px;
  width: 100%;
`;

export const SoftwareComponentInstallationNotes = (props: { component: SoftwareComponent; device: Device }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const deviceInstallationNotes = getCurrentInstallationActionForComponentOnDevice(props.component, props.device)?.notes;

  if (!deviceInstallationNotes) {
    return '-';
  }

  return (
    <>
      <ScrollDialog
        open={previewVisible}
        onCancel={() => {
          setPreviewVisible(false);
        }}
        title="Installation notes"
        footer={null}
      >
        <ContentContainer>
          <MarkdownRenderer>{deviceInstallationNotes}</MarkdownRenderer>
        </ContentContainer>
      </ScrollDialog>
      <Tooltip title="Installation notes" trigger={['hover', 'click']}>
        <Button 
          type="text"
          icon={<InstallationNotesIcon />}
          disabled={!deviceInstallationNotes} 
          onClick={() => setPreviewVisible(true)}
        />
      </Tooltip>
    </>
  );
};
