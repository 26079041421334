import { TableColumnProps, Table } from 'antd';
import { getCurrentInstallationActionForComponentOnDevice } from '../helper/latestComponents';
import { ComponentForDevice, ComponentForDevices } from '../helper/deploymentTypes';
import { SoftwareComponentInstallationNotes } from './SoftwareComponentInstallationNotes';
import { formatDateTime, useTableFilter, useTableSearch } from '../../../contexts/shared/components';
import { Comparator } from '../../../domain';

export const ClosedInstallationsNestedTable = (props: { data: ComponentForDevices }) => {
  const data: ComponentForDevice[] = props.data.targets.map((t) => ({
    device: t.device,
    component: props.data.component,
    isInstalled: t.isInstalled
  }));

  const rdsSearch = useTableSearch({ searchValueProvider: 'device.rds' });

  const installerSearch = useTableSearch({
    searchValueProvider: (c: ComponentForDevice) => getCurrentInstallationActionForComponentOnDevice(c.component, c.device)?.executor.name ?? ''
  });

  const installDateSorter = (a: ComponentForDevice, b: ComponentForDevice) => {
    const at = getCurrentInstallationActionForComponentOnDevice(a.component, a.device);
    const bt = getCurrentInstallationActionForComponentOnDevice(b.component, b.device);
    return new Date(at?.timestamp ?? '0').getTime() - new Date(bt?.timestamp ?? '0').getTime();
  };

  const installationStatusSorter = (a: ComponentForDevice, b: ComponentForDevice) => {
    if (a.isInstalled && !b.isInstalled) return -1;
    if (b.isInstalled && !a.isInstalled) return 1;
    return 0;
  };

  const executorSorter = (a: ComponentForDevice, b: ComponentForDevice) => {
    const at = getCurrentInstallationActionForComponentOnDevice(a.component, a.device);
    const bt = getCurrentInstallationActionForComponentOnDevice(b.component, b.device);
    return Comparator.lexicographicalComparison(at?.executor.name ?? '', bt?.executor.name ?? '');
  };

  const rdsSorter = (a: ComponentForDevice, b: ComponentForDevice) => Comparator.lexicographicalComparison(a.device.rds, b.device.rds);

  const installedFilter = useTableFilter<ComponentForDevice>({
    key: 'hide-installed-filter',
    onFilter: (dat, val) => {
      return val === 'hideInstalled' && !dat.isInstalled;
    },
    values: [
      {
        text: 'Hide Installed',
        value: 'hideInstalled'
      }
    ]
  });

  const deviceListColumn: TableColumnProps<ComponentForDevice>[] = [
    {
      title: 'Device',
      align: 'left',
      key: 'rds',
      sorter: rdsSorter,
      ...rdsSearch,
      width: 240,
      render: (d: ComponentForDevice) => {
        return d.device.rds;
      }
    },
    {
      title: 'Satus',
      key: 'status',
      align: 'left',
      sorter: installationStatusSorter,
      ...installedFilter,
      render: (d: ComponentForDevice) => {
        return d.isInstalled ? 'Installed' : 'Not Installed';
      }
    },
    {
      title: 'Installed by',
      align: 'left',
      sorter: executorSorter,
      ...installerSearch,
      key: 'installedBy',
      render: (d: ComponentForDevice) => {
        return getCurrentInstallationActionForComponentOnDevice(d.component, d.device)?.executor.name ?? '-';
      }
    },
    {
      title: 'Installation date',
      align: 'left',
      key: 'installDate',
      sorter: installDateSorter,
      render: (d: ComponentForDevice) => {
        const timestamp = getCurrentInstallationActionForComponentOnDevice(d.component, d.device)?.timestamp;
        return timestamp ? formatDateTime(new Date(timestamp)) : '-';
      }
    },
    {
      title: 'Installation notes',
      align: 'center',
      key: 'installNotes',
      render: (d: ComponentForDevice) => <SoftwareComponentInstallationNotes component={d.component} device={d.device} />
    }
  ];
  return (
    <Table
      size="small"
      key={props.data.identifier}
      rowKey={(row) => props.data.identifier + row.device.rds}
      pagination={false}
      columns={deviceListColumn}
      dataSource={data}
    />
  );
};
