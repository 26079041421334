import { BookOutlined, ExperimentOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { ProjectType } from '../../../api/engineering/domain/types';

export const ProjectTypeIcon = (props: { projectType: ProjectType }) => {
  if (props.projectType.name.toLowerCase().includes('r&d')) {
    return <ExperimentOutlined />;
  }
  if (props.projectType.name.toLowerCase().includes('reference')) {
    return <BookOutlined />;
  }
  return <ThunderboltOutlined />;
};
