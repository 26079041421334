/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

export type PacTSIconLightProps = {
  style?: React.CSSProperties;
};

export const PacTSIconLight: React.FunctionComponent<PacTSIconLightProps> = (props: PacTSIconLightProps) => {
  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg style={props.style} viewBox="0 0 256 256" width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>PacTS</title>
      <path
        d="M264.496 123.604L203.158 62.2657C202.504 61.6119 201.386 62.0749 201.386 62.9995V86.0523C201.386 86.6028 201.605 87.1308 201.994 87.52L238.078 123.604C240.509 126.035 240.509 129.978 238.078 132.41L201.994 168.494C201.605 168.883 201.386 169.411 201.386 169.961V193.014C201.386 193.939 202.504 194.402 203.158 193.748L264.496 132.41C266.927 129.978 266.927 126.035 264.496 123.604Z"
        fill="#19D2FF"
      />
      <path
        d="M119.197 60.4973C124.06 55.6338 131.945 55.6338 136.809 60.4973L152.953 76.6416C157.816 81.505 157.816 89.3902 152.953 94.2536L130.938 116.269C129.317 117.89 126.688 117.89 125.067 116.269L103.052 94.2536C98.1888 89.3902 98.1888 81.505 103.052 76.6416L119.197 60.4973Z"
        fill="#19D2FF"
      />
      <path
        d="M161.759 103.059C166.622 98.1957 174.508 98.1957 179.371 103.059L195.515 119.203C200.379 124.067 200.379 131.952 195.515 136.815L179.371 152.96C174.508 157.823 166.622 157.823 161.759 152.96L139.744 130.945C138.123 129.324 138.123 126.695 139.744 125.074L161.759 103.059Z"
        fill="white"
      />
      <path
        d="M76.6342 103.059C81.4976 98.1957 89.3828 98.1957 94.2462 103.059L116.261 125.074C117.882 126.695 117.882 129.324 116.261 130.945L94.2462 152.96C89.3828 157.823 81.4976 157.823 76.6342 152.96L60.4898 136.815C55.6264 131.952 55.6264 124.067 60.4898 119.203L76.6342 103.059Z"
        fill="#CB75FF"
      />
      <path
        d="M126.535 138.282C127.345 137.472 128.66 137.472 129.47 138.282L152.953 161.765C157.816 166.629 157.816 174.514 152.953 179.377L136.809 195.521C131.945 200.385 124.06 200.385 119.197 195.521L103.052 179.377C98.1888 174.514 98.1888 166.629 103.052 161.765L126.535 138.282Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M105.985 9.11892C118.144 -3.03964 137.856 -3.03964 150.015 9.11892L186.707 45.8106C189.949 49.0529 189.949 54.3097 186.707 57.552C183.464 60.7942 178.208 60.7942 174.965 57.552L138.274 20.8603C132.6 15.1863 123.4 15.1863 117.726 20.8603L20.8603 117.726C15.1863 123.4 15.1863 132.6 20.8603 138.274L117.726 235.14C123.4 240.814 132.6 240.814 138.274 235.14L174.965 198.448C178.208 195.206 183.464 195.206 186.707 198.448C189.949 201.69 189.949 206.947 186.707 210.189L150.015 246.881C137.856 259.04 118.144 259.04 105.985 246.881L9.11892 150.015C-3.03964 137.856 -3.03964 118.144 9.11892 105.985L105.985 9.11892Z"
        fill="white"
      />
    </svg>
  );

  return <Icon {...props} component={svg} />;
};
