import { useQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useCommonSoftwareAppVersionVulnerabilities(appId: number, appVersionId: number) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ softwareAppId: appId.toString(), idSoftwareAppVersion: appVersionId.toString() });

  return useQuery(
    ['commonSoftwareAppVersionVulnerabilities', appId.toString(), appVersionId.toString()],
    () => {
      return backend.queryCommonAppVersionVulnerabilities(appId, appVersionId);
    },
    {
      enabled: permissions.engineeringSvc$getCommonSoftwareAppVersionVulnerabilities
    }
  );
}
