import { useCallback, useEffect, useState } from 'react';
import ScrollIntoView from 'scroll-into-view';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';

export const useScrollInto = (scrollTo: string, trigger: any, ref: any) => {
  const [triggerState, setTriggerState] = useState(undefined);
  const debouncedTrigger = useDebounce(trigger, 50);

  const doScroll = useCallback(() => {
    if (!scrollTo) return;
    const rows = document.querySelectorAll(scrollTo);
    if (rows.item(0)) {
      ScrollIntoView(rows.item(0) as HTMLElement, { time: 10 });
    }
  }, [scrollTo]);

  // Delayed scroll, called when trigger changes, e.g., when dataset is loaded
  useEffect(() => {
    const triggerChanged = debouncedTrigger !== triggerState;
    const scroll = triggerChanged && ref;
    if (scroll) {
      setTriggerState(debouncedTrigger);
      doScroll();
    }
  }, [scrollTo, debouncedTrigger, triggerState, setTriggerState, ref, doScroll]);

  // Initial scroll, called when page is loaded
  useEffect(() => {
    if (!ref) return;
    doScroll();
  }, [doScroll, ref]);
};
