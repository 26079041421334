import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { SoftwareAppSelection } from '../../../domain';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import { SubscriptionPopup } from '../../notifications/components/SubscriptionPopup';
import { Project } from '../../../api/engineering/domain/types';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { scopedAppHash } from '../utils/scopedAppHash';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const ProjectSoftwareAppsActionMenu = (props: { swa: ScopedSoftwareApp; project: Project; currentSelection: SoftwareAppSelection | undefined }) => {
  const navigate = useInAppNavigate();
  const metrics = useSubmitMetric();
  const permissions = usePermissions({
    projectId: props.project.idProject.toString(),
    softwareAppId: props.currentSelection?.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.currentSelection?.version.idSoftwareAppVersion.toString()
  });

  const canGetVersions =
    permissions.engineeringSvc$getProjectSoftwareAppVersions || permissions.engineeringSvc$getProjectSoftwareAppVersion$specific().length > 0;

  return (
    <Space>
      <SubscriptionPopup
        type="text"
        title={props.swa.name}
        topics={[{ key: `projects/${props.project.idProject}/apps/${props.swa.idSoftwareApp}/**`, title: props.swa.name }]}
      />
      <ExpandableMenu textType id={`project-app-actions-${props.project.idProject}-${props.swa.idSoftwareApp}`}>
        {permissions.webui$showComponentDetails && (
          <ExpandableMenuItem
            icon={<InfoCircleOutlined />}
            onClick={() => {
              metrics
                .submitClick({
                  operationId: MetricClickIds.componentDetails
                })
                .withAppContext(props.swa, props.project);
              navigate(`/projects/${props.project.idProject.toString()}/apps/${props.swa.idSoftwareApp}/details?scope=project`, {}, scopedAppHash(props.swa));
            }}
          >
            Details
          </ExpandableMenuItem>
        )}
        {canGetVersions && (
          <ExpandableMenuItem
            icon={<OrderedListOutlined />}
            onClick={() => {
              metrics
                .submitClick({
                  operationId: MetricClickIds.componentVersions
                })
                .withAppContext(props.swa, props.project);
              navigate(`/projects/${props.project.idProject.toString()}/apps/${props.swa.idSoftwareApp}/versions?scope=project`, {}, scopedAppHash(props.swa));
            }}
          >
            Versions
          </ExpandableMenuItem>
        )}
        {props.currentSelection && (
          <DownloadContextMenuButton artifact={props.currentSelection.app} version={props.currentSelection.version} projectId={props.project.idProject} />
        )}
        {props.currentSelection && <SoftwareAppsOpenInToolManagerButton selection={props.currentSelection} project={props.project} />}
      </ExpandableMenu>
    </Space>
  );
};
