import { Alert, Card, Row, Col, Space, Typography } from 'antd';
import { useState, useRef, useMemo, useEffect } from 'react';
import { useBundles } from '../../bundles/hooks/useBundles';
import { useDeleteBundleConfiguration } from '../../bundles/hooks/useDeleteBundleConfiguration';
import { useDeleteBundleConfigurationVersion } from '../../bundles/hooks/useDeleteBundleConfigurationVersion';
import { useProject } from '../../projects/hooks/useProject';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { EngineeringToolsCompareList } from './EngineeringToolsCompareList';
import { AddBundleConfiguration } from '../../bundles/components/AddBundleConfiguration';
import { AddBundleConfigurationVersion } from '../../bundles/components/AddBundleConfigurationVersion';
import { BundleConfigurationVersionDropdown } from '../../bundles/components/BundleConfigVersionDropdown';
import { ReportDownloadButton } from '../../bundles/components/BundleReportDownloadButton';
import { ComparisonViewSwitch } from '../../bundles/components/ComparisonViewSwitch';
import { RevertButton } from '../../bundles/components/RevertButton';
import { ShowBundleItemsOnlySwitch } from '../../bundles/components/ShowBundleItemsOnlySwitch';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useBundleConfigurationSelection } from '../hooks/useBundleConfigurationSelection';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { EngineeringToolSelection } from '../../../domain/engineeringToolsSelection';
import { BundleOpenInToolManagerButton } from '../../bundles/components/BundleOpenInToolManagerButton';
import EngineeringToolsList from './EngineeringToolsList';
import { formatDateTime } from '../../shared/components/formatDate';
import { MarkdownPreview } from '../../shared/components/MarkdownPreview';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { Tool } from '../../../api/engineering/domain/types';
import { UpdateBundleConfiguration } from '../../bundles/components/EditBundleConfiguration';

const { Text } = Typography;

type EngineeringToolsBundleViewProps = {
  projectId: string;
};

const initialBundleSelection = {
  bundleId: '',
  projectId: '',
  bundleConfigurationId: '',
  bundleConfigurationVersionId: '',
  bundleConfigurationName: '',
  bundleConfigurationVersionName: ''
};

export const EngineeringToolsBundleView = (props: EngineeringToolsBundleViewProps) => {
  const [selectedBundleConfigPrimary, setSelectedBundleConfigPrimary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const [selectedBundleConfigSecondary, setSelectedBundleConfigSecondary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const projectId = useMemo(() => {
    setSelectedBundleConfigPrimary(initialBundleSelection);
    setSelectedBundleConfigSecondary(initialBundleSelection);
    return props.projectId;
  }, [props.projectId, setSelectedBundleConfigPrimary, setSelectedBundleConfigSecondary]);
  const permissions = usePermissions({ projectId: projectId.toString() });
  const emptyArray = useRef([]);
  const [selectedTools, setSelectedTools] = useState([] as EngineeringToolSelection[]);
  const [initialSelection, setInitialSelection] = useState([] as EngineeringToolSelection[]);
  const [comparisonViewSearchParam, setComparisonViewSearchParam] = useSearchParameter('mode');
  const [isDirty, setIsDirty] = useState(false);
  const [showSearchParameter] = useSearchParameter('show');

  const comparisonView = comparisonViewSearchParam === 'compare';
  const setComparisonView = (enabled: boolean) => setComparisonViewSearchParam(enabled ? 'compare' : 'control');

  const project = useProject(projectId);
  const bundles = useBundles(projectId);

  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes('Engineering'));
  const bundleId = usedBundle?.idBundle?.toString() || '';

  const deleteBundleConfigVersion = useDeleteBundleConfigurationVersion();
  const deleteBundleConfig = useDeleteBundleConfiguration();

  const showBundleItemsOnly = !showSearchParameter || showSearchParameter === 'bundle';

  // if a project is an rpl and has a bundle config, no further can be added
  const isRpl = !!project.data?.projectType.isReferenceProject;
  const addBundleConfigDisabledDueToRpl = isRpl && !!selectedBundleConfigPrimary.bundleConfigurationId;

  const bundleReleaseA = useBundleConfigurationSelection(projectId, bundleId, selectedBundleConfigPrimary);

  const bundleReleaseB = useBundleConfigurationSelection(projectId, bundleId, selectedBundleConfigSecondary);

  const extractSelectionFromBundleRelease = useMemo(
    () => (data: Tool[] | undefined) => {
      if (!data) {
        return emptyArray.current;
      }
      return (
        data.map((et) => {
          return { engineeringTool: et, version: et.latestVersion };
        }) || emptyArray.current
      );
    },
    []
  );

  const extractedToolsLeft = useMemo(() => {
    if (bundleReleaseB.isLoading) return [];
    if (!bundleReleaseB.release) {
      return [];
    }
    return extractSelectionFromBundleRelease(bundleReleaseB.release.engineeringTools);
  }, [bundleReleaseB.isLoading, bundleReleaseB.release, extractSelectionFromBundleRelease]);

  useEffect(() => {
    if (bundleReleaseA.isLoading) return;
    if (!bundleReleaseA.release) {
      setInitialSelection([]);
      setSelectedTools([]);
    } else {
      setInitialSelection(extractSelectionFromBundleRelease(bundleReleaseA.release.engineeringTools));
      setSelectedTools(extractSelectionFromBundleRelease(bundleReleaseA.release.engineeringTools));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleReleaseA.isLoading, bundleReleaseA.release, extractSelectionFromBundleRelease]);

  if (parseInt(projectId, 10) < 0) {
    return <Alert message="Invalid Project Id" type="error" />;
  }

  const configDropdownExtensions =
    (!comparisonView && [
      <ExpandableMenu id={`bundle-config-actions-${props.projectId}-${bundleId}`}>
        {permissions.engineeringSvc$addProjectBundleConfiguration && (
          <AddBundleConfiguration
            projectId={projectId.toString()}
            disabled={addBundleConfigDisabledDueToRpl}
            bundleId={bundleId}
            onAddBundleConfiguration={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$updateProjectBundleConfiguration && (
          <UpdateBundleConfiguration
            projectId={projectId.toString()}
            bundleId={bundleId}
            configId={selectedBundleConfigPrimary.bundleConfigurationId}
            onEditBundleConfigurationName={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: bc.latestBundleReleaseId?.toString() || '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfiguration && (
          <ConfirmationButton
            caption="Delete Bundle Configuration"
            danger
            description="All deployment plans associated with this configuration will also be deleted."
            onOk={() => {
              deleteBundleConfig.mutate([projectId.toString(), bundleId, selectedBundleConfigPrimary.bundleConfigurationId]);
            }}
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const configVersionDropdownExtensions =
    (!comparisonView && [
      <RevertButton key="revertBundleConfigurationVersion" disabled={!isDirty} onClick={() => setSelectedTools(initialSelection)} />,
      <ExpandableMenu id={`bundle-config-version-actions-${props.projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        {permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
          <AddBundleConfigurationVersion
            key="addBundleConfigurationVersion"
            projectId={projectId.toString()}
            bundleId={bundleId}
            bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
            selectedProjectSoftwareAppVersionIds={emptyArray.current}
            selectedSoftwareAppVersionIds={emptyArray.current}
            selectedEngineeringToolVersionIds={selectedTools.map((st) => st.version.idToolVersion || -1).filter((sai) => sai > -1)}
            onAddBundleConfigurationVersion={(bcv) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfigurationRelease && (
          <ConfirmationButton
            caption="Delete Version"
            key="deleteBundleConfigurationVersion"
            danger
            onOk={() =>
              deleteBundleConfigVersion.mutate([
                projectId.toString(),
                bundleId,
                selectedBundleConfigPrimary.bundleConfigurationId,
                selectedBundleConfigPrimary.bundleConfigurationVersionId
              ])
            }
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const bundleActions = [
    <ExpandableMenu id={`bundle-config-version-extra-actions-${props.projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
      <ComparisonViewSwitch state={comparisonView} onChange={() => setComparisonView(!comparisonView)} />
      {!comparisonView &&
        permissions.engineeringSvc$getProjectBundleConfigurationReleaseReport &&
        bundleReleaseA.release &&
        project.data &&
        usedBundle &&
        selectedBundleConfigPrimary && (
          <ReportDownloadButton project={project.data} bundle={usedBundle} bundleConfig={selectedBundleConfigPrimary} isDirty={isDirty} />
        )}
      {permissions.impacts$use && (
        <BundleOpenInToolManagerButton bundle={usedBundle} project={project.data} selection={selectedBundleConfigPrimary} assetType="tool" />
      )}
    </ExpandableMenu>
  ];

  const aIsEmpty = !selectedBundleConfigPrimary.bundleConfigurationVersionId;
  const bIsEmpty = !selectedBundleConfigSecondary.bundleConfigurationVersionId;
  const aLoading = bundleReleaseA.isLoading && !aIsEmpty;
  const bLoading = comparisonView && bundleReleaseB.isLoading && !bIsEmpty;
  const loading = aLoading || bLoading;

  return (
    <Card loading={project.isLoading}>
      <Row justify="start">
        <Col flex="50%">
          {!comparisonView && bundleReleaseA && (
            <>
              <Text type="secondary">Release Notes:</Text>
              <MarkdownPreview content={bundleReleaseA.release?.releaseNotes || ''} title="Release note" />
              <br />
              <Text type="secondary">
                {bundleReleaseA.release?.createdAt ? formatDateTime(new Date(bundleReleaseA.release?.createdAt)) : ''}
                {bundleReleaseA.release?.createdBy ? ` by ${bundleReleaseA.release?.createdBy}` : ''}
              </Text>
            </>
          )}
        </Col>
        <Col flex="auto">
          <Space direction="vertical" style={{ float: 'right' }} size={0}>
            <Space style={{ float: 'right' }}>
              {comparisonView && (
                <BundleConfigurationVersionDropdown
                  projectId={projectId.toString()}
                  bundleId={bundleId}
                  selected={selectedBundleConfigSecondary}
                  onSelect={setSelectedBundleConfigSecondary}
                  id="l"
                />
              )}
              <BundleConfigurationVersionDropdown
                projectId={projectId.toString()}
                bundleId={bundleId}
                selected={selectedBundleConfigPrimary}
                onSelect={(val) => setSelectedBundleConfigPrimary(val)}
                disableLabel={comparisonView}
                isDirty={isDirty}
                configurationDropdownExtensions={configDropdownExtensions}
                configurationVersionDropdownExtensions={configVersionDropdownExtensions}
                id="r"
                // onLoading={setBundleConfigVersionLoading}
              />
            </Space>
            <Space style={{ float: 'right' }}>
              {!comparisonView && <ShowBundleItemsOnlySwitch itemName="Tools" extension={bundleActions} />}
              {comparisonView && bundleActions}
            </Space>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          {!comparisonView && (
            <EngineeringToolsList
              selected={selectedTools}
              onDirty={setIsDirty}
              onSelect={setSelectedTools}
              showBundleItemsOnly={showBundleItemsOnly}
              initiallySelected={initialSelection}
              project={project.data}
              loading={loading}
            />
          )}
          {comparisonView && (
            <EngineeringToolsCompareList
              project={project.data}
              selectedTitleLeft={`${selectedBundleConfigSecondary.bundleConfigurationName}: ${selectedBundleConfigSecondary.bundleConfigurationVersionName}`}
              selectedTitleRight={`${selectedBundleConfigPrimary.bundleConfigurationName}: ${selectedBundleConfigPrimary.bundleConfigurationVersionName}`}
              selectedToolsRight={selectedTools}
              selectedToolsLeft={extractedToolsLeft}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};
