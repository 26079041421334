import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ScopedSoftwareApp } from './scopedSoftwareApp';
import { SoftwareApp } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

export function useProjectSoftwareApps(projectId: string) {
  const { backend } = useEngineeringBackend();
  const parsedBundleId = parseInt(projectId, 10);
  const permissions = usePermissions({ projectId });
  return useQuery<SoftwareApp[], [string, string]>(
    ['softwareapps', projectId],
    (): Promise<SoftwareApp[]> => {
      return backend.queryProjectApps(projectId);
    },
    {
      enabled: parsedBundleId > -1 && permissions.engineeringSvc$getProjectSoftwareApps
    }
  );
}

export function useProjectSoftwareAppsScoped(projectId: string) {
  const softwareApps = useProjectSoftwareApps(projectId);

  const data = useMemo(() => {
    let scopedApps: ScopedSoftwareApp[] = [];
    if (softwareApps.data) {
      scopedApps = [...softwareApps.data].map((a) => {
        const scopedapp: ScopedSoftwareApp = {
          ...a,
          scope: 'project'
        };
        return scopedapp;
      });
    }
    return scopedApps;
  }, [softwareApps.data]);
  return {
    ...softwareApps,
    data
  };
}
