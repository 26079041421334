import { useQuery } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { usePermissions } from '../../session';
import { DeploymentPlan } from '../../../api/deployment/domain';

export const useDeploymentPlan = (deploymentPlanId: string, envId: string, projectId: number, opts?: { disabled?: boolean }) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({ projectId: projectId.toString(), environmentId: envId, deploymentPlanId });

  return useQuery<DeploymentPlan, [string]>(
    ['deploymentplan', projectId, envId, deploymentPlanId],
    (): Promise<DeploymentPlan> => {
      return backend.queryDeploymentPlan(projectId, envId, deploymentPlanId);
    },
    {
      enabled: !!deploymentPlanId && permissions.deploymentSvc$getDeploymentPlan && !opts?.disabled,
      staleTime: 5 * 60 * 1000
    }
  );
};
