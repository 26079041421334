import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useSyncProjectRoles } from '../hooks/useSyncProjectRoles';

export const SyncProjectRolesButton = () => {
  const permissions = usePermissions();
  const sync = useSyncProjectRoles();

  return (
    <Button icon={<SyncOutlined />} loading={sync.isLoading} disabled={!permissions.pactsFormationSvc$unrestrictedAdministration} onClick={() => sync.mutate()}>
      Sync Project Roles
    </Button>
  );
};
