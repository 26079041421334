import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { Otc } from '../../../api';

export function useCreateOtc(projectId: number, envId: string) {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<Otc, any, [Otc]>(([otc]) => {
    return new Promise<Otc>((resolve, reject) => {
      backend
        .createOtc(projectId, envId, otc)
        .then((dev) => {
          presentSuccess(`Successfully created OTC ${otc.name}`);
          queryClient.invalidateQueries(['otc']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
