import { useUsers } from '..';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

export const UserName = (props: { userId: number }) => {
  const users = useUsers();

  if (users.isLoading) return <AnimatedLoadingIcon />;

  const found = users.data?.find((u) => u.id === props.userId);

  if (found) {
    return <span>{`${found.name} (${props.userId})`}</span>;
  }

  return <span>{`unknown (${props.userId})`}</span>;
};
