import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import EventEmitter from 'eventemitter3';
import { MessageManager } from './messageManager';
import { ReconnectingWebSocket } from './reconnectingWebsocket';
import { useToken } from '../useBackendConfiguration';
import { SharedAxiosInstance } from '../sharedAxiosInstance';
import { PacTSContext } from '../../../state/store';
import { Token } from '../../../state/actions';

export type AuthenticatedWebsocketUrl = {
  websocketUrl: string;
  connectUrl: string;
};

export enum ManagedWebsocketConnectionState {
  IDLE,
  CONNECTING,
  CONNECTED,
  ERROR,
  CLOSED
}

type ManagedWebsocketStatus = {
  statusReadable: string;
  status: ManagedWebsocketConnectionState;
  isReady: boolean;
  isClosed: boolean;
  isError: boolean;
  isConnecting: boolean;
};

export type WebsocketOperations = {
  reconnect: () => void;
};

export type ManagedWebsocket = [MessageManager, ManagedWebsocketStatus, WebsocketOperations];

export const useManagedWebsocket = (forUrl: AuthenticatedWebsocketUrl, manager: MessageManager, options: { enabled: boolean }): ManagedWebsocket => {
  const [state] = useContext(PacTSContext);
  const emitter = useRef(new EventEmitter());
  const [wsState, setWsState] = useState(ManagedWebsocketConnectionState.IDLE);
  const usedToken = useRef<Token>({ refreshToken: '', token: '' });
  const tokenProvider = useToken();

  const wsEnabled = options.enabled;

  const urlProvider = useRef(async () => {
    const token = await tokenProvider.token();
    usedToken.current = token;
    const result = await SharedAxiosInstance.instance().get(forUrl.connectUrl, { headers: { Authorization: `Bearer ${usedToken.current.token}` } });
    const connectToken = result.data.token;
    return `${forUrl.websocketUrl}?token=${connectToken}&client=PACTS`;
  });

  const ws = useMemo(() => {
    const newWebSocket = new ReconnectingWebSocket(urlProvider.current, undefined, { startClosed: true, debug: false, maxEnqueuedMessages: 1024 });
    newWebSocket.onopen = () => {
      setWsState(ManagedWebsocketConnectionState.CONNECTED);
      manager.setSendMessage((msg) => newWebSocket.send(msg));
    };
    newWebSocket.onerror = () => {
      setWsState(ManagedWebsocketConnectionState.ERROR);
    };
    newWebSocket.onclose = () => {
      setWsState(ManagedWebsocketConnectionState.CLOSED);
    };
    newWebSocket.onmessage = (event) => {
      emitter.current.emit('message', event);
    };
    manager.addInputEmitter(emitter.current);
    return newWebSocket;
  }, [setWsState, manager]);

  useEffect(() => {
    if (state.token.token === usedToken.current.token) {
      /* do nothing */
    } else if (state.token.token === '') {
      usedToken.current = { refreshToken: '', token: '' };
      ws.close();
    } else {
      usedToken.current = state.token;
      setWsState(ManagedWebsocketConnectionState.CONNECTING);
      if (wsEnabled) ws.reconnect();
    }
  }, [state.token, ws, wsEnabled]);

  // Use reference to register hook only on enabled changes
  const reconnectRef = useRef(() => {
    /* */
  });

  const reconnect = useMemo(() => {
    const res = () => {
      if (!wsEnabled) {
        console.warn('websocket disabled, ignoring reconnect');
        return;
      }
      try {
        ws.reconnect();
      } catch (error) {
        console.warn('reconnect failed', error);
      }
    };
    reconnectRef.current = res;
    return res;
  }, [ws, wsEnabled]);

  useEffect(() => {
    // Use ref here to keep effect's dependencies on enabled
    if (wsEnabled) reconnectRef.current();
  }, [wsEnabled]);

  const retMemo: ManagedWebsocket = useMemo(() => {
    return [
      manager,
      {
        statusReadable: Object.keys(ManagedWebsocketConnectionState).find((x) => ManagedWebsocketConnectionState[wsState] === x) || 'UNKNOWN',
        status: wsState,
        isReady: wsState === ManagedWebsocketConnectionState.CONNECTED,
        isClosed: wsState === ManagedWebsocketConnectionState.CLOSED,
        isError: wsState === ManagedWebsocketConnectionState.ERROR,
        isConnecting: wsState === ManagedWebsocketConnectionState.CONNECTING
      },
      { reconnect }
    ];
  }, [manager, wsState, reconnect]);

  return retMemo;
};
