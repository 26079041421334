import { ApiError } from '../../shared/apiError';
import {
  UserRoleWithPermissionsCreate,
  UserRole,
  UserRoleWithPermissions,
  UserRoleWithPermissionsUpdate,
  UserWithRoles,
  UserInfo,
  RefreshTokenMetadata,
  RefreshTokenMetadataWithUserId
} from '../domain/users';

export class UserBackendError extends ApiError {}

export interface UserBackend {
  onError(handler: (error: UserBackendError) => any): void;

  queryUsers(withRoles: boolean): Promise<UserWithRoles[]>;

  deleteUser(id: number): Promise<void>;

  queryUserRoles(userId: number): Promise<UserRole[]>;
  updateUserRoles(userId: number, roles: UserRole[]): Promise<UserRole[]>;

  queryRoles(): Promise<UserRoleWithPermissions[]>;
  createRole(create: UserRoleWithPermissionsCreate): Promise<UserRoleWithPermissions>;
  updateRole(update: UserRoleWithPermissionsUpdate): Promise<UserRoleWithPermissions>;
  deleteRole(id: number): Promise<void>;

  getUserInfo(): Promise<UserInfo>;
  getCurrentUserSessions(): Promise<RefreshTokenMetadata[]>;
  getUserSessions(): Promise<RefreshTokenMetadataWithUserId[]>;
  terminateUserSession(userId: number, tokenFamily: string): Promise<void>;

  heartbeat(): Promise<void>;
}
