import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OtcDelete } from '../../../api/deployment/domain/deployments';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteOtc(envId: string) {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [OtcDelete, number]>(([otc, projectId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteOtc(projectId, envId, otc.id, otc)
        .then(() => {
          queryClient.invalidateQueries(['otc']);
          presentSuccess(`Successfully deleted OTC ${otc.name}`);
          return resolve();
        })
        .catch(reject);
    });
  });
}
