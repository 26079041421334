import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useAddDeploymentEnvironment() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();

  return useMutation<void, any, [number, string, string]>(async ([projectId, name, description]) => {
    backend.addDeploymentEnvironment(projectId, name, description).then((env) => {
      presentSuccess(`Deployment environment ${env.name} successfully created`);
      queryClient.invalidateQueries(['deploymentEnvironments', projectId]);
    });
  });
}
