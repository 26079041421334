import { BarChartOutlined } from '@ant-design/icons';
import { CenterMenuElement } from '../../shared/components/CenterMenuElement';
import { useGrafanaUrl } from '../hooks/useGrafanaUrl';
import { AxiosError } from 'axios';
import { Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useStyles } from '../../theme/useStyles';
import { useIsDevVersion } from '../../../contexts/navigation/hooks/useIsDevVersion';
import { Project } from '../../../api/engineering/domain/types';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

export const DashboardButton = (props: { target: Project }) => {
  const isDev = useIsDevVersion();
  const grafanaUrl = useGrafanaUrl(props.target.idProject.toString(), props.target.name);
  const error = grafanaUrl.error ? (grafanaUrl.error as AxiosError) : undefined;
  const token = useStyles();

  const { submitClick } = useSubmitMetric();

  const [errorState, setErrorState] = useState(error);

  useEffect(() => {
    if (grafanaUrl.error) {
      setErrorState(grafanaUrl.error as AxiosError);
    }
  }, [grafanaUrl.error, setErrorState]);

  useEffect(() => {
    setErrorState(undefined);
  }, [props.target, setErrorState]);

  const GoToGrafanaButton = () => {
    return (
      <span>
        <Typography.Text style={{ color: token.colorTextSecondary }}>In order to access the page</Typography.Text>
        <Button size="small" type="link" onClick={() => window.open(`https://${isDev ? 'dev.' : ''}kpi.pacts.cloud/grafana`)}>
          Login to Dashboards
        </Button>
      </span>
    );
  };

  const DashboardNotExisting = () => {
    return (
      <span>
        <Typography.Text type="secondary">No dashboard exists for this project</Typography.Text>
      </span>
    );
  };

  const dashboardUrl = grafanaUrl.data || '';
  const disabled = !grafanaUrl.isSuccess || dashboardUrl === '';
  const hasError = errorState !== undefined;
  const isGrafanaLoginRequiredError = disabled && errorState?.response?.status === 406;
  const isDashboardNotExistingError = disabled && errorState?.response?.status === 404;
  const isGenericError = disabled && hasError && !isGrafanaLoginRequiredError && !isDashboardNotExistingError;

  return (
    <>
      <CenterMenuElement
        disabled={disabled}
        icon={<BarChartOutlined />}
        title="Dashboards & Analytics"
        subtitle="Statistics"
        onClick={() => {
          submitClick({ operationId: MetricClickIds.projectKPIsOpen }).withProjectContext(props.target);
          window.open(dashboardUrl);
        }}
      />
      <div style={{ position: 'absolute', right: 25, bottom: 10 }}>
        {grafanaUrl.isLoading && <AnimatedLoadingIcon style={{ marginRight: 2, color: token.colorTextDisabled }} />}
        {isGrafanaLoginRequiredError && <GoToGrafanaButton />}
        {isDashboardNotExistingError && <DashboardNotExisting />}
        {isGenericError && <DashboardNotExisting />}
      </div>
    </>
  );
};
