export class InternalError extends Error {
  constructor() {
    super('Something went wrong. Please try again.');
    this.name = 'Internal Error';
  }
}

export class NotFoundError extends Error {
  constructor() {
    super('The requested file was not found.');
    this.name = 'Not Found';
  }
}

export class NoAccessError extends Error {
  constructor() {
    super('Access to the file was denied due to missing permissions.');
    this.name = 'Access Denied';
  }
}

export class BadRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Invalid Data';
  }
}

export class ChecksumMismatchError extends Error {
  constructor() {
    super(
      `The file's checksum does not match the expected value in the PacTS database. Please contact the software responsible or the PacTS team to resolve this issue.`
    );
    this.name = 'Checksum Mismatch';
  }
}
