import { UserOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { PacTSNotification } from '../../../../api/notifications/domain/types';
import { DefaultNotification } from './DefaultNotification';
import { useStyles } from '../../../theme/useStyles';

export const NewUser = (props: { notification: PacTSNotification }) => {
  const token = useStyles();
  return (
    <Space size="large">
      <UserOutlined style={{ fontSize: '24px', color: token.colorWarning }} />
      <DefaultNotification notification={props.notification} withLink />
    </Space>
  );
};
