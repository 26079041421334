import { Col, Form, Select } from 'antd';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { StyledRow } from '../../shared/base/Components.styled';
import { bundleLabel } from './BundleConfigVersionDropdown';

type ShowBundleItemsOnlySwitchProps = {
  itemName: 'Tools' | 'Apps';
  extension?: React.ReactNode[];
};

export const ShowBundleItemsOnlySwitch = (props: ShowBundleItemsOnlySwitchProps) => {
  const [showSearchParam, setShowSearchParam] = useSearchParameter('show');
  const selectStyle = {
    width: '100%',
    flex: 'auto'
  } as any;

  const selected = showSearchParam || 'bundle';

  return (
    <Form layout="horizontal">
      <Form.Item label={bundleLabel('Show')}>
        <StyledRow gutter={8} width={'250px'} wrap={false}>
          <Col flex="auto">
            <Select
              className='bundle-show-bundle-items-only-selector'
              value={selected}
              onSelect={(val: any) => {
                setShowSearchParam(val);
              }}
              style={selectStyle}
              options={[
                { value: 'bundle', label: `Bundle ${props.itemName}` },
                { value: 'all', label: `All ${props.itemName}` }
              ]}
            />
          </Col>
          {props.extension?.map((e, i) => (
            <Col key={`switch${i}`}>{e}</Col>
          ))}
        </StyledRow>
      </Form.Item>
    </Form>
  );
};

export default ShowBundleItemsOnlySwitch;
