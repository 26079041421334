import { useEffect } from 'react';
import { useLastUiNotification } from '../hooks/userNotificationProvider';
import { notification } from 'antd';

export const NotificationContainer = () => {
  const [api, contextHolder] = notification.useNotification();
  const lastNotification = useLastUiNotification();
  useEffect(() => {
    if (!lastNotification) return;
    switch (lastNotification.type) {
      case 'info':
        api.info(lastNotification);
        break;
      case 'success':
        api.success(lastNotification);
        break;
      case 'error':
        api.error(lastNotification);
        break;
      case 'warning':
        api.warning(lastNotification);
        break;
    }
  }, [api, lastNotification]);

  return contextHolder;
};
