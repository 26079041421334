import { useCallback, useEffect, useRef, useState } from 'react';

const getTime = () => Math.floor(Date.now() / 1000);

// if reverseTimeout is passed the tick will go backwards from the value provided to 0
// otherwise the tick goes from 0 to Infinity
export const useTick: (reverseTimeout?: number) => [number, () => void, () => void] = (reverseTimeout?: number) => {
  const [tick, setTick] = useState(getTime());
  const intervalRef = useRef<NodeJS.Timer | null | any>(null);
  const initialValue = useRef<number | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const newTick = getTime();

      // We need to store the initial value for the reverse functionality
      if (initialValue.current == null) {
        initialValue.current = newTick;
      }

      setTick(newTick);
    }, 500);
    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [setTick]);

  const clearTick = useCallback(() => {
    if (intervalRef.current != null) {
      clearInterval(intervalRef.current);
    }
  }, []);

  const resetTick = useCallback(() => {
    initialValue.current = getTime();
  }, []);

  const time = reverseTimeout != null ? reverseTimeout - (tick - (initialValue.current || tick)) : tick;

  return [time, clearTick, resetTick];
};
