import { SearchOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { formatDateTime } from '../../shared/components/formatDate';
import { getLatestInstallations } from '../helper/latestComponents';
import { Device } from '../../../api';
import { Button, Drawer, Flex, Input, Tabs, Timeline, Tooltip, Typography } from 'antd';
import { CurrentInstallationsTable } from './CurrentInstallationsTable';

export const DeviceInstallations = (props: { device: Device; buttonMode?: boolean }) => {
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const installations = getLatestInstallations(props.device?.actionLog);
  const actions = props.device.actionLog.sort((a, b) => {
    return Date.parse(b.timestamp) - Date.parse(a.timestamp);
  });

  const isCurrentInstallationsDisabled = installations.length < 1;
  const isInstallationHistoryDisabled = actions.length < 1;
  const isButtonDisabled = isCurrentInstallationsDisabled && isInstallationHistoryDisabled;

  const timelineCurrentInstallationsItems = actions
    .filter((a) =>
      searchValue
        ? `${a.affectedSoftwareComponent.name.toLowerCase()} ${a.affectedSoftwareComponent.version.toLowerCase()}`.includes(searchValue.toLowerCase()) ||
          a.executor.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          formatDateTime(new Date(a.timestamp)).includes(searchValue.toLowerCase())
        : true
    )
    .map((a) => {
      const isInstalled = installations.find(
        (da) =>
          da.timestamp === a.timestamp &&
          da.affectedSoftwareComponent.version === a.affectedSoftwareComponent.version &&
          da.affectedSoftwareComponent.versionId === a.affectedSoftwareComponent.versionId
      );
      return (
        <Timeline.Item
          color={isInstalled ? 'green' : 'blue'}
          label={
            <>
              <p style={{ marginBottom: 0 }}>{`${formatDateTime(new Date(a.timestamp))} - ${a.action}`}</p>
            </>
          }
        >
          <p style={{ marginBottom: 0 }}>
            {a.affectedSoftwareComponent.name} {a.affectedSoftwareComponent.version}
          </p>
          <p style={{ marginBottom: 0 }}>{a.executor.name}</p>
        </Timeline.Item>
      );
    });

  return (
    <>
      <Tooltip title={isButtonDisabled ? 'There is no software installed on this device' : 'Installed software'}>
        <Button 
          type="text" 
          onClick={() => setVisible(true)} 
          disabled={isButtonDisabled}
          icon={<UnorderedListOutlined />}
        />
      </Tooltip>
      
      <Drawer width={778} title={`Installed Software on ${props.device.rds}`} footer={null} open={visible} onClose={() => setVisible(false)}>
        <Tabs type="card">
          <Tabs.TabPane tab="Current installations" key="currentinstallations" disabled={isCurrentInstallationsDisabled}>
            <CurrentInstallationsTable data={installations} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Installation history" key="installationhistory" disabled={isInstallationHistoryDisabled}>
            <>
              <Flex justify="start">
                <Input
                  style={{
                    marginBottom: '40px',
                    width: '250px'
                  }}
                  bordered
                  value={searchValue}
                  allowClear
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  suffix={
                    <Typography.Text type="secondary">
                      <SearchOutlined />
                    </Typography.Text>
                  }
                />
              </Flex>
              <Timeline mode="left">{timelineCurrentInstallationsItems}</Timeline>
              <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
                Events that lead to the current installation status are marked green
              </Typography.Paragraph>
            </>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default DeviceInstallations;
