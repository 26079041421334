import { Target } from '../../../../api/engineering/domain/types';
import { useSharedState } from '../../../shared/hooks/useSharedState';
import { useSoftwareappTargets } from '../../../softwareApps/hooks/useSoftwareappTargets';
import { EntityAdministrationListLayout } from './EntityAdministrationListLayout';

type UsedType = Target;
const entityName = 'App Target';
const entityKey = 'entity-admin-app-target';
const idAttribute = 'idTarget';
const titleAttribute = 'name';
export const AppTargetsList = () => {
  const entities = useSoftwareappTargets();
  const [selected, setSelected] = useSharedState<UsedType>(entityKey);
  const creatingNew = selected !== null && entities.data?.find((e) => e[idAttribute] === (selected ? selected[idAttribute] : null)) === null;
  return (
    <>
      <EntityAdministrationListLayout
        isLoading={entities.isLoading}
        data={entities.data || []}
        idAttribute={idAttribute}
        titleAttribute={titleAttribute}
        onSelect={(val) => setSelected(val!)}
        selected={selected}
        onReset={() => setSelected({ idTarget: -1, name: '' })}
        entityName={entityName}
        creatingNew={creatingNew}
      />
    </>
  );
};
