import { Route, Routes, Navigate } from 'react-router-dom';
import { ProjectBundleView } from './ProjectBundleView';
import { ProjectAISForce } from './ProjectAISForce';
import { ProjectDeployments } from '../../deployments/components/ProjectDeployments';
import { SoftwareAppDetails } from '../../softwareApps/components/SoftwareAppDetails';
import { SoftwareAppVersions } from '../../softwareApps/components/SoftwareAppVersions';
import { ProjectsPortal } from './ProjectsPortal';
import { aisForceConfig } from '@p-apps/ais-force/config';
import { Report } from '../../reports/components/Report';
import DeploymentEnvironments from '../../../contexts/deployments/components/DeploymentEnvironments';
import CreateDeploymentPage from '../../../contexts/deployments/components/CreateDeploymentPage';

// TODO: permissions filter!
export const ProjectRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectsPortal />} />
      <Route path=":projectId/deployments/environments" element={<DeploymentEnvironments />} />
      <Route path=":projectId/deployments/create" element={<CreateDeploymentPage />} />
      <Route path=":projectId/deployments/:envId" element={<ProjectDeployments />} />
      <Route path=":projectId/:bundleName" element={<ProjectBundleView />} />
      <Route path={`:projectId/${aisForceConfig.route}/*`} element={<ProjectAISForce />} />
      <Route path=":projectid/apps/:id/details" element={<SoftwareAppDetails />} />
      <Route path=":projectid/apps/:id/versions" element={<SoftwareAppVersions />} />
      <Route path=":projectid/reports/:sourceid/:revision" element={<Report />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
