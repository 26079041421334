import { InfoMenu } from '../../../contexts/navigation/components/InfoMenu';
import { TopMenuTitle } from '../../../contexts/navigation/components/TopMenuTitle';
import { THEMES, useTheme } from '../../theme/ThemeProvider';
import { PacTSFooter } from './Footer';

export const InfoLayout = (props: {
  children: React.ReactElement;
  header?: boolean;
  support?: boolean;
  footer?: boolean;
  brandTheme?: THEMES;
  helpTheme?: THEMES;
}) => {
  const [theme] = useTheme();

  const actualHelpTheme = props.helpTheme ?? theme;

  return (
    <div>
      <div>{props.children}</div>
      {props.header && (
        <div style={{ position: 'absolute', top: 16, left: 32 }}>
          <TopMenuTitle />
        </div>
      )}
      {props.footer && (
        <div style={{ position: 'absolute', left: 0, bottom: 0, width: '100vw' }}>
          <PacTSFooter />
        </div>
      )}
      {props.support && (
        <div style={{ position: 'absolute', right: 32, top: 16 }}>
          <InfoMenu theme={actualHelpTheme} />
          <div />
        </div>
      )}
    </div>
  );
};
