import { useMemo } from 'react';
import { useSearchParameters } from './useSearchParameters';
import { useSetSearchParameter } from './useSetSearchParameter';

export const useSearchParameter = (
  parameterName: string,
  defaultValue?: string,
  shouldOverride: boolean = true,
  shouldReplace?: boolean
): [string | undefined, (value: string) => void] => {
  const parameter: Record<string, string> = useSearchParameters();
  const setParameter = useSetSearchParameter(shouldReplace);
  const setSearchParam = useMemo(() => {
    return (value: string) => {
      if (Object.keys(parameter).includes(parameterName) && !shouldOverride) {
        return;
      }

      setParameter(parameterName, value);
    };
  }, [parameterName, setParameter, parameter, shouldOverride]);
  const param = parameterName in parameter ? parameter[parameterName] : defaultValue;
  return [param, setSearchParam];
};
