import EventEmitter from 'eventemitter3';
import { useMemo } from 'react';
import {
  CommunicationApi,
  Configuration,
  ProjectsApi,
  RolesApi,
  SoftwareappsApi,
  ToolsApi,
  ProjectMemberAccessRequest as InfraProjectMemberAccessRequest,
  UploadApi
} from '@pacts/engineeringservice-api';
import { AxiosError, AxiosInstance, AxiosResponse, AxiosResponseHeaders } from 'axios';
import { EngineeringBackend, EngineeringBackendError } from '../service/engineeringBackend';
import type {
  Project,
  ProjectPhase,
  ProjectSolutionType,
  ProjectType,
  ProjectCreate,
  ProjectPhaseCreate,
  ProjectSolutionTypeCreate,
  ProjectTypeCreate,
  ProjectUpdate,
  Bundle,
  BundleConfiguration,
  BundleRelease,
  BundleReleaseLite,
  BundleConfigurationCreate,
  BundleReleaseCreate,
  SoftwareApp,
  SoftwareAppUsage,
  SoftwareAppVersion,
  SoftwareAppCategory,
  Target,
  SoftwareAppCategoryCreate,
  TargetCreate,
  SoftwareAppCreate,
  SoftwareAppVersionCreate,
  Tool,
  ToolUsage,
  ToolVersion,
  ToolCreate,
  ToolVersionCreate,
  ToolCategoryCreate,
  ToolCategory,
  ProjectMember,
  ProjectRoleWithPermissions,
  ProjectMemberChange,
  ProjectRoleCreate,
  ToolUpdate,
  SoftwareAppVersionUpdate,
  SoftwareAppUpdate,
  ProjectMemberAccessRequest,
  UploadToken,
  Download,
  DownloadMeta,
  ToolVersionVulnerability,
  SoftwareAppVersionVulnerability,
  ProjectTypeUpdate
} from '../domain/types';
import { useRestBackendConfig } from '../../shared/useBackendConfiguration';
import { SharedAxiosInstance } from '../../shared/sharedAxiosInstance';
import { GlobalState } from '../../../state/globalState';

class RestEngineeringBackend implements EngineeringBackend {
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly pApi: ProjectsApi;

  private readonly aApi: SoftwareappsApi;

  private readonly tApi: ToolsApi;

  private readonly rApi: RolesApi;

  private readonly cApi: CommunicationApi;

  private readonly uApi: UploadApi;

  constructor(
    public readonly config: Configuration,
    instance: AxiosInstance
  ) {
    this.pApi = new ProjectsApi(config, undefined, instance);
    this.aApi = new SoftwareappsApi(config, undefined, instance);
    this.tApi = new ToolsApi(config, undefined, instance);
    this.rApi = new RolesApi(config, undefined, instance);
    this.cApi = new CommunicationApi(config, undefined, instance);
    this.uApi = new UploadApi(config, undefined, instance);
  }

  getBundleConfigurationReleaseReport(
    idProject: number,
    idBundle: number,
    idConfiguration: number,
    idRelease: number,
    reportTitle?: boolean,
    columns?: Array<'softwareName' | 'softwareDescription' | 'softwareVersion' | 'softwareReleaseNotes' | 'softwareChecksum'>,
    sign?: boolean,
    sort?: 'ASC' | 'DESC',
    format?: 'xlsx' | 'pdf'
  ): Promise<{ streamUri: string; signature?: string }> {
    return new Promise((resolve, reject) => {
      this.pApi
        .getProjectBundleConfigurationReleaseReport(idProject, idBundle, idConfiguration, idRelease, reportTitle, columns, sign, sort, format)
        .then((res) => {
          const signature = res.headers['x-signature-ecdsa-sha-512'];
          return resolve({ streamUri: res.config.url!, signature });
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  onError(handler: (error: EngineeringBackendError) => any): void {
    this.emitter.on('error', handler);
  }

  heartbeat(): Promise<void> {
    return this.genericPromise(this.cApi.getHeartbeat());
  }

  queryProjects(): Promise<Project[]> {
    return this.genericPromise(this.pApi.getProjects());
  }

  queryProject(id: string): Promise<Project> {
    return this.genericPromise(this.pApi.getProject(parseInt(id, 10)));
  }

  queryProjectPhases(): Promise<ProjectPhase[]> {
    return this.genericPromise(this.pApi.getProjectPhases());
  }

  queryProjectSolutionTypes(): Promise<ProjectSolutionType[]> {
    return this.genericPromise(this.pApi.getProjectSolutionTypes());
  }

  queryProjectTypes(): Promise<ProjectType[]> {
    return this.genericPromise(this.pApi.getProjectTypes());
  }

  createProject(create: ProjectCreate): Promise<Project> {
    return this.genericPromise(this.pApi.addProject(create));
  }

  createProjectPhase(create: ProjectPhaseCreate): Promise<ProjectPhase> {
    return this.genericPromise(this.pApi.addProjectPhase(create));
  }

  createProjectSolutionType(create: ProjectSolutionTypeCreate): Promise<ProjectSolutionType> {
    return this.genericPromise(this.pApi.addProjectSolutionType(create));
  }

  createProjectType(create: ProjectTypeCreate): Promise<ProjectType> {
    return this.genericPromise(this.pApi.addProjectType(create));
  }

  updateProject(update: ProjectUpdate): Promise<Project> {
    return this.genericPromise(this.pApi.updateProject(update));
  }

  updateProjectPhase(update: ProjectPhase): Promise<ProjectPhase> {
    return this.genericPromise(this.pApi.updateProjectPhase(update));
  }

  updateProjectSolutionType(update: ProjectSolutionType): Promise<ProjectSolutionType> {
    return this.genericPromise(this.pApi.updateProjectSolutionType(update));
  }

  updateProjectType(update: ProjectTypeUpdate): Promise<ProjectType> {
    return this.genericPromise(this.pApi.updateProjectType(update.idProjectType, update));
  }

  deleteProject(id: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProject(parseInt(id, 10)));
  }

  deleteProjectPhase(id: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectPhase(parseInt(id, 10)));
  }

  deleteProjectSolutionType(id: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectSolutionType(parseInt(id, 10)));
  }

  deleteProjectType(id: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectType(parseInt(id, 10)));
  }

  queryBundle(projectId: string, bundleId: string): Promise<Bundle> {
    return this.genericPromise(this.pApi.getProjectBundle(parseInt(projectId, 10), parseInt(bundleId, 10)));
  }

  queryBundles(projectId: string): Promise<Bundle[]> {
    return this.genericPromise(this.pApi.getProjectBundles(parseInt(projectId, 10)));
  }

  queryBundleConfigurations(projectId: string, bundleId: string): Promise<BundleConfiguration[]> {
    return this.genericPromise(this.pApi.getProjectBundleConfigurations(parseInt(projectId, 10), parseInt(bundleId, 10)));
  }

  queryBundleConfiguration(projectId: string, bundleId: string, configurationId: string): Promise<BundleConfiguration> {
    return this.genericPromise(this.pApi.getProjectBundleConfiguration(parseInt(projectId, 10), parseInt(bundleId, 10), parseInt(configurationId, 10)));
  }

  queryBundleConfigurationVersion(
    projectId: string,
    bundleId: string,
    configurationId: string,
    versionId: string,
    shouldTriggerError = true
  ): Promise<BundleRelease> {
    return this.genericPromise(
      this.pApi.getProjectBundleConfigurationRelease(parseInt(projectId, 10), parseInt(bundleId, 10), parseInt(configurationId, 10), parseInt(versionId, 10)),
      shouldTriggerError
    );
  }

  queryBundleConfigurationVersions(projectId: string, bundleId: string, configurationId: string): Promise<BundleReleaseLite[]> {
    return this.genericPromise(this.pApi.getProjectBundleConfigurationReleases(parseInt(projectId, 10), parseInt(bundleId, 10), parseInt(configurationId, 10)));
  }

  createBundleConfiguration(projectId: string, bundleId: string, create: BundleConfigurationCreate): Promise<BundleConfiguration> {
    return this.genericPromise(this.pApi.addProjectBundleConfiguration(parseInt(projectId, 10), parseInt(bundleId, 10), create));
  }

  createBundleConfigurationVersion(projectId: string, bundleId: string, configurationId: string, create: BundleReleaseCreate): Promise<BundleRelease> {
    return this.genericPromise(
      this.pApi.addProjectBundleConfigurationRelease(parseInt(projectId, 10), parseInt(bundleId, 10), parseInt(configurationId, 10), create)
    );
  }

  updateBundleConfiguration(projectId: string, bundleId: string, configuration: BundleConfiguration): Promise<BundleConfiguration> {
    return this.genericPromise(this.pApi.updateProjectBundleConfiguration(parseInt(projectId, 10), parseInt(bundleId, 10), configuration));
  }

  deleteBundle(projectId: number, bundleId: number): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectBundle(projectId, bundleId));
  }

  deleteBundleConfiguration(projectId: string, bundleId: string, configurationId: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectBundleConfiguration(parseInt(projectId, 10), parseInt(bundleId, 10), parseInt(configurationId, 10)));
  }

  deleteBundleConfigurationVersion(projectId: string, bundleId: string, configurationId: string, versionid: string): Promise<void> {
    return this.genericPromise(
      this.pApi.deleteProjectBundleConfigurationRelease(parseInt(projectId, 10), parseInt(bundleId, 10), parseInt(configurationId, 10), parseInt(versionid, 10))
    );
  }

  queryCommonApp(id: string): Promise<SoftwareApp> {
    return this.genericPromise(this.aApi.getCommonSoftwareApp(parseInt(id, 10)));
  }

  queryCommonApps(): Promise<SoftwareApp[]> {
    return this.genericPromise(this.aApi.getCommonSoftwareApps());
  }

  queryCommonAppUsage(id: string): Promise<SoftwareAppUsage[]> {
    return this.genericPromise(this.aApi.getCommonSoftwareAppUsage(parseInt(id, 10)));
  }

  queryCommonAppVersion(id: number, versionId: number): Promise<SoftwareAppVersion> {
    return this.genericPromise(this.aApi.getCommonSoftwareAppVersion(id, versionId));
  }

  queryCommonAppVersions(id: string): Promise<SoftwareAppVersion[]> {
    return this.genericPromise(this.aApi.getCommonSoftwareAppVersions(parseInt(id, 10)));
  }

  queryCommonAppVersionVulnerabilities(appId: number, appVersionId: number): Promise<SoftwareAppVersionVulnerability[]> {
    return this.genericPromise(this.aApi.getCommonSoftwareAppVersionVulnerabilities(appId, appVersionId), false);
  }

  downloadCommonAppVersionTarget(appId: number, versionId: number, targetId: number): Promise<Download> {
    return new Promise((resolve, reject) => {
      this.aApi
        .getCommonSoftwareAppVersionTargetDownload(appId, versionId, targetId)
        .then((r) => {
          return resolve({
            ...this.extractDownloadHeaders(r.headers),
            url: r.data.url
          });
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryProjectApp(projectId: string, appId: string): Promise<SoftwareApp> {
    return this.genericPromise(this.pApi.getProjectSoftwareApp(parseInt(appId, 10), parseInt(projectId, 10)));
  }

  queryProjectApps(projectId: string): Promise<SoftwareApp[]> {
    return this.genericPromise(this.pApi.getProjectSoftwareApps(parseInt(projectId, 10)));
  }

  queryProjectAppVersion(projectId: number, appId: number, versionId: number): Promise<SoftwareAppVersion> {
    return this.genericPromise(this.pApi.getProjectSoftwareAppVersion(appId, versionId, projectId));
  }

  queryProjectAppVersions(projectId: string, appId: string): Promise<SoftwareAppVersion[]> {
    return this.genericPromise(this.pApi.getProjectSoftwareAppVersions(parseInt(appId, 10), parseInt(projectId, 10)));
  }

  queryProjectAppVersionVulnerabilities(projectId: number, appId: number, appVersionId: number): Promise<SoftwareAppVersionVulnerability[]> {
    return this.genericPromise(this.pApi.getProjectSoftwareAppVersionVulnerabilities(projectId, appId, appVersionId), false);
  }

  downloadProjectAppVersionTarget(projectId: number, appId: number, versionId: number, targetId: number): Promise<Download> {
    return new Promise((resolve, reject) => {
      this.pApi
        .getProjectSoftwareAppVersionTargetDownload(projectId, appId, versionId, targetId)
        .then((r) => {
          return resolve({
            ...this.extractDownloadHeaders(r.headers),
            url: r.data.url
          });
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryAppCategories(): Promise<SoftwareAppCategory[]> {
    return this.genericPromise(this.aApi.getSoftwareAppCategories());
  }

  queryAppTargets(): Promise<Target[]> {
    return this.genericPromise(this.aApi.getSoftwareAppTargets());
  }

  createAppCategory(create: SoftwareAppCategoryCreate): Promise<SoftwareAppCategory> {
    return this.genericPromise(this.aApi.addSoftwareAppCategory(create));
  }

  createAppTarget(create: TargetCreate): Promise<Target> {
    return this.genericPromise(this.aApi.addSoftwareAppTarget(create));
  }

  createCommonApp(create: SoftwareAppCreate): Promise<SoftwareApp> {
    return this.genericPromise(this.aApi.addCommonSoftwareApp(create));
  }

  createCommonAppVersion(appId: string, create: SoftwareAppVersionCreate): Promise<SoftwareAppVersion> {
    return this.genericPromise(this.aApi.addCommonSoftwareAppVersion(parseInt(appId, 10), create));
  }

  createProjectApp(projectId: string, create: SoftwareAppCreate): Promise<SoftwareApp> {
    return this.genericPromise(this.pApi.addProjectSoftwareApp(parseInt(projectId, 10), create));
  }

  createProjectSoftwareAppVersion(projectId: string, appId: string, create: SoftwareAppVersionCreate): Promise<SoftwareAppVersion> {
    return this.genericPromise(this.pApi.addProjectSoftwareAppVersion(parseInt(appId, 10), parseInt(projectId, 10), create));
  }

  updateAppCategory(update: SoftwareAppCategory): Promise<SoftwareAppCategory> {
    return this.genericPromise(this.aApi.updateSoftwareAppCategory(update));
  }

  updateAppTarget(update: Target): Promise<Target> {
    return this.genericPromise(this.aApi.updateSoftwareAppTarget(update));
  }

  updateCommonApp(update: SoftwareAppUpdate): Promise<SoftwareApp> {
    return this.genericPromise(this.aApi.updateCommonSoftwareApp(update));
  }

  updateCommonAppVersion(appId: string, update: SoftwareAppVersionUpdate): Promise<SoftwareAppVersion> {
    return this.genericPromise(this.aApi.updateCommonSoftwareAppVersion(parseInt(appId, 10), update));
  }

  updateProjectApp(projectId: string, update: SoftwareAppUpdate): Promise<SoftwareApp> {
    return this.genericPromise(this.pApi.updateProjectSoftwareApp(parseInt(projectId, 10), update));
  }

  updateProjectAppVersion(projectId: string, appId: string, update: SoftwareAppVersionUpdate): Promise<SoftwareAppVersion> {
    return this.genericPromise(this.pApi.updateProjectSoftwareAppVersion(parseInt(appId, 10), parseInt(projectId, 10), update));
  }

  deleteAppCategory(id: string): Promise<void> {
    return this.genericPromise(this.aApi.deleteSoftwareAppCategory(parseInt(id, 10)));
  }

  deleteAppTarget(id: string): Promise<void> {
    return this.genericPromise(this.aApi.deleteSoftwareAppTarget(parseInt(id, 10)));
  }

  deleteCommonSoftwareApp(id: string): Promise<void> {
    return this.genericPromise(this.aApi.deleteCommonSoftwareApp(parseInt(id, 10)));
  }

  deleteCommonSoftwareAppVersion(appId: string, versionId: string): Promise<void> {
    return this.genericPromise(this.aApi.deleteCommonSoftwareAppVersion(parseInt(appId, 10), parseInt(versionId, 10)));
  }

  deleteProjectSoftwareApp(projectId: string, appId: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectSoftwareApp(parseInt(appId, 10), parseInt(projectId, 10)));
  }

  deleteProjectSoftwareAppVersion(projectId: string, appId: string, versionId: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectSoftwareAppVersion(parseInt(appId, 10), parseInt(versionId, 10), parseInt(projectId, 10)));
  }

  queryTool(id: string): Promise<Tool> {
    return this.genericPromise(this.tApi.getTool(parseInt(id, 10)));
  }

  queryTools(): Promise<Tool[]> {
    return this.genericPromise(this.tApi.getTools());
  }

  queryToolUsage(id: string): Promise<ToolUsage[]> {
    return this.genericPromise(this.tApi.getToolUsage(parseInt(id, 10)));
  }

  queryToolVersion(id: number, versionId: number): Promise<ToolVersion> {
    return this.genericPromise(this.tApi.getToolVersion(id, versionId));
  }

  queryToolVersions(id: string): Promise<ToolVersion[]> {
    return this.genericPromise(this.tApi.getToolVersions(parseInt(id, 10)));
  }

  downloadToolVersion(toolId: number, versionId: number): Promise<Download> {
    return new Promise((resolve, reject) => {
      this.tApi
        .getToolVersionDownload(toolId, versionId)
        .then((r) => {
          return resolve({
            ...this.extractDownloadHeaders(r.headers),
            url: r.data.url
          });
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryToolVersionVulnerabilities(id: number, versionId: number): Promise<ToolVersionVulnerability> {
    return this.genericPromise(this.tApi.getToolVersionVulnerabilities(id, versionId), false);
  }

  queryToolCategories(): Promise<ToolCategory[]> {
    return this.genericPromise(this.tApi.getToolCategories());
  }

  createTool(create: ToolCreate): Promise<Tool> {
    return this.genericPromise(this.tApi.addTool(create));
  }

  createToolVersion(toolId: string, create: ToolVersionCreate): Promise<ToolVersion> {
    return this.genericPromise(this.tApi.addToolVersion(parseInt(toolId, 10), create));
  }

  createToolCategory(create: ToolCategoryCreate): Promise<ToolCategory> {
    return this.genericPromise(this.tApi.addToolCategory(create));
  }

  updateTool(update: ToolUpdate): Promise<Tool> {
    return this.genericPromise(this.tApi.updateTool(update));
  }

  updateToolVersion(toolId: string, update: ToolVersion): Promise<ToolVersion> {
    return this.genericPromise(this.tApi.updateToolVersion(parseInt(toolId, 10), update));
  }

  updateToolCategory(update: ToolCategory): Promise<ToolCategory> {
    return this.genericPromise(this.tApi.updateToolCategory(update));
  }

  deleteTool(toolId: string): Promise<void> {
    return this.genericPromise(this.tApi.deleteTool(parseInt(toolId, 10)));
  }

  deleteToolVersion(toolId: string, versionId: string): Promise<void> {
    return this.genericPromise(this.tApi.deleteToolVersion(parseInt(toolId, 10), parseInt(versionId, 10)));
  }

  deleteToolCategory(id: string): Promise<void> {
    return this.genericPromise(this.tApi.deleteToolCategory(parseInt(id, 10)));
  }

  queryProjectMembers(id: string): Promise<ProjectMember[]> {
    return this.genericPromise(this.pApi.getProjectMembers(parseInt(id, 10)));
  }

  queryProjectRoles(): Promise<ProjectRoleWithPermissions[]> {
    return this.genericPromise(this.rApi.getProjectRoles());
  }

  assignProjectMember(projectId: string, member: ProjectMemberChange): Promise<ProjectMember> {
    return this.genericPromise(this.pApi.addProjectMember(parseInt(projectId, 10), member));
  }

  unassignProjectMember(projectId: string, memberId: string): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectMember(parseInt(projectId, 10), parseInt(memberId, 10)));
  }

  createProjectRole(create: ProjectRoleCreate): Promise<ProjectRoleWithPermissions> {
    return this.genericPromise(this.rApi.addProjectRole(create));
  }

  updateProjectMember(projectId: string, member: ProjectMemberChange): Promise<ProjectMember> {
    return this.genericPromise(this.pApi.updateProjectMember(parseInt(projectId, 10), member));
  }

  updateProjectRole(update: ProjectRoleWithPermissions): Promise<ProjectRoleWithPermissions> {
    return this.genericPromise(
      this.rApi.updateProjectRole({
        ...update,
        createdBy: update.createdBy ?? 'unknown',
        createdAt: update.createdAt ?? new Date().toString(),
        updatedBy: update.updatedBy ?? 'unknown',
        updatedAt: update.updatedAt ?? new Date().toString()
      })
    );
  }

  deleteProjectRole(id: string): Promise<void> {
    return this.genericPromise(this.rApi.deleteProjectRole(parseInt(id, 10)));
  }

  private mapProjectMemberAccessRequest(r: InfraProjectMemberAccessRequest): ProjectMemberAccessRequest {
    const ret: ProjectMemberAccessRequest = {
      creation: new Date(r.creation),
      expiry: new Date(r.expiry),
      email: r.email,
      idMember: r.idMember,
      idProject: r.idProject,
      name: r.name
    };
    return ret;
  }

  getProjectAccessRequest(projectId: number): Promise<ProjectMemberAccessRequest | undefined> {
    return new Promise((resolve, reject) => {
      this.pApi
        .getProjectMemberAccessRequest(projectId)
        .then((r) => resolve(this.mapProjectMemberAccessRequest(r.data)))
        .catch((err) => {
          if ((err as AxiosError).response?.status === 404) {
            return resolve(undefined);
          }
          this.errorHandler(reject)(err).bind(this);
        });
    });
  }

  addProjectAccessRequest(projectId: number): Promise<ProjectMemberAccessRequest> {
    return new Promise((resolve, reject) => {
      this.pApi
        .addProjectMemberAccessRequest(projectId)
        .then((r) => resolve(this.mapProjectMemberAccessRequest(r.data)))
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  deleteProjectAccessRequest(projectId: number, memberId: number): Promise<void> {
    return this.genericPromise(this.pApi.deleteProjectMemberAccessRequest(projectId, memberId));
  }

  getProjectAccessRequests(projectId: number): Promise<ProjectMemberAccessRequest[]> {
    return new Promise((resolve, reject) => {
      this.pApi
        .getProjectMemberAccessRequests(projectId)
        .then((r) => resolve(r.data.map(this.mapProjectMemberAccessRequest)))
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  getTemporaryBlobUploadToken(contentType: string, fileName: string): Promise<UploadToken> {
    return this.genericPromise(this.uApi.getPresignedUploadUrl(contentType, fileName));
  }

  private errorHandler(rejector: (error: EngineeringBackendError) => any, emitError: boolean = true): (error: AxiosError) => any {
    return (error: AxiosError) => {
      const resData = error.response?.data as { message: string; details: { field: string; info: string; value: string }[] | undefined } | undefined | null;
      const resMessage = resData?.message ?? error.message;
      const resDetails: { path: string; message: string; value: string }[] =
        resData?.details?.map((d) => {
          return { message: d.info, path: d.field, value: d.value };
        }) || [];
      const err = new EngineeringBackendError(resMessage, error.response?.status || 999, resDetails);

      if (emitError) {
        this.emitter.emit('error', err);
      }

      rejector(err);
    };
  }

  private genericPromise<T>(promise: Promise<AxiosResponse<T>>, emitError = true) {
    return new Promise<T>((rs, rj) => {
      promise.then((r) => rs(r.data)).catch(this.errorHandler(rj, emitError).bind(this));
    });
  }

  private extractDownloadHeaders(headers: AxiosResponseHeaders): DownloadMeta {
    const pactsSha256 = headers['x-checksum-sha256'];
    const artifactorySha256 = headers['x-checksum-sha256-external'];
    const contentLength = parseInt(headers['x-url-content-length'] ?? 0);

    return { pactsSha256, artifactorySha256, contentLength };
  }
}

export const useRestEngineeringBackend = (state: GlobalState) => {
  const config = useRestBackendConfig(state.engineeringServicePath);
  const backend = useMemo(() => new RestEngineeringBackend(new Configuration(config), SharedAxiosInstance.instance()), [config]);

  return backend;
};
