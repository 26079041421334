import React, { useContext } from 'react';

import { useWebsocketNotificationsBackendProvider } from './notifications/hooks/useWebsocketNotificationsBackendProvider';
import { useWebsocketPactsFormationBackendProvider } from './pactsformation/hooks/useWebsocketPactsFormationBackendProvider';
import { useRestEngineeringBackendProvider } from './engineering/hooks/useRestEngineeringBackendProvider';
import { useRestUserBackendProvider } from './user/hooks/useRestUserBackendProvider';
import { useRestAuthBackendProvider } from './auth/hooks/useRestAuthBackendProvider';
import { useRestDeploymentBackendProvider } from './deployment/hooks/useRestDeploymentBackendProvider';
import { useRestReportBackendProvider } from './report/hooks/useRestReportBackendProvider';
import { useRestMetricsBackendProvider } from './metrics/hooks/useRestMetricsBackendProvider';
import { PacTSContext } from '../state/store';

export const BackendProvider = (props: { children: React.ReactNode }) => {
  const [ctx] = useContext(PacTSContext);

  // Initialize auth backend first because it is used by other backends to obtain refreshed token
  useRestAuthBackendProvider(ctx);

  useWebsocketNotificationsBackendProvider();
  useWebsocketPactsFormationBackendProvider();
  useRestEngineeringBackendProvider(ctx);
  useRestUserBackendProvider(ctx);
  useRestDeploymentBackendProvider(ctx);
  useRestReportBackendProvider(ctx);
  useRestMetricsBackendProvider(ctx);

  return <>{props.children}</>;
};
