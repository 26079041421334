import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Target, TargetCreate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useCreateAppTarget() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<Target, any, [TargetCreate]>(([ent]) => {
    return new Promise<Target>((resolve, reject) => {
      backend
        .createAppTarget(ent)
        .then((dev) => {
          presentSuccess(`Successfully created App Target ${ent.name}`);
          queryClient.invalidateQueries(['softwareappTargets']);
          resolve(dev);
        })
        .catch(reject);
    });
  });
}
