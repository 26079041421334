import { useForm } from 'antd/lib/form/Form';
import { useSearchParameters } from '../../../contexts/navigation/hooks/useSearchParameters';
import { useCreateDeploymentPlan } from '../hooks/useCreateDeploymentPlan';
import { CreateDeploymentPlanForm } from './DeploymentPlanForm';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { SoftwareComponent, SoftwareComponentDeployment } from '../../../api';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';
import { BackToProjectButton } from '../../navigation/components/BackToProjectButton';
import { useProject } from '../../../contexts/projects/hooks/useProject';
import styled from 'styled-components';
import { useDeploymentEnvironments } from '../hooks/useDeploymentEnvironments';
import { groupBy } from 'lodash';

const FullHeightCard = styled(Card)`
  min-height: 75vh;

  & .ant-card-body {
    min-height: 75vh;
  }
`;

const CreateDeploymentPage = () => {
  const navigate = useInAppNavigate();
  const params = useParams();
  const projectId = parseInt(params.projectId ?? '0');
  const envs = useDeploymentEnvironments(projectId);
  const searchParams = useSearchParameters();
  const project = useProject(projectId.toString());
  const createDeploymentPlan = useCreateDeploymentPlan();
  const [form] = useForm();
  const isLoading = envs.isLoading || project.isLoading || !envs.data;

  return (
    <CenteredLayout fixed title={`${project.data?.name || ''}: New deployment`} extra={[<BackToProjectButton key="btp" projectId={projectId.toString()} />]}>
      <FullHeightCard loading={isLoading}>
        <CreateDeploymentPlanForm
          envs={envs}
          bundleConfigVersionId={parseInt(searchParams.bundleConfigVersionId ?? '0', 10)}
          bundleConfigId={parseInt(searchParams.bundleConfigId ?? '0', 10)}
          bundleId={parseInt(searchParams.bundleId ?? '0', 10)}
          projectId={projectId}
          form={form}
          isCreateButtonLoading={createDeploymentPlan.isLoading}
          onFinish={async (ps) => {
            const envId = ps.envId;
            const allDeployments = ps.deployments
              .map((d) => {
                return d.device.map((device) => {
                  // TODO: infer scope and type
                  console.warn('assuming scope and type for software component');
                  const swComponent: SoftwareComponent = {
                    id: d.selection.app.idSoftwareApp,
                    versionId: Number(d.selection.versionId),
                    name: d.selection.app.name,
                    version: d.selection.version.version,
                    scope: d.selection.app.scope,
                    type: 'app'
                  };
                  const deployment: SoftwareComponentDeployment = {
                    device,
                    components: [swComponent]
                  };
                  return deployment;
                });
              })
              .flat();
            const deviceGroupedDeployments = groupBy(allDeployments, (d) => d.device.id);
            const deployments = Object.entries(deviceGroupedDeployments).map((k) => {
              const deployment: SoftwareComponentDeployment = {
                device: k[1][0].device,
                components: k[1].map((d) => d.components).flat()
              };
              return deployment;
            });
            createDeploymentPlan
              .mutateAsync([
                {
                  name: ps.name,
                  description: ps.description,
                  deployments,
                  release: {
                    bundleId: Number(searchParams.bundleId),
                    configId: Number(searchParams.bundleConfigId),
                    releaseId: Number(searchParams.bundleConfigVersionId)
                  }
                },
                projectId,
                envId
              ])
              .then((res: any) => {
                if (res) {
                  form.resetFields();
                  navigate(`/projects/${projectId}/deployments/${envId}`);
                }
              })
              .catch(() => {
                /* */
              });
          }}
        />
      </FullHeightCard>
    </CenteredLayout>
  );
};

export default CreateDeploymentPage;
