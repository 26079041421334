import { List, Avatar, Skeleton, Statistic, Space, Divider, Row, Col, Switch, Card } from 'antd';
import { useMemo, useState } from 'react';
import { Project } from '../../../api';
import { Comparator } from '../../../domain/extensions/comparison';
import { useProjectRoles } from '../../administration/hooks/useProjectRoles';
import { usePermissions } from '../../session/hooks/usePermissions';
import { PrimaryHeader } from '../../shared/components/PrimaryHeader';
import { useProjectMembers } from '../hooks/useProjectMembers';
import { ProjectAccessRequests } from './ProjectAccessRequests';
import styled from 'styled-components';
  
const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colorPrimary};
`;

type ProjectMemberListProps = {
  project: Project;
};

export const ProjectMemberList = (props: ProjectMemberListProps) => {
  const projectMembers = useProjectMembers(props.project.idProject.toString());
  const roles = useProjectRoles();
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });

  const loading = projectMembers.isLoading || roles.isLoading;
  const success = projectMembers.isSuccess && roles.isSuccess;

  const [showMembers, setShowMembers] = useState(false);

  const sortedProjectMembers = (projectMembers.data || []).sort((a, b) => {
    return Comparator.lexicographicalComparison(a.member.name.toLowerCase(), b.member.name.toLowerCase());
  });

  const statistics = useMemo(() => {
    if (success) {
      const stats = [<Statistic key="memberStatistic" title="Project Members" value={projectMembers.data?.length || 0} />];
      roles.data.forEach((r) => {
        const membersWithRole = (projectMembers?.data || []).filter((m) => (m.roles || []).find((role) => r.idProjectRole === role.idProjectRole));
        stats.push(<Statistic key={`roleStatistic-${r.idProjectRole}`} title={r.name} value={membersWithRole.length} />);
      });
      return stats;
    }
    return [];
  }, [projectMembers.data, roles.data, success]);

  return (
    <Card
      title={<PrimaryHeader>Project Members</PrimaryHeader>}
      extra={[
        <Space key="showMembers" style={{ float: 'right' }}>
          Show Members
          <Switch checked={showMembers} onChange={setShowMembers} />
        </Space>
      ]}
    >
      {loading && <Skeleton active />}
      {success && (
        <div>
          {permissions.engineeringSvc$addProjectMember && (
            <>
              <ProjectAccessRequests project={props.project} />
              <Divider />
            </>
          )}
          <Row justify="start">
            <Col>
              <Space size="large" align="center" split={<Divider type="vertical" />}>
                {statistics}
              </Space>
            </Col>
          </Row>
          {showMembers && (
            <>
              <Divider />
              <List
                dataSource={sortedProjectMembers}
                grid={{ gutter: 16, column: 4 }}
                loading={projectMembers.isLoading}
                renderItem={(pm) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<StyledAvatar>{pm.member.name[0]}</StyledAvatar>}
                      title={pm.member.name}
                      description={pm.roles
                        ?.map((r) => r.name)
                        .sort()
                        .join(', ')}
                    />
                  </List.Item>
                )}
              />
            </>
          )}
        </div>
      )}
    </Card>
  );
};
