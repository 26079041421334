import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { DeviceDelete } from '../../../api/deployment/domain/deployments';

export function useDeleteDevice() {
  const { backend } = useDeploymentBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [DeviceDelete, number, string]>(([device, projectId, envId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteDevice(projectId, envId, device.id, device)
        .then(() => {
          queryClient.invalidateQueries(['device', projectId, envId]);
          presentSuccess(`Successfully deleted Device ${device.rds}`);
          return resolve();
        })
        .catch(reject);
    });
  });
}
