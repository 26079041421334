import { VersionPart } from './versionPart';

export class VersionAdditionalString implements VersionPart {
  private readonly internalValue: string;

  public constructor(s: string) {
    this.internalValue = s;
  }

  equals(part: VersionPart): boolean {
    if (part instanceof VersionAdditionalString) {
      return this.toString().toLowerCase() === part.toString().toLowerCase();
    }
    return false;
  }

  compareTo(part: VersionPart): number {
    if (part instanceof VersionAdditionalString) {
      const a = this.toString().toLowerCase();
      const b = part.toString().toLowerCase();
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }
    return 1;
  }

  public toString(): string {
    return this.internalValue;
  }
}
