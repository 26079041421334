import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectSolutionType } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteProjectSolutionType() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [ProjectSolutionType]>(([pst]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteProjectSolutionType(pst.id.toString())
        .then(() => {
          presentSuccess(`Successfully deleted Project Solution Type ${pst.name}`);
          queryClient.invalidateQueries(['projectSolutionTypes']);
          resolve();
        })
        .catch(reject);
    });
  });
}
