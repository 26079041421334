import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tool, ToolUpdate } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUpdateEngineeringTool() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<Tool, any, [ToolUpdate]>(([tool]) => {
    return new Promise<Tool>((resolve, reject) => {
      backend
        .updateTool(tool)
        .then((updatedTool) => {
          queryClient.invalidateQueries(['engineeringTools']);
          queryClient.invalidateQueries(['engineeringTool', updatedTool.id?.toString()]);
          presentSuccess(`Successfully updated Engineering Tool ${tool.name}`);
          return resolve(updatedTool);
        })
        .catch(reject);
    });
  });
}
