import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNotificationsBackend } from '../../../api/notifications/hooks/useNotificationsBackend';
import { usePermissions } from '../../session/hooks/usePermissions';;

const queryKey = ['notifications', 'heartbeat'];

export const useNotificationHeartbeat = () => {
  const { backend, state } = useNotificationsBackend();
  const permissions = usePermissions();
  return useQuery<any>(
    queryKey,
    (): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        backend
          .ping({ timeout: 2000 })
          .then(() => resolve(true))
          .catch((err) => {
            backend.reconnect();
            reject(err);
          });
      });
    },
    {
      enabled: state.isReady && permissions.notificationSvc$ping,
      retry: 2
    }
  );
};

export const useInvalidateNotificationHeartbeat = () => {
  const queryClient = useQueryClient();
  const invalidate = useMemo(() => {
    return () => {
      queryClient.invalidateQueries(queryKey);
    };
  }, [queryClient]);
  return invalidate;
};
